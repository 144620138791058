import React from 'react';
import PropTypes from 'prop-types';
import { axisLeft } from 'd3-axis';
import { select } from 'd3-selection';

class Grid extends React.Component {
  componentDidMount() {
    this.renderAxis();
  }
  componentDidUpdate() {
    this.renderAxis();
  }
  renderAxis() {
    const { node } = this;
    const {
      scale, name, tickSize
    } = this.props;

    let axis;
    if (name === 'y-grid') {
      axis = axisLeft(scale).tickSize(-tickSize)
    }
    select(node)
      .call(axis);
  }

  render() {
    return (
      <g
        className={`grid ${this.props.name}`}
        ref={node => {
          this.node = node;
        }}
        transform={this.props.translate}
      >
      </g>
    );
  }
}

Grid.propTypes = {
  scale: PropTypes.func,
  orient: PropTypes.string,
  translate: PropTypes.string,
};

export default Grid;
