import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as projectAction from '../../../actions/project/index';
import { scaleThreshold } from 'd3-scale';
import { select } from 'd3-selection';
import iso3Toiso2 from '../../../helpers/iso3toiso2';
import iso2Country from '../../../helpers/iso2-countries-code';
import numberFormatter from '../../../utils/numberFormatter';
import {countries} from '../../../helpers/helper'

// convert iso3 id to respective country name
const getCountryName = id => {
  const iso2Code = iso3Toiso2[id];
  return iso2Country[iso2Code];
};

/**
 * 
 * @param {iso3code} id 
 * Returns iso2code from iso3code sent in argument id
 */
const getIso2code = id => {
  return iso3Toiso2[id];
};

// let debounceCountryName;

class CountriesLayer extends React.Component {
  constructor() {
    super();
    this.state = {
      colorScale: scaleThreshold().range([
        '#8CC1D9',
        '#67A9CF',
        '#3690BF',
        '#1D8A76',
        '#006C59',
        '#035945'
      ])
    };
    this.id = null;
  }
  componentWillReceiveProps(nextProps) {
    const { mapData } = nextProps;
    const maxPayment = mapData && mapData.length ? mapData[0].totalPayment : 0;
    const oneSixthPayment = Math.floor(maxPayment / 6);

    const domain = [
      oneSixthPayment,
      2 * oneSixthPayment,
      3 * oneSixthPayment,
      4 * oneSixthPayment,
      5 * oneSixthPayment
    ];

    this.setState(({ colorScale }) => ({
      colorScale: colorScale.domain(domain)
    }));
  }
  render() {
    const { zoomTransform, k } = this.props;
    return (
      <g
        transform={zoomTransform}
        className="countries-layer"
        ref={node => (this.node = node)}
      >
        {this.props.countriesGeojson.features.map((d, i) => {
          return (
            <path
              style={{ strokeWidth: 0.5 / k }}
              className="countries-path"
              id={d.id}
              key={`key-${i}`}
              d={this.props.path(d)}
              fill={this.getcountriesColor(d)}
              // onMouseEnter={e => this.onMouseEnter(e, d)}
              onClick={() => this.onClick(d)}
              onMouseMove={e => this.onMouseMove(e, d)}
              onMouseOut={e => this.onMouseOut(e)}
            />
          );
        })}
      </g>
    );
  }
  getcountriesColor(d) {
    const { id } = d;
    const { colorScale } = this.state;
    const { mapData } = this.props;
    const country = getCountryName(id);
    if (mapData.length === 0) return '#EFEFEF';

    const payment = mapData.find(obj => obj.country === country);

    if (country && payment) {
      return colorScale(payment.totalPayment);
    }
    return '#EFEFEF';
  }
  onClick(d) {
    const { mapData } = this.props;
    // const country = getCountryName(d.id);
    const country = getIso2code(d.id)
    const matchedData = mapData.find(d => d.countryCode === country);

    // const matchedData = mapData.find(d => d.country === country);

    if (matchedData) {
      this.props.history.push(`/country/${country}`);
      this.props.dispatchMapTooltipData({
        shouldShowTooltip:false
      });
      // window.location.href = `/country/${country}`;
    }
  }
  // onMouseEnter(e, d) {
  //   const path = e.target;
  //   select(path).attr('class', 'countries-path active');
  //   select(path).attr('cursor', 'pointer');
  // }
  onMouseOut(e) {
    select(e.target).attr('class', 'countries-path');
    this.props.dispatchMapTooltipData({
      shouldShowTooltip: false
    });
  }
  onMouseMove(e, d) {
    const { mapData } = this.props;
    const country = getCountryName(d.id);
    let amount;

    const matchedData = mapData.find(d => d.country === country);

    if (matchedData) {
      amount = numberFormatter(matchedData.totalPayment);
      const path = e.target;
      select(path).attr('class', 'countries-path active');
      select(path).attr('cursor', 'pointer');
    } else {
      amount = null;
      const path = e.target;
      select(path).attr('class', 'countries-path active');
    }

    let { x, y } = document
      .getElementById('company-details')
      .getBoundingClientRect();
    x = e.clientX - x;
    y = e.clientY - y;

    this.props.dispatchMapTooltipData({
      shouldShowTooltip: true,
      tooltipX: x,
      tooltipY: y,
      amount,
      country
    });
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchMapTooltipData: mapTooltipData => {
      dispatch(projectAction.mapTooltipData(mapTooltipData));
    }
  };
};

export default withRouter(connect(
  null,
  mapDispatchToProps
)(CountriesLayer));
