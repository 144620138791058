import React from 'react';
import PropTypes from 'prop-types';
import Axis from './axis';

const XYAxis = props => {
  const { scales, dimensions } = props;

  const xSettings = {
    scale: scales.x,
    name: 'x-axis',
    translate: `translate(0, ${dimensions.height})`
  };

  const ySettings = {
    scale: scales.y,
    name: 'y-axis',
    translate: 'translate(0, 0)'
  };

  return (
    <g className="axis-group">
      <Axis {...xSettings} />
      <Axis {...ySettings} />
    </g>
  );
};

XYAxis.propTypes = {
    scales: PropTypes.object,
    dimensions: PropTypes.object,
};

export default XYAxis;
