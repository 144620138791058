import React from 'react';
import { connect } from 'react-redux';
class Tooltip extends React.Component {
  render() {
    const { mapTooltipData } = this.props;

    const {
      shouldShowTooltip,
      tooltipX,
      tooltipY,
      amount,
      country
    } = mapTooltipData;

    // if (!amount) return null;

    if (!shouldShowTooltip) return null;

    const style = {
      position: 'absolute',
      left: tooltipX - 50,
      top: tooltipY + 20,
      display: 'block',
      zIndex: '99999'
    };

    return (
      <div style={style} className="tooltip">
        <div>
          <table className="table">
            <tbody>
              <tr>
                <th>Country</th>
                <th className="title">{country}</th>
              </tr>
              <tr>
                <td>Amount</td>
                {isNaN(parseInt(amount)) ? (
                  <td>n/a</td>
                ) : (
                  <td>USD {amount} </td>
                )}
              </tr>
            </tbody>
          </table>
          {isNaN(parseInt(amount)) ? null : (
            <span>Click to view profile for this country.</span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    mapTooltipData: state.project.mapTooltipData
  };
};
export default connect(mapStateToProps)(Tooltip);
