import { combineReducers } from 'redux';

import page from './page';

import project from './project/index';
import projectAttribute from './project/attribute';

import entity from './entity/index';
import entityAttribute from './entity/attribute';

import source from './source/index';
import sourceAttribute from './source/attribute';

import newReport from './source/newReport';

import country from './country/index';
import countryStats from './country/stats';

import company from './company';

import filter from './filter';

export default combineReducers({
  page,
  project,
  projectAttribute,
  entity,
  entityAttribute,
  source,
  sourceAttribute,
  country,
  countryStats,
  filter,
  company,
  newReport
});
