import color from 'color';

/**
 * Return an rgba string value for CSS
 * @param  {String} hex     Hexidecimal color value
 * @param  {Number} opacity Number value between 0 and 1
 * @return {String}         RGBA string
 */
function rgba(hex, opacity) {
	return color(hex).alpha(opacity).string();
}

function checkLuminosity(colorValue){
	if(color(colorValue).luminosity() < .6)
		return 'dark';
	else
		return 'light';
}


export {
	rgba,
	checkLuminosity
};