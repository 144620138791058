import React from 'react';
import PropTypes from 'prop-types';
import { line, curveCardinal } from 'd3-shape';

const Line = props => {
  const { scales, data, colors, selected } = props;

  const newLine = line()
    .curve(curveCardinal)
    .x(d => scales.x(d.date))
    .y(d => scales.y(d.payload));

  const loop = (d, key) => (
    <path
      fill="none"
      stroke={colors[key]}
      opacity={selected === null || selected === key ? 1 : 0.4}
      strokeWidth="2"
      className="line"
      d={newLine(d.values)}
      key={key}
    />
  );

  return <g className="line-group">{data.map(loop)}</g>;
};

Line.propTypes = {
  scales: PropTypes.object
};

export default Line;
