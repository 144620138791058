/* global window */
import React, { Component } from 'react';

export default ChartComponent =>
  class ResponsiveChart extends Component {
    constructor(props) {
      super(props);
      this.state = {
        containerWidth: null
      };
      this.fitParentContainer = this.fitParentContainer.bind(this);
    }

    componentDidMount() {
      this.fitParentContainer();
      // trigger event while resize ends
      let container;
      window.addEventListener('resize', () => {
        clearTimeout(container);
        container = setTimeout(() => {
          this.fitParentContainer();
        }, 500);
      });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer);
      let container;
      window.removeEventListener('resize', () => {
        clearTimeout(container);
        container = setTimeout(() => {
          this.fitParentContainer();
        }, 500);
      }, false);
    }

    fitParentContainer() {
      const { containerWidth } = this.state;

      if (this.chartContainer) {
        const currentContainerWidth = this.chartContainer.getBoundingClientRect()
          .width;

        const shouldResize = containerWidth !== currentContainerWidth;

        if (shouldResize) {
          this.setState({
            containerWidth: currentContainerWidth
          });
        }
      }
    }

    renderChart() {
      const parentWidth = this.state.containerWidth;

      return <ChartComponent {...this.props} parentWidth={parentWidth} />;
    }

    render() {
      const { containerWidth } = this.state;
      const shouldRenderChart = containerWidth !== null;

      return (
        <div
          ref={el => {
            this.chartContainer = el;
          }}
          className="Responsive-wrapper"
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      );
    }
  };