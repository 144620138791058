import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { isENV } from './helpers';

const congifureStore = (initialState = {}) => {
  const middlewares = [thunk];

  if (isENV('local')) {
    // middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(reducers, initialState, compose(...enhancers));

  return store;
};

export default congifureStore;
