import React from 'react';
import * as d3 from 'd3';
import numberFormatter from '../../../../utils/numberFormatter';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

let margin = {
  top: 0,
  right: 20,
  bottom: 0,
  left: 60
};
let svg = '',
  g = '',
  width = 200,
  height = 320;
class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barData: [],
      width: 300
    };
    this.renderBarChart = this.renderBarChart.bind(this);
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ barData: nextProps.data, width: nextProps.width });
  }

  componentDidUpdate() {
    this.renderBarChart();
  }

  componentDidMount() {
    svg = d3.select(this.jitterBar).append('svg');

    g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    window.addEventListener('resize', debounce(this.calculateWidth, 150));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.calculateWidth, 150), false);
  }

  calculateWidth() {
    if(!div) return null;
    let div = d3.select('.jitter-bar').node();
    const { width } = this.state;
    const currentWidth = div.getBoundingClientRect().width;

    const shouldResize = width !== currentWidth;
    if (shouldResize) {
      this.setState({ width: currentWidth });
      // d3.select('svg').style('width', currentWidth);
    }
  }

  renderBarChart() {
    let barData = this.state.barData || [];
    let self = this;
    function onMouseOver(d) {
      const html = `
    	<div>
    		<table class='table'>
          <tbody>
            <tr>
              <th>Pay. Type</th>
              <th class='title'>${d.name}</th>
            </tr>
    				<tr>
    					<td>Amount</td>
    					<td>USD ${numberFormatter(d.value)}</td>
            </tr>
    				<tr>
    					<td>Country</td>
    					<td>${d.country ? self.props.otherCountryLabel : self.props.countryName}</td>
    				</tr>
    			</tbody>
    		</table>
    	</div>
    	`;

      tooltip
        .style('left', Math.max(0, d3.event.pageX - 150) + 'px')
        .style('top', d3.event.pageY + 10 + 'px')
        .style('display', 'inline-block')
        .html(html);
    }
    function verticalWrap(text, width) {
      text.each(function() {
        var text = d3.select(this),
          words = text.text().split(' '),
          h = words.length > 1 ? '0' : '0.2em',
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy')),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', -10)
            .attr('y', y)
            .attr('dy', h);

        words.map((word, i) => {
          dy = i === 0 ? (h === 0 ? '-0.5em' : h) : '0.8em';
          tspan = text
            .append('tspan')
            .attr('x', -20)
            .attr('y', y)
            .attr('dy', dy)
            .text(word);
          return true;
        });
      });
    }
    width = this.state.width - margin.left - margin.right;

    let y0 = d3
      .scaleBand()
      .padding(0.6)
      .range([height, 0]);

    let x = d3.scaleLinear().range([0, width - margin.right]);
    let xAxis = d3
      .axisBottom(x)
      .ticks(6)
      .tickFormat(d => {
        return numberFormatter(d);
      });

    let options = d3.keys(barData[0]).filter(function(key) {
      return key !== 'label';
    });
    let countryData = [];
    let otherCountryData = [];

    barData.forEach(function(d) {
      d.valores = options.map(function(name) {
        let fill = name === 'country' ? '#b0b6be' : '#eceaf1';
        return { name: name, value: +d[name], fill: fill };
      });
      countryData.push({ name: d.label, value: d.country });
      otherCountryData.push({ name: d.label, value: d.otherCountry });
    });

    y0.domain(
      barData.map(function(d) {
        return d.label;
      })
    );
    x.domain([
      0,
      d3.max(barData, d => {
        return d3.max(d.valores, function(d) {
          return d.value;
        });
      })
    ]);

    svg.attr('width', this.state.width).attr('height', height + 40);

    let tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip');

    g.select('.x.axis').remove();
    g.select('.y.axis').remove();
    // g.selectAll('rect').remove();

    g.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(30,' + height + ')')
      .call(xAxis);

    g.append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(40)')
      .call(d3.axisLeft(y0))
      .selectAll('.tick text')
      .call(verticalWrap, 70);

    let bar = g.selectAll('.bar').data(countryData);

    bar
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('transform', 'translate( 30, 0)')
      .attr('x', 0)
      .attr('height', y0.bandwidth())
      .attr('y', d => {
        return y0(d.name);
      })
      .attr('width', d => {
        return d.value < 0 ? 0 : x(d.value);
      })
      .style('fill', '#b0b6be')
      .on('mousemove', function(d) {
        d3.select(this).style('fill', '#8f939b');
        onMouseOver(d);
      })
      .on('mouseout', function() {
        tooltip.style('display', 'none');
        d3.select(this).style('fill', '#b0b6be');
      });

    let bar1 = g.selectAll('.bar1').data(otherCountryData);

    let rectWidth = 2; //other country rectangle width
    bar1
      .enter()
      .append('rect')
      .each(d => (d.country = 'Others'))
      .attr('class', 'bar1')
      .attr('transform', 'translate( 30, 0)')
      .attr('x', d => {
        return x(d.value) - rectWidth;
      })
      .attr('height', y0.bandwidth())
      .attr('y', d => {
        return y0(d.name);
      })
      .attr('width', d => {
        return d.value <= 0 ? 0 : rectWidth;
      })
      .style('fill', '#000')
      .on('mousemove', onMouseOver)
      .on('mouseout', () => {
        tooltip.style('display', 'none');
      });

    // removed data:
    bar.exit().remove();
    bar1.exit().remove();

    bar
      .transition()
      .ease(d3.easeLinear)
      .duration(600)
      .attr('x', 0)
      .attr('y', function(d) {
        return y0(d.name);
      })
      .attr('height', y0.bandwidth())
      .attr('width', function(d) {
        let w = parseFloat(x(d.value));
        return w <= 0 ? 0 : w;
      });

    bar1
      .transition()
      .ease(d3.easeLinear)
      .duration(600)
      .each(d => (d.country = 'Others'))
      .attr('x', d => {
        return x(d.value) - rectWidth;
      })
      .attr('y', function(d) {
        return y0(d.name);
      })
      .attr('width', function(d) {
        return d.value <= 0 ? 0 : rectWidth;
      })
      .attr('height', y0.bandwidth());
  }

  

  render() {
    return (
      <div
        className="jitter-bar"
        ref={el => {
          this.jitterBar = el;
        }}
        style={{visibility: this.props.data && this.props.data.length ? 'visible' : 'hidden' }}
      />
    );
  }
}
const mapStateToProps = state => ({
  countryList: state.country.countryList
});

export default connect(mapStateToProps)(BarChart);
