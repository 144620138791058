import React from 'react';
import oilPin from '../../../images/location-marker_oil-gas@2x.png';
import minePin from '../../../images/location-marker_mining@2x.png';
import corporatePin from '../../../images/location-marker_corporate@2x.png';
import * as projectAction from '../../../actions/project/index';
import { connect } from 'react-redux';
import _ from 'lodash';

// import ProjectService from '../../services/project/index';

class Markers extends React.Component {
  // shouldComponentUpdate(nextProps, nextState) {
  //   return true;
  //   return (
  //     this.props.parentWidth !== nextProps.parentWidth ||
  //     this.props.projects !== nextProps.projects
  //   );
  // }
  showTooltip(e, data) {
    this.props.dispatchMarkerTooltipData({
      ...data,
      x: e.clientX,
      y: e.clientY,
      shouldShowTooltip: true
    });
  }

  hideTooltip() {
    this.props.dispatchMarkerTooltipData({ shouldShowTooltip: false });
  }
  render() {
    const { projection, projects, zoomTransform, k } = this.props;
    let newData = [];
    _(projects)
      .groupBy('name')
      .map((obj2, name) => {
        const totalPayment = _.sumBy(obj2, d => d.payment);
          newData.push({...obj2[0], payment:totalPayment});
        })
        .value();

    return (
      <g className="project-payment-markers">
        {newData.map((d, key) => {
          let iconUrl;
          if (d.type === 'Mining') {
            iconUrl = minePin;
          } else if (d.type === 'Corporate') {
            iconUrl = corporatePin;
          } else {
            iconUrl = oilPin;
          }
          if (d.coordinates)
            return (
              <g transform={zoomTransform} key={key}>
                <image
                  onMouseMove={e => this.showTooltip(e, d)}
                  onMouseOut={() => this.hideTooltip()}
                  xlinkHref={iconUrl}
                  transform={`translate(${projection([d.coordinates[0], d.coordinates[1]])[0]}, ${
                    projection([d.coordinates[0], d.coordinates[1]])[1]
                    })`}
                    y={-16/k}
                    x={-10/k}
                  height={22 / k}
                  width={22 / k}
                />                
                {/* <circle
              transform={`translate(${projection([d.coordinates[0], d.coordinates[1]])[0]}, ${
                projection([d.coordinates[0], d.coordinates[1]])[1]
              })`}
                r={3 / k}
                style={{
                  fillOpacity: 0.5,
                  fill: 'red',
                  stroke: 'red',
                  strokeWidth: 0.5,
                  strokeOpacity: 0.7
                }}
              /> */}
              </g>
            );
        })}
      </g>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchMarkerTooltipData: markerTooltipData => {
      dispatch(projectAction.markerTooltipData(markerTooltipData));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Markers);
