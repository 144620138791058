import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { media } from '../../components/styled/utils/';
import { Heading } from '../../components/styled/ui/';
import { flex } from '../../components/styled/utils/';
import { Transition } from 'react-transition-group';
import CountryService from '../../services/country/index';
import * as CountryAction from '../../actions/country/index';
import * as ProjectActions from '../../actions/project/index';
import ProjectService from '../../services/project/index';
import { Helmet } from 'react-helmet';
import { downloadImage, getAppImages } from '../../helpers';
import Map from '../../components/map/map';
// import iso2toiso3 from '../../helpers/iso2toiso3';

const {mapIcon, downloadChartIcon} = getAppImages;

class CountryProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      isOpen: false
    };
    this.imageBaseURL =
      'https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/';
    this.countryChanged = this.countryChanged.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  getCountryImage(countryCode) {
    return this.imageBaseURL + countryCode.toLowerCase() + '.png';
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.overallStats.maxComponent)
      this.props.dispatchVisualizationData(nextProps.overallStats.maxComponent);
  }

  componentWillMount() {
    if (window) {
      window.scrollTo(0, 0);
    }
    this.props.getCountryList();
    this.props.getOverAllStats();
    if (this.props.overallStats.maxComponent) {
      this.props.dispatchVisualizationData(
        this.props.overallStats.maxComponent
      );
    }
  }

  countryChanged(e) {
    this.setState({
      searchValue: e.target.value
    });
  }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  render() {
    const { countryList, overallStats } = this.props;
    const { searchValue } = this.state;
    const { countryCount, maxComponent, totalPayment } = overallStats;

    const filteredCountryList = countryList.filter(country =>
      country.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    const transitionStyles = {
      entered: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0)'
      }
    };
    return (
      <CountryPage>
      <Helmet>
          <title>{'Country Profiles'}</title>
          <meta
            name="og:description"
            content={`Mandatory disclosure reports on this website contain
               USD ${totalPayment || 0} in payments made to
              ${countryCount || 0} countries. View their
              profiles to get a detailed breakdown of these payments and
              understand how they compare with others.`}
          />
          <meta
            name="og:title"
            content={'Country Profiles - Resource Projects : An open-source data repository on oil, gas and mining projects'}
          />
        </Helmet>
        <section className="section__top">
          <div>
            <h1>Country Profiles</h1>
            <p>
              Mandatory disclosure reports on this website contain
              <strong> USD {totalPayment || 0}</strong> in payments made to
              <strong> {countryCount || 0} countries</strong>. View their
              profiles to get a detailed breakdown of these payments and
              understand how they compare with others.
            </p>
          </div>
        </section>
        <section className="section__content">
          <div className="static-page">
            <div className="select-country">
              <Dropdown
                onMouseEnter={() => this.handleDropdownMenu(true)}
                onMouseLeave={() => this.handleDropdownMenu(false)}
              >
                <DropdownTitle isOpen={this.state.isOpen}>
                  <img
                    src={mapIcon}
                    alt=""
                    width="21"
                    height="21"
                  />
                  <Heading.h2 className="title">
                    Select a country profile
                  </Heading.h2>
                  <span className="icon" />
                </DropdownTitle>

                <Transition
                  in={this.state.isOpen}
                  timeout={100}
                  mountOnEnter={true}
                >
                  {state => (
                    <DropdownMenu
                      style={{
                        ...transitionStyles[state]
                      }}
                    >
                      <div className="search-wrap">
                        <SearchInput
                          autoComplete="none"
                          type="text"
                          placeholder="country name..."
                          onChange={this.countryChanged.bind(this)}
                        />
                      </div>
                      <ul className="list">
                        {filteredCountryList.map((country, i) => {
                          // let country3Code = iso2toiso3[country.code]
                          // console.log(country3Code)
                          return (
                            <li key={i}>
                              <Link to={`/country/${country.code}`}>
                                <span>
                                  <img
                                    className="flag"
                                    src={this.getCountryImage(country.code)}
                                    alt=""
                                  />
                                </span>

                                <span>{country.name}</span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </DropdownMenu>
                  )}
                </Transition>
              </Dropdown>
            </div>
            <DownloadImage className="download-image" onClick={()=>downloadImage('project-payment', 'Country-Map-Resource-Projects')}>
              <img src = {downloadChartIcon} alt = "Download Map"/>
              <span> Download Map </span>
            </DownloadImage>
            {maxComponent && <Map component={maxComponent} />}
          </div>
        </section>
      </CountryPage>
    );
  }
}

const formatInitialData = (data, component) => {
  // make same object key name for both comonents
  // console.log(data, component);
  if (component === 'project') {
    data.forEach(d => {
      d.payment = d.projectPayment;
      d.name = d.projectName;
      d.country = d.projectCountry;
      d.countryCode = d.projectCountryCode;

      delete d.projectPayment;
      delete d.projectName;
      delete d.projectCountry;
      delete d.projectCountryCode
    });
  }
  if (component === 'entity') {
    data.forEach(d => {
      d.payment = d.entityPayment;
      d.name = d.entityName;
      d.country = d.entityCountry;
      d.countryCode = d.entityCountryCode;

      delete d.entityPayment;
      delete d.entityName;
      delete d.entityCountry;
      delete d.entityCountryCode
    });
  }
  // assign '0' if value is undefined or negative
  data.forEach(d => {
    if (d.payment === undefined) {
      d.payment = 0;
    }
  });

  return data;
};

const mapStateToProps = state => ({
  countryList: state.country.countryList,
  overallStats: state.country.overallStats
});

const mapDispatchToProps = dispatch => ({
  getCountryList: () => {
    CountryService.getCountryList().then(response => {
      dispatch(CountryAction.countryListFetched(response.result));
    });
  },
  getOverAllStats: () => {
    CountryService.getOverAllStats().then(response => {
      dispatch(CountryAction.overallStatsFetched(response.body.result));
    });
  },
  dispatchVisualizationData: component => {
    dispatch(ProjectActions.visualizationDataFetching());
    ProjectService.getVisualizationData(component)
      .then(res => {
        // copy of original data
        let response = JSON.parse(JSON.stringify(res));

        response = formatInitialData(response, component);
        dispatch(ProjectActions.visualizationDataFetched(response));
      })
      .catch(error => {
        console.log(error);
      });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryProfile);

const CountryPage = styled.div`
  .section__top {
    padding: 50px 0;
    ${media.sm`padding: 50px 0px 230px;`}
    > div {
      position: relative;
      max-width: 640px;
      text-align: center;
      z-index: 100;
      margin: 35px auto 0px;
    }
    h1 {
      font-size: 24px;
      line-height: 1.4;
      color: rgb(236, 234, 241);
      font-weight: 700;
      margin-bottom: 18px;
      padding: 0 20px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      color: rgb(236, 234, 241);
      margin-bottom: 60px;
      padding: 0 20px;
    }
    strong {
      font-weight: bold;
    }
  }
  .section__content {
    background-color: #eceaf1;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      max-width: 768px;
      max-height: 600px;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: rgba(0, 0, 0, 0.2) 0px -34px 50px -10px;
      z-index: 25;
      background-image: linear-gradient(-180deg, #ffffff 0%, #eceaf1 100%);
      ${media.sm`margin-top: -120px;border-radius: 12px;`}
    }
  }

  .select-country {
    margin: 24px auto 0;
    max-width: 406px;
    ${media.sm`margin-top: -110px`};
    text-align: left;
    > div {
      border: 1px solid #3a424c;
      border-radius: 25px;
      height: 50px;
      margin: 0 auto;
      max-width: 296px;
    }
    h2 {
      color: #3a424c;
      font-size: 12px;
      text-transform: uppercase;
    }
    p {
      color: rgba(58, 66, 76, 0.5);
      padding-top: 20px;
      text-align: center;
    }
  }

  .static-page {
    position: relative;
    max-width: 768px;
    z-index: 100;
    min-height: 400px;
    padding: 8px 0 54px;
    text-align: center;
    ${media.sm`margin: -120px auto 0px; padding: 16px 0 54px;`}
    > p {
      color: rgba(58, 66, 76, 0.5);
    }
  }
  .Responsive-wrapper {
    > div {
      background: transparent;
    }
  }
`;

const Dropdown = styled.div`
  position: relative;
  padding: 12px 0;
  margin-right: 36px;

  @media screen and (max-width: 546px) {
    margin-bottom: 12px;
  }
`;

const DropdownTitle = styled.div`
  ${flex('row', null, 'center')};
  cursor: pointer;
  padding: 0 26px 0 20px;
  justify-content: space-between;

  .image {
    background-color: #eee;
    border: 2px solid ${props => props.theme.primary.white};
    height: 28px;
    margin-right: 7px;
    text-indent: -9999px;
    width: 38px;
  }

  .title {
    color: ${props => props.theme.primary.white};
    display: inline;
    line-height: 1;
  }

  .icon{
    border-color: ${props =>
      props.isOpen
        ? 'transparent transparent #3a424c;'
        : '#3a424c transparent transparent;'}
      border-width: ${props => (props.isOpen ? '0 5px 5px' : '5px 5px 0')};
    border-style: solid;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: 2px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 102%;
  width: auto;
  min-width: 184px;
  z-index: 9999000;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.5);
  transform: translateY(-4px);
  transition: all 300ms ease-in-out;
  width: 296px;

  .search-wrap {
    padding: 18px 8px 10px;
    background-color: ${props => props.theme.primary.white};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
  }

  .list {
    background-color: ${props => props.theme.primary.white};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 40px -8px;
    border-top-bottom-radius: 4px;
    border-top-bottom-radius: 4px;
    max-height: 239px;
    overflow: hidden;
    overflow-y: scroll;
    > li {
      color: rgb(32, 188, 251);
      border-bottom: 1px solid rgb(238, 238, 238);
      transition: all 0.1s ease-in-out;

      &:hover {
        background: ${props => props.theme.primary.primary};
        > a {
          color: ${props => props.theme.primary.primary};
        }
      }

      > a {
        display: flex;
        align-items: center;
        padding: 9px 20px;

        > span {
          &:nth-child(1) {
            flex: 0 0 24px;
            margin-right: 12px;

            img {
              height: 12px;
              width: auto;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
            }
          }
          &:nth-child(2) {
            color: ${props => props.theme.primary.dark};
            flex: 1;
            line-height: 1.4;
          }
        }
      }
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  background: ${props => props.theme.sec.lightGreen};
  border: 1px solid #d8e1e6;
  border-radius: 18px;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;


const DownloadImage = styled.button`
  align-items: center;
  background: rgba(177,184,208,0.8);
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 24px;
  line-height: 24px;
  margin-left: auto;
  margin-right: 40px;
  margin-top: 40px;
  padding: 0 8px;
  span {
    color: #fff;
    text-transform: uppercase;
  }
  &:hover,
  &:focus {
    background: rgba(177, 184, 208, 1);
    outline: 0;
  }
  img {
    margin-right: 3px;
  }
`;