import React from 'react';
import styled from 'styled-components';
import LineChart from './line-chart/line-chart';
import { flex, media } from '../../../components/styled/utils/';
import { darken } from '../../../components/styled/utils/colors';

const mapObject = (object, callback) =>
  Object.keys(object).map(function(key, i) {
    return callback(key, object[key], i);
  });

class Wgi extends React.Component {
  constructor() {
    super();
    this.state = {
      colors: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#d4d4bc'],
      selected: null
    };
  }

  changeColor = i => {
    setTimeout(() => {
      let newColor = this.state.colors;
      newColor[i] = darken(`${newColor[i]}`, '30');
      this.setState({ colors: newColor, selected: i });
    }, 10);
  };

  resetColor = () => {
    this.setState({
      colors: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#d4d4bc'],
      selected: null
    });
  };

  render() {
    const { wgiData } = this.props;
    const {selected, colors} = this.state;
    const legendData = wgiData[Object.keys(wgiData)[0]];

    return (
      <div id="country-stats">
        <Content>
          <div className="column">
            <LineChart
              data={wgiData}
              colors={colors}
              handleChangeColor={this.changeColor}
              handleResetColor={this.resetColor}
              selected={selected}
            />
          </div>
          <div className="column">
            <Legend colors={colors}>
              {mapObject(legendData, (key, value, i) => (
                <li className="legend-list-item" key={key} onMouseEnter={() =>this.changeColor(i)} onMouseLeave={this.resetColor} >
                  <span style={selected === i ? {color: '#000'}: {color: '#777'}}>{key}</span>
                </li>
              ))}
            </Legend>
          </div>
        </Content>
      </div>
    );
  }
}

export default Wgi;

const Content = styled.div`
  ${flex('column', null, null)};

  ${media.sm`
  	  ${flex('row', 'flex-start', null)};
  `};

  .column {
    &:first-child {
      flex: 1 1 80%;
      margin-bottom: 12px;
      ${media.sm`
        margin-bottom: 0;
      `};
    }

    &:nth-child(2) {
      flex: 0 0 20%;
    }
  }
`;

const Legend = styled.div`
  list-style: none;
  padding-left: 0;

  .legend-list-item {
    color: ${props => props.theme.primary.gray};
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    line-height: 1.3;

    ${media.sm`
      ${flex('row', 'flex-start', 'center')};
  `};

    &:not(:last-child) {
      margin: 0 17px 8px 0;

      ${media.sm`
        margin: 0 0 8px 0;
    `};
    }

    &:before {
      content: '';
      border-radius: 9px;
      display: inline-block;
      margin-right: 6px;
      height: 9px;
      width: 9px;
    }

    span {
      flex: 1;
    }

    &:nth-child(1) {
      &:before {
        background-color: ${props => props.colors[0]};
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: ${props => props.colors[1]};
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: ${props => props.colors[2]};
      }
    }

    &:nth-child(4) {
      &:before {
        background-color: ${props => props.colors[3]};
      }
    }

    &:nth-child(5) {
      &:before {
        background-color: ${props => props.colors[4]};
      }
    }

    &:nth-child(6) {
      &:before {
        background-color: ${props => props.colors[5]};
      }
    }
  }
`;
