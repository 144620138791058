import { injectGlobal } from 'styled-components';
import reset from 'styled-reset';
import base from './base';
import visualization from './visualization';
import pagination from './pagination';

export default injectGlobal`

	//reseting styles
	${reset};

    
    //Base styles
	${base};
	
	//for visualization
	${visualization};

	//pagination
	${pagination}
 
	/**
	 *  Custom scroll bar design
	 * */

	/* width */
	/* ::-webkit-scrollbar {
		width: 10px;
	} */

	/* Track */
	/* ::-webkit-scrollbar-track {
		background: #f0f0f0; 
	} */
	
	/* Handle */
	/* ::-webkit-scrollbar-thumb {
		background-color: #ee8750;
		border-left: 7px solid transparent;
		background-clip: content-box;
		min-height: 35px;
	} */

	/* Handle on hover */
	/* ::-webkit-scrollbar-thumb:hover {
		border-left: 2px solid transparent;
    	background-clip: content-box;
	} */
   
    `;
