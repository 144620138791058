import React, { Component } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../components/styled/ui/';
import { Transition } from 'react-transition-group';
import { Dropdown, DropdownMenu, transitionStyles } from './dropdownStyles';
import Colours from '../../../components/styled/theme/colours';
const { primary } = Colours;

class CardDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: this.props.items.length
        ? this.props.items[0].label
        : 'NO INDEX DATA AVAILABLE',
      isOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if(nextProps.items[0].label !== this.state.selectedValue) {
  //     this.setState({selectedValue: nextProps.items[0].label})
  //   }
  // }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  handleChange(dropdownValue, dropdownLabel) {
    this.setState(
      {
        isOpen: false,
        selectedValue: dropdownLabel
      },
      () => {
        this.props.onChange(dropdownValue);
      }
    );
  }

  render() {
    const { items } = this.props;

    return (
      <Dropdown
        onMouseEnter={() => this.handleDropdownMenu(true)}
        onMouseLeave={() => this.handleDropdownMenu(false)}
      >
        <DropdownLink className="dropdown-title">
          <Title uppercase className="title" isOpen={this.state.isOpen}>
            {this.state.selectedValue}
          </Title>
          <DropdownIcon className="icon" isOpen={this.state.isOpen} />
        </DropdownLink>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <ul className="dropdown-list">
                {items.map((val, key) => (
                  <li
                    className="dropdown-list-item"
                    key={key}
                    onClick={() => this.handleChange(val.value, val.label)}
                  >
                    {val.label}
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

export default CardDropdown;

const DropdownLink = styled.div`
  display: inline-block;
  color: rgb(255, 255, 255);
  font-size: 12px;
  cursor: default;
  background: transparent;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 16px;
  background-color: rgba(176, 182, 190, 0.1);
  transition: 0.15s ease-in-out;

  &:hover {
    background-color: rgba(176, 182, 190, 0.6);
  }
`;

const Title = Heading.h4.extend`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: ${props => primary.dark};
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? 'transparent transparent #3a424c;'
      : '#3a424c transparent transparent;'}
    border-width: ${props => (props.isOpen ? '0 4px 4px' : '4px 4px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
`;
