import Api from '../api';

class Source {
  async newReport(date) {
    let params;
    if(date)
     params = { date };

    return await Api.get('/sources/newReports', { params });
  }
}

export default new Source();
