import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Hint = ({ title, text }) => (
  <Content>
    <span className="icon">{title}</span>
    <span className="content">
      <span className="caret" />
      <span className="text">{text}</span>
    </span>
  </Content>
);

Hint.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string
};

const Content = styled.span`
  position: relative;
  display: inline-block;
  &:hover {
    .content {
      visibility: visible;
      opacity: 1;
    }
  }

  .caret {
    height: 0;
    width: 0;
    position: absolute;
    top: 2px;
    left: 5px;
    //transform: translateX(-50%);
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent ${props => props.theme.primary.gray}
      transparent;
    border-style: solid;
  }

  .content {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    //transform: translateX(-50%);
    padding-top: 6px;
    //right: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 110;
    transition: all 0.15s ease-in-out;
    z-index: 9999;
  }

  .text {
    display: block;
    min-width: 150px;
    background: ${props => props.theme.primary.gray};
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    letter-spacing: 0.5px;
    padding: 8px;
    color: #fff;
    box-shadow: 0 2px 25px -5px rgba(0, 0, 0, 0.15);
  }
`;

export default Hint;
