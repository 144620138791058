import React, { Component } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../../../components/styled/ui/';
import { flex } from '../../../../../components/styled/utils/';
import { CloseFilter, FilterSection } from '../../../../../components/styled';
import querystring from 'querystring';
import { Filter } from '../../../../../components/layouts/filters';
import { getHelpText } from '../../../../../helpers';
import { withRouter } from 'react-router-dom';
import { countries } from '../../../../../helpers/helper'

class SideBarComponent extends Component {
  componentDidMount() {
    //check if filter empty
    let filter = this.removeEmpty(this.props.filter);
    filter = this.replaceCountryName(filter)
    if (Object.values(filter).length !== 0) {
      this.props.history.push({
        search: 'tab=0&' + querystring.stringify(filter)
      });
    }
  }
  removeEmpty(obj) {
    let newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }
  componentWillReceiveProps(nextProps) {
    //remove empty item from filter
    let filter = this.removeEmpty(nextProps.filter);
    // if (Object.values(filter).length !== 0) {
    let { component } = this.props;
    if (component === 'project') {
      delete filter['entityTypes'];
    } else if (component === 'entity') {
      delete filter['projectTypes'];
    }
    filter = this.replaceCountryName(filter)
    let query = 'tab=0&' + querystring.stringify(filter);
    //to make sure component is not re-render unnecessarily
    if ('?' + query !== this.props.location.search) {
      this.props.history.push({
        search: query
      });
    }
    // }
  }
  /**
   * This is for replacing country name by country code.
   * @param {function} obj 
   */
  replaceCountryName(obj) {
    let newObj = { ...obj }

    Object.keys(newObj).forEach(prop => {
      if (prop === 'countries' || prop === 'reportingJurisdiction') {
        let splittedCountryName = newObj[prop].split(':')
        let countryTest = []
        countries.filter(country => {
          splittedCountryName.map((selectedCountry) => {
            if (selectedCountry === country.name) {
              countryTest.push(country.code)

            }
          })
        })
        let joinedData = countryTest.join(':')
        newObj[prop] = joinedData
      }
    });
    return newObj;
  }

  _renderFilter() {
    let { filter, component } = this.props;
    let title =
      component === 'entity'
        ? 'Agency'
        : component.charAt(0).toUpperCase() + component.slice(1);
    let { data } = this.props[component + 'Attribute'];
    let source;
    if (component !== 'source') {
      source = this.props.sourceAttribute.data;
    }
    let countries = [];
    let reportingCompanies = [];
    let paymentTypes = [];
    let reportingJurisdiction = [];
    let years = [];
    let projectName = [];
    let projectTypes = [];
    let entityTypes = [];

    if (data) {
      countries = data.countries || [];
      reportingCompanies = data.reportingCompanies || [];
      paymentTypes = data.paymentTypes || [];
      years = data.years || [];
      projectName = data[component + 'Name'] || [];
      reportingJurisdiction = data.reportingJurisdiction || [];
      projectTypes = data.projectTypes || [];
      entityTypes = data.entityTypes || [];
    }

    if (source) {
      reportingJurisdiction = source.countries || [];
    }

    // if (typeof window !== 'undefined') {
    //   window.history.replaceState(
    //     {},
    //     '/' + component,
    //     '?' + querystring.stringify(filter)
    //   );
    // }

    return (
      <FilterSection className="filterOpen">
        <CloseFilter
          className="close-filter"
          onClick={this.props.toggleSideBarOptions}
        >
          <i>Close</i>
        </CloseFilter>

        {projectName.length > 0 && (
          <Filter
            selected={filter.query}
            filterName="query"
            title={title}
            onChange={this.props._onChangefilter.bind(this, 'query')}
            data={projectName}
            searchKey={component + ' name'}
            helpText={getHelpText[component + 'Name']}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {years.length > 0 && (
          <Filter
            selected={filter.years}
            filterName="years"
            title="Fiscal Year End"
            onChange={this.props._onChangefilter.bind(this, 'years')}
            data={years}
            showSearch={false}
            searchKey="Fiscal Year End"
            helpText={getHelpText.reportStartDate}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {countries.length > 0 && (
          <Filter
            selected={
              component === 'source'
                ? filter.reportingJurisdiction
                : filter.countries
            }
            filterName={
              component === 'source' ? 'reportingJurisdiction' : 'countries'
            }
            title={
              component === 'source'
                ? 'Reporting Jurisdiction'
                : title + ' Country'
            }
            onChange={this.props._onChangefilter.bind(
              this,
              component === 'source' ? 'reportingJurisdiction' : 'countries'
            )}
            data={countries}
            searchKey="country name"
            helpText={getHelpText.projectCountry}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {reportingCompanies.length > 0 && (
          <Filter
            selected={filter.reportingCompanies}
            filterName="reportingCompanies"
            title="Reporting Companies"
            onChange={this.props._onChangefilter.bind(
              this,
              'reportingCompanies'
            )}
            data={reportingCompanies}
            searchKey="reporting company name"
            helpText={getHelpText.reportingCompany}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {paymentTypes.length > 0 && (
          <Filter
            selected={filter.paymentTypes}
            filterName="paymentTypes"
            showSearch={false}
            title="Payment Types"
            onChange={this.props._onChangefilter.bind(this, 'paymentTypes')}
            data={paymentTypes}
            searchKey="payment type"
            helpText={getHelpText.paymentTypes}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {reportingJurisdiction.length > 0 && (
          <Filter
            selected={filter.reportingJurisdiction}
            filterName="reportingJurisdiction"
            title="Reporting Jurisdiction"
            onChange={this.props._onChangefilter.bind(
              this,
              'reportingJurisdiction'
            )}
            data={reportingJurisdiction}
            searchKey="jurisdiction country"
            helpText={getHelpText.reportingJurisdiction}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {projectTypes.length > 0 && (
          <Filter
            selected={filter.projectTypes}
            filterName="projectTypes"
            title="Project Type"
            onChange={this.props._onChangefilter.bind(this, 'projectTypes')}
            data={projectTypes}
            searchKey="project type"
            helpText={getHelpText.projectType}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
        {entityTypes.length > 0 && (
          <Filter
            selected={filter.entityTypes}
            filterName="entityTypes"
            title="Entity Type"
            onChange={this.props._onChangefilter.bind(this, 'entityTypes')}
            data={entityTypes}
            searchKey="entity type"
            helpText={getHelpText.entityType}
            onHandleClear={this.onHandleClear.bind(this)}
          />
        )}
      </FilterSection>
    );
  }

  onHandleClear(filter) {
    this.props._handleReset(filter, '');
  }

  render() {
    return (
      <Sidebar id="sidebar" className="sidebar-close">
        <div className="sidebar--inner">
          <FilterBy>{this.props._renderFilterBy()}</FilterBy>
          <Heading.h4 light uppercase className="filter-title">
            Data Filters
          </Heading.h4>
          {this._renderFilter()}
        </div>
      </Sidebar>
    );
  }
}

export default withRouter(SideBarComponent);

const Sidebar = styled.div`
  // @media (max-width: 1366px) {
  box-shadow: 2px 0 490px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  //width: 0;
  //transition: width 0.3s ease-in-out;
  width: 320px;
  background: #4c3154;
  padding: 244px 0 36px;
  min-height: 100vh;
  height: 100%;
  /* height: calc(100% + 273px); */
  overflow: auto;
  z-index: 10000;
  transition: transform 0.3s ease-in-out;
  /* @media (max-width: 1280px) { */
  position: fixed;
  overflow: auto;
  height: 50vh;
  /* } */
  // }

  &.sidebar-open {
    //width: 320px;
    //transition: width 0.3s ease-in-out;
    transform: translateX(0%);
  }

  &.sidebar-close {
    //opacity: 0;
    /* display: none; */
    transform: translateX(-100%);
    .close-filter {
      display: none;
    }
    // @media screen and (max-width: 1366px) {
    //   opacity: 0;
    // }
  }

  .filter-icon {
    // @media screen and (max-width: 1366px) {
    display: none;
    // }
  }

  .sidebar--inner {
    padding: 0 36px 0;
    width: 300px;
  }
`;

const FilterBy = styled.div`
  margin: 6px 0 12px;
  ${flex('column', null, 'flex-start')};
  span {
    border-radius: 0;
  }
`;
