import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Heading } from '../../../components/styled/ui';
import { flex } from '../../../components/styled/utils/';
import { CloseFilter, FilterSection } from '../../../components/styled';
import { Filter } from '../../../components/layouts/filters';
import { getHelpText } from '../../../helpers';
import { withRouter } from 'react-router-dom';

class SideBarComponent extends Component {
  constructor({ data }) {
    super();
    this.state = {
      data
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  render() {
    const { data } = this.state;
    const {
      renderFilterBy,
      sidebarIsOpen,
      handleSidebar,
      selectedYears,
      handleYearFilter,
      handleYearClear,
      selectedCountry,
      handleCountryFilter,
      handleCountryClear,
      selectedReportingCompany,
      handleCompanyFilter,
      handleCompanyClear,
      selectedProject,
      handleProjectFilter,
      handleProjectClear,
      selectedPaymentType,
      handlePaymentTypeFilter,
      handlePaymentTypeClear,
      selectedType,
      handleTypeFilter,
      handleTypeClear,
      selectedReportingJurisdiction,
      handleReportingJurisdictionFilter,
      handleReportingJurisdictionClear,
      profile
    } = this.props;

    let yearData = data;
    let countryData = data;
    let companyData = data;
    let projectData = data;
    let jurisdictionData = data;
    let paymentTypeData = data;
    let typeData = data;

    if (selectedProject !== '') {
      countryData = countryData.filter(d => selectedProject.includes(d.name));
      yearData = yearData.filter(d => selectedProject.includes(d.name));
      jurisdictionData = jurisdictionData.filter(d =>
        selectedProject.includes(d.name)
      );
      paymentTypeData = paymentTypeData.filter(d =>
        selectedProject.includes(d.name)
      );
      typeData = typeData.filter(d => selectedProject.includes(d.name));
    }

    if (selectedPaymentType !== '') {
      countryData = countryData.filter(d =>
        selectedPaymentType.includes(d.paymentType)
      );
      yearData = yearData.filter(d =>
        selectedPaymentType.includes(d.paymentType)
      );
      jurisdictionData = jurisdictionData.filter(d =>
        selectedPaymentType.includes(d.paymentType)
      );
      projectData = projectData.filter(d =>
        selectedPaymentType.includes(d.paymentType)
      );
      typeData = typeData.filter(d =>
        selectedPaymentType.includes(d.paymentType)
      );
    }

    if (selectedType !== '') {
      countryData = countryData.filter(d => selectedType.includes(d.type));
      yearData = yearData.filter(d => selectedType.includes(d.type));
      jurisdictionData = jurisdictionData.filter(d =>
        selectedType.includes(d.type)
      );
      projectData = projectData.filter(d => selectedType.includes(d.type));
      paymentTypeData = paymentTypeData.filter(d =>
        selectedType.includes(d.type)
      );
    }

    if (selectedYears !== '') {
      countryData = countryData.filter(d =>
        selectedYears.includes(d.reportYear)
      );
      projectData = projectData.filter(d =>
        selectedYears.includes(d.reportYear)
      );
      jurisdictionData = jurisdictionData.filter(d =>
        selectedYears.includes(d.reportYear)
      );
      paymentTypeData = paymentTypeData.filter(d =>
        selectedYears.includes(d.reportYear)
      );
      typeData = typeData.filter(d => selectedYears.includes(d.reportYear));
    }

    if (selectedCountry !== '') {
      yearData = yearData.filter(d => selectedCountry.includes(d.country));
      projectData = projectData.filter(d =>
        selectedCountry.includes(d.country)
      );
      jurisdictionData = jurisdictionData.filter(d =>
        selectedCountry.includes(d.country)
      );
      paymentTypeData = paymentTypeData.filter(d =>
        selectedCountry.includes(d.country)
      );
      typeData = typeData.filter(d => selectedCountry.includes(d.country));
    }

    if (selectedReportingJurisdiction !== '') {
      yearData = yearData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
      projectData = projectData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
      countryData = countryData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
      paymentTypeData = paymentTypeData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
      typeData = typeData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
    }

    const reportingYears = _.uniq(_.map(yearData, 'reportYear')).sort();
    const countries = _.uniq(_.map(countryData, 'country')).sort();
    const companies = _.uniq(_.map(companyData, 'reportingCompany')).sort();
    const projects = _.uniq(_.map(projectData, 'name')).sort();
    const paymentTypes = _.uniq(_.map(paymentTypeData, 'paymentType')).sort();
    const types = _.uniq(_.map(typeData, 'type')).sort();
    const reportingJurisdiction = _.uniq(
      _.map(jurisdictionData, 'reportingJurisdiction')
    ).sort();
    // console.log(reportingYears, paymentTypes, reportingJurisdiction, projects);

    return (
      <Sidebar
        id="sidebar"
        className={sidebarIsOpen ? 'sidebar-open' : 'sidebar-close'}
      >
        <div className="sidebar--inner">
          <FilterBy>{renderFilterBy()}</FilterBy>
          <Heading.h4 light uppercase className="filter-title">
            Data Filters
          </Heading.h4>
          <FilterSection className="filterOpen">
            <CloseFilter className="close-filter" onClick={handleSidebar}>
              <i>Close</i>
            </CloseFilter>
            <Filter
              title={
                this.props.selectedDropdown === 'project' ? 'Project' : 'Agency'
              }
              selected={selectedProject}
              onChange={handleProjectFilter}
              data={projects}
              searchKey={
                this.props.selectedDropdown === 'project'
                  ? 'project name'
                  : 'agency name'
              }
              helpText={
                this.props.selectedDropdown === 'project'
                  ? getHelpText.projectName
                  : getHelpText.entityName
              }
              onHandleClear={handleProjectClear}
            />
            <Filter
              title="Report Years"
              selected={selectedYears}
              showSearch={false}
              searchKey=""
              data={reportingYears}
              helpText="The report of the project."
              onChange={handleYearFilter}
              onHandleClear={handleYearClear}
            />
            <Filter
              title="Payment Types"
              selected={selectedPaymentType}
              showSearch={false}
              searchKey=""
              data={paymentTypes}
              helpText="The payment type of the project."
              onChange={handlePaymentTypeFilter}
              onHandleClear={handlePaymentTypeClear}
            />
            <Filter
              title={
                this.props.selectedDropdown === 'project'
                  ? 'Project Types'
                  : 'Entity Types'
              }
              selected={selectedType}
              showSearch={false}
              searchKey=""
              data={types}
              helpText={
                this.props.selectedDropdown === 'project'
                  ? getHelpText.projectType
                  : getHelpText.entityType
              }
              onChange={handleTypeFilter}
              onHandleClear={handleTypeClear}
            />
            {profile === 'company' ? (
              <Filter
                title="Country"
                selected={selectedCountry}
                onChange={handleCountryFilter}
                data={countries}
                searchKey="country name"
                helpText={getHelpText.projectCountry}
                onHandleClear={handleCountryClear}
              />
            ) : (
              <Filter
                title="Company"
                selected={selectedReportingCompany}
                onChange={handleCompanyFilter}
                data={companies}
                searchKey="reporting company name"
                helpText={getHelpText.reportingCompany}
                onHandleClear={handleCompanyClear}
              />
            )}
            <Filter
              title="Reporting Jurisdiction"
              selected={selectedReportingJurisdiction}
              onChange={handleReportingJurisdictionFilter}
              data={reportingJurisdiction}
              searchKey="jurisdiction Country"
              helpText={getHelpText.reportingJurisdiction}
              onHandleClear={handleReportingJurisdictionClear}
            />
          </FilterSection>
        </div>
      </Sidebar>
    );
  }
}

export default withRouter(SideBarComponent);

const Sidebar = styled.div`
  // @media (max-width: 1366px) {
  box-shadow: 2px 0 490px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  //width: 0;
  //transition: width 0.3s ease-in-out;
  width: 320px;
  background: #4c3154;
  padding: 244px 0 36px;
  min-height: 100vh;
  /* height: calc(100% + 273px); */
  overflow: auto;
  z-index: 10000;
  transition: transform 0.3s ease-in-out;
  /* @media (max-width: 1280px) { */
  position: fixed;
  overflow: auto;
  height: 50vh;
  /* } */
  // }

  &.sidebar-open {
    //width: 320px;
    //transition: width 0.3s ease-in-out;
    transform: translateX(0%);
  }

  &.sidebar-close {
    //opacity: 0;
    /* display: none; */
    transform: translateX(-100%);
    .close-filter {
      display: none;
    }
    // @media screen and (max-width: 1366px) {
    //   opacity: 0;
    // }
  }

  .filter-icon {
    // @media screen and (max-width: 1366px) {
    display: none;
    // }
  }

  .sidebar--inner {
    padding: 0 36px 0;
    width: 300px;
  }

  .filter-title {
    margin-bottom: 10px;
  }
`;

const FilterBy = styled.div`
  margin: 6px 0 12px;
  ${flex('column', null, 'flex-start')};
  span {
    border-radius: 0;
  }
`;
