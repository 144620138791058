import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';

class Axis extends React.Component {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  renderAxis() {
    const node = ReactDOM.findDOMNode(this.refs.axisContainer);
    let axis, yVal;
    const { tickFormat, tickValues, hideLine, scale } = this.props;
    if (this.props.orient === 'bottom') {
      axis = d3.axisBottom(scale).ticks(5);
      yVal = 14;
    } else if (this.props.orient === 'left') {
      axis = d3.axisLeft(scale);
      yVal = 9;
    }

    if (tickValues) axis = axis.tickValues(tickValues);
    if (tickFormat) axis = axis.tickFormat(tickFormat);
    d3
      .select(node)
      .call(axis)
      .selectAll('.tick text')
      .call(this.wrap, yVal);
    if (hideLine)
      d3
        .select(node)
        .selectAll('path')
        .attr('visibility', 'hidden');
  }

  wrap(text, width) {
    text.each(function() {
      var text = d3.select(this),
        words = text.text().split(' '),
        h = words.length > 1 ? '0' : '0.2em',
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy')),
        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', h);
      // if(!parseInt(words)){
      words.map((word, i) => {
        dy = i === 0 ? (h === 0 ? '-0.5em' : h) : '0.8em';
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', width)
          .attr('dy', dy)
          .text(word);
        return true;
      });
    });
  }

  render() {
    return (
      <g
        className={this.props.className}
        ref="axisContainer"
        transform={this.props.translate}
      />
    );
  }
}

export default Axis;
