import React from 'react';
import PropTypes from 'prop-types';
import { axisBottom, axisLeft } from 'd3-axis';
import { select } from 'd3-selection';

class Axis extends React.Component {
  componentDidMount() {
    this.renderAxis();
  }
  componentDidUpdate() {
    this.renderAxis();
  }
  renderAxis() {
    const { node } = this;
    const {
      scale, name,
    } = this.props;

    let axis;
    if (name === 'y-axis') {
      axis = axisLeft(scale);
    }
    if (name === 'x-axis') {
      axis = axisBottom(scale);
    }
    select(node).call(axis);
  }

  render() {
    return (
      <g
        className={`axis ${this.props.name}`}
        ref={node => {
          this.node = node;
        }}
        transform={this.props.translate}
      >
      </g>
    );
  }
}

Axis.propTypes = {
  scale: PropTypes.func,
  orient: PropTypes.string,
  translate: PropTypes.string,
};

export default Axis;
