import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { isENV } from '../helpers';

import Home from './home';
import About from './about';
// import Glossary from './glossary';
// import Analysis from './analysis';
import Data from './data';
import { Country } from './country/';
import NoMatch from './nomatch';
import App from '../components/app';
// import AddSource from '../components/layouts/addSource';
import NewReports from '../screens/data/tabs/data/newReport';
import Subscription from './subscription/';
import SubscriptionMessage from './subscription/subscriptionMessage';
import Company from './company/company';
import CountryProfile from './landing/countryProfile';
import CompanyProfile from './landing/companyProfile/companyProfile';

const pages = [
  { label: 'Project Payments', path: '/projects' },
  { label: 'Gov\'t Entity Payments', path: '/entities' },
  { label: 'Sources', path: '/sources' }
];
class Index extends Component {
  render() {
    return (
      <App>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/glossary" component={Glossary} /> */}

          {/* <Route exact path="/analysis" component={Analysis} /> */}
          <Route exact path="/sources/newReports" component={NewReports} />
          <Route exact path="/sources/newReports/:date" component={NewReports} />

          {/* <Route exact path="/sources/add-source" component={AddSource} /> */}
          {pages.map((page, index) => (
            <Route
              path={page.path}
              render={props => <Data {...props} title={page.label} />}
              key={index}
            />
          ))}

          {process.env.REACT_APP_AWS_BUCKET !== 'production' && (
            <Route exact path="/country/:countryName" component={Country} />
          )}
          <Route exact path="/subscribe" component={Subscription} />
          <Route exact path="/subscribe/update/:id" component={Subscription} />
          <Route
            exact
            path="/unsubscribe/:id"
            component={SubscriptionMessage}
          />
          <Route
            exact
            path="/subscribe/verify/:id"
            component={SubscriptionMessage}
          />
          <Route exact path="/company/:companyName" component={Company} />
          <Route exact path="/country-profiles" component={CountryProfile} />
          <Route exact path="/company-profiles" component={CompanyProfile} />

          <Route exact path="/404" component={NoMatch} />
          <Route component={NoMatch} />
        </Switch>
      </App>
    );
  }
}

export default Index;
