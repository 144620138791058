import L from 'leaflet-headless';
import 'leaflet/dist/leaflet.css';
// import countryTopojson from './country-github.json';

const CountryLayer = ({ map, countryData, countryTopojson }) => {
  const { location, countryName } = countryData;
  const { latitude, longitude } = location;

  // convert topojson to geojson
  const geojson = countryTopojson;
  // .features.filter(
  //   country => country.properties.name === countryName
  // );

  const style = () => {
    let featureStyle;

    featureStyle = {
      weight: 2,
      color: '#4ab3d6',
      fillOpacity: 0
    };

    return featureStyle;
  };
  let layer = L.geoJSON(geojson, {
    style
  });
  layer.addTo(map);

  let bounds = layer.getBounds();
  let pixelWidth = 340;
  var GLOBE_WIDTH = 256; // a constant in Google's map projection
  var west = bounds._southWest.lng;
  var east = bounds._northEast.lng;
  var angle = east - west;
  if (angle < 0) {
    angle += 360;
  }
  var zoom = Math.round(
    Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2
  );

  if (countryName === 'Russian Federation' || countryName === 'United States of America') {
    zoom = zoom + 2;
  }

  window.currentZoom = zoom;
  map.setView([latitude, longitude], zoom);

  // map.fitBounds(layer.getBounds());

  return null;
};

export default CountryLayer;
