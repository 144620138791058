import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Heading,
  Text,
  DownloadImageButton,
  Card
} from '../../../components/styled/ui/';
import { flex, rgba } from '../../../components/styled/utils/';
import {
  CardDropdown,
  Hint,
  NoData,
  Loader
} from '../../../components/layouts/';
import { getAppImages, downloadImage } from '../../../helpers/helper';
import Rgi from './rgi';
import Wgi from './wgi';
import NaturalWealth from './naturalWealth';
import Snapshot from './snapshot';
import CardBoundary from '../../../screens/CardBoundary';

const { helpIcon, downloadChartIcon } = getAppImages;

class ComponentScores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: '',
      rgiIndex: 'Mining',
      rgiKey: [],
      keys: []
    };
  }

  componentWillReceiveProps(nextProps) {
    let { countryData } = nextProps;

    // if ( countryData !== this.props.countryData) {
    if (countryData.length > 0) this.setIndexes(countryData[0]);
    // }
  }

  setIndexes(data) {
    let allKeys, rgiKey, index, rgiIndex;
    if (data instanceof Array) {
      this.setState({ keys: [] });
      return false;
    }
    // allKeys = Object.keys(data).filter(key => {
    //   return (
    //     key !== 'countryName' &&
    //     key !== '_id' &&
    //     key !== 'location' &&
    //     key !== 'Aggregates'
    //   );
    // });
    if(data.RGI){
      allKeys = ['snapshot', 'RGI']
    }else{
      allKeys = ['snapshot']
    }

    index = 'Country Snapshot';
    if (data.RGI) {
      if (data.RGI['Mining']) {
        rgiIndex = 'Mining';
      } else {
        rgiIndex = 'Oil and gas';
      }
      rgiKey = Object.keys(data.RGI).filter(rkey => {
        return rkey;
      });
    }
    // else if (data.WGI) {
    //   index = 'Worldwide Governance';
    // } else if (data['Natural Wealth']) {
    //   index = 'Natural Wealth';
    // }
    this.setState({ keys: allKeys, rgiKey, index, rgiIndex });
  }

  onIndexChange(val) {
    this.setState({ index: val });
  }

  onRGIChange(val) {
    this.setState({ rgiIndex: val });
  }

  mainFilter() {
    let dropDownLabel = [];

    const { keys } = this.state;

    if (keys && keys.length > 0) {
      keys.forEach(key => {
        if (key === 'RGI')
          dropDownLabel.push({
            label: 'Resource Governance',
            value: 'Resource Governance'
          });
        else if (key === 'snapshot')
          dropDownLabel.push({
            label: 'Country Snapshot',
            value: 'Country Snapshot'
          });
        // else if (key === 'WGI')
        //   dropDownLabel.push({
        //     label: 'Worldwide Governance',
        //     value: 'Worldwide Governance'
        //   });
        // else if (key === 'Natural Wealth')
        //   dropDownLabel.push({
        //     label: 'Natural Wealth',
        //     value: 'Natural Wealth'
        //   });
      });
      return dropDownLabel;
    }
  }

  rgiFilter() {
    const { rgiKey } = this.state;
    let dropDownLabel = [];

    if (rgiKey && rgiKey.length > 0) {
      rgiKey.forEach(key => {
        if (key === 'Oil and gas')
          dropDownLabel.push({ label: 'Oil and gas', value: 'Oil and gas' });
        else if (key === 'Mining')
          dropDownLabel.push({ label: 'Mining', value: 'Mining' });
      });
      // this.onRGIChange(dropDownLabel[0].value);
      return dropDownLabel;
    }
  }

  handleDownload = () => {
    if (this.state.index === 'Resource Governance') {
      downloadImage(
        'country-stats-legend',
        `${this.props.countryName}-${this.state.index}-Legends-Resource-Projects`
      );
    }
    downloadImage(
      'country-stats',
      `${this.props.countryName}-${this.state.index}-Resource-Projects`
    );
  };

  renderComponent() {
    let { countryData, snapShotData } = this.props;

    let rgiData, wgiData, naturalWealth;
    if (countryData && countryData.length) {
      rgiData = countryData[0].RGI;
      wgiData = countryData[0].WGI;
      naturalWealth = countryData[0]['Natural Wealth'];
    }

    switch (this.state.index) {
      case 'Country Snapshot':
        return <Snapshot snapShotData={snapShotData} />;
      case 'Resource Governance':
        return <Rgi rgiData={rgiData} rgiIndex={this.state.rgiIndex} />;
      // case 'Worldwide Governance':
      //   return <Wgi wgiData={wgiData} />;
      // case 'Natural Wealth':
      //   return (
      //     <NaturalWealth
      //       countryName={this.props.countryName}
      //       naturalWealth={naturalWealth}
      //     />
      //   );
      default:
        return '';
    }
  }

  renderHeader() {
    let { countryData, countryName } = this.props;
    let mainFilter = this.mainFilter();
    let rgiFilter = this.rgiFilter();
    if (countryData && countryData.length === 0) return null;
    let helpText = '';

    if (this.state.index === 'Resource Governance') {
      // helpText = `This chart shows the 2017 Resource Governance Index scores for ${this.props.countryName}. The colors indicate the performance of ${this.props.countryName} compared to other countries in the area of managing oil, gas and mining resources.`;
      helpText = `The 2017 Resource Governance Index from NRGI measures the quality of governance in the oil, gas and mining sectors of 81 countries.`;
    } else if (this.state.index === 'Worldwide Governance') {
      helpText = `This chart shows the World Governance Indicator for ${this.props.countryName}. Hover on the chart to observe how these indicators have moved over the years.`;
    } else {
      // helpText =
      //   'This chart is based on wealth accounting data from the World Bank. It shows the reliance of a country on natural resources when compared to other forms of wealth such as human, and agricultural capital.';
      helpText =
        `Summary of payment data disclosed in ${this.props.countryName}. Projects and entities are classified by NRGI when possible.`;
    }
    return (
      <Header data-ignore={true}>
        <div>
          <Hint
            title={<img src={helpIcon} alt="Outlier help" />}
            text={helpText}
          />

          {mainFilter.length > 1 ? (
            <CardDropdown
              onChange={val => this.onIndexChange(val)}
              items={mainFilter}
            />
          ) : (
            <div className="rgi-type">
              <Heading.h4 uppercase style={{ padding: '0 8px' }}>
                {mainFilter[0] ? mainFilter[0].label : 'n/a'}
              </Heading.h4>
            </div>
          )}

          {this.state.index === 'Resource Governance' &&
            (rgiFilter.length > 1 ? (
              <div className="rgi-type">
                <Text.span>of</Text.span>
                <CardDropdown
                  onChange={val => this.onRGIChange(val)}
                  items={rgiFilter}
                />
              </div>
            ) : (
              <div className="rgi-type">
                <Text.span>of</Text.span>
                <Heading.h4 uppercase style={{ padding: '0 8px' }}>
                  {rgiFilter[0].label}
                </Heading.h4>
              </div>
            ))}

          {this.state.index !== 'Country Snapshot' && (
            <Heading.h4 className="title-country" bold>
              in {countryName}
            </Heading.h4>
          )}
        </div>
      </Header>
    );
  }

  renderNoData() {
    return this.props.countryFetched && this.props.countryData.length !== 0 ? (
      <NoData countryName={this.props.countryName} />
    ) : (
      ''
    );
  }

  render() {
    let indexList = this.state.keys;
    let sourceLink = '',
      sourceText = '';
    if (this.state.index === 'Resource Governance') {
      sourceLink = 'http://resourcegovernanceindex.org';
      sourceText = '2017 Resource Governance Index, NRGI.';
    }
    // else if (this.state.index === 'Worldwide Governance') {
    //   sourceLink = 'http://info.worldbank.org/governance/wgi/#home';
    //   sourceText = 'Worldwide Governance Indicators, World Bank.';
    // } else {
    //   sourceLink =
    //     'http://databank.worldbank.org/data/reports.aspx?source=wealth-accounts';
    //   sourceText = 'Wealth Accounts, World Bank';
    // }
    return (
      <React.Fragment>
        <FlexDownload>
          <HelpText>
            <Hint
              title={<img src={helpIcon} alt="Outlier help" />}
              text={
                "This section provides an overview of the data disclosed in this country. Use the drop down to view data from the 2017 Resource Governance Index for select countries."
              }
            />
            <Text.span size={12} light>
              Country overview section
            </Text.span>
          </HelpText>
          <DownloadImageButton
            className="download-image"
            onClick={this.handleDownload}
          >
            <img src={downloadChartIcon} alt="Download Chart" />
            <span>Download {this.state.index === "Country Snapshot" ? 'Snapshot' : 'Chart'} </span>
          </DownloadImageButton>
        </FlexDownload>
        <Card style={{ height: '92%', padding: "28px 40px 28px" }}>
          <CardBoundary>
            <Content>
              {!this.props.countryFetched && <Loader />}
              {this.props.countryFetched && indexList.length > 0 ? (
                <div>
                  {this.renderHeader()}
                  <div> {this.renderComponent()} </div>
                </div>
              ) : (
                this.renderNoData()
              )}
              {this.props.countryFetched &&
              indexList.length &&
              this.state.index !== 'Country Snapshot' ? (
                <div data-ignore={true}>
                  <IndexSource>
                    <Text.span size={11}>Source:</Text.span>
                    <Link
                      to={sourceLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {sourceText}
                    </Link>
                  </IndexSource>
                </div>
              ) : (
                ''
              )}
            </Content>
          </CardBoundary>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  countryData: state.country.countryData,
  snapShotData: state.country.snapShotData,
  countryFetching: state.country.countryFetching,
  countryFetched: state.country.countryFetched
});

export default connect(mapStateToProps)(ComponentScores);

const Content = styled.div`
  /* padding: 0 4px 0 4px; */
`;

const Header = styled.div`
  ${flex('row', 'space-between', 'center')};
  margin-bottom: 28px;

  .dropdown-title {
    margin-right: 12px;
    ${flex(null, null, 'center')};
    white-space: nowrap;
    .title {
      white-space: nowrap;
    }
  }

  > div {
    ${flex('row', null, 'center')};
    flex-wrap: wrap;
    .title-country {
      color: ${rgba('#3a424c', 0.7)};
    }
    > span:first-child {
      margin-right: 4px;
    }
  }

  .rgi-type {
    ${flex('row', null, 'center')};
    white-space: nowrap;
    .dropdown-title {
      margin-left: 12px;
    }
    .title-country {
      white-space: nowrap;
    }
  }
`;

const IndexSource = styled.div`
  margin-top: 14px;
  span {
    margin-right: 4px;
  }
  a {
    color: ${props => props.theme.primary.primary};
    font-size: 11px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const HelpText = styled.div`
  /* margin-bottom: 4px; */

  > span {
    &:nth-child(1) {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      & + span {
        text-transform: uppercase;
      }
    }
  }
`;

const FlexDownload = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  position: relative;
`;
