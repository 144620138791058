import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from '../../../components/styled/utils/';
import CompanyService from '../../../services/company';
import * as companyActions from '../../../actions/company';
import ReportingCompanyTable from './ReportingCompanyTable';
import FilterCountry from './filterCountry';
import { Pagination } from '../../../components/layouts';
import { Helmet } from 'react-helmet';
// import CompanyDropdown from './companyDropdown';

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: [],
      selectedJurisdiction: [],
      selectedCompany: '',
      page: 1,
      sortBy: 'totalPayment',
      order: 'asc'
    };
  }

  componentWillMount() {
    if (window) {
      window.scrollTo(0, 0);
    }
    this.props.dispatchCompanyTable();
  }

  // componentDidUpdate() {
  //   const { maxComponent } = this.props.stats;
  //   if (maxComponent) this.props.dispatchCompanyTable(maxComponent);
  // }

  tableList = () => {
    const { list } = this.props;
    const {
      selectedCountry,
      selectedJurisdiction,
      page,
      sortBy,
      order,
      selectedCompany
    } = this.state;

    let filteredList = list;

    if (selectedCompany.length) {
      filteredList = filteredList.filter(element => {
        let companyName = element._id.toLowerCase();
        return companyName.includes(selectedCompany.toLowerCase());
      });
    }

    if (selectedCountry.length) {
      filteredList = filteredList.filter(element => {
        for (const i in selectedCountry) {
          if (element.country.includes(selectedCountry[i])) return element;
        }
        return false;
      });
    }

    if (selectedJurisdiction.length) {
      filteredList = filteredList.filter(element => {
        for (const i in selectedJurisdiction) {
          if (element.reportingJurisdiction === selectedJurisdiction[i])
            return element;
        }
        return false;
      });
    }
    // const filteredList =
    //   country.length || reportingJurisdiction.length || company.length > 2
    //     ? [...new Set([...countryFilter, ...reportingJurisdictionFilter, ...companyFilter])]
    //     : list;
    const offset = page * 25 - 25;
    filteredList.sort((c, d) => {
      let a, b;
      if (order === 'asc') {
        a = c;
        b = d;
      } else {
        a = d;
        b = c;
      }
      if (sortBy === 'reports') return b[sortBy].length - a[sortBy].length;
      else if (sortBy === '_id') return a[sortBy].localeCompare(b[sortBy]);
      else return b[sortBy] - a[sortBy];
    });
    const listItem = filteredList.slice(offset, page * 25);
    const totalPage = Math.ceil(filteredList.length / 25);
    return { listItem, totalPage };
  };

  onSort = sortBy => {
    const { order } = this.state;
    const newOrder = order.toLowerCase() === 'asc' ? 'desc' : 'asc';
    this.setState({ sortBy, order: newOrder });
  };

  handleCompany = e =>
    this.setState({ selectedCompany: e.target.value, page: 1 });

  handleCountry = country =>
    this.setState({
      selectedCountry: country,
      page: 1
    });

  handleJurisdiction = jurisdiction =>
    this.setState({ selectedJurisdiction: jurisdiction, page: 1 });

  render() {
    const { stats, list } = this.props;
    const {
      page,
      order,
      sortBy,
      selectedCompany,
      selectedCountry,
      selectedJurisdiction
    } = this.state;
    let countryData = list;
    let jurisdictionData = list;

    if (selectedCompany.length) {
      countryData = countryData.filter(element => {
        let companyName = element._id.toLowerCase();
        return companyName.includes(selectedCompany.toLowerCase());
      });
      jurisdictionData = jurisdictionData.filter(element => {
        let companyName = element._id.toLowerCase();
        return companyName.includes(selectedCompany.toLowerCase());
      });
    }

    // }

    if (selectedCountry.length) {
      jurisdictionData = jurisdictionData.filter(element => {
        for (const i in selectedCountry) {
          if (element.country.includes(selectedCountry[i])) return element;
        }
        return false;
      });
    }

    if (selectedJurisdiction.length) {
      countryData = countryData.filter(element => {
        for (const i in selectedJurisdiction) {
          if (element.reportingJurisdiction === selectedJurisdiction[i])
            return element;
        }
        return false;
      });
    }

    return (
      <CompanyPage>
        <Helmet>
          <title>{'Company Profiles'}</title>
          <meta
            name="og:description"
            content={`Since 2014, <strong>${stats.reportingCompanyNumber ||
              0}</strong> companies have disclosed extractive sector payments
              totalling <strong>USD ${stats.totalPayment || 0}</strong> under
              mandatory disclosure laws.`}
          />
          <meta
            name="og:title"
            content={
              'Company Profiles - Resource Projects : An open-source data repository on oil, gas and mining projects'
            }
          />
        </Helmet>
        <section className="section__top">
          <div>
            <h1>Company Profiles</h1>
            <p>
              Since 2014, <strong>{stats.reportingCompanyNumber || 0}</strong>{' '}
              companies have disclosed extractive sector payments totalling{' '}
              <strong>USD {stats.totalPayment || 0}</strong> under mandatory
              disclosure laws.
            </p>
          </div>
        </section>
        <section className="section__content">
          <div className="static-page">
            <div className="select-company">
              <input
                type="text"
                autoComplete="none"
                value={selectedCompany}
                placeholder="Filter by typing a Reporting Company's name. Eg. Repsol S.A."
                onChange={this.handleCompany}
              />
              {/* <CompanyDropdown companyList={companyList} /> */}
            </div>
            <FilterCountry
              countryData={countryData}
              jurisdictionData={jurisdictionData}
              onChangeCountry={this.handleCountry}
              onChangeJurisdiction={this.handleJurisdiction}
            />
            <ReportingCompanyTable
              list={this.tableList().listItem}
              sortHandle={this.onSort}
              order={order}
              sortBy={sortBy}
            />
            <Pagination
              total={this.tableList().totalPage}
              current={page}
              onPageClick={({ selected }) => {
                this.setState({ page: selected + 1 });
                window.scroll(0, 200);
              }}
            />
          </div>
        </section>
      </CompanyPage>
    );
  }
}

const mapStateToProps = state => ({
  list: state.company.companyTable.list,
  stats: state.company.companyTable.stats
});

const mapDispatchToProps = dispatch => ({
  // dispatchCompanyStats: () => {
  //   CompanyService.companyStats().then(response => {
  //     dispatch(companyActions.companyProfileFetched(response));
  //   });
  //   // CompanyService.companyDetails()
  // },
  dispatchCompanyTable: () => {
    dispatch(companyActions.companyTableFetching());
    CompanyService.companyTable().then(response => {
      dispatch(companyActions.companyTableFetched(response));
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfile);

const CompanyPage = styled.div`
  .section__top {
    padding: 50px 0;
    ${media.sm`padding: 50px 0px 230px;`}
    > div {
      position: relative;
      max-width: 640px;
      text-align: center;
      z-index: 100;
      margin: 35px auto 0px;
    }
    h1 {
      font-size: 24px;
      line-height: 1.4;
      color: ${props => props.theme.neutral.darkWhite};
      font-weight: 700;
      margin-bottom: 18px;
      padding: 0 20px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      color: ${props => props.theme.neutral.darkWhite};
      margin-bottom: 60px;
      padding: 0 20px;
    }
    strong {
      font-weight: bold;
    }
  }
  .section__content {
    background-color: ${props => props.theme.neutral.darkWhite};
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      max-width: 768px;
      max-height: 600px;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: rgba(0, 0, 0, 0.2) 0px -34px 50px -10px;
      z-index: 25;
      background-image: linear-gradient(-180deg, #ffffff 0%, #eceaf1 100%);
      ${media.sm`margin-top: -110px; border-radius: 12px;`}
    }
  }

  .select-company {
    background-color: #faf8ff;
    border-bottom: 1px solid #e7eaed;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    ${media.sm`margin-top: -127px`};
    > div {
      border: 1px solid #3a424c;
      border-radius: 25px;
      height: 50px;
      margin: 0 auto;
      width: 296px;
    }
    h2 {
      color: #3a424c;
      font-size: 12px;
      text-transform: uppercase;
    }
    p {
      color: rgba(58, 66, 76, 0.5);
      padding-top: 20px;
      text-align: center;
    }
    input {
      background: transparent;
      border: 0;
      color: #3a424c;
      font-size: 14px;
      height: 60px;
      padding: 0 28px 0 60px;
      width: 100%;
      &:focus {
        outline: 0;
      }
      background: url(../../images/ic_search.svg) no-repeat 28px;
      @media all and (min-width: 600px) {
        height: 110px;
        font-size: 16px;
      }
    }
  }

  .static-page {
    position: relative;
    max-width: 768px;
    z-index: 100;
    min-height: 400px;
    padding: 8px 0 54px;
    ${media.sm`margin: -120px auto 0px; padding: 16px 0 0;`}
    .pagination {
      background: ${props => props.theme.primary.white};
      li a.prev,
      li a.next {
        color: ${props => props.theme.primary.dark};
      }
    }
  }

  @media all and (max-width: 640px) {
    .pagination li a,
    .pagination li span {
      color: inherit !important;
    }
  }
`;
