import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet-headless';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

class BaseLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null
    };
  }
  componentDidMount() {
    this.init();
  }
  componentDidUpdate(nextProps) {
    if (nextProps.countryName !== this.props.countryName) {
      this.init();
    }
  }
  init() {
    const { map } = this.state;
    if (map) map.remove();

    const { mapNode } = this;

    const mapLayer = L.map(mapNode, {
      scrollWheelZoom: false,
      fullscreenControl: true
    });

    this.setState({ map: mapLayer });
  }
  sendPropsToChildren() {
    const { map } = this.state;
    const data = this.props.countryData;
    const countryData = data[0];
    const projectData = data[1];
    const countryTopojson = data[2];
    if (countryData && !Object.keys(countryData).length) return null;
    return React.Children.map(this.props.children, function(child) {
      return React.cloneElement(child, {
        countryData,
        projectData,
        map,
        countryTopojson
      });
    });
  }
  render() {
    const { map } = this.state;

    return (
      <div
        style={{ height: '100%', width: '100%' }}
        ref={node => {
          this.mapNode = node;
        }}
      >
        {map && this.props.children && this.sendPropsToChildren()}
      </div>
    );
  }
}

BaseLayer.propTypes = {
  children: PropTypes.node,
  countryData: PropTypes.array
};

export default BaseLayer;
