import React from 'react';

// let debounceCountryName;

class CountryStrokeLayer extends React.Component {
  shouldComponentUpdate(nextProps) {
    return true;
    // return this.props.parentWidth !== nextProps.parentWidth || this.props.zoomTransform !== nextProps.zoomTransform;
  }
  render() {
    const { name, path, zoomTransform, k } = this.props;

    const strokeWidth = (name === 'second-stroke-layer' ? 20 : 7) / k;
    return (
      <g transform={zoomTransform} className={name}>
        {this.props.countriesGeojson.features.map((d, key) => {
          return <path d={path(d)} key={key} style={{strokeWidth}} />;
        })}
      </g>
    );
  }
}

export default CountryStrokeLayer;
