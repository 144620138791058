import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../.././styled/ui/';

class TextTruncate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textTruncate: true
    };

    this.truncate = this.truncate.bind(this);
  }

  truncate(value, length) {
    if (this.state.textTruncate) {
      return value.substring(0, length);
    } else {
      return value;
    }
  }

  render() {
    const { text, truncateLength } = this.props;

    return (
      <TextContent>
        <Text.span
          className={this.state.textTruncate ? 'is-closed' : ''}
          size={12}
          light
        >
          {this.truncate(text, truncateLength)}
        </Text.span>
        <Text.span
          size={12}
          bold
          light
          onClick={() =>
            this.setState(prevState => ({
              textTruncate: !prevState.textTruncate
            }))
          }
        >
          {this.state.textTruncate ? 'Learn more' : 'Show Less'}
        </Text.span>
      </TextContent>
    );
  }
}

export default TextTruncate;

const TextContent = styled(Text)`
  line-height: 1.2;
  display: inline-block;

  > span {
    &.is-closed {
      &:after {
        content: '...';
      }
    }

    &:nth-child(2) {
      cursor: pointer;
      font-weight: 600;
      transition: color 0.1s ease-in-out;
      margin-left: 4px;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
`;
