import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { List, AutoSizer } from 'react-virtualized';
import { FilterWrapper, SearchInput } from '../../styled';
import { Checkbox } from './checkbox';
import 'react-scrollbar/dist/css/scrollArea.css';
import $ from 'jquery';
import { Burger, Hint } from '../../layouts/';
import { getAppImages } from '../../../helpers/helper';

const { helpIcon } = getAppImages;

export class Filter extends Component {
  constructor(props) {
    super(props);
    this.separator = ':';
    this.state = {
      data: [],
      original: [],
      elements: [],
      showSearch: true
    };
    this._handleOnChange = this._handleOnChange.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  componentDidMount() {
    let { data, showSearch, selected } = this.props;
    showSearch = typeof showSearch === 'undefined' ? true : showSearch;

    let elements = [];
    if (typeof selected === 'string' && selected.length > 0) {
      elements = selected.split(this.separator);
    }

    this.setState({ data, original: data, showSearch, elements });
  }

  componentWillReceiveProps(nextProps) {
    let { selected, data } = nextProps;
    let elements = [];
    if (typeof selected === 'string' && selected.length > 0) {
      elements = selected.split(this.separator);
    }
    // console.log(elements, data);
    this.setState({ elements: elements, data: data, original: data });
    let searchEle = ReactDOM.findDOMNode(this.searchInput);
    // console.log(searchEle);
    searchEle && this._handleOnChange(searchEle);
  }

  _onSelect(event) {
    let { value, checked } = event.target;
    let { elements } = this.state;
    let { onChange } = this.props;

    if (checked) {
      elements.push(value);
    } else {
      elements = elements.filter(function(val) {
        return val !== value;
      });
    }

    this.setState({ elements });

    onChange(elements);
  }

  _renderOptions() {
    let { elements, data } = this.state;
    data = data.length ? data : this.props.data;

    let checked = false;
    return (
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowHeight={20}
            style={{ outline: 'none' }}
            rowRenderer={({ index, style }) => {
              checked = elements.indexOf(data[index]) > -1;
              return (
                <Checkbox
                  className="option"
                  checked={checked}
                  label={data[index]}
                  style={style}
                  onCheck={this._onSelect.bind(this)}
                  key={index}
                  value={data[index]}
                />
              );
            }}
            rowCount={data.length}
            overscanRowCount={3}
          />
        )}
      </AutoSizer>
    );
  }

  _handleOnChange(e) {
    let { data, original } = this.state;
    e = e.target || e;
    let search = e.value;
    if (search.length > 0) {
      data = original.filter(row => {
        let status = false;

        if (row.toLowerCase().indexOf(search.toLowerCase().trim()) !== -1) {
          status = true;
        }

        return status;
      });
    } else {
      data = original;
    }
    this.setState({ data });

    e.parentElement.querySelector('.scrollarea').style.marginTop = 0;
  }

  toggleFilter(el, showClear) {
    el.persist();
    //onclick handler of clear button
    if (el.target.classList.contains('clear')) {
      this.props.onHandleClear(this.props.filterName);
    } else {
      $(ReactDOM.findDOMNode(this.refs.filterBody))
        .stop()
        .slideToggle('linear', () => {
          //@important: burger component toggle is set from here
          if (el.target.classList.contains('filter-title')) {
            // filter bar
            el.target.parentNode
              .querySelector('.hamburger-icon')
              .classList.toggle('active');
          } else if (
            // hamburger + icon
            el.target.parentElement.classList.contains('hamburger-icon')
          ) {
            el.target.parentElement.classList.toggle('active');
          } else {
            if (!el.target.parentElement.classList.contains('icon')) {
              el.target.parentElement
                .querySelector('.hamburger-icon')
                .classList.toggle('active');
            }
          }
        });
    }
  }

  render() {
    let { title, searchKey, helpText, scrollable = true } = this.props;
    let { showSearch, elements } = this.state;
    let showClear = elements.length;

    return (
      <FilterWrapper showSearch={showSearch} scrollable={scrollable}>
        <div
          className="filter-header"
          onClick={el => this.toggleFilter(el, showClear)}
        >
          <p className="filter-title">
            <Hint
              title={<img src={helpIcon} alt="Outlier help" />}
              text={helpText}
            />
            {title}
          </p>
          <div className="filter-header--right">
            {showClear !== 0 && <span className="clear">Clear</span>}
            <Burger />
          </div>
        </div>
        <div className="filter-body" ref="filterBody">
          {showSearch && (
            <SearchInput
              ref={input => {
                this.searchInput = input;
              }}
              autoComplete="none"
              onChange={this._handleOnChange}
              type="text"
              placeholder={`Type a ${searchKey.toLowerCase()}...`}
            />
          )}

          <div className="scrollarea options" style={{ height: '300px' }}>
            {this._renderOptions()}
          </div>
        </div>
      </FilterWrapper>
    );
  }
}

export default Filter;
