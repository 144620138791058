import React from 'react';
import { connect } from 'react-redux';
import numberFormatter from '../../utils/numberFormatter';
import styled from 'styled-components';
import { media } from '../../components/styled/utils';

class Legend extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { mapData } = this.props;
    const barWidth = 60;
    const topFiveThresholdIndex = Math.round(0.05 * mapData.length);

    if (mapData.length === 0) return null;

    const oneFifthPayment = Math.floor(
      mapData[topFiveThresholdIndex].payment / 5
    );

    const domain = [
      0,
      oneFifthPayment,
      2 * oneFifthPayment,
      3 * oneFifthPayment,
      4 * oneFifthPayment,
      5 * oneFifthPayment,
      mapData[0].payment // max value
    ];

    const colors = [
      '#8CC1D9',
      '#67A9CF',
      '#3690BF',
      '#1D8A76',
      '#006C59',
      '#035945'
    ];

    return (
      <LegendWrap>
        <div
          style={{
            color: '#3A424C',
            fontSize: 11,
            fontWeight: 'bold',
            marginTop: 14,
            marginBottom: 14,
            textAlign: 'left'
          }}
        >
          Payments in USD
        </div>
        <div className="legend-bar">
          {colors.map(color => (
            <div
              key={color}
              style={{
                width: barWidth,
                height: 8,
                backgroundColor: color
              }}
            />
          ))}
        </div>
        <div className="legend-label">
          {domain.map((value, key) => (
            <div
              key={key}
              style={{
                width: barWidth,
                height: 10
              }}
            >
              <span
                key={value}
                style={{
                  display: 'flex',
                  fontSize: 11,
                  marginLeft: value === 0 ? 0 : -10,
                  marginTop: 3,
                  fontWeight: 'bold',
                  width: 100
                }}
              >
                {key < domain.length - 2
                  ? numberFormatter(value)
                  : `${numberFormatter(value)} and above`}
              </span>
            </div>
          ))}
        </div>
      </LegendWrap>
    );
  }
}

const mapStateToProps = state => {
  return {
    mapData: state.project.mapData
  };
};

export default connect(mapStateToProps)(Legend);

const LegendWrap = styled.div`
  bottom: 0;
  position: absolute;
  z-index: 9999;
  transform: translateX(-50%);
  left: 50%;
  ${media.sm`bottom: 100px;`}
  .legend-bar {
    display: flex;
    justify-content: center;
    @media all and (max-width: 420px) {
      > div {
        width: 46px !important;
      }
    }
  }
  .legend-label {
    display: flex;
    > div:last-child {
      display: none;
    }
    @media all and (max-width: 420px) {
      > div {
        width: 42px !important;
        > span {
          font-size: 10px !important;
        }
        &:first-child > span {
          margin-left: 0 !important;
        }
      }
    }
  }
`;
