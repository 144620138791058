import React, { Component } from 'react';
import Select2 from 'react-select2-wrapper';
import '../../../styles/select2.css';

class Select2Component extends Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSelectChange() {
    this.getYears();
  }

  componentWillUnmount() {
    document.querySelector(
      '.select2-container--open .select2-dropdown'
    ) &&
      document
        .querySelector('.select2-container--open .select2-dropdown')
        .removeEventListener('mouseleave', () => {
          this.refs[this.props.reference].el.select2('close');
        });
  }

  getYears() {
    let sel = this.props.reference;
    let source = this.refs[sel].el.select2('data');

    let years = [];
    source.forEach(selectedData => {
      years.push(parseInt(selectedData.text, 10));
    });

    years = years.length > 0 ? years : ['all'];

    return this.props.onSelectChange(years);
  }

  handleClose() {
    this.getYears();
  }

  render() {
    const { data, value, reference, placeholder } = this.props;
    return (
      <div
        onMouseEnter={() => this.refs[this.props.reference].el.select2('open')}
        onMouseLeave={e => {
          // setTimeout(()=> {
          //   this.refs[this.props.reference].el.select2('close');
          // }, 100);
          if (
            typeof e.relatedTarget.querySelector === 'function' &&
            e.relatedTarget.querySelector(
              '.select2-container--open .select2-dropdown'
            ) === null
          ) {
            this.refs[this.props.reference].el.select2('close');
          } else {
            document.querySelector(
              '.select2-container--open .select2-dropdown'
            ) &&
              document
                .querySelector('.select2-container--open .select2-dropdown')
                .addEventListener('mouseleave', () => {
                  this.refs[this.props.reference].el.select2('close');
                });
          }
          // console.log();
        }}
      >
        <Select2
          multiple
          // allowClear="true"
          ref={reference}
          value={value}
          data={data}
          onSelect={this.handleSelectChange}
          onUnselect={this.handleClose}
          options={{
            placeholder: placeholder
          }}
        />
      </div>
    );
  }
}

Select2Component.defaultProps = {
  data: {},
  reference: '',
  placeholder: '',
  value: ''
};
export default Select2Component;
