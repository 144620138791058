import styled from 'styled-components';
import { getAppImages } from '../../helpers';
const { siteBg } = getAppImages;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: url(${siteBg}) no-repeat fixed 0 0 / cover;
`;
export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Container = styled.div`
  width: 100%;
  margin: 0px auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const TableContainer = styled.div`
  max-width: 100%;
  position: relative;
  // margin: 0px 36px;
  @media (max-width: 480px) {
    margin: 0px 10px;
  }
`;
export const FlexContainer = Container.extend`
  display: flex;
`;
export const TableFilterWrapper = styled.div`
  // display: flex;
  //margin-top: 34px;
  position: relative;
`;
export const Card = styled.div`
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 25px 40px -20px rgba(0, 0, 0, 0.5);
  padding: 8px;
`;
