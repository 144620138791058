import React, { Component } from 'react';
import styled from 'styled-components';

class CompanyStats extends Component {
  render() {
    const { totalPayment, reportNumber } = this.props;

    return (
      <StatsList>
        <StatsListItem>
          <span className="payment__highlight">
            <span className="payment__cost">
              <strong>
                {totalPayment.indexOf('-') === -1
                  ? `$${totalPayment}`
                  : `-$${totalPayment.slice(1)}`}
              </strong>
            </span>
          </span>
          <span className="payment__type">Payments</span>
        </StatsListItem>
        <StatsListItem>
          <span className="payment__highlight">
            <span className="payment__count">
              <strong>{reportNumber}</strong>
            </span>
          </span>
          <span className="payment__type">Reports</span>
        </StatsListItem>
      </StatsList>
    );
  }
}

export default CompanyStats;

const StatsList = styled.ul`
  list-style-type: none;
`;

const StatsListItem = styled.li`
  color: ${props => props.theme.neutral.darkWhite};
  display: inline-block;
  vertical-align: middle;
  &:first-child {
    @media all and (max-width: 460px) {
      margin-bottom: 20px;
    }
  }

  &:not(last-child) {
    margin-right: 50px;
  }
  &:last-child {
    margin-right: 0;
  }

  .payment {
    &__highlight,
    &__type {
      display: block;
    }

    &__highlight {
      margin-bottom: 6px;
    }

    &__cost,
    &__count {
      font-size: 28px;
      > strong {
        font-weight: 600;
      }
    }
    &__type {
      text-transform: uppercase;
      font-weight: ${props => props.theme.fontWeight.bold};
      font-size: ${props => props.theme.fontSize.xs + 'px'};
    }
  }
`;
