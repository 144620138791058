const initialState = {
  fetching: true,
  fetched: false,
  data: [],
  total: 0,
  totalPayment: '',
  totalPayments: 0,
  perPage: 25,
  currentPage: 1,
  filter: {
    countries: '',
    reportingCompanies: '',
    paymentTypes: '',
    reportingJurisdiction: ''
  },
  sortBy: 'entityName',
  order: 'asc',
  query: '',
  error: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'ENTITY_FETCHING':
      state = { ...state, ...{ error: null, fetching: true } };
      break;

    case 'ENTITY_SET_CURRENT_PAGE':
      state = { ...state, ...{ currentPage: parseInt(payload, 10) } };
      break;

    case 'ENTITY_SET_FILTER':
      state = { ...state, ...{ filter: payload } };
      break;

    case 'ENTITY_SET_SORT':
      state = { ...state, ...{ sortBy: payload.sortBy, order: payload.order } };
      break;

    case 'ENTITY_SET_QUERY':
      state = { ...state, ...{ query: payload } };
      break;

    case 'ENTITY_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          data: payload.result,
          total: payload.total,
          totalPayment: payload.totalPayment,
          totalPayments: payload.totalPayments,
          fetching: false,
          fetched: true
        }
      };
      break;

    case 'ENTITY_ERROR':
      state = {
        ...state,
        ...{ error: payload, fetching: false, fetched: true }
      };
      break;
    default:
      break;
  }
  return state;
};

export default reducer;
