/*global process*/
import domtoimage from 'dom-to-image';
import { left } from 'glamor';
import { Style } from 'glamorous';
import watermark from 'watermarkjs';

export const analytics = pathname => {
  pathname = pathname || document.location.pathname;
  if (window.ga && process.env.REACT_APP_ENV === 'production') {
    window.ga('set', 'page', pathname);
    window.ga('send', 'pageview');
  }
};

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getFlag = code => {
  code = code && code.toLowerCase();
  return `https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/${code}.png`;
};

export const isENV = env => {
  return env === process.env.REACT_APP_ENV;
};

export const getHeader = {
  projects: 'Project Payments',
  entities: "Gov't Entity Payments",
  sources: 'Sources',
  country: 'Country Profiles',
  company: 'Company Profiles'
};

export const getHelpText = {
  entityName: 'The name of agency',
  projectName: 'The name of project',
  reportStartDate: 'The end of the fiscal reporting period',
  projectCountry: 'The name of the country where the project is located.',
  entityCountry: 'The name of the country where the agency is located.',
  projectType: 'The name of project type',
  entityType: 'The name of entity type',
  reportingJurisdiction:
    'The name of the country where the payment disclosure was made.',
  reportingCompany:
    'The name of the company that reported a particular payment.',
  paymentTypes:
    'The seven categories into which disclosed payments must be classified, in accordance with the EU directive.'
};

export const bucketEndPoint =
  'https://' +
  process.env.REACT_APP_AWS_BUCKET +
  '.s3.amazonaws.com/resourceImages/';

export const getAppImages = {
  EntityBgImg: bucketEndPoint + 'entity-payments-img.jpg',
  ProjectBgImg: bucketEndPoint + 'project-payments-img.jpg',
  SourcesBgImg: bucketEndPoint + 'sources-img.jpg',
  StaticPageBg: bucketEndPoint + 'static-page-bg.jpg',
  FilterIconSvg: bucketEndPoint + 'filter-icon.svg',
  // NRGILogo: bucketEndPoint + 'nrgi-logo.png',
  HeroImage: bucketEndPoint + 'hero-img.jpg',
  ReportIcon: bucketEndPoint + 'report-icon.svg',
  ExternalLinkIcon: bucketEndPoint + 'external-link-icon.svg',
  NoteIcon: bucketEndPoint + 'note-icon.svg',
  NoteIconWhite: bucketEndPoint + 'note-icon-white.svg',
  NRGILogo3x: bucketEndPoint + 'nrgi-logo@3x.png',
  DownloadIcon: bucketEndPoint + 'download-csv-icon.svg',
  SortIcon: bucketEndPoint + 'ic_sort.svg',
  siteBg: bucketEndPoint + 'site-bg.jpg',
  helpIcon: bucketEndPoint + 'help-icon.svg',
  resetIcon: bucketEndPoint + 'reset-icon.svg',
  viewAllIcon: bucketEndPoint + 'view-all-icon.svg',
  dataIcon: bucketEndPoint + 'data-icon.svg',
  graphIcon: bucketEndPoint + 'graph-icon.svg',
  noDataIcon: bucketEndPoint + 'no-data.svg',
  rpLogo: bucketEndPoint + 'RP-logo.png',
  pdfIcon: bucketEndPoint + 'ic_pdf.svg',
  successIcon: bucketEndPoint + 'ic_success.svg',
  errorIcon: bucketEndPoint + 'ic_error.svg',
  arrowRightIcon: bucketEndPoint + 'ic_arrow-right.svg',
  companyIcon: bucketEndPoint + 'ic_company.svg',
  infoIcon: bucketEndPoint + 'ic_info.svg',
  mapIcon: bucketEndPoint + 'ic_map.svg',
  wrongIcon: bucketEndPoint + 'ic_wrong.svg',
  downloadChartIcon: bucketEndPoint + 'ic_download-chart.svg',
  NRGILogo: bucketEndPoint + 'nrgi-logo.svg',
  companySpriteIcon: bucketEndPoint + 'ic_sprite-company.svg',
  companyProfile: bucketEndPoint + 'bg_company-profile.jpg',
  countryProfile: bucketEndPoint + 'bg_country-profile.jpg',
  updateSubscribe: bucketEndPoint + 'bg_update.jpg',
  clickIcon: bucketEndPoint + 'ic_click.svg',
  RLogo: bucketEndPoint + 'ic_R.svg',
  closeIcon: bucketEndPoint + 'ic_close.svg',
  mailIcon: bucketEndPoint + 'ic_subscribe.svg',
  sadIcon: bucketEndPoint + 'ic_sad.svg',
  paymentIcon: bucketEndPoint + 'ic_payment.svg',
  fiscalYearIcon: bucketEndPoint + 'ic_fiscal_year.svg',
  videoThumbnail: bucketEndPoint + 'video-thumbnail-new.png',
  videoRP: bucketEndPoint + 'resource_projects.mp4',
  videoPlayerThumbnail: bucketEndPoint + 'resource_projects.png',
  CloudUp: bucketEndPoint + 'video-images/Cloud-up.svg',
  CloudDown1: bucketEndPoint + 'video-images/Cloud-down-1.svg',
  CloudDown2: bucketEndPoint + 'video-images/Cloud-down-2.svg',
  CloudDown3: bucketEndPoint + 'video-images/Cloud-down-3.svg',
  CloudCenter: bucketEndPoint + 'video-images/Cloud-center.svg',
  Globe: bucketEndPoint + 'video-images/globe.svg',
  CoinsFront: bucketEndPoint + 'video-images/coin.svg',
  CoinsBack: bucketEndPoint + 'video-images/coins_back.svg'
};

export const downloadImage = (chartId, fileName, bgcolor = '#fff') => {
  const imageNode = document.getElementById(chartId);
  var newElement = document.createElement('img');
  newElement.setAttribute('id', 'composite-image');

  const options = {
    init(img) {
      img.crossOrigin = 'anonymous';
    }
  };

  const x = function(boat, metrics, context) {
    console.log(boat, metrics, context);
    return 10;
  };

  const y = function(boat, metrics, context) {
    return boat.height - 15;
  };

  const pos = watermark.text.atPos;

  domtoimage.toJpeg(imageNode, { filter: filter, bgcolor }).then(dataUrl => {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    const textColor = bgcolor === '#fff' ? '#000' : '#fff';

    watermark([dataUrl], options)
      .image(
        pos(
          x,
          y,
          'Source: resourceprojects.org',
          '12px Open Sans',
          textColor,
          0.8
        )
      )

      .then(img => {
        link.download = `${fileName}.png`;
        link.href = img.src;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  });
};
const filter = node => {
  //ignore specific control of leaflet map
  if (
    node.nodeName == 'DIV' &&
    node.className &&
    (node.className.includes('leaflet-top') ||
      node.className.includes('leaflet-bottom'))
  ) {
    return false;
  }

  //ignore node which consist data-ignore={true}
  if (node.dataset) return !node.dataset.ignore;

  return true;
};

export const countries = [
	{ code: 'AF', name: 'Afghanistan' },
	{ code: 'AX', name: 'Aland Islands' },
	{ code: 'AL', name: 'Albania' },
	{ code: 'DZ', name: 'Algeria' },
	{ code: 'AS', name: 'American Samoa' },
	{ code: 'AD', name: 'Andorra' },
	{ code: 'AO', name: 'Angola' },
	{ code: 'AI', name: 'Anguilla' },
	{ code: 'AQ', name: 'Antarctica' },
	{ code: 'AG', name: 'Antigua And Barbuda' },
	{ code: 'AR', name: 'Argentina' },
	{ code: 'AM', name: 'Armenia' },
	{ code: 'AW', name: 'Aruba' },
	{ code: 'AU', name: 'Australia' },
	{ code: 'AT', name: 'Austria' },
	{ code: 'AZ', name: 'Azerbaijan' },
	{ code: 'BS', name: 'Bahamas' },
	{ code: 'BH', name: 'Bahrain' },
	{ code: 'BD', name: 'Bangladesh' },
	{ code: 'BB', name: 'Barbados' },
	{ code: 'BY', name: 'Belarus' },
	{ code: 'BE', name: 'Belgium' },
	{ code: 'BZ', name: 'Belize' },
	{ code: 'BJ', name: 'Benin' },
	{ code: 'BM', name: 'Bermuda' },
	{ code: 'BT', name: 'Bhutan' },
	{ code: 'BO', name: 'Bolivia, Plurinational State of' },
	{ code: 'BQ', name: 'Bonaire, Saint Eustatius and Saba' },
	{ code: 'BA', name: 'Bosnia And Herzegovina' },
	{ code: 'BW', name: 'Botswana' },
	{ code: 'BV', name: 'Bouvet Island' },
	{ code: 'BR', name: 'Brazil' },
	{ code: 'IO', name: 'British Indian Ocean Territory' },
	{ code: 'BN', name: 'Brunei Darussalam' },
	{ code: 'BG', name: 'Bulgaria' },
	{ code: 'BF', name: 'Burkina Faso' },
	{ code: 'BI', name: 'Burundi' },
	{ code: 'KH', name: 'Cambodia' },
	{ code: 'CM', name: 'Cameroon' },
	{ code: 'CA', name: 'Canada' },
	{ code: 'CV', name: 'Cape Verde' },
	{ code: 'KY', name: 'Cayman Islands' },
	{ code: 'CF', name: 'Central African Republic' },
	{ code: 'TD', name: 'Chad' },
	{ code: 'CL', name: 'Chile' },
	{ code: 'CN', name: 'China' },
	{ code: 'CX', name: 'Christmas Island' },
	{ code: 'CC', name: 'Cocos (Keeling) Islands' },
	{ code: 'CO', name: 'Colombia' },
	{ code: 'KM', name: 'Comoros' },
	{ code: 'CG', name: 'Congo' },
	{ code: 'CD', name: 'Congo, the Democratic Republic of the' },
	{ code: 'CK', name: 'Cook Islands' },
	{ code: 'CR', name: 'Costa Rica' },
	{ code: 'CI', name: 'Cote d\'Ivoire' },
	{ code: 'HR', name: 'Croatia' },
	{ code: 'CU', name: 'Cuba' },
	{ code: 'CW', name: 'Curaçao' },
	{ code: 'CY', name: 'Cyprus' },
	{ code: 'CZ', name: 'Czechia' },
	{ code: 'DK', name: 'Denmark' },
	{ code: 'DJ', name: 'Djibouti' },
	{ code: 'DM', name: 'Dominica' },
	{ code: 'DO', name: 'Dominican Republic' },
	{ code: 'EC', name: 'Ecuador' },
	{ code: 'EG', name: 'Egypt' },
	{ code: 'SV', name: 'El Salvador' },
	{ code: 'GQ', name: 'Equatorial Guinea' },
	{ code: 'ER', name: 'Eritrea' },
	{ code: 'EE', name: 'Estonia' },
	{ code: 'ET', name: 'Ethiopia' },
	{ code: 'FK', name: 'Falkland Islands (Malvinas)' },
	{ code: 'FO', name: 'Faroe Islands' },
	{ code: 'FJ', name: 'Fiji' },
	{ code: 'FI', name: 'Finland' },
	{ code: 'FR', name: 'France' },
	{ code: 'GF', name: 'French Guiana' },
	{ code: 'PF', name: 'French Polynesia' },
	{ code: 'TF', name: 'French Southern Territories' },
	{ code: 'GA', name: 'Gabon' },
	{ code: 'GM', name: 'Gambia' },
	{ code: 'GE', name: 'Georgia' },
	{ code: 'DE', name: 'Germany' },
	{ code: 'GH', name: 'Ghana' },
	{ code: 'GI', name: 'Gibraltar' },
	{ code: 'GR', name: 'Greece' },
	{ code: 'GL', name: 'Greenland' },
	{ code: 'GD', name: 'Grenada' },
	{ code: 'GP', name: 'Guadeloupe' },
	{ code: 'GU', name: 'Guam' },
	{ code: 'GT', name: 'Guatemala' },
	{ code: 'GG', name: 'Guernsey' },
	{ code: 'GN', name: 'Guinea' },
	{ code: 'GW', name: 'Guinea-Bissau' },
	{ code: 'GY', name: 'Guyana' },
	{ code: 'HT', name: 'Haiti' },
	{ code: 'HM', name: 'Heard Island and Mcdonald Islands' },
	{ code: 'VA', name: 'Holy See (Vatican City State)' },
	{ code: 'HN', name: 'Honduras' },
	{ code: 'HK', name: 'Hong Kong' },
	{ code: 'HU', name: 'Hungary' },
	{ code: 'IS', name: 'Iceland' },
	{ code: 'IN', name: 'India' },
	{ code: 'ID', name: 'Indonesia' },
	{ code: 'IR', name: 'Iran, Islamic Republic of' },
	{ code: 'IQ', name: 'Iraq' },
	{ code: 'IE', name: 'Ireland' },
	{ code: 'IM', name: 'Isle of Man' },
	{ code: 'IL', name: 'Israel' },
	{ code: 'IT', name: 'Italy' },
	{ code: 'JM', name: 'Jamaica' },
	{ code: 'JP', name: 'Japan' },
	{ code: 'JE', name: 'Jersey' },
	{ code: 'JO', name: 'Jordan' },
	{ code: 'KZ', name: 'Kazakhstan' },
	{ code: 'KE', name: 'Kenya' },
	{ code: 'KI', name: 'Kiribati' },
	{ code: 'KP', name: 'Korea, Democratic People\'s Republic of' },
	{ code: 'KR', name: 'Korea, Republic of' },
	{ code: 'XK', name: 'Kosovo' },
	{ code: 'KW', name: 'Kuwait' },
	{ code: 'KG', name: 'Kyrgyzstan' },
	{ code: 'LA', name: 'Lao People\'S Democratic Republic' },
	{ code: 'LV', name: 'Latvia' },
	{ code: 'LB', name: 'Lebanon' },
	{ code: 'LS', name: 'Lesotho' },
	{ code: 'LR', name: 'Liberia' },
	{ code: 'LY', name: 'Libya' },
	{ code: 'LI', name: 'Liechtenstein' },
	{ code: 'LT', name: 'Lithuania' },
	{ code: 'LU', name: 'Luxembourg' },
	{ code: 'MO', name: 'Macao' },
	{ code: 'MK', name: 'North Macedonia' },
	{ code: 'MG', name: 'Madagascar' },
	{ code: 'MW', name: 'Malawi' },
	{ code: 'MY', name: 'Malaysia' },
	{ code: 'MV', name: 'Maldives' },
	{ code: 'ML', name: 'Mali' },
	{ code: 'MT', name: 'Malta' },
	{ code: 'MH', name: 'Marshall Islands' },
	{ code: 'MQ', name: 'Martinique' },
	{ code: 'MR', name: 'Mauritania' },
	{ code: 'MU', name: 'Mauritius' },
	{ code: 'YT', name: 'Mayotte' },
	{ code: 'MX', name: 'Mexico' },
	{ code: 'FM', name: 'Micronesia, Federated States of' },
	{ code: 'MD', name: 'Moldova, Republic of' },
	{ code: 'MC', name: 'Monaco' },
	{ code: 'MN', name: 'Mongolia' },
	{ code: 'ME', name: 'Montenegro' },
	{ code: 'MS', name: 'Montserrat' },
	{ code: 'MA', name: 'Morocco' },
	{ code: 'MZ', name: 'Mozambique' },
	{ code: 'MM', name: 'Myanmar' },
	{ code: 'NA', name: 'Namibia' },
	{ code: 'NR', name: 'Nauru' },
	{ code: 'NP', name: 'Nepal' },
	{ code: 'NL', name: 'Netherlands' },
	{ code: 'AN', name: 'Netherland Antilles' },
	{ code: 'NC', name: 'New Caledonia' },
	{ code: 'NZ', name: 'New Zealand' },
	{ code: 'NI', name: 'Nicaragua' },
	{ code: 'NE', name: 'Niger' },
	{ code: 'NG', name: 'Nigeria' },
	{ code: 'NU', name: 'Niue' },
	{ code: 'NF', name: 'Norfolk Island' },
	{ code: 'MP', name: 'Northern Mariana Islands' },
	{ code: 'NO', name: 'Norway' },
	{ code: 'OM', name: 'Oman' },
	{ code: 'PK', name: 'Pakistan' },
	{ code: 'PW', name: 'Palau' },
	{ code: 'PS', name: 'Palestinian Territory, Occupied' },
	{ code: 'PA', name: 'Panama' },
	{ code: 'PG', name: 'Papua New Guinea' },
	{ code: 'PY', name: 'Paraguay' },
	{ code: 'PE', name: 'Peru' },
	{ code: 'PH', name: 'Philippines' },
	{ code: 'PN', name: 'Pitcairn' },
	{ code: 'PL', name: 'Poland' },
	{ code: 'PT', name: 'Portugal' },
	{ code: 'PR', name: 'Puerto Rico' },
	{ code: 'QA', name: 'Qatar' },
	{ code: 'RE', name: 'Reunion' },
	{ code: 'RO', name: 'Romania' },
	{ code: 'RU', name: 'Russian Federation' },
	{ code: 'RW', name: 'Rwanda' },
	{ code: 'BL', name: 'Saint Barthelemy' },
	{ code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
	{ code: 'KN', name: 'Saint Kitts and Nevis' },
	{ code: 'LC', name: 'Saint Lucia' },
	{ code: 'MF', name: 'Saint Martin(French part)' },
	{ code: 'PM', name: 'Saint Pierre and Miquelon' },
	{ code: 'VC', name: 'Saint Vincent and The Grenadines' },
	{ code: 'WS', name: 'Samoa' },
	{ code: 'SM', name: 'San Marino' },
	{ code: 'ST', name: 'Sao Tome and Principe' },
	{ code: 'SA', name: 'Saudi Arabia' },
	{ code: 'SN', name: 'Senegal' },
	{ code: 'RS', name: 'Serbia' },
	{ code: 'SC', name: 'Seychelles' },
	{ code: 'SL', name: 'Sierra Leone' },
	{ code: 'SG', name: 'Singapore' },
	{ code: 'SX', name: 'Sint Maarten(Dutch part)' },
	{ code: 'SK', name: 'Slovakia' },
	{ code: 'SI', name: 'Slovenia' },
	{ code: 'SB', name: 'Solomon Islands' },
	{ code: 'SO', name: 'Somalia' },
	{ code: 'ZA', name: 'South Africa' },
	{ code: 'GS', name: 'South Georgia and The South Sandwich Islands' },
	{ code: 'SS', name: 'South Sudan' },
	{ code: 'ES', name: 'Spain' },
	{ code: 'LK', name: 'Sri Lanka' },
	{ code: 'SD', name: 'Sudan' },
	{ code: 'SR', name: 'Suriname' },
	{ code: 'SJ', name: 'Svalbard and Jan Mayen' },
	{ code: 'SZ', name: 'Swaziland' },
	{ code: 'SE', name: 'Sweden' },
	{ code: 'CH', name: 'Switzerland' },
	{ code: 'SY', name: 'Syrian Arab Republic' },
	{ code: 'TW', name: 'Taiwan, Province of China' },
	{ code: 'TJ', name: 'Tajikistan' },
	{ code: 'TZ', name: 'Tanzania, United Republic of' },
	{ code: 'TH', name: 'Thailand' },
	{ code: 'TL', name: 'Timor-Leste' },
	{ code: 'TG', name: 'Togo' },
	{ code: 'TK', name: 'Tokelau' },
	{ code: 'TO', name: 'Tonga' },
	{ code: 'TT', name: 'Trinidad and Tobago' },
	{ code: 'TN', name: 'Tunisia' },
	{ code: 'TR', name: 'Turkey' },
	{ code: 'TM', name: 'Turkmenistan' },
	{ code: 'TC', name: 'Turks and Caicos Islands' },
	{ code: 'TV', name: 'Tuvalu' },
	{ code: 'UG', name: 'Uganda' },
	{ code: 'UA', name: 'Ukraine' },
	{ code: 'AE', name: 'United Arab Emirates' },
	{ code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
	{ code: 'US', name: 'United States of America' },
	{ code: 'UM', name: 'United States Minor Outlying Islands' },
	{ code: 'UY', name: 'Uruguay' },
	{ code: 'UZ', name: 'Uzbekistan' },
	{ code: 'VU', name: 'Vanuatu' },
	{ code: 'VE', name: 'Venezuela, Bolivarian Republic of' },
	{ code: 'VN', name: 'Viet Nam' },
	{ code: 'VG', name: 'Virgin Islands, British' },
	{ code: 'VI', name: 'United States Virgin Islands' },
	{ code: 'WF', name: 'Wallis and Futuna' },
	{ code: 'EH', name: 'Western Sahara' },
	{ code: 'YE', name: 'Yemen' },
	{ code: 'ZM', name: 'Zambia' },
	{ code: 'ZW', name: 'Zimbabwe' },
];
