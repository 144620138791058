import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  CountryStats,
  CountrySwitcher,
  CountryMap,
  // CountrySankey,
  ComponentScores,
  JitterPlot,
  PaymentFilter,
  PaymentTable
  // TopTenList
} from './index';
import { flex, media } from '../../components/styled/utils/';
import {
  Heading,
  Text,
  DownloadButton,
  Container,
  Card,
  DownloadImageButton
} from '../../components/styled/ui/';
import CountryService from '../../services/country/index';
import * as CountryAction from '../../actions/country/index';
import BarCard from './barCard/barCard';
import BarChart from './jitterPlot/BarChart/index';
import { MultiDropdown, SimpleDropdown, Hint } from '../../components/layouts/';
import { API_URL, getAppImages, downloadImage } from '../../helpers';
import CardBoundary from '../../screens/CardBoundary';
import Helmet from 'react-helmet';
// import AllCountryList from './allCountryList/allCountryList';

import {countries} from '../../helpers/helper'
// import iso3Toiso2 from '../../helpers/iso3toiso2';
// import iso2Country from '../../helpers/iso2-countries-code';
const { helpIcon, downloadChartIcon } = getAppImages;

class Country extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryName: '',
      globalDropDown: 'project',
      globalFilter: [],
      truncateOverview: true,
      outliners: false,
      incomeGroup: 'all',
      region: 'all',
      component: 'projects'
    };

    this.onGlobalDropDownChange = this.onGlobalDropDownChange.bind(this);
    this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.match.params.countryName !== nextProps.match.params.countryName
    ) {
      let countryName = this.mapCountryNameFromCode(this.props.match.params.countryName)
      // let countryName = this.props.match.params.countryName;
      countryName = countryName.charAt(0).toUpperCase() + countryName.slice(1);
      this.setState({
        countryName: countryName
      });
      this.props.getStats(countryName);
      this.props.getCountryData(countryName);
      this.props.getIncomeGroup();
    }
  }

  componentWillMount() {
    if (window) {
      window.scrollTo(0, 0);
    }
    // let countryName = this.props.match.params.countryName;
    let countryName = this.mapCountryNameFromCode(this.props.match.params.countryName)
    countryName = countryName.charAt(0).toUpperCase() + countryName.slice(1);
    this.setState({
      countryName: countryName
    });
    this.props.getStats(countryName);
    this.props.getCountryData(countryName);
    this.props.getIncomeGroup();
    this.props.getCountryYears(countryName, this.state.globalDropDown);
  }

  /**
   * For jitter plot
   * */
  onGlobalFilterChange(globalFilter) {
    this.setState({ globalFilter });
  }

  onGlobalDropDownChange(globalDropDown) {
    this.setState({ globalDropDown });
  }

  onOutlinerChange(outliners) {
    if (this.state.outliners !== JSON.parse(outliners)) {
      //to convert string to boolean
      this.setState({ outliners: JSON.parse(outliners) });
    }
  }

  handlePaymentChange(val) {
    this.props.setPaymentType(val);
    this.setState({ component: val });
  }

  _downloadAllData(e) {
    e.preventDefault();
    window.open(
      API_URL +
        `projects?currentPage=&perPage=&countries=${this.state.countryName}&download=1`,
      '_blank'
    );
  }

  onIncomeGroupChange(title, value) {
    let state = {};
    let excludes = '';
    if (title === 'Income Group') {
      title = 'incomeGroup';
      excludes = 'region';
    } else {
      title = 'region';
      excludes = 'incomeGroup';
    }
    state[title] = value;
    state[excludes] = 'all';
    this.setState(state);
  }

  // onRegionChange(region) {
  // 	this.setState({ title: value });
  // }

  // handlePaymentChange(val) {
  // 	this.props.setPaymentType(val);
  // }

  getDatasetSchema() {
    let url = 'https://resourceprojects.org/country/' + this.state.countryName;
    return {
      '@context': 'http://schema.org',
      '@type': 'Dataset',
      name: 'Resource Projects',
      author: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute'
      },
      description:
        'Explore payments made by companies for extracting oil, gas and mining resources around the world.',
      url: url,
      keywords: [
        'MANDATORY DISCLOSURE > PAYMENTS',
        'PAYMENTS TO GOVERNMENT',
        'PROJECT LEVEL PAYMENTS',
        'EXTRACTIVE SECTOR PAYMENTS',
        'MINING AND OIL GAS PAYMENTS'
      ],
      creator: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute',
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'public engagement',
          email: 'admin@resourceprojects.org',
          url: 'https://resourcegovernance.org/'
        }
      },
      distribution: [
        {
          '@type': 'DataDownload',
          encodingFormat: 'application/zip',
          contentUrl: url
        }
      ],
      temporalCoverage: '2014-01-01/..',
      spatialCoverage: {
        '@type': 'Place',
        name: 'Worldwide'
      }
    };
  }

  mapCountryNameFromCode (countryCode) {
    let country = countries.filter(country=>country.code === countryCode)
    return country[0].name 
    //this one was for iso2toiso3 code mapping 
    // const iso2Code = iso3Toiso2[countryCode];
    // return iso2Country[iso2Code];
  }

  render() {
    const dropdownItems = [
      { label: 'PER PROJECT', value: 'project' },
      { label: "TO GOV'T ENTITIES", value: 'entity' }
    ];

    const overViewText = `This section gives an overview of the natural resource sector in ${this.state.countryName}. The map shows the locations of identifiable projects while the charts to the right show governance and wealth indicators for the country. The tables below that show the top projects and government agencies in 
    ${this.state.countryName}
    by payment value.
      `;
    let jitterFilterBy = this.state.incomeGroup;

    if (this.state.incomeGroup === 'all' && this.state.region !== 'all') {
      jitterFilterBy = this.state.region;
    }

    return (
      <PageWrap>
        <Helmet>
          <title>{this.state.countryName}</title>
          <meta
            name="og:description"
            content={`View project-level payment to government data from  ${this.state.countryName}`}
          />
          <meta
            name="og:title"
            content={`${this.state.countryName} - Resource Projects : An open-source data repository on oil, gas and mining projects`}
          />
          <script type="application/ld+json">
            {JSON.stringify(this.getDatasetSchema())}
          </script>
        </Helmet>
        <Container>
          <TopBar>
            <div>
              <CountrySwitcher countryName={this.state.countryName} />
              <CountryStats countryName={this.state.countryName} />
            </div>
            <DownloadButton
              to="#"
              target="_blank"
              onClick={this._downloadAllData.bind(this)}
              className="download-button"
            >
              <span className="download-icon" />
              <div>
                <span>Download</span>
                <span>Country Data</span>
              </div>
            </DownloadButton>
          </TopBar>
          {/* <CardHeader>
            <Heading.h3 uppercase light style={{ marginRight: '12px' }}>
              Overview
            </Heading.h3>

            <div>
              <p>{overViewText}</p>
            </div>
          </CardHeader> */}
          <MapDetailWrap>
            <div>
              <FlexDownload>
                <HelpText>
                  <Hint
                    title={<img src={helpIcon} alt="Outlier help" />}
                    text={`The map shows the location of projects disclosed by companies that we were able to identify. Hover on the locations for more information specific to these projects.`}
                  />
                  <Text.span size={12} light>
                    Project locations
                  </Text.span>
                </HelpText>
                <DownloadImageButton
                  className="download-image download-image__map"
                  onClick={() =>
                    downloadImage(
                      'country-map',
                      `${this.state.countryName}-Projects-Map-Resource-Projects`
                    )
                  }
                >
                  <img src={downloadChartIcon} alt="Download Map" />
                  <span> Download Map </span>
                </DownloadImageButton>
              </FlexDownload>
              <Card id="country-map">
                <CardBoundary>
                  <CountryMap countryName={this.state.countryName} />
                </CardBoundary>
              </Card>
            </div>
            <div>
              {/* <FlexDownload>
              <HelpText>
                <Hint
                  title={<img src={helpIcon} alt="Outlier help" />}
                  text = {
                    `This section provides an overview of the country's resource governance and wealth. Use the drop down to select the relevant measures.`
                  }
                />
                <Text.span size={12} light>
                  Country overview section
                </Text.span>
              </HelpText>
             <DownloadImageButton className="download-image" onClick={()=>downloadImage('country-stats', `${this.state.countryName}-Stats-Resource-Projects`)}>
              <img src ={downloadChartIcon} alt="Download Chart"/>
              <span> Download Chart </span>
            </DownloadImageButton>
            </FlexDownload>
              <Card style={{height: '92%'}}>
                <CardBoundary> */}
              <ComponentScores countryName={this.state.countryName} />
              {/* </CardBoundary>
              </Card> */}
            </div>
          </MapDetailWrap>

          <PaymentTable
            fetched={this.props.countryFetched}
            data={this.props.paymentData}
            name={this.state.countryName}
            profile="country"
          />
          {/* <TopTenList countryName={this.state.countryName} /> */}
          {/* <CardHeader>
            <div style={{ color: '#fff', fontSize: '12px' }}>
              <Heading.h3 uppercase light>
                Payments from companies
              </Heading.h3>
              <SimpleDropdown
                onChange={this.handlePaymentChange}
                items={dropdownItems}
              />{' '}
              <span
                style={{
                  paddingLeft: '3px',
                  fontSize: '16px',
                  fontWeight: '600',
                  textTransform: 'uppercase',
                  color: '#eceaf1'
                }}
              >
                for
              </span>
              <PaymentFilter for="sankey" />
              <div className="learnmore">
                <p>
                  Use the charts below to visualize the payment flows from
                  companies active in {this.state.countryName} that have
                  disclosed under mandatory disclosure laws. Interact with the
                  sankey flow chart to see corresponding changes in the bar
                  chart to the right.
                  {/* truncateLength={72} 
                </p>
              </div>
            </div>
          </CardHeader> */}

          {/* <VisWrap>
            <div className="column"> */}
          {/* <button onClick={()=>downloadImage('sankey', `${this.state.countryName}-sankey`)}>download</button> */}
          {/* <FlexDownload>
                <HelpText>
                  <Hint
                    title={<img src={helpIcon} alt="Outlier help" />}
                    text={`The sankey chart below shows the flow of payments from companies ${
                      this.state.component === 'projects'
                        ? 'per project'
                        : "to Gov't Entities"
                    }. Hover on each node to see it compared on the bar chart.`}
                  />
                  <Text.span size={12} light>
                    Flow of payment from Reporting Companies
                  </Text.span>
                </HelpText>
                <DownloadImageButton
                  className="download-image"
                  onClick={() =>
                    downloadImage(
                      'sankey',
                      `${this.state.countryName}-Sankey-Resource-Projects`
                    )
                  }
                >
                  <img src={downloadChartIcon} alt="Download Chart" />
                  <span> Download Chart </span>
                </DownloadImageButton>
              </FlexDownload> */}
          {/* <Card id="sankey">
                <CardBoundary>
                  <CountrySankey countryName={this.state.countryName} />
                </CardBoundary>
              </Card> */}
          {/* </div>
            <div className="column"> */}
          {/* <button onClick={()=>downloadImage('sankey-bar', `${this.state.countryName}-sankey-bar`)}>download</button> */}
          {/* <FlexDownload>
                <HelpText>
                  <Hint
                    title={<img src={helpIcon} alt="Outlier help" />}
                    text="The bar chart below shows the total payments received under each payment type. Hover on the sankey chart to the left to compare individual payment flows with total amounts received."
                  />
                  <Text.span size={12} light>
                    Total payment by type (in USD)
                  </Text.span>
                </HelpText>
                <DownloadImageButton
                  className="download-image"
                  onClick={() =>
                    downloadImage(
                      'sankey-bar',
                      `${
                        this.state.countryName
                      }-Payment-by-Type-Chart-Resource-Projects`
                    )
                  }
                >
                  <img src={downloadChartIcon} alt="Download Chart" />
                  <span> Download Chart </span>
                </DownloadImageButton>
              </FlexDownload>
              <Card id="sankey-bar">
                <CardBoundary>
                  <BarCard countryName={this.state.countryName} />
                </CardBoundary>
              </Card>
            </div>
          </VisWrap> */}

          <JitterHeader>
            <Heading.h3 uppercase light>
              Payments
            </Heading.h3>
            <SimpleDropdown
              onChange={this.onGlobalDropDownChange}
              items={dropdownItems}
            />{' '}
            <span
              style={{
                paddingLeft: '3px',
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'uppercase',
                marginBottom: 0,
                color: '#eceaf1'
              }}
            >
              for
            </span>
            <PaymentFilter
              for="jitter"
              onGlobalFilterChange={this.onGlobalFilterChange}
            />
            <Heading.h3 uppercase light>
              compared to
            </Heading.h3>
            {/* <Heading.h3 uppercase light>
              in 
              <span className="country_name">{this.state.countryName}</span>
               vs.
            </Heading.h3> */}
            <MultiDropdown
              onChange={(title, value) =>
                this.onIncomeGroupChange(title, value)
              }
            />
            <Heading.h3 uppercase light>
              countries
            </Heading.h3>
            {/* <SimpleDropdown
              onChange={val => this.onOutlinerChange(val)}
              items={[
                { label: 'Without Outliers', value: 'false' },
                { label: 'With Outliers', value: 'true' }
              ]}
            />  */}
            <div className="learnmore">
              <p style={{ lineHeight: '1.5' }}>
                The chart below shows project payments made in{' '}
                {this.state.countryName} with those in other countries. The
                highlighted points are those made in {this.state.countryName}.
                Hover over the points to see project names and countries, to
                identify projects receiving similar sized-payments elsewhere.
                Outlier project payments are removed.
              </p>
            </div>
          </JitterHeader>

          <VisWrap>
            <div className="column">
              <FlexDownload>
                <HelpText>
                  <Hint
                    title={<img src={helpIcon} alt="Outlier help" />}
                    text={`This plot compares the total payments received (by type) ${
                      this.state.globalDropDown === 'project'
                        ? 'per project'
                        : "to Gov't Entities"
                    } in ${
                      this.state.countryName
                    } to ${jitterFilterBy} countries. Hover on each dot to see the specific payment values.`}
                  />
                  <Text.span size={12} light>
                    Total payments to each project (in USD)
                  </Text.span>
                </HelpText>
                <DownloadImageButton
                  className="download-image"
                  onClick={() =>
                    downloadImage(
                      'jitter',
                      `${this.state.countryName}-Jitter-Resource-Projects`
                    )
                  }
                >
                  <img src={downloadChartIcon} alt="Download Chart" />
                  <span> Download Chart </span>
                </DownloadImageButton>
              </FlexDownload>
              {/* <button onClick={()=>downloadImage('jitter', `${this.state.countryName}-jitter`)}>download</button> */}

              <Card>
                <CardBoundary>
                  <JitterPlot
                    outliners={this.state.outliners}
                    globalFilter={this.state.globalFilter}
                    globalDropDown={this.state.globalDropDown}
                    countryName={this.state.countryName}
                    incomeGroup={this.state.incomeGroup}
                    region={this.state.region}
                  />
                </CardBoundary>
              </Card>
            </div>

            {/* <div className="column">
              <FlexDownload>
                <HelpText>
                  <Hint
                    title={<img src={helpIcon} alt="Outlier help" />}
                    text={`This plot compares the total payments received (by type) ${
                      this.state.globalDropDown === 'project'
                        ? 'per project'
                        : "to Gov't Entities"
                    } in ${
                      this.state.countryName
                    } to ${jitterFilterBy} countries. Hover on each dot to see the specific payment values.`}
                  />
                  <Text.span size={12} light>
                    Total payments to each project (in USD)
                  </Text.span>
                </HelpText>
                <DownloadImageButton
                  className="download-image"
                  onClick={() =>
                    downloadImage(
                      'jitter-bar',
                      `${this.state.countryName}-Payment-to-Project-Chart-Resource-Projects`
                    )
                  }
                >
                  <img src={downloadChartIcon} alt="Download Chart" />
                  <span> Download Chart </span>
                </DownloadImageButton>
              </FlexDownload>
              {/* <button onClick={()=>downloadImage('jitter-bar', `${this.state.countryName}-jitter-bar`)}>download</button> */}
            {/*  <Card id="jitter-bar">
                <CardBoundary>
                  <BarChart countryName={this.state.countryName} />
                </CardBoundary>
              </Card> 
            </div>*/}
          </VisWrap>

          {/* <AllCountryList countryName={this.state.countryName} /> */}
        </Container>
      </PageWrap>
    );
  }
}

const mapStateToProps = state => ({
  paymentData: state.country.paymentData,
  countryFetching: state.country.countryFetching,
  countryFetched: state.country.countryFetched
});

const mapDispatchToProps = dispatch => ({
  getStats: country => {
    dispatch(CountryAction.statsFetching);
    CountryService.getStats(country)
      .then(response => {
        if (response.status === 'success') {
          dispatch(CountryAction.statsFetched(response.result));
        } else {
          dispatch(CountryAction.statsError(response));
        }
      })
      .catch(e => {
        dispatch(CountryAction.statsError(e));
      });
  },
  getIncomeGroup: () => {
    CountryService.incomeGroup()
      .then(response => {
        dispatch(CountryAction.incomeGroupFetched(response.body.result.result));
      })
      .catch(err => {
        //handle error
      });
  },

  getCountryData: countryName => {
    dispatch(CountryAction.fetching());

    CountryService.countryData(countryName)
      .then(response => {
        if (response.status) {
          dispatch(CountryAction.fetched(response.body));
        } else {
          dispatch(CountryAction.error(response));
        }
      })
      .catch(error => {
        dispatch(CountryAction.error(error));
      });
  },
  setPaymentType: title => {
    dispatch(CountryAction.setPaymentType(title));
  },
  getCountryYears: (countryName, component) => {
    dispatch(CountryAction.countryYearsFetching());

    CountryService.getCountryYears(countryName, component)
      .then(response => {
        if (response.status) {
          dispatch(CountryAction.countryYearsFetched(response));
        } else {
          dispatch(CountryAction.countryYearsError(response));
        }
      })
      .catch(error => {
        dispatch(CountryAction.countryYearsError(error));
      });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Country);

/*--------------------------------------------------------------------*\
  Grid for cards
\*---------------------------------------------------------------------*/

const PageWrap = styled.div`
  // padding: 30px 0;

  .learnmore {
    margin-top: 5px;
    margin-left: 10px;
  }
`;

const CardHeader = styled.div`
  display: inline;
  margin-bottom: 14px;

  h3 {
    line-height: 1.5;
  }

  > h3,
  > div,
  > span {
    display: inline;
    > p {
      display: inline;
      color: #eceaf1;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .learnmore {
    margin-bottom: 4px;
    p {
      display: inline;
      line-height: 1.5;
    }
  }
  > div {
    //display: flex;
    align-items: center;

    &:nth-child(1) {
      margin-bottom: 12px;

      ${media.sm`
        margin-right: 12px;
        margin-bottom: 0;
	`};

      > h3 {
        margin-right: 12px;
        margin-bottom: 4px;
        line-height: 1.3;

        @media screen and (min-width: 900px) {
          margin-bottom: 0;
        }
      }

      > h3,
      > div {
        //display: inline-block;
        display: inline;
      }
    }
  }
`;

const JitterHeader = styled.div`
  color: #fff;
  display: inline;
  font-size: 12px;
  > span {
    display: inline-block;
    /* margin-bottom: 12px; */
  }
  > h3 {
    display: inline;
    line-height: 42px;
    &:nth-child(1) {
      margin-right: 6px;
    }

    &:not(:first-child) {
      margin: 0 6px;
    }

    .country_name {
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      line-height: normal;
      margin: -3px 6px 0;
      vertical-align: middle;
    }
  }

  >span{

    &:nth-of-type(2){
      margin-bottom: 12px;
    }
  }

 
  }

  .learnmore {
    display: inline;
    > p {
      display: inline;
    }

    &.learnmore-payment {
      margin-top: 0;
      @media all and (min-width: 992px) {
        display: block;
        margin: 0; 
      }
    }
  }
`;

/*--------------------------------------------------------------------*\
  Top bar with country switcher, country stats and download buttons
\*---------------------------------------------------------------------*/

const TopBar = styled.div`
  ${flex('column', null, 'flex-start')};
  width: 100%;
  margin: 30px 0;

  ${media.sm`
  	  ${flex('row', 'flex-start', 'center')};
  	  margin: 30px 0 44px 0;
  `};

  > div {
    margin-bottom: 12px;

    > div,
    > ul {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .download-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 21px;
    padding: 6px 14px 6px 8px;
    ${media.sm`
  		margin-left: auto;
  	`};
  }
`;

/*--------------------------------------------------------------------*\
  Grid for cards
\*---------------------------------------------------------------------*/

const CardGrid = styled.div`
  ${flex('column', null, null)};
  position: relative;
  margin-bottom: 50px;

  ${media.sm`
  	  ${flex('row', 'space-between', 'stretch')};
  `};
`;

const MapDetailWrap = CardGrid.extend`
  min-height: 340px;
  margin-top: 14px;
  > div {
    position: relative;
    &:nth-child(1) {
      height: 360px;
      margin-bottom: 24px;
      ${media.sm`
      		height: initial;
    	      margin-bottom: 0;
    	      width: 38%;
    `};
    }
    &:nth-child(2) {
      ${media.sm`
    	      width: calc(62% - 20px);
    	`};
    }
    &:hover {
      .download-image {
        background: #978aa8;
        padding: 0 8px 0 4px;
        transition: width 0.3s ease-in-out;
        width: 154px;
        span {
          line-height: 24px;
          display: block;
        }
        &__map {
          width: 120px;
        }
      }
    }
  }
  #country-map {
    height: 92%;
    width: 100%;
  }
`;

const VisWrap = CardGrid.extend`
  min-height: 260px;
  margin-top: 14px;
  .column {
    ${flex('column', null, null)};
    min-height: 322px;

    &:nth-child(1) {
      margin-bottom: 24px;

      ${media.sm`
    	      width: 100%;
    	      margin-bottom: 0;
    `};
    }
    &:nth-child(2) {
      ${media.sm`
    	      width: 39.4%;
    `};
    }

    > div:nth-child(2) {
      position: relative;
      flex: 1;
    }
    &:hover {
      .download-image {
        background: #978aa8;
        padding: 0 8px 0 4px;
        transition: width 0.3s ease-in-out;
        width: 132px;
        span {
          line-height: 24px;
          display: block;
        }
      }
    }
  }
`;

const HelpText = styled.div`
  /* margin-bottom: 4px; */

  > span {
    &:nth-child(1) {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      & + span {
        text-transform: uppercase;
      }
    }
  }
`;

const FlexDownload = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  position: relative;
`;
