import React, { Component } from 'react';
import {
  TableWrapper,
  Table,
  TH,
  TR,
  TD
} from '../../../../../components/styled';
import { Loading, Sort } from '../../../../../components/layouts/filters';
import { header } from './tableHeader';
import TableBody from './tableBody';

class TableComponent extends Component {
  _renderTableHead() {
    let { _handleSorting } = this.props;
    let { sortBy, order } = this.props.report;
    let tableHeader = header[this.props.component];

    return (
      <thead>
        <tr>
          {tableHeader.map((header, i) => (
            <TH
              key={i}
              width={header.width}
              title={'Sort by ' + header.title}
              className={header.class}
            >
              <Sort
                onClickHandler={() => _handleSorting(header.name)}
                showOrder={sortBy === header.name}
                order={order}
              >
                {header.title.toUpperCase()}
              </Sort>
            </TH>
          ))}
          {/* <TH width="5%" />
          <TH width="10px" /> */}
        </tr>
      </thead>
    );
  }

  _renderTableBody(report) {
    let { data, fetching, error, fetched } = report;
    if (error || typeof data === 'undefined') {
      return (
        <TR className="message-row">
          <TD colSpan="4">{'Network Error. Please try again later.'}</TD>
        </TR>
      );
    }
    if (fetching) {
      return (
        <tr>
          <Loading />
        </tr>
      );
    }
    if (fetched && typeof data !== 'undefined' && data.length === 0) {
      return (
        <TR className="message-row">
          <TD colSpan="4">Data not found.</TD>
        </TR>
      );
    }
    return data.map((d, key) => (
      <TableBody component={this.props.component} d={d} i={key} key={key} />
    ));
  }

  render() {
    let { report } = this.props;
    return (
      <TableWrapper>
        <Table className="table__data">
          {this._renderTableHead()}
          <tbody>{this._renderTableBody(report)}</tbody>
        </Table>
      </TableWrapper>
    );
  }
}
export default TableComponent;
