import React from 'react';
import { getAppImages } from '../../../helpers';

const {SortIcon} = getAppImages;

export const Sort = ({ children, onClickHandler, showOrder, order }) => {
  return (
    <span onClick={onClickHandler} className="sortable">
      {showOrder &&
        (order === 'asc' ? (
          <img src={SortIcon} alt="" />
        ) : (
          <img src={SortIcon} alt="" style={{ transform: 'rotate(180deg)'}} />
        ))}
      {children}
    </span>
  );
};
