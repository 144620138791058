import styled from 'styled-components';
import Colours from '../../theme/colours';
const { primary, neutral } = Colours;

export const ThumbnailWrapper = styled.div`
  .thumbnail-wrapper {
      clear: both;
      display: flex;
      flex-wrap: wrap;
      /* align-items: center; */
      justify-content: center;
      @media all and (min-width: 600px) {
        justify-content: space-between;
      }
  }
  .analysis-loader {
    flex: 0 1 100%;
    font-size: 22px;
    margin-top: ${props => (props.itemsCount > 0 ? '12px' : '120px')};
    opacity: 0.8;
    text-align: center;
  }
`;

export const Thumbnail = styled.div`
  background-color: ${primary.white};
  border-radius: 12px;
  display: inline-block;
  height: 484px;
  max-width: 370px;
  vertical-align: top;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 0 0 24px;
  width: 100%;
  @media all and (min-width: 600px) {
    margin: 0 0 20px;
    width: 48.5%;
    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }

  > a {
    border-radius: 12px;
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    position: relative;
  }

  img {
    max-height: 100%;
    width: auto;
    max-width: 100%;
  }

  &:after {
    border-radius: 12px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 85%;
    height: 80%;
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 0 100px 0 #4b4659;
    content: '';
    opacity: 0;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:hover {
    /* box-shadow: 0 28px 25px -14px rgba(118, 170, 187, 0.2); */
    text-decoration: none;
    &:after {
      opacity: 1;
    }
    .image__analysis {
      transform: scale(1.1);
    }
  }

  .image-wrap {
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    height: 178px;
    overflow: hidden;
  }
`;

export const AnalysisImageWrap = styled.div`
  background: url(${props => props.image}) no-repeat 0 0/cover;
  /* background-size: auto 100%; */
  /* background-position: center center; */
  height: 178px;
  transition: transform 0.5s;
  /* border-top-left-radius: 11px;
  border-top-right-radius: 11px; */
`;

export const AnalysisInfo = styled.div`
  position: absolute;
  left: 0;
  top: 200px;
  width: 100%;
  max-height: 265px;
  overflow: hidden;
  padding: 0 16px;
  text-align: left;

 
  .published-date,.content-type {
    display: block;
    color: ${primary.dark};
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    /* font-style: italic; */
    margin: 2px 0 6px;
    /* opacity: 0.7; */
    > strong {
      color: ${neutral.lightGray};
      font-weight: bold;
      margin-right: 4px;
    }
  }

  p.analysis-title {
    color: ${primary.dark};
    margin: 0 0 14px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  .analysis-description p span {
    margin-top: 12px;
    margin-bottom: 0;
    position: relative;
    font-size: 14px;
    line-height: 1.57;
    font-weight: normal;
    color: ${primary.gray};
    & + span {
      display: none;
    }
  }

  .tags__analysis {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    > div {
      width: calc(100% - 40px);
    }
    .tag-active{
      background-color:#eae9ef !important;
      border-color:#eae9ef !important;
    }
    .tag-inactive{
      background-color:#transparent !important;
      border-color:#ddd !important;   
    }
      .tag {        
        cursor: pointer;
        /* background-color: #eceaf1; */
        background: transparent;
        border: 1px solid #ccc;
        border-radius: 12px;
        color: ${primary.dark};
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        margin: 0 6px 4px 0;
        padding: 0 10px;
        width: auto;
        position:relative;
        z-index:9999999999999999999;
        &:hover {
          background-color: #eae9ef;
          border-color: #eae9ef!important;
        }
      }
      &:empty {
        display: none;
      }
      > span {
        color: ${neutral.lightGray};
        font-size: 12px;
        line-height: normal;
        margin-right: 6px;
        margin-top: 3px;
      }
  }
`;

export const SelectedTagWrap = styled.div `
  display: flex;
  font-size: 13px;
  font-weight: 600;
  max-width: 370px;
  /* margin-bottom: 20px; */
  float: left;
  width: calc(100% - 200px);
  @media all and (min-width: 600px) {
    max-width: 768px;
  }
  > span {
    color: #fff;
    margin-right: 5px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
`;


export const SelectedTag= styled.div`
display: inline-block;
margin-right: 6px;  
  > div {
      align-items: center;
      background: rgba(255,255,255,0.2);
      border-radius: 14px;
      color: #fff;
      display: flex;
      font-weight: 600;
      height: 22px;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 0px 2px 0 8px;
      width: 100%;
  }
  .close-tag {
    background: rgba(255,255,255,0.3) url(../../images/ic_close.svg) no-repeat center;
    border-radius: 50%;
    cursor: pointer;
    float: right;
    height: 12px;
    text-indent: -9999px;
    width: 12px;
    padding: 14px;
    transform: scale(0.5);
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
`

export const ThumbnailTopWrap = styled.div`
  /* display: flex;
  justify-content: space-between; */
  display: inline-block;
  margin-bottom: 12px;
  width: 100%;
`

export const SortByDateAnalysis = styled.div`
  float: right;
  > span {
    display: block;
  }
  .sort-label {
    color: ${primary.white};
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    & + span {
      display: inline-block;
      > span {
        padding: 6px 16px;
      }
      > ul {
        min-width: 150px;
      }
    }
  }
`