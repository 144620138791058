import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as pageAction from '../actions/page';
import PageService from '../services/page';
import Page from '../components/layouts/page';

class About extends Component {
	constructor(props) {
		super(props);
		this.slug = 'about-us';
	}

	componentWillMount() {
		let page = this.getContent(this.slug);

		if (typeof page.title === 'undefined') {
			this.props.getPageContent();
		}
	}

	getContent() {
		let match = {};
		this.props.page.all.forEach((page) => {
			if (page.slug === this.slug) {
				match = page;
			}
		});

		return match;
	}

	render() {
		let { title, header, description, content } = this.getContent();

		return (
			<Page title={title} header={header} description={description}>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</Page>
		);
	}
}

const mapStateToProps = state => ({ page: state.page });

const mapDispatchToProps = dispatch => ({
	getPageContent: () => {
		dispatch(pageAction.fetching());

		PageService.fetch().then((response) => {
			dispatch(pageAction.fetched(response));
		}).catch((error) => {
			dispatch(pageAction.error({ error }));
		});
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(About);