import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import querystring from 'querystring';
import { Link } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Container, Text } from '../../../../components/styled/ui/';
import { flex, media } from '../../../../components/styled/utils';
import { Pagination, TextTruncate } from '../../../../components/layouts';
import { FilterKeyWord } from '../../../../components/styled';
import { header } from './table/tableHeader';
import { TableWrapper, Table, TH, TR, TD } from '../../../../components/styled';
import { Sort, Loading } from '../../../../components/layouts/filters';
import * as NewReportAction from '../../../../actions/source/newReport';
import NewReportService from '../../../../services/source/newReport';
import { getFlag, getAppImages } from '../../../../helpers';
import Stats from '../../stats/stats';
import { rgba } from '../../../../components/styled/utils/colors';
import Colours from '../../../../components/styled/theme/colours';
import SideBarComponent from './sidebar';
import {
  Dropdown,
  DropdownMenu,
  transitionStyles
} from '../../../../components/layouts/dropdown/dropdownStyles';
import $ from 'jquery';
const { primary } = Colours;
const { infoIcon, clickIcon } = getAppImages;

class newReport extends Component {
  constructor(props) {
    super(props);
    this.separator = ':';
    this.state = {
      newReports: [],
      sortBy: 'reportingCompany',
      order: 'asc',
      total: 0,
      perPage: 25,
      currentPage: 1,
      sourceAttribute: {},
      filter: {
        years: '',
        reportingCompanies: '',
        reportingJurisdiction: '',
        countries: ''
      }
    };
  }
  componentDidMount() {
    // console.log(this.props);
    if (this.props.match.params.date) {
      this.props.getNewReports(this.props.match.params.date);
    } else {
      this.props.getNewReports();
    }
    let filter = querystring.parse(this.props.location.search.slice(1));
    delete filter.tab;
    if (
      filter.years ||
      filter.reportingCompanies ||
      filter.reportingJurisdiction ||
      filter.countries
    )
      this.setState({ filter });
    if (this.props.newReports.data) {
      this.setState({ newReports: this.props.newReports.data });
    }
  }

  componentDidUpdate() {
    $(document).ready(function() {
      var tableHeight = $('.table__data').outerHeight();
      var windowHeight = $(window).height();
      if (tableHeight > windowHeight) {
        $('.filter-icon-mob').css({
          position: 'fixed',
          left: 'auto',
          bottom: 0
        });
      } else {
        $('.filter-icon-mob').css({ position: 'absolute', bottom: '-8px' });
      }

      $(window).scroll(function() {
        var hT = $('.table-wrap').offset().top,
          hH = $('.table-wrap').outerHeight() - 140,
          wH = $(window).height(),
          wS = $(this).scrollTop(),
          filterIcon = $('.filter-icon-mob');
        if (wS > hT + hH - wH) {
          filterIcon.css({ position: 'absolute', bottom: '-8px' });
        } else {
          filterIcon.css({ position: 'fixed', left: 'auto', bottom: 0 });
        }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newReports.data !== this.props.newReports.data) {
      this.setState({
        newReports: nextProps.newReports.data
      });
    }
  }
  _renderPagination(pageCount) {
    let currentPage = 1;

    if (pageCount < 2) {
      return null;
    }

    return (
      <Pagination
        onPageClick={this._handlePageClick.bind(this)}
        total={pageCount}
        current={currentPage}
      />
    );
  }
  _handlePageClick(data) {
    let currentPage = data.selected + 1;
    window.scrollTo(0, 0);
    this.setState({ currentPage });
  }
  _renderFlag(projectCountry, code) {
    if (code !== '') {
      return (
        <span>
          <img
            src={getFlag(code)}
            alt={projectCountry + 'Flag'}
            className="flag"
          />
          {projectCountry.substr(0, 12)}
        </span>
      );
    }

    return projectCountry;
  }
  _handleSorting(col) {
    let { newReports, order } = this.state;

    if (order === 'asc') {
      newReports.sort((a, b) => {
        if (col.indexOf('Date') === -1) {
          if (a[col] < b[col]) {
            return -1;
          }
          if (a[col] > b[col]) {
            return 1;
          }
        } else {
          return new Date(b[col]) - new Date(a[col]);
        }

        return 0;
      });
    } else {
      newReports.sort((a, b) => {
        if (col.indexOf('Date') === -1) {
          if (a[col] < b[col]) {
            return 1;
          }
          if (a[col] > b[col]) {
            return -1;
          }
        } else {
          return new Date(a[col]) - new Date(b[col]);
        }
        return 0;
      });
    }

    this.setState({
      newReports,
      sortBy: col,
      order: order === 'asc' ? 'desc' : 'asc'
    });
  }
  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }
  renderOnboarding = () => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('filter-onBoarding-render')
        : null;

    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" /> on the filter button to filter
            payment data.
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'filter-onBoarding-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };
  toggleSideBarOptions() {
    let sidebar = document.getElementById('sidebar');
    let filterIcon = document.getElementById('filter-icon');
    let backEndOverlay = document.getElementById('backend-overlay');
    if (sidebar.classList.contains('sidebar-close')) {
      sidebar.classList.remove('sidebar-close');
      sidebar.classList.add('sidebar-open');
      filterIcon.setAttribute('style', 'display:none');
      backEndOverlay.classList.remove('is-close');
      backEndOverlay.classList.add('is-open');
    } else {
      sidebar.classList.remove('sidebar-open');
      sidebar.classList.add('sidebar-close');
      filterIcon.setAttribute('style', 'display:flex');
      backEndOverlay.classList.remove('is-open');
      backEndOverlay.classList.add('is-close');
    }

    $(document).ready(function() {
      var tableHeight = $('.table__data').outerHeight();
      var windowHeight = $(window).height();
      console.log(tableHeight, windowHeight);
      if (tableHeight > windowHeight) {
        $('.filter-icon-mob').css({
          position: 'fixed',
          left: 'auto',
          bottom: 0
        });
      } else {
        $('.filter-icon-mob').css({ position: 'absolute', bottom: '-8px' });
      }
    });
  }
  _onChangefilter(type, element) {
    let { filter } = this.state;
    if (element.length) {
      filter[type] = element.join(this.separator);
    } else {
      filter[type] = '';
    }

    this.setState({ filter, currentPage: 1 });
  }

  _handleReset(type, label) {
    let { filter } = this.state;
    if (type === 'all') {
      filter = {
        years: '',
        reportingCompanies: '',
        reportingJurisdiction: '',
        countries: ''
      };
    } else {
      let filterText = '';

      if (filter[type] && filter[type].length && label.length) {
        let _filter = filter[type];
        let values = _filter.split(this.separator);
        values.splice(values.indexOf(label), 1);
        filterText = values.join(this.separator);
      }
      filter[type] = filterText;
    }
    this.setState({ filter, currentPage: 1 });
  }
  _renderFilterBy() {
    let { filter } = this.state;
    // delete filter.countries;
    let items = [];
    for (let key in filter) {
      let labels = filter[key].split(this.separator);
      labels.forEach(label => {
        if (label.length > 0) {
          items.push({ type: key, label });
        }
      });
    }
    let itemsHtml = null;
    if (items.length > 0) {
      itemsHtml = items.map((item, key) => {
        return (
          <FilterKeyWord className={item.type} key={key}>
            <span className="keyword-label">{item.label}</span>
            <span
              className="remove-keyword"
              onClick={this._handleReset.bind(this, item.type, item.label)}
            />
          </FilterKeyWord>
        );
      });
      if (items.length > 1) {
        itemsHtml.push(
          <button
            className="primary-btn filter-reset-btn"
            onClick={this._handleReset.bind(this, 'all')}
            style={{ marginBottom: '12px' }}
            key={items.length}
          >
            Clear All Filters
          </button>
        );
      }
    }
    return itemsHtml;
  }

  _renderTableBody(data, report) {
    let { fetched = false, fetching = true } = report;
    const sourceText =
      'Original source points to the online link that was used either by the company or some third party to post this mandatory disclosure report. However, over time some of these links may become defunct. We therefore maintain backups for all the sources used on this website so that users can always trace back data to the original payment table.';
    if (typeof data === 'undefined') {
      return (
        <TR className="message-row">
          <TD colSpan="8">{'Network Error. Please try again later.'}</TD>
        </TR>
      );
    }
    if (fetching) {
      return (
        <tr>
          <Loading />
        </tr>
      );
    }
    if (fetched && typeof data !== 'undefined' && data.length === 0) {
      return (
        <TR className="message-row">
          <TD colSpan="8">Data not found.</TD>
        </TR>
      );
    }
    return (
      <tbody>
        {data.map(r => (
          <TR key={r.sourceID}>
            <TD className="project-name" data-label="REPORTING COMPANY">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  to={`/company/${encodeURIComponent(r.reportingCompany)}`}
                  style={{ overflow: 'hidden' }}
                >
                  <span className="content">
                    {r.reportingCompany.substr(0, 26)}
                    {r.reportingCompany.length <= 26 ? '' : <span>...</span>}
                    {r.reportingCompany.length <= 26 ? (
                      ''
                    ) : (
                      <span className="popup-content">
                        {r.reportingCompany}
                      </span>
                    )}
                  </span>
                </Link>
                <Dropdown
                  onMouseEnter={() => this.handleDropdownMenu(r.sourceID)}
                  onMouseLeave={() => this.handleDropdownMenu(false)}
                >
                  <DropdownButton>
                    <TDTitle className="title">Sources</TDTitle>
                    <DropdownIcon isOpen={this.state.isOpen === r.sourceID} />
                  </DropdownButton>

                  <Transition
                    in={this.state.isOpen === r.sourceID}
                    timeout={100}
                    mountOnEnter={true}
                  >
                    {state => (
                      <DropdownMenu
                        style={{
                          ...transitionStyles[state]
                        }}
                      >
                        <ul className="dropdown-list" style={{ minWidth: 220 }}>
                          <li className="dropdown-list-item dropdown-list-item-link">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={r.sourceURL}
                              title="Go to source site."
                            >
                              Backup source
                              {/* <span className="external-link">link</span> */}
                            </a>
                          </li>
                          <li className="dropdown-list-item dropdown-list-item-link">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={r.originalURL}
                              title="Go to source site."
                            >
                              Original source
                              {/* <span className="external-link">link</span> */}
                            </a>
                          </li>
                          <li className="source-text">
                            <img
                              src={infoIcon}
                              alt="info"
                              style={{
                                marginTop: 4,
                                marginRight: 4
                              }}
                            />
                            <TextTruncate
                              text={sourceText}
                              truncateLength={72}
                            />
                          </li>
                        </ul>
                      </DropdownMenu>
                    )}
                  </Transition>
                </Dropdown>
              </div>
            </TD>
            <TD className="/country" data-label="COUNTRY">
              <Link to={'/country/' + r.reportingCountry}>
                <span className="content">
                  {this._renderFlag(r.reportingCountry, r.countryCode)}
                  {r.reportingCountry.length <= 12 ? (
                    ''
                  ) : (
                    <span>
                      <span>...</span>
                      <span className="popup-content">
                        {r.reportingCountry}
                      </span>
                    </span>
                  )}
                </span>
              </Link>
            </TD>

            <TD data-label="Start date">
              {moment(r.reportStartDate, 'MM-DD-YYYY').format('MMM, YYYY')}
            </TD>
            <TD data-label="End Date">
              {moment(r.reportEndDate, 'MM-DD-YYYY').format('MMM, YYYY')}
            </TD>
            <TD
              data-label="Published Date"
              className={!r.sourcePublishDate ? 'noData' : ''}
            >
              {!r.sourcePublishDate
                ? 'Not Available'
                : moment(r.sourcePublishDate, 'MM-DD-YYYY').format('MMM, YYYY')}
            </TD>
            <TD data-label="File Format">
              <FileFormat className={'ic_file ic_' + r.format.substr(1)}>
                {r.format.substr(1)}
              </FileFormat>
            </TD>
          </TR>
        ))}
      </tbody>
    );
  }

  render() {
    const {
      newReports,
      sortBy,
      order,
      currentPage,
      perPage,
      filter
    } = this.state;
    const tableHeader = header['source'];

    const { fetching = true } = this.props.newReports;

    const initialIndex = (currentPage - 1) * perPage;
    const finalIndex = currentPage * perPage;
    let newData = [...newReports];
    let yearData = newReports;
    let companyData = newReports;
    let jurisdictionData = newReports;

    if (filter.years && filter.years !== '') {
      newData = newData.filter(d => filter.years.includes(d.reportYear));
      companyData = companyData.filter(d =>
        filter.years.includes(d.reportYear)
      );
      jurisdictionData = jurisdictionData.filter(d =>
        filter.years.includes(d.reportYear)
      );
    }
    if (filter.reportingCompanies && filter.reportingCompanies !== '') {
      newData = newData.filter(d =>
        filter.reportingCompanies.includes(d.reportingCompany)
      );
      yearData = yearData.filter(d =>
        filter.reportingCompanies.includes(d.reportingCompany)
      );
      jurisdictionData = jurisdictionData.filter(d =>
        filter.reportingCompanies.includes(d.reportingCompany)
      );
    }
    if (filter.reportingJurisdiction && filter.reportingJurisdiction !== '') {
      newData = newData.filter(d =>
        filter.reportingJurisdiction.includes(d.reportingCountry)
      );
      yearData = yearData.filter(d =>
        filter.reportingJurisdiction.includes(d.reportingCountry)
      );
      companyData = companyData.filter(d =>
        filter.reportingJurisdiction.includes(d.reportingCountry)
      );
    }

    if (filter.countries && filter.countries !== '') {
      newData = newData.filter(
        d => d.countries && d.countries.includes(filter.countries)
      );
      yearData = yearData.filter(
        d => d.countries && d.countries.includes(filter.countries)
      );
      companyData = companyData.filter(
        d => d.countries && d.countries.includes(filter.countries)
      );
      jurisdictionData = jurisdictionData.filter(
        d => d.countries && d.countries.includes(filter.countries)
      );
    }

    const years = _.uniq(_.map(yearData, 'reportYear')).sort();
    const reportingCompanies = _.uniq(
      _.map(companyData, 'reportingCompany')
    ).sort();
    const reportingJurisdiction = _.uniq(
      _.map(jurisdictionData, 'reportingCountry')
    ).sort();

    const sourceAttribute = {
      data: { years, reportingCompanies, reportingJurisdiction }
    };

    const total = newData.length;
    let data = newData.splice(initialIndex, finalIndex);

    return (
      <PageWrap>
        <Container>
          <TopBar className="top-bar">
            <Title className="title">New Reports</Title>
            <Stats component={'Source'} project={{ total: total }} />
          </TopBar>

          <Content>
            <SideBarComponent
              filter={filter}
              toggleSideBarOptions={this.toggleSideBarOptions.bind(this)}
              _onChangefilter={this._onChangefilter.bind(this)}
              sourceAttribute={sourceAttribute}
              component={'source'}
              _handleReset={this._handleReset.bind(this)}
              _renderFilterBy={this._renderFilterBy.bind(this)}
            />
            <BackendOverlay
              id="backend-overlay"
              onClick={this.toggleSideBarOptions.bind(this)}
            />
            <Main>
              <div className="filter_list">{this._renderFilterBy()}</div>

              <div className="table-wrap">
                <TableWrapper>
                  <Table className="table__data">
                    <thead>
                      <tr>
                        {tableHeader.map((header, i) => (
                          <TH
                            key={i}
                            width={header.width}
                            title={'Sort by ' + header.title}
                            className={header.class}
                          >
                            <Sort
                              onClickHandler={() =>
                                this._handleSorting(header.name)
                              }
                              showOrder={sortBy === header.name}
                              order={order}
                            >
                              {header.title.toUpperCase()}
                            </Sort>
                          </TH>
                        ))}
                        {/* <TH width="5%" /> */}
                        <TH width="70px" />
                      </tr>
                    </thead>
                    {this._renderTableBody(data, this.props.newReports)}
                  </Table>

                  {!fetching && (
                    <FilterIconWrap>
                      <FilterIcon
                        id="filter-icon"
                        className="filter-icon-mob"
                        style={{ display: 'flex' }}
                      >
                        {this.renderOnboarding()}
                        <div onClick={this.toggleSideBarOptions.bind(this)}>
                          <div className="filter">
                            <a className={'hamburger-icon'} title="Menu">
                              <span className="line line-1" />
                              <span className="line line-2" />
                              <span className="line line-3" />
                              <span className="line line-4" />
                            </a>
                          </div>
                          <Text.span size={10} uppercase bold className="label">
                            Filters
                          </Text.span>
                        </div>
                      </FilterIcon>
                    </FilterIconWrap>
                  )}
                </TableWrapper>

                {this._renderPagination(Math.ceil(total / 25))}
              </div>
            </Main>
          </Content>
        </Container>
      </PageWrap>
    );
  }
}

const mapStateToProps = state => ({
  newReports: state.newReport
});

const mapDispatchToProps = dispatch => {
  return {
    getNewReports: (type = null) => {
      dispatch(NewReportAction.fetching);
      NewReportService.newReport(type)
        .then(response => {
          if (response.status) {
            dispatch(NewReportAction.fetched(response.body));
          } else {
            dispatch(NewReportAction.error(response.body));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(NewReportAction.error(error));
        });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(newReport);

const PageWrap = styled.div``;

/*--------------------------------------------------------------------*\
  Top bar with Page switcher, payment stats and download button
\*---------------------------------------------------------------------*/

const Title = styled.span`
  display: inline-block;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: ${props => props.theme.fontSize.lg}px;
  font-weight: ${props => props.theme.fontWeight.bold};
  cursor: default;
  background: transparent;
  text-decoration: none;
  padding: 8px 36px 8px 0;
  transition: 0.15s ease-in-out;
  white-space: nowrap;

  @media all and (max-width: 480px) {
    font-size: 21px;
  }
`;
const TDTitle = styled.span`
  color: ${primary.white};
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
`;
const TopBar = styled.div`
  ${flex('column', null, 'flex-start')};
  width: 100%;
  margin-bottom: 36px;

  ${media.sm`
  	  ${flex('row', 'flex-start', 'center')};
  `};

  > span,
  > ul {
    margin-bottom: 12px;
    ${media.sm`
      margin-bottom: 0;
    `};
  }

  > div {
    margin-bottom: 12px;

    > div,
    > ul {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .download-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 21px;
    line-height: 13px;
    padding: 6px 14px 6px 8px;
    ${media.sm`
  		margin-left: auto;
  	`};
  }
`;

const FileFormat = styled.span`
  background-repeat: no-repeat;
  display: block;
  background: rgba(51, 51, 51, 0.3);
  text-transform: uppercase;
  color: #fff;
  padding: 1px;
  border-radius: 4px;
  font-size: 10px;
  position: relative;
  z-index: 1;
  width: 33px;
  text-align: center;
  ${media.sm`margin: 0 auto;`}
`;

const Main = styled.div`
  position: relative;
  .filter_list {
    left: -12%;
    //min-width: 200px;
    max-width: 122px;
    position: absolute;
    top: 74px;
    display: none;
    > span {
      border-radius: 13px;
      background: rgba(255, 255, 255, 0.1);
      line-height: normal;
      margin-bottom: 4px;
      padding: 4px 10px;
      &:hover {
        .remove-keyword {
        }
      }
    }
    > span:first-of-type {
      width: 100%;
      word-break: break-word;
    }
    @media screen and (min-width: 1280px) {
      display: block;
      left: -15.5%;
      min-width: 130px;
      max-width: 130px;
    }
    @media screen and (min-width: 1320px) {
      left: -17.5%;
      min-width: 150px;
      max-width: 150px;
    }
    @media screen and (min-width: 1360px) {
      left: -19%;
    }
    @media screen and (min-width: 1400px) {
      left: -21%;
      min-width: 170px;
      max-width: 170px;
    }
  }

  .table-wrap {
    .table__data {
      tbody tr:nth-last-child(2) td {
        padding-bottom: 11px;
      }

      tbody tr:last-child td {
        padding-bottom: 60px;
      }
    }
  }
`;

const DropdownButton = styled.span`
  position: relative;
  display: inline-block;
  background-color: ${rgba('#c6cce0', 0.8)};
  border-radius: 2px;
  color: #333;
  font-size: 9px;
  cursor: pointer;
  padding: 2px 8px 1px;
  margin-left: 4px;
  border-radius: 16px;

  &:hover {
    background-color: ${rgba('#c6cce0', 0.9)};
  }
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent ${primary.white};`
      : `${primary.white} transparent transparent;`};
  border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
`;
const Content = styled.div`
  /* position: relative; */

  .filter-title {
    margin-bottom: 10px;
  }
  .keyword-label {
    float: left;
    width: calc(100% - 26px);
    padding: 4px 6px 4px 10px;
  }

  .filter-reset-btn {
    margin-bottom: 12px;
    background: ${props => props.theme.primary.primary};
    border: 0;
    border-radius: 13px;
    color: ${props => props.theme.primary.white};
    height: 29px;
    width: 100%;
    &:hover {
      background: #15a3dc;
    }
  }
  @media screen and (min-width: 767px) {
    ${flex('row', null, null)};
  }

  .filter-icon-mob {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    background: #20bcfb;
    box-shadow: 0 0 24px rgb(24, 161, 214);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 8px 4px;
    padding: 0;
    left: 0;
    bottom: -8px;
    > div {
      padding: 10px 20px;
      text-align: center;
    }
    .filter {
      margin-bottom: 4px;
    }
    &.sticky {
      position: fixed;
      top: 33px;
      left: auto;
      transform: translateX(-53px);
      @media screen and(min-width: 1360 px) {
        transform: translateX(-53px);
      }
    }
  }
`;
const FilterIconWrap = styled.div`
  /* position: relative;
  @media all and (max-width: 1280px) {
    position: static;
  } */
`;
const FilterIcon = styled.div`
  display: none;
  margin-bottom: 8px;
  @media screen and (min-width: 1340px) {
    // position: absolute;
    // left: -46px;
    display: block;
    ${flex('column', 'center', 'flex-end')};
  }

  .filter {

    & + .label {
      color: ${props => props.theme.primary.white}!important;
      font-weight: bold;
    }
    .hamburger-icon .line {
        background-color: ${props => props.theme.primary.white};
      }
    }
  }

  .hamburger-icon {
    width: 18px;
    height: 10px;
    position: relative;
    display: inline-block;
    .line {
      display: block;
      background: #3a424c;
      width: 18px;
      height: 2px;
      position: absolute;
      left: 0;
      border-radius: 1px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
        width: 12px;
      }
      &.line-3 {
        top: 100%;
        width: 4px;
      }
      &.line-4 {
        top: 100%;
        width: 18px;
        opacity: 0;
      }
    }
    &:hover .line-1,
    &:focus .line-1 {
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
    }
    &:hover .line-3,
    &:focus .line-3 {
      transform: translateY(1px);
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
    }
    &.active {
      .line-1 {
        transform: translateY(5px) translateX(0) rotate(45deg);
        -webkit-transform: translateY(5px) translateX(0) rotate(45deg);
        -moz-transform: translateY(5px) translateX(0) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        opacity: 0;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
      .line-4 {
        opacity: 1;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
    }
  }

  .icon {
    ${flex('column', 'center', 'center')};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: ${props => rgba(props.theme.primary.white, 0.7)};
    margin-bottom: 4px;
    padding: 8px;
    transition: ${props => props.theme.transition.base};
  }

  .label {
    color: ${props => rgba(props.theme.primary.white, 0.5)};
    transition: ${props => props.theme.transition.base};
    display: block;
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.primary};
      padding-left: 4px;
    }
  }

  &:hover {
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.dark};
    }
    .icon {
      background: ${props => props.theme.primary.white};
    }
    .label {
      color: ${props => props.theme.primary.white};
      @media screen and (max-width: 1100px) {
        color: ${props => props.theme.primary.primary};
      }
    }
  }
`;
const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 200px;
  position: absolute;
  /* left: -4px;
  top: 11.5%; */
  left: -18%;
  bottom: 60px;
  z-index: 1;
  @media all and (min-width: 1400px) {
    padding: 18px 18px 12px;
    left: -222px;
    bottom: -6px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 134px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width: 1400px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 42px;
      right: -13px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
const BackendOverlay = styled.div`
  &.is-open {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 273px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
`;
