import Api from '../api';

class Source {

	async paginate(currentPage, perPage, filter, sortBy, order, download) {

		let params = { currentPage, perPage, ...filter, sortBy, order, download };

		return await Api.get('sources', { params });
	}
}

export default new Source();
