import React from 'react';
import styled from 'styled-components';
import { flex, rgba } from '../../../components/styled/utils/';
import ScrollBar from 'react-scrollbar/dist/no-css';
import { Progress } from '../../../components/layouts/';
import { Text } from '../../../components/styled/ui/';

function getScoreColor(percent) {
  if (percent > 74) return '#589a28';
  else if (percent >= 60 && percent <= 74) return '#98e064';
  else if (percent >= 45 && percent <= 69) return '#f2dd50';
  else if (percent >= 30 && percent <= 44) return '#f98c42';
  else return '#a3282f';
}

const Rgi = ({ rgiData, rgiIndex }) => {
  let description = '';
  let composite = 0;
  let valueRealization = 0;
  let revenueManagement = 0;
  let enablingEnvironment = 0;

  if (rgiData && rgiData !== 0) {
    description = rgiData[rgiIndex]['Blurb'] || '';
    composite = rgiData[rgiIndex]['2017 Resource Governance Index'] || 0;
    composite = parseFloat(composite);
    valueRealization = rgiData[rgiIndex]['Value Realization'] || 0;
    valueRealization = parseFloat(valueRealization);
    revenueManagement = rgiData[rgiIndex]['Revenue Management'] || 0;
    revenueManagement = parseFloat(revenueManagement);
    enablingEnvironment = rgiData[rgiIndex]['Enabling Environment'] || 0;
    enablingEnvironment = parseFloat(enablingEnvironment);
  }

  return (
    <div>
      <ScoreDescriptionWrap>
        <ScoreWrap>
        <div id="country-stats" style={{padding:'5px'}}>
          <AverageScore>
            <AverageScoreNumber>
              <span>{composite}</span>
              <span>/100</span>
            </AverageScoreNumber>
            <AverageScoreLine>
              <span style={{ fontSize: '12px', fontWeight: '400' }}>
                Overall
              </span>
              <Progress percent={composite} color={getScoreColor(composite)} />
            </AverageScoreLine>
          </AverageScore>

          <div>
            <ScoreValue>
              <span>Value Realization</span>
              <div>
                <Progress
                  percent={valueRealization}
                  color={getScoreColor(valueRealization)}
                />
                <ScoreValueNum>
                  <span>{valueRealization}</span>
                  <span>/100</span>
                </ScoreValueNum>
              </div>
            </ScoreValue>

            <ScoreValue>
              <span>Revenue Management</span>
              <div>
                <Progress
                  percent={revenueManagement}
                  color={getScoreColor(revenueManagement)}
                />
                <ScoreValueNum>
                  <span>{revenueManagement}</span>
                  <span>/100</span>
                </ScoreValueNum>
              </div>
            </ScoreValue>

            <ScoreValue>
              <span>Enabling Environment</span>
              <div>
                <Progress
                  percent={enablingEnvironment}
                  color={getScoreColor(enablingEnvironment)}
                />
                <ScoreValueNum>
                  <span>{enablingEnvironment}</span>
                  <span>/100</span>
                </ScoreValueNum>
              </div>
            </ScoreValue>
          </div>
          </div>
        </ScoreWrap>
        <CountryDescriptionWrap data-ignore={true}>
          <ScrollBar
            className="options"
            contentClassName="content"
            smoothScrolling={true}
            minScrollSize={30}
          >
            <div>
              <CountryDescription>{description}</CountryDescription>
            </div>
          </ScrollBar>
        </CountryDescriptionWrap>
      </ScoreDescriptionWrap>
      <div style={{marginLeft: -10}}>
      <div id='country-stats-legend'>
      <Legend>
        <li className="legend-list-item">
          <Text.span>Good (&#62;74)</Text.span>
        </li>
        <li className="legend-list-item">
          <Text.span>Satisfactory (60-74)</Text.span>
        </li>
        <li className="legend-list-item">
          <Text.span>Weak (45-69)</Text.span>
        </li>
        <li className="legend-list-item">
          <Text.span>Poor (30-44)</Text.span>
        </li>
        <li className="legend-list-item">
          <Text.span>Failing (&#60;30)</Text.span>
        </li>
      </Legend>
      </div>
      </div>
    </div>
  );
};

export default Rgi;

const ScoreDescriptionWrap = styled.div`
  ${flex('column', null, null)};
  /* margin-bottom: 28px; */

  @media screen and (min-width: 600px) {
    ${flex('row', 'space-between', 'stretch')};
  }

  .scrollarea.options {
    max-height: 186px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        to top,
        rgb(255, 255, 255) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  svg {
    height: 8px;
    min-width: 260px;

    @media screen and (min-width: 600px) {
      width: 100%;
    }
  }
`;
const ScoreWrap = styled.div`
  width: 100%;
  margin: -5px 0 24px -5px;

  @media screen and (min-width: 600px) {
    width: 34%;
    margin-bottom: 0;
    #country-stats {
      width: 100%;
    }
  }
`;

const AverageScore = styled.div`
    ${flex('row', 'flex-start', 'center')};
    margin-bottom: 23px;
    
    >div:nth-child(2){
      flex: 1;
      >span{
      	margin-bottom: 4px;
      }
    }
    
    span{
        &:first-child{
        display: block;
        color: ${props => props.theme.primary.gray};
        font-size: 24px;
        font-weight: 600;
    }
        &:nth-child(2){
        color: ${props => rgba(props.theme.primary.dark, 0.7)}
        font-size: 14px;
    	}
    }
        `;

const AverageScoreNumber = styled.div`
  margin-right: 8px;
`;

const AverageScoreLine = styled.div`
  // width: 122px;
`;

const ScoreValue = styled.div`
  margin-bottom: 14px;

  > span {
    color: ${props => props.theme.primary.gray};
    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 8px;
    }
  }
`;

const ScoreValueNum = styled.div`
  > span {
    &:first-child {
      color: ${props => props.theme.primary.gray};
      font-size: 16px;
      font-weight: 600;
    }
    &:nth-child(2) {
      color: ${props => rgba(props.theme.primary.dark, 0.7)}
      font-size: 12px;
    }
  }
`;

const CountryDescriptionWrap = styled.div`
  flex: 1;
  padding: 0 0 0 18px;
`;

const CountryDescription = styled.div`
        color: #3a424c;
        font-size: 14px;
        line-height: 1.69;
        margin: 0;
        position: relative;
        padding: 0 28px 18px 0;
        }
        `;

const Legend = styled.ul`
  list-style: none;
  padding-left: 0;
  white-space: nowrap;

  .legend-list-item {
    color: ${props => props.theme.primary.gray};
    display: inline-block;
    font-size: 11px;
    span {
      font-size: 11px;
    }

    &:not(:last-child) {
      margin-right: 17px;
    }

    &:before {
      content: '';
      border-radius: 9px;
      float: left;
      margin-top: 4px;
      margin-right: 6px;
      height: 9px;
      width: 9px;
    }

    &:nth-child(1) {
      &:before {
        background-color: #589a28;
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: #98e064;
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: #f2dd50;
      }
    }

    &:nth-child(4) {
      &:before {
        background-color: #f98c42;
      }
    }

    &:nth-child(5) {
      &:before {
        background-color: #a3282f;
      }
    }
  }
`;
