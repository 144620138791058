import React, { Component } from 'react';
import { FilterKeyWord, TableWrapper } from '../../../../components/styled';
import { Helmet } from 'react-helmet';
import { getHeader, getAppImages } from '../../../../helpers';
import TableComponent from './table/TableComponent';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { flex, rgba } from '../../../../components/styled/utils/';
import SideBarComponent from './sidebar';
import { Pagination } from '../../../../components/layouts';
import { Text } from '../../../../components/styled/ui/';
import $ from 'jquery';
import { countries } from '../../../../helpers/helper'

//services and actions
import * as projectAction from '../../../../actions/project/index';
import * as SourceAction from '../../../../actions/source/index';
import * as EntityAction from '../../../../actions/entity/index';
import ProjectService from '../../../../services/project/index';
import EntityService from '../../../../services/entity/index';
import SourceService from '../../../../services/source/index';
import * as filterAction from '../../../../actions/filter';
import querystring from 'querystring';
import ProjectAttribute from '../../../../services/project/attribute';
import * as projectAttributeAction from '../../../../actions/project/attribute';
import * as EntityAttributeAction from '../../../../actions/entity/attribute';
import EntityAttribute from '../../../../services/entity/attribute';
import * as sourceAttributeAction from '../../../../actions/source/attribute';
import SourceAttribute from '../../../../services/source/attribute';
import HttpQueue from '../../../../utils/httpQueue';
let projectDataQueue;
let reportAttributeQueue;
let reportAttributeSourceQueue;

const { clickIcon } = getAppImages;

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.per_page = 25;
    this.separator = ':';
    this._handleSorting = this._handleSorting.bind(this);
    this.state = {
      onboardingHide: false
    };

    // this.myRef = React.createRef();
  }

  componentDidMount() {

    /*********
     * To make queue for api calling such that accurate result is obtained
     * initialize new queue object
     *  */
    const { component } = this.props;
    window.component = component;
    const reportService =
      component === 'project'
        ? ProjectService
        : window.component === 'entity'
        ? EntityService
        : SourceService;
    projectDataQueue = new HttpQueue(reportService);

    const reportAttributeService =
      component === 'project' ? ProjectAttribute : EntityAttribute;
    reportAttributeQueue = new HttpQueue(reportAttributeService);

    reportAttributeSourceQueue = new HttpQueue(SourceAttribute);

    /**
     *
     */

    this._loadReports();
    this._loadAttributes();
  }

 componentDidUpdate() {
        $(document).ready(function () { 
          var tableHeight = $('.table__data').outerHeight();
          var windowHeight = $(window).height();
          if (tableHeight > windowHeight) {
            $('.filter-icon-mob').css({'position':'fixed', 'left': 'auto', 'bottom': 0});
          } else {
            $('.filter-icon-mob').css({'position':'absolute', 'bottom': '-8px'});
          }

        $(window).scroll(function() {
           
          var hT = $('.table-wrap').offset().top,
            hH = $('.table-wrap').outerHeight() - 140,
            wH = $(window).height(),
            wS = $(this).scrollTop(),
            filterIcon = $('.filter-icon-mob');
            if (wS > hT + hH - wH) {
              filterIcon.css({'position':'absolute', 'bottom':'-8px'});
            } else {
              filterIcon.css({'position':'fixed', 'left': 'auto', 'bottom': 0});
            }

        });

      });
 }

  componentWillUnmount() {
    if ($(window)) {
      $(window).off('scroll');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      this._loadAttributes(nextProps.filter);
    }
  }

  //load project/entity/sources data
  _loadReports(reset) {
    let params = null;
    if (!reset) {
      params =
        this.props.history.location.search.length > 0
          ? this.props.history.location.search.slice(1)
          : ''; //remove ? from querystring
      params = params ? querystring.parse(params) : this.props.filter;
    }

    let currentPage = 1,
      filter = params,
      sortBy =
        window.component === 'project'
          ? 'projectCountry'
          : window.component === 'entity'
          ? 'entityCountry'
          : 'reportingCountry',
      order = 'asc',
      download = 0;
      // this was done for country and country code mapping for project,entitity & sources.
    if (filter && (filter.hasOwnProperty('countries') || filter.hasOwnProperty('reportingJurisdiction'))) {
      let newObj = { ...filter }

      Object.keys(newObj).forEach(prop => {
        if (prop === 'countries' || prop === 'reportingJurisdiction') {
          let splittedCountryName = newObj[prop].split(':')
          let countryTest = []
          countries.filter(country => {
            splittedCountryName.map((selectedCountry) => {
              if (selectedCountry === country.code) {
                countryTest.push(country.name)

              }
            })
          })
          let joinedData = countryTest.join(':')
          newObj[prop] = joinedData
        }
      });
      filter = { ...newObj }
    }

    this.props.getReportContent(
      currentPage,
      this.per_page,
      filter,
      sortBy,
      order,
      download
    );
  }

  //table header sorting
  _handleSorting(newSortBy) {
    let { getReportContent, filter } = this.props;
    let report = this.props[this.props.component];
    let { perPage, sortBy, order } = report;

    order = order.toLowerCase() === 'asc' ? 'desc' : 'asc';

    if (newSortBy !== sortBy) {
      order = 'asc';
    }

    getReportContent(1, perPage, filter, newSortBy, order);
  }

  /**
   * For pagination
   */
  _renderPagination(pageCount) {
    let { currentPage } = this.props[this.props.component];

    if (pageCount < 2) {
      return null;
    }

    return (
      <Pagination
        onPageClick={this._handlePageClick.bind(this)}
        total={pageCount}
        current={currentPage}
      />
    );
  }

  _handlePageClick(data) {
    let currentPage = data.selected + 1;
    let { perPage, sortBy, order } = this.props[this.props.component];
    let filter = this.props.filter;
    window.scrollTo(0, 0);
    let { getReportContent } = this.props;
    getReportContent(currentPage, perPage, filter, sortBy, order);
  }

  /**
   * For filter @todo
   */
  toggleSideBarOptions() {
    let sidebar = document.getElementById('sidebar');
    let filterIcon = document.getElementById('filter-icon');
    let backEndOverlay = document.getElementById('backend-overlay');
    if (sidebar.classList.contains('sidebar-close')) {
      sidebar.classList.remove('sidebar-close');
      sidebar.classList.add('sidebar-open');
      filterIcon.setAttribute('style', 'display:none');
      backEndOverlay.classList.remove('is-close');
      backEndOverlay.classList.add('is-open');
    } else {
      sidebar.classList.remove('sidebar-open');
      sidebar.classList.add('sidebar-close');
      filterIcon.setAttribute('style', 'display:flex');
      backEndOverlay.classList.remove('is-open');
      backEndOverlay.classList.add('is-close');
    }
    $(document).ready(function(){
       var tableHeight = $('.table__data').outerHeight();
        var windowHeight = $(window).height();
        if (tableHeight > windowHeight) {
          $('.filter-icon-mob').css({'position':'fixed', 'left': 'auto', 'bottom': 0});
        } else {
          $('.filter-icon-mob').css({'position': 'absolute', 'bottom': '-8px'});
        }
    });
  }

  _onChangefilter(type, element) {
    // this.setState({ reportFormShown: false, activeReport: -1 });
    let { getReportContent } = this.props;
    let report = this.props[this.props.component];
    let { perPage, sortBy, order } = report;
    let filter = this.props.filter;
    let value = '';

    if (element.length) {
      value = element.join(this.separator);
    }

    getReportContent(1, perPage, { ...filter, [type]: value }, sortBy, order);
    this._loadAttributes({ ...filter, [type]: value });
  }

  _loadAttributes(filter = this.props.filter) {
    if (
      this.props.component === 'project' ||
      this.props.component === 'entity'
    ) {
      this.props.getReportAttribute(filter);
    }
    this.props.getSourceAttribute(filter);
  }

  _handleReset(type, label) {
    if (type === 'all') {
      this._loadReports(true);
      this._loadAttributes({});
    } else {
      let filterText = '';

      if (
        this.props.filter[type] &&
        this.props.filter[type].length &&
        label.length
      ) {
        let _filter = this.props.filter[type];
        let values = _filter.split(this.separator);
        values.splice(values.indexOf(label), 1);
        filterText = values;
      }

      this._onChangefilter(type, filterText);
    }
  }

  _renderFilterBy() {
    let filter = this.props.filter;
    let { component } = this.props;
    if (component === 'project') {
      filter['entityTypes'] = '';
    } else if (component === 'entity') {
      filter['projectTypes'] = '';
    }
    let items = [];

    for (let key in filter) {
      // console.log('=================', key, filter[key]);
      let labels = filter[key].split(this.separator);
      labels.forEach(label => {
        if (label.length > 0) {
          items.push({ type: key, label });
        }
      });
    }

    let itemsHtml = null;

    if (items.length > 0) {
      itemsHtml = items.map((item, key) => {
        return (
          <FilterKeyWord className={item.type} key={key}>
            <span className="keyword-label">{item.label}</span>
            <span
              className="remove-keyword"
              onClick={this._handleReset.bind(this, item.type, item.label)}
            />
          </FilterKeyWord>
        );
      });

      if (items.length > 1) {
        itemsHtml.push(
          <button
            className="primary-btn filter-reset-btn"
            onClick={this._handleReset.bind(this, 'all')}
            style={{ marginBottom: '12px' }}
            key={items.length}
          >
            Clear All Filters
          </button>
        );
      }
    }

    return itemsHtml;
  }

  renderOnboarding = () => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem( 'filter-onBoarding-render')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" /> on the filter button to filter
            payment data.
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'filter-onBoarding-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  render() {
    // const node = this.myRef.current;
    // if (node) {
    //   console.log(this.myRef);
    //   console.log(node.clientHeight);
    // }

    let pageCount = Math.ceil(
      this.props[this.props.component].total / this.per_page
    );
    let header =
      this.props.component === 'project'
        ? getHeader.projects
        : this.props.component === 'entity'
        ? getHeader.entities
        : getHeader.sources;
    return (
      <Content>
        <SideBarComponent
          filter={this.props.filter}
          toggleSideBarOptions={this.toggleSideBarOptions.bind(this)}
          _onChangefilter={this._onChangefilter.bind(this)}
          projectAttribute={this.props.projectAttribute}
          sourceAttribute={this.props.sourceAttribute}
          entityAttribute={this.props.entityAttribute}
          component={this.props.component}
          _handleReset={this._handleReset.bind(this)}
          _renderFilterBy={this._renderFilterBy.bind(this)}
        />
        <BackendOverlay
          id="backend-overlay"
          onClick={this.toggleSideBarOptions.bind(this)}
        />
        <Helmet>
          <title>{header}</title>
        </Helmet>
        <Main>
          <div className="filter_list">{this._renderFilterBy()}</div>

          <div className="table-wrap">
            <TableWrapper>
            <TableComponent
              component={this.props.component}
              report={this.props[this.props.component]}
              _handleSorting={this._handleSorting}
            />           
          <FilterIconWrap>
            {!this.props[this.props.component].fetching && (
              <FilterIcon
                id="filter-icon"
                className="filter-icon-mob"
                style={{ display: 'flex' }}
              >
                {this.renderOnboarding()}
                <div onClick={this.toggleSideBarOptions.bind(this)}>
                  <div className="filter">
                    <a className={'hamburger-icon'} title="Menu">
                      <span className="line line-1" />
                      <span className="line line-2" />
                      <span className="line line-3" />
                      <span className="line line-4" />
                    </a>
                  </div>
                  <Text.span size={10} uppercase bold className="label">
                    Filters
                  </Text.span>
                </div>
              </FilterIcon>
            )}
          </FilterIconWrap>
          </TableWrapper> 
            {this._renderPagination(pageCount)}
          </div>
        </Main>
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  project: state.project,
  entity: state.entity,
  source: state.source,
  filter: state.filter,
  projectAttribute: state.projectAttribute,
  sourceAttribute: state.sourceAttribute,
  entityAttribute: state.entityAttribute
});

const mapDispatchToProps = dispatch => ({
  getReportContent: (currentPage, perPage, filter, sortBy, order, download) => {
    // console.log(filter, 'content');
    const reportAction =
      window.component === 'project'
        ? projectAction
        : window.component === 'entity'
        ? EntityAction
        : SourceAction;

    dispatch(reportAction.fetching());
    dispatch(filterAction.setFilter(filter));
    dispatch(reportAction.setCurrentPage(currentPage));
    dispatch(reportAction.setSort({ sortBy, order }));

    projectDataQueue
      .add('paginate', [currentPage, perPage, filter, sortBy, order, download])
      .then(response => {
        if (response.status) {
          dispatch(reportAction.fetched(response.body));
        } else {
          dispatch(reportAction.error(response.body));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(reportAction.error(error));
      });
  },
  getReportAttribute: filter => {
    // console.log(filter, 'attribute');
    const reportAttributeAction =
      window.component === 'project'
        ? projectAttributeAction
        : EntityAttributeAction;
    dispatch(reportAttributeAction.fetching());

    reportAttributeQueue
      .add('list', [filter])
      .then(response => {
        if (response.status) {
          dispatch(reportAttributeAction.fetched(response.body));
        } else {
          dispatch(reportAttributeAction.error(response.body));
        }
      })
      .catch(error => {
        dispatch(reportAttributeAction.error(error));
      });
  },

  getSourceAttribute: filter => {
    const reportAttributeAction = sourceAttributeAction;
    dispatch(reportAttributeAction.fetching());

    reportAttributeSourceQueue
      .add('list', [filter])
      .then(response => {
        if (response.status) {
          dispatch(reportAttributeAction.fetched(response.body));
        } else {
          dispatch(reportAttributeAction.error(response.body));
        }
      })
      .catch(error => {
        dispatch(reportAttributeAction.error(error));
      });
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DataTable)
);

const Content = styled.div`
  // position: relative;

  .filter-title {
    margin-bottom: 10px;
  }
  .keyword-label {
    float: left;
    width: calc(100% - 26px);
    padding: 4px 6px 4px 10px;
  }

  .filter-reset-btn {
    margin-bottom: 12px;
    background: ${props => props.theme.primary.primary};
    border: 0;
    border-radius: 13px;
    color: ${props => props.theme.primary.white};
    height: 29px;
    width: 100%;
    &:hover {
      background: #15a3dc;
    }
  }
  @media screen and (min-width: 767px) {
    ${flex('row', null, null)};
  }

  .filter-icon-mob {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    background: #20bcfb;
    box-shadow: 0 0 24px rgb(24, 161, 214);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 8px 4px;
    padding: 0;
    left: 0;
    bottom: -8px;
    > div {
      padding: 10px 20px;
      text-align: center;
    }
    .filter {
      margin-bottom: 4px;
    }
    &.sticky {
      position: fixed;
      top: 33px;
      left: auto;
      transform: translateX(-53px);
      @media screen and(min-width: 1360 px) {
        transform: translateX(-53px);
      }
    }
  }
`;

const Main = styled.div`
  position: relative;
  .filter_list {
    left: -12%;
    //min-width: 200px;
    max-width: 122px;
    position: absolute;
    top: 74px;
    display: none;
    > span {
      border-radius: 13px;
      background: rgba(255, 255, 255, 0.1);
      line-height: normal;
      margin-bottom: 4px;
      padding: 4px 10px;
      &:hover {
        .remove-keyword {
        }
      }
    }
    > span:first-of-type {
      width: 100%;
      word-break: break-word;
    }
    @media screen and (min-width: 1280px) {
      display: block;
      left: -15.5%;
      min-width: 130px;
      max-width: 130px;
    }
    @media screen and (min-width: 1320px) {
      left: -17.5%;
      min-width: 150px;
      max-width: 150px;
    }
    @media screen and (min-width: 1360px) {
      left: -19%;
    }
    @media screen and (min-width: 1400px) {
      left: -21%;
      min-width: 170px;
      max-width: 170px;
    }
  }
`;

const BackendOverlay = styled.div`
  &.is-open {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 273px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
`;

const FilterIcon = styled.div`
  display: none;
  margin-bottom: 8px;
  @media screen and (min-width: 1340px) {
    // position: absolute;
    // left: -46px;
    display: block;
    ${flex('column', 'center', 'flex-end')};
  }

  .filter {

    & + .label {
      color: ${props => props.theme.primary.white}!important;
      font-weight: bold;
    }
    .hamburger-icon .line {
        background-color: ${props => props.theme.primary.white};
      }
    }
  }

  .hamburger-icon {
    width: 18px;
    height: 10px;
    position: relative;
    display: inline-block;
    .line {
      display: block;
      background: #3a424c;
      width: 18px;
      height: 2px;
      position: absolute;
      left: 0;
      border-radius: 1px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
        width: 12px;
      }
      &.line-3 {
        top: 100%;
        width: 4px;
      }
      &.line-4 {
        top: 100%;
        width: 18px;
        opacity: 0;
      }
    }
    &:hover .line-1,
    &:focus .line-1 {
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
    }
    &:hover .line-3,
    &:focus .line-3 {
      transform: translateY(1px);
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
    }
    &.active {
      .line-1 {
        transform: translateY(5px) translateX(0) rotate(45deg);
        -webkit-transform: translateY(5px) translateX(0) rotate(45deg);
        -moz-transform: translateY(5px) translateX(0) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        opacity: 0;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
      .line-4 {
        opacity: 1;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
    }
  }

  .icon {
    ${flex('column', 'center', 'center')};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: ${props => rgba(props.theme.primary.white, 0.7)};
    margin-bottom: 4px;
    padding: 8px;
    transition: ${props => props.theme.transition.base};
  }

  .label {
    color: ${props => rgba(props.theme.primary.white, 0.5)};
    transition: ${props => props.theme.transition.base};
    display: block;
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.primary};
      padding-left: 4px;
    }
  }

  &:hover {
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.dark};
    }
    .icon {
      background: ${props => props.theme.primary.white};
    }
    .label {
      color: ${props => props.theme.primary.white};
      @media screen and (max-width: 1100px) {
        color: ${props => props.theme.primary.primary};
      }
    }
  }
`;

const FilterIconWrap = styled.div`
  position: relative;
  @media all and (max-width: 1280px) {
    position: static;
  }
`;

const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 200px;
  position: absolute;
  /* left: -4px;
  top: 11.5%; */
  left: -18%;
  bottom: 60px;
  z-index: 1;
  @media all and (min-width: 1400px) {
    padding: 18px 18px 12px;
    left: -222px;
    bottom: -6px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 134px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width: 1400px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 42px;
      right: -13px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
