import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { SidebarComponent } from '../../screens/company/index';
import { FilterKeyWord } from '../styled';
import { Text, Heading } from '../styled/ui';
import { flex, rgba, media } from '../styled/utils';
import { getAppImages } from '../../helpers';
import { Hint, SimpleDropdown } from '../layouts';
import CompanyTable from './table';
import CardBoundary from '../../screens/CardBoundary';
import { Pagination } from '../../components/layouts/pagination';
import { StackedChart } from '../../screens/company/index';
import $ from 'jquery';

const { helpIcon, companySpriteIcon, clickIcon } = getAppImages;

class AllCompanyList extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedDropdown: 'project',
      sidebarIsOpen: false,
      selectedYears: '',
      selectedCountry: '',
      selectedReportingCompany: '',
      selectedProject: '',
      selectedReportingJurisdiction: '',
      selectedPaymentType: '',
      selectedType: '',
      onboardingHide: false,
      currentPage: 1,
      sortBy: 'payment',
      order: 'asc'
    };
  }

  componentDidUpdate() {
    $(document).ready(function () { 

      $(window).scroll(function() {
        var hT = $('.company-table-text').offset().top + 150,
            tT = $('.table-wrap').offset().top,
            hH = $('.company-table-text').outerHeight(),
            tH = $('.table-wrap').outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();
        if (wS > (hT+hH-wH) && wS < (tT+tH-wH)){
            $('.company-filter .filter-icon-mob').css({'position':'fixed', 'left': '8%', 'top':'auto','bottom': '40px'});
        }
        else {
            $('.company-filter .filter-icon-mob').css({'position':'absolute', 'left': '-81px', 'top':'auto','bottom': '40px'});
        }


      });

      });
  }

  componentWillMount() {
    const { data, fetched } = this.props;
    if (fetched && !data.project.length) {
      this.setState({ selectedDropdown: 'entity' });
    }
  }

  handlePaymentChange(selectedDropdown) {
    this.setState({
      selectedDropdown,
      currentPage : 1
    });
  }

  toggleSideBar() {
    this.setState(({ sidebarIsOpen }) => ({
      sidebarIsOpen: !sidebarIsOpen
    }));
  }

  handleYearFilter(selectedYears) {
    if (selectedYears.length === 0) {
      this.handleYearClear();
    } else {
      this.setState({ selectedYears: selectedYears.join(':') });
    }
  }

  handlePaymentTypeFilter(selectedPaymentType) {
    if (selectedPaymentType.length === 0) {
      this.handlePaymentTypeClear();
    } else {
      this.setState({ selectedPaymentType: selectedPaymentType.join(':') } ,
      // ()=>this.props.handlePaymentTypeFilterChange(this.state.selectedPaymentType)
      );
    }
  }

  handleCountryFilter(selectedCountry) {
    if (selectedCountry.length === 0) {
      this.handleCountryClear();
    } else {
      this.setState({ selectedCountry: selectedCountry.join(':') });
    }
  }

  handleCompanyFilter(selectedReportingCompany) {
    if (selectedReportingCompany.length === 0) {
      this.handleCompanyClear();
    } else {
      this.setState({
        selectedReportingCompany: selectedReportingCompany.join(':')
      });
    }
  }

  handleProjectFilter(selectedProject) {
    if (selectedProject.length === 0) {
      this.handleProjectClear();
    } else {
      this.setState({ selectedProject: selectedProject.join(':') });
    }
  }

  handleTypeFilter(selectedType) {
    if (selectedType.length === 0) {
      this.handleTypeClear();
    } else {
      this.setState({ selectedType: selectedType.join(':') });
    }
  }

  handleReportingJurisdictionFilter(selectedReportingJurisdiction) {
    if (selectedReportingJurisdiction.length === 0) {
      this.handleReportingJurisdictionClear();
    } else {
      this.setState({
        selectedReportingJurisdiction: selectedReportingJurisdiction.join(':')
      });
    }
  }

  handleYearClear() {
    this.setState({ selectedYears: '' });
  }

  handlePaymentTypeClear() {
    this.setState({ selectedPaymentType: '' },
    // ()=>this.props.handlePaymentTypeFilterChange(this.state.selectedPaymentType)
    );
  }

  handleCountryClear() {
    this.setState({ selectedCountry: '' });
  }

  handleCompanyClear() {
    this.setState({ selectedReportingCompany: '' });
  }

  handleProjectClear() {
    this.setState({ selectedProject: '' });
  }

  handleReportingJurisdictionClear() {
    this.setState({ selectedReportingJurisdiction: '' });
  }

  handleTypeClear() {
    this.setState({ selectedType: '' });
  }

  resetFilter(type, label) {
    let {
      selectedYears,
      selectedPaymentType,
      selectedCountry,
      selectedReportingCompany,
      selectedProject,
      selectedType,
      selectedReportingJurisdiction
    } = this.state;
    if (type === 'years') {
      selectedYears = selectedYears.replace(`:${label}`, '');
      selectedYears = selectedYears.replace(`${label}:`, '');
      selectedYears = selectedYears.replace(`${label}`, '');
    }
    if (type === 'paymentType') {
      selectedPaymentType = selectedPaymentType.replace(`:${label}`, '');
      selectedPaymentType = selectedPaymentType.replace(`${label}:`, '');
      selectedPaymentType = selectedPaymentType.replace(`${label}`, '');
    }
    if (type === 'country') {
      selectedCountry = selectedCountry.replace(`:${label}`, '');
      selectedCountry = selectedCountry.replace(`${label}:`, '');
      selectedCountry = selectedCountry.replace(`${label}`, '');
    }
    if (type === 'company') {
      selectedReportingCompany = selectedReportingCompany.replace(
        `:${label}`,
        ''
      );
      selectedReportingCompany = selectedReportingCompany.replace(
        `${label}:`,
        ''
      );
      selectedReportingCompany = selectedReportingCompany.replace(
        `${label}`,
        ''
      );
    }
    if (type === 'project') {
      selectedProject = selectedProject.replace(`:${label}`, '');
      selectedProject = selectedProject.replace(`${label}:`, '');
      selectedProject = selectedProject.replace(`${label}`, '');
    }
    if (type === 'type') {
      selectedType = selectedType.replace(`:${label}`, '');
      selectedType = selectedType.replace(`${label}:`, '');
      selectedType = selectedType.replace(`${label}`, '');
    }
    if (type === 'reportingJurisdiction') {
      selectedReportingJurisdiction = selectedReportingJurisdiction.replace(
        `:${label}`,
        ''
      );
      selectedReportingJurisdiction = selectedReportingJurisdiction.replace(
        `${label}:`,
        ''
      );
      selectedReportingJurisdiction = selectedReportingJurisdiction.replace(
        `${label}`,
        ''
      );
    }
    this.setState({
      selectedYears,
      selectedCountry,
      selectedReportingCompany,
      selectedProject,
      selectedPaymentType,
      selectedType,
      selectedReportingJurisdiction
    },
    // ()=>this.props.handlePaymentTypeFilterChange(this.state.selectedPaymentType)
    );
  }

  resetAllFilter() {
    this.setState({
      selectedYears: '',
      selectedCountry: '',
      selectedReportingCompany: '',
      selectedProject: '',
      selectedPaymentType: '',
      selectedType: '',
      selectedReportingJurisdiction: ''
    },
    // ()=>this.props.handlePaymentTypeFilterChange(this.state.selectedPaymentType)
    );
  }

  renderFilterBy() {
    let filterData = [];
    const {
      selectedYears,
      selectedCountry,
      selectedReportingCompany,
      selectedProject,
      selectedPaymentType,
      selectedType,
      selectedReportingJurisdiction
    } = this.state;

    if (selectedYears) {
      selectedYears.split(':').forEach(year => {
        filterData.push({
          type: 'years',
          label: year
        });
      });
    }

    if (selectedPaymentType) {
      selectedPaymentType.split(':').forEach(paymentType => {
        filterData.push({
          type: 'paymentType',
          label: paymentType
        });
      });
    }

    if (selectedType) {
      selectedType.split(':').forEach(type => {
        filterData.push({
          type: 'type',
          label: type
        });
      });
    }

    if (selectedCountry) {
      selectedCountry.split(':').forEach(country => {
        filterData.push({
          type: 'country',
          label: country
        });
      });
    }

    if (selectedReportingCompany) {
      selectedReportingCompany.split(':').forEach(company => {
        filterData.push({
          type: 'company',
          label: company
        });
      });
    }

    if (selectedProject) {
      selectedProject.split(':').forEach(project => {
        filterData.push({
          type: 'project',
          label: project
        });
      });
    }

    if (selectedReportingJurisdiction) {
      selectedReportingJurisdiction
        .split(':')
        .forEach(reportingJurisdiction => {
          filterData.push({
            type: 'reportingJurisdiction',
            label: reportingJurisdiction
          });
        });
    }

    let itemsHtml = filterData.map(item => (
      <FilterKeyWord className={item.type} key={item.label}>
        <span className="keyword-label">{item.label}</span>
        <span
          className="remove-keyword"
          onClick={this.resetFilter.bind(this, item.type, item.label)}
        />
      </FilterKeyWord>
    ));

    if (filterData.length > 1) {
      itemsHtml.push(
        <button
          className="primary-btn filter-reset-btn"
          onClick={this.resetAllFilter.bind(this)}
          style={{ marginBottom: '12px' }}
          key={'clearButton'}
        >
          Clear All Filters
        </button>
      );
    }
    return itemsHtml;
  }

  toggleSideBarOptions() {
    let sidebar = document.getElementById('sidebar');
    let filterIcon = document.getElementById('filter-icon');
    let backEndOverlay = document.getElementById('backend-overlay');
    if (sidebar.classList.contains('sidebar-close')) {
      sidebar.classList.remove('sidebar-close');
      sidebar.classList.add('sidebar-open');
      filterIcon.setAttribute('style', 'display:none');
      backEndOverlay.classList.remove('is-close');
      backEndOverlay.classList.add('is-open');
    } else {
      sidebar.classList.remove('sidebar-open');
      sidebar.classList.add('sidebar-close');
      filterIcon.setAttribute('style', 'display:flex');
      backEndOverlay.classList.remove('is-open');
      backEndOverlay.classList.add('is-close');
    }
  }

  renderOnboarding = () => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('filter-company-onboarding')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" /> on the filter button to filter
            payment data.
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'filter-company-onboarding',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  handlePageClick = data => {
    this.setState({ currentPage: data.selected + 1 });
  };

  onSort = (sortBy, order) => {
    this.setState({ order, sortBy });
  };

  render() {
    const { data, fetched, name, profile } = this.props;
    // const {
    //   companyDetail: { companyName }
    // } = data;

    const {
      selectedDropdown,
      sidebarIsOpen,
      selectedYears,
      selectedCountry,
      selectedReportingCompany,
      selectedProject,
      selectedPaymentType,
      selectedType,
      selectedReportingJurisdiction,
      currentPage,
      sortBy,
      order
    } = this.state;

    // const type = selectedDropdown === 'project' ? projectType : entityType;

    let dropdownItems;
    if (fetched && !data.project.length)
      dropdownItems = [{ label: "To Gov't entities", value: 'entity' }];
    else
      dropdownItems = [
        { label: 'per project', value: 'project' },
        { label: "To Gov't entities", value: 'entity' }
      ];

    if (fetched && !data.entity.length)
      dropdownItems = [{ label: 'per project', value: 'project' }];

    let filteredData = data[`${selectedDropdown}Table`];
    if (filteredData) {
      filteredData.sort((c, d) => {
        let a, b;
        if (order === 'asc') {
          a = c;
          b = d;
        } else {
          a = d;
          b = c;
        }
        if (sortBy === 'payment') return b['totalPayment'] - a['totalPayment'];
        else if (sortBy === 'lastReported')
          return Number(b.reportYear) - Number(a.reportYear);
        else return a[sortBy] && a[sortBy].localeCompare(b[sortBy]);
      });
    }

    if (selectedYears !== '') {
      filteredData = filteredData.filter(d =>
        selectedYears.includes(d.reportYear)
      );
    }

    if (selectedPaymentType !== '') {
      filteredData = filteredData.filter(d =>
        // selectedPaymentType.includes(d.paymentType)
        {
         let newFilter= d.payments.map((payment)=>selectedPaymentType.split(':').includes(payment.paymentType))
         return newFilter.includes(true)
        }
      );
    }

    if (selectedType !== '') {
      filteredData = filteredData.filter(d =>
        selectedType.split(':').includes(d.type)
      );
    }

    if (selectedCountry !== '') {
      filteredData = filteredData.filter(d =>
        selectedCountry.includes(d[`${selectedDropdown}Country`])
      );
    }

    if (selectedReportingCompany !== '') {
      filteredData = filteredData.filter(d =>
        selectedReportingCompany.includes(d.reportingCompany)
      );
    }

    if (selectedProject !== '') {
      filteredData = filteredData.filter(d =>
        selectedProject.includes(d[`${selectedDropdown}Name`])
      );
    }

    if (selectedReportingJurisdiction !== '') {
      filteredData = filteredData.filter(d =>
        selectedReportingJurisdiction.includes(d.reportingJurisdiction)
      );
    }

    let pg = currentPage,
      pgSize = 20,
      offset = (pg - 1) * pgSize;
    let pageCount = filteredData && Math.ceil(filteredData.length / pgSize);

    let slicedData = _.drop(_.cloneDeep(filteredData), offset).slice(0, pgSize);

    // console.log(slicedData)

    return (
      <CompanyList>
        <SidebarComponent
          selectedDropdown={selectedDropdown}
          data={data[selectedDropdown]}
          sidebarIsOpen={sidebarIsOpen}
          profile={profile}
          renderFilterBy={this.renderFilterBy.bind(this)}
          handleSidebar={this.toggleSideBarOptions.bind(this)}
          selectedYears={selectedYears}
          handleYearFilter={this.handleYearFilter.bind(this)}
          handleYearClear={this.handleYearClear.bind(this)}
          selectedCountry={selectedCountry}
          handleCountryFilter={this.handleCountryFilter.bind(this)}
          handleCountryClear={this.handleCountryClear.bind(this)}
          selectedReportingCompany={selectedReportingCompany}
          handleCompanyFilter={this.handleCompanyFilter.bind(this)}
          handleCompanyClear={this.handleCompanyClear.bind(this)}
          selectedProject={selectedProject}
          handleProjectFilter={this.handleProjectFilter.bind(this)}
          handleProjectClear={this.handleProjectClear.bind(this)}
          selectedPaymentType={selectedPaymentType}
          handlePaymentTypeFilter={this.handlePaymentTypeFilter.bind(this)}
          handlePaymentTypeClear={this.handlePaymentTypeClear.bind(this)}
          selectedType={selectedType}
          handleTypeFilter={this.handleTypeFilter.bind(this)}
          handleTypeClear={this.handleTypeClear.bind(this)}
          selectedReportingJurisdiction={selectedReportingJurisdiction}
          handleReportingJurisdictionFilter={this.handleReportingJurisdictionFilter.bind(
            this
          )}
          handleReportingJurisdictionClear={this.handleReportingJurisdictionClear.bind(
            this
          )}
          toggleSideBarOptions={this.toggleSideBarOptions.bind(this)}
        />
        <BackendOverlay
          id="backend-overlay"
          onClick={this.toggleSideBarOptions.bind(this)}
        />
        <div style={{ position: 'relative' }}>
          <div className="filter_list">{this.renderFilterBy()}</div>
        </div>
        <CompanyListHelpText className="company-table-text">
          <Hint
            title={<img src={helpIcon} alt="Outlier help" />}
            text={`The tables and charts below show the complete list of payments made by ${name}. The charts show different payment characteristics. Use the drop downs to switch between projects and government agencies.`}
          />
          <Heading.h3 uppercase light style={{ marginRight: '8px' }}>
            {profile === 'company'
              ? 'Payments from the company'
              : 'Payments reported'}
          </Heading.h3>
          <SimpleDropdown
            onChange={this.handlePaymentChange.bind(this)}
            items={dropdownItems}
          />
          <Text.span>
            Currently showing all data. Apply filters to change.
          </Text.span>
        </CompanyListHelpText>
       
        <VisWrap>
          <div className="column">
            <div className="table-wrap">
            { this.props.profile === 'company' && <StackedChart
              fetched={this.props.fetched}
              data={this.props.data}
              name={this.props.companyName}
              // paymentTypes={this.state.filteredSelectedPaymentType}
              paymentTypes={selectedPaymentType}
              type={selectedDropdown}
              selectedYears={selectedYears}
              selectedCountry={selectedCountry}
              selectedReportingCompany={selectedReportingCompany}
              selectedProject={selectedProject}
              selectedReportingJurisdiction={selectedReportingJurisdiction}
              selectedType={selectedType}
            />}
              <CardBoundary>
                {/* {filteredData && ( */}
                <CompanyTable
                  data={slicedData}
                  type={selectedDropdown}
                  fetched={fetched}
                  profile={profile}
                  onSort={this.onSort}
                  sortBy={sortBy}
                  selectedPaymentType={selectedPaymentType}
                />
                {/* )} */}
              </CardBoundary>
              <FilterIconWrap className={this.props.profile === 'company' ? 'company-filter': ''}>
                <FilterIcon
                  id="filter-icon"
                  className="filter-icon-mob"
                  style={{ display: 'flex' }}
                >
                  {this.renderOnboarding()}
                  <div onClick={this.toggleSideBarOptions.bind(this)}>
                    <div className="filter">
                      <a className={'hamburger-icon'} title="Menu">
                        <span className="line line-1" />
                        <span className="line line-2" />
                        <span className="line line-3" />
                        <span className="line line-4" />
                      </a>
                    </div>
                    <Text.span size={10} uppercase bold className="label">
                      Filters
                    </Text.span>
                  </div>
                </FilterIcon>
              </FilterIconWrap>
            </div>

            {pageCount > 1 && (
              <Pagination
                onPageClick={this.handlePageClick}
                total={pageCount}
                current={this.state.currentPage}
              />
            )}
          </div>
        </VisWrap>
      </CompanyList>
    );
  }
}

export default AllCompanyList;

const CardGrid = styled.div`
  ${flex('column', null, null)};
  position: relative;
  margin-bottom: 50px;

  ${media.sm`
  	  ${flex('row', 'space-between', 'stretch')};
  `};
`;

const VisWrap = CardGrid.extend`
  // min-height: 260px;
  margin-top: 14px;
  .column {
    ${flex('column', null, null)};
    // min-height: 322px;

    &:nth-child(1) {
      margin-bottom: 24px;
      position: relative;

      ${media.sm`
    	      // width: calc(60.6% - 20px);
    	      margin-bottom: 0;
    `};
    }
    &:nth-child(2) {
      ${media.sm`
    	      width: 39.4%;
    `};
    }

    > div:nth-child(2) {
      position: relative;
      flex: 1;
    }
    .row {
      h3 {
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        // margin-bottom: 10px;
      }
      & + .row {
        margin-top: 40px;
      }
      > div:last-child {
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
        min-height: 183px;
        max-height: initial;
      }
      .bar-group rect {
        /* fill: #96bd9e; */
        fill: #b0b6be;
        &.bar--negative {
          fill: #f5c5c5;
        }
      }

      .bar-group2 rect {
        /* fill: #96bd9e; */
        fill: #b0b6be;
        &.bar--negative {
          fill: #f5c5c5;
        }
      }

      &:hover .download-image {
        background: #978aa8;
        padding: 0 8px 0 4px;
        transition: width 0.3s ease-in-out;
        width: 132px;
        span {
          line-height: 24px;
          display: block;
        }
      }
    }
    > div {
      padding: 0;
    }
  }
  .company-info {
    height: 100%;
    > div {
      /* background-color: #eef2f3; */
      height: 100%;
      padding: 26px 35px;
    }
    li {
      border-bottom: 1px solid #e8eced;
      margin-bottom: 18px;
      padding-bottom: 14px;
      padding-left: 36px;
      position: relative;
      span {
        display: block;
        font-size: 12px;
        strong {
          font-weight: 600;
        }
        &:nth-child(1) {
          font-size: 10px;
          margin-bottom: 5px;
          text-transform: uppercase;
        }
      }
      a {
        color: ${props => props.theme.primary.primary};
        & + a {
          margin-left: 2px;
        }
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &:before {
        background-image: url(${companySpriteIcon});
        background-repeat: no-repeat;
        content: '';
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;
      }
      &.headquarter:before {
        background-position: 0 0;
      }
      &.website:before {
        background-position: 0 -59px;
      }
      &.lei:before {
        background-position: 0 -120px;
        width: 25px;
      }
      &.stock-ticker:before {
        background-position: 0 -182px;
      }
      &.report:before {
        background-position: 0 -245px;
      }
      &.year:before {
        background-position: 0 -307px;
      }
    }
  }
`;

const CompanyListHelpText = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;

  & > * {
    margin-bottom: 10px;
  }

  li {
    text-transform: uppercase;
  }
  > span {
    &:nth-child(1) {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
    &:last-child {
      color: ${props => props.theme.primary.white};
      font-size: 12px;
      padding-left: 12px;
    }
  }
`;

const CompanyList = styled.div`
  .sidebar-open {
    position: fixed;
    overflow: auto;
    height: 50vh;
  }
  .filter_list {
    left: -12%;
    //min-width: 200px;
    max-width: 122px;
    position: absolute;
    top: 122px;
    display: none;
    > span {
      border-radius: 13px;
      background: rgba(255, 255, 255, 0.1);
      line-height: normal;
      margin-bottom: 4px;
      padding: 4px 10px;
      &:hover {
        .remove-keyword {
        }
      }
    }
    > span:first-of-type {
      width: 100%;
      word-break: break-word;
    }
    @media screen and (min-width: 1280px) {
      display: block;
      left: -15.5%;
      min-width: 130px;
      max-width: 130px;
    }
    @media screen and (min-width: 1320px) {
      left: -17.5%;
      min-width: 150px;
      max-width: 150px;
    }
    @media screen and (min-width: 1360px) {
      left: -19%;
    }
    @media screen and (min-width: 1400px) {
      left: -21%;
      min-width: 170px;
      max-width: 170px;
    }
  }
`;

const BackendOverlay = styled.div`
  &.is-open {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 273px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
`;

const FilterIcon = styled.div`
  display: none;
  margin-bottom: 8px;
  @media screen and (min-width: 1340px) {
    // position: absolute;
    // left: -46px;
    display: block;
    ${flex('column', 'center', 'flex-end')};
  }

  .filter {

    & + .label {
      color: ${props => props.theme.primary.white}!important;
      font-weight: bold;
    }
    .hamburger-icon .line {
        background-color: ${props => props.theme.primary.white};
      }
    }
  }

  .hamburger-icon {
    width: 18px;
    height: 10px;
    position: relative;
    display: inline-block;
    .line {
      display: block;
      background: #3a424c;
      width: 18px;
      height: 2px;
      position: absolute;
      left: 0;
      border-radius: 1px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
        width: 12px;
      }
      &.line-3 {
        top: 100%;
        width: 4px;
      }
      &.line-4 {
        top: 100%;
        width: 18px;
        opacity: 0;
      }
    }
    &:hover .line-1,
    &:focus .line-1 {
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
    }
    &:hover .line-3,
    &:focus .line-3 {
      transform: translateY(1px);
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
    }
    &.active {
      .line-1 {
        transform: translateY(5px) translateX(0) rotate(45deg);
        -webkit-transform: translateY(5px) translateX(0) rotate(45deg);
        -moz-transform: translateY(5px) translateX(0) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        opacity: 0;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
      .line-4 {
        opacity: 1;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
    }
  }

  .icon {
    ${flex('column', 'center', 'center')};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: ${props => rgba(props.theme.primary.white, 0.7)};
    margin-bottom: 4px;
    padding: 8px;
    transition: ${props => props.theme.transition.base};
  }

  .label {
    color: ${props => rgba(props.theme.primary.white, 0.5)};
    transition: ${props => props.theme.transition.base};
    display: block;
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.primary};
      padding-left: 4px;
    }
  }

  &:hover {
    @media screen and (max-width: 1100px) {
      color: ${props => props.theme.primary.dark};
    }
    .icon {
      background: ${props => props.theme.primary.white};
    }
    .label {
      color: ${props => props.theme.primary.white};
      @media screen and (max-width: 1100px) {
        color: ${props => props.theme.primary.primary};
      }
    }
  }

  &.filter-icon-mob {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #20BCFB;
    box-shadow: 0 0 24px rgb(24, 161, 214);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 8px 4px;
    /* left: 48px; */
    bottom: 0;
    /* position: fixed; */
    /* transform: translateX(-50px); */
    padding: 0;
    > div {
      padding: 10px 20px;
      text-align: center;
    } 
    @media all and (min-width: 767px) {
      /* background-color: red; */
    }
    .filter {
      margin-bottom: 4px; 
    }
    &.no-fixed {
      position: absolute;
      bottom: 91px;
      /* left: 28px; */
      /* bottom: 99px; */
      @media all and (min-width: 768px) {
        bottom: 150px;
      }
    }
    &.sticky {
      position: fixed;
      top: 33px;
      left: auto;
      transform: translateX(-53px);
      @media screen and(min-width: 1360 px) {
        transform: translateX(-53px);
      }
    }
  }
`;

const FilterIconWrap = styled.div`
  position: relative;
  &.company-filter {
    /* left: -79px;
    position: absolute;
    top: 90px; */
    .filter-icon-mob {
      border-radius: 10px;
      height: 50px;
      @media all and (max-width: 1210px) {
        left: auto!important;
      }
      > div {
        padding: 10px 14px;
      }
    }
  }
  /* @media all and (max-width: 768px) {
    position: static;
  } */
`;

const FlexDownload = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 200px;
  position: absolute;
  /* left: -4px;
  top: 11.5%; */
  left: -18%;
  bottom: 60px;
  z-index: 1;
  @media all and (min-width: 1400px) {
    padding: 18px 18px 12px;
    left: -222px;
    bottom: -6px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 134px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width: 1400px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 42px;
      right: -13px;
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
