import styled from 'styled-components';
import { getAppImages } from '../../helpers';
import Colours from '../../theme/colours';
const { primary } = Colours;
const { EntityBgImg, ProjectBgImg, SourcesBgImg } = getAppImages;

export const HomeWrapper = styled.div`
  background: ${primary.white};
`;

export const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  min-height: calc(75vh - 75px);
  position: relative;
  padding: 50px 50px 200px 50px;

  @media (max-width: 480px) {
    padding: 50px 24px 200px 24px;
  }
`;

export const HeroContent = styled.div`
  max-width: 680px;
  position: relative;
  margin: 50px auto 0 auto;
  text-align: center;
  color: ${primary.white};

  @media (max-width: 767px) {
    margin-top: 16px;
  }

  * {
    font-weight: 300;
  }

  .hero-title {
    font-size: 42px;
    max-width: 680px;
    padding: 0 25px;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
      margin: 16px 20px 18px 20px;
      padding: 0;
    }

    @media (max-width: 568px) {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 18px 0;
    }
  }

  .hero-text {
    opacity: 0.7;
    font-weight: 300;
    margin: 24px 100px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      margin: 0 50px 18px 50px;
    }

    @media (max-width: 568px) {
      font-size: 14px;
      line-height: 22px;
      padding: 0;
    }

    @media (max-width: 568px) {
      margin: 0 30px 18px 30px;
    }
  }

  .learn-more {
    font-weight: normal;
    color: ${primary.cyan};
    margin-left: 4px;
    text-decoration: none;
    border-bottom: 1px dashed ${primary.cyan};
  }
`;

export const HeroLinks = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 760px;
    width: 100%;
    margin: -180px auto 34px auto;
    padding: 0 36px;

    .link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 215px;
        min-height: 260px;
        width: 215px;
        background: #096CD6;
        padding: 24px;
        margin-bottom: 36px;
        color: ${primary.white};
        position: relative;
        border-radius: 4px;
        text-decoration: none;
        text-align: center;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        @media (max-width: 480px){
            flex: 0 0 275px;
            min-height: 300px;
        }

        &:before{
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.1;
        }

        .link-title{
            font-size: 26px;
            font-weight: 600;
            line-height: 30px;
            position: relative;
            min-height: 56px;

            span{
                display: block;
                opacity: 0.7;
                font-size: 20px;
                line-height: 24px;
                font-weight: normal;
                letter-spacing: 0.8px;
            }
        }

        .link-info{
            position: relative;
            font-size: 15px;
            font-weight: 300;
        }

        &.project{
            box-shadow: 0 30px 40px -15px rgba(33, 122, 218, 0.6);

            &:before{
                background: url('${ProjectBgImg}') no-repeat center center;
                background-size: cover;
            }

            &:hover{
                transform: translateY(-4px);
                box-shadow: 0 40px 55px -15px rgba(33, 122, 218, 0.6);
            }
        }

        &.entity{
            background: #09B7D6;
            box-shadow: 0 30px 40px -15px rgba(6, 148, 185, 0.6);
            margin-left: 12px;
            margin-right: 12px;

            .link-title{
                letter-spacing: 0px;
            }

            &:before{
                background: url('${EntityBgImg}') no-repeat center center;
                background-size: cover;
            }

            &:hover{
                transform: translateY(-6px);
                box-shadow: 0 40px 55px -15px rgba(6, 148, 185, 0.6);
            }
        }

        &.sources{
            background: #09D7CB;
            box-shadow: 0 30px 40px -15px rgba(33, 204, 187, 0.6);

            &:before{
                background: url('${SourcesBgImg}') no-repeat center center;
                background-size: cover;
            }

            &:hover{
                transform: translateY(-6px);
                box-shadow: 0 40px 55px -15px rgba(33, 204, 187, 0.6);
            }
        }
    }
`;
