import React, { Component } from 'react';
import {
  TableWrapper,
  Table,
  TH,
  TR,
  TD,
  TRExpanded,
  TDExpanded
} from '../../../../../components/styled';
import { getFlag, getAppImages } from '../../../../../helpers';
import moment from 'moment';
import Report from '../../../../../components/layouts/report';
import { Notes, TextTruncate } from '../../../../../components/layouts';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownMenu,
  transitionStyles
} from '../../../../../components/layouts/dropdown/dropdownStyles';
import { Transition } from 'react-transition-group';
import { rgba } from '../../../../../components/styled/utils/colors';
import Colours from '../../../../../components/styled/theme/colours';
import { Link } from 'react-router-dom';
import { media } from '../../../../../components/styled/utils';
import Sparkline from '../../../../../screens/company/sparkline/sparkline';
import numberFormatter from '../../../../../utils/numberFormatter';

// import * as _ from 'lodash';
// import ScrollArea from 'react-scrollbar/dist/no-css';

const { primary } = Colours;

const { infoIcon, clickIcon } = getAppImages;

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeReport: -1,
      windowSize: 0,
      position: '',
      showSubTable: false,
      pos: {},
      tablePos: {},
      extendedTablePos: {},
      onboardingHide: false
    };
  }

  componentDidMount() {
    this.setState({ windowSize: window.innerWidth });
  }

  componentDidUpdate() {
    // console.log(this.state.pos)
  }
  /**
   *
   * Render report
   */
  _renderReport(index, data) {
    let { activeReport, reportFormShown, position } = this.state;
    return (
      <Report
        reportFormShown={reportFormShown}
        showForm={this._showReportForm.bind(this, index)}
        hideForm={this._hideReportForm.bind(this)}
        index={index}
        position={position}
        activeReport={activeReport}
        data={data}
        reportType={
          this.props.component.charAt(0).toUpperCase() +
          this.props.component.slice(1) //to make the first letter uppercase
        }
      />
    );
  }

  _showReportForm(index) {
    this.setState({ activeReport: index, reportFormShown: true });
  }

  _hideReportForm(e) {
    e.stopPropagation();
    this.setState({ reportFormShown: false, activeReport: -1 });
  }

  _toggleReportIcon(index = -1) {
    !this.state.reportFormShown && this.setState({ activeReport: index });
  }

  /**
   * Render notes
   */
  _renderNote(index, notes, nrgiNotes) {
    let { activeNote, position } = this.state;

    if (
      (notes !== 'undefined' && notes !== 'NA' && notes) ||
      (nrgiNotes !== 'undefined' && nrgiNotes)
    ) {
      return (
        <Notes
          onHide={() => this.setState({ activeNote: -1 })}
          onSetActive={this._setActiveNote.bind(this)}
          index={index}
          position={position}
          activeNote={activeNote}
          notes={notes}
          nrgiNotes={nrgiNotes}
          pos={this.state.pos}
          tablePos={this.state.tablePos}
          extendedTablePos={this.state.extendedTablePos}
        />
      );
    }

    return '';
  }

  _setActiveNote(e, index = -1) {
    if (!this.state.activeNote !== index) {
      let viewportOffset = e.target.getBoundingClientRect();
      let position = '';
      let topOffset = viewportOffset.top;
      this.setState({ pos: viewportOffset });

      if (viewportOffset.top > window.innerHeight - viewportOffset.bottom) {
        position = 'top';
      } else {
        position = 'bottom';
      }

      this.setState({ activeNote: index }, () => {
        let noteContent = document.getElementsByClassName('note-content');
        let elemHeight = noteContent[0].offsetHeight;
        noteContent[0].addEventListener('mouseover', function(e) {
          e.stopPropagation();
        });

        if (elemHeight > topOffset) {
          position = 'bottom';
        }

        this.setState({ position });
      });
    }
  }

  //to get flag icon
  _renderFlag(projectCountry, code) {
    if (code !== '') {
      return (
        <span>
          <img
            src={getFlag(code)}
            alt={projectCountry + 'Flag'}
            className="flag"
          />
          {projectCountry.substr(0, 12)}
        </span>
      );
    }

    return projectCountry;
  }

  _renderHeaderYear(payments) {
    let minYear = payments[0].minYear;
    let maxYear = payments[0].maxYear;
    const years = [];
    for (let y = maxYear; y >= minYear; y--) {
      years.push(y);
    }
    return (
      <div className="table__info-row table__heading">
        {years.map(y => (
          <div>
            <p>{y}</p>
          </div>
        ))}
      </div>
    );
  }

  _renderPaymentTable(payments, i, d, component) {
    let minYear = payments[0].minYear;
    let maxYear = payments[0].maxYear;
    const years = [];
    for (let y = maxYear; y >= minYear; y--) {
      years.push(y);
    }
    return payments.map((a, y) => (
      <div
        className="table__info-row"
        onMouseEnter={this.handleTableHover.bind(this)}
      >
        {a.data
          .sort((c, d) => Number(d.year) - Number(c.year))
          .map((b, z) => (
            <div
              className={`table__col-content ${
                b.notes || b.nrgiNotes ? 'has-note' : ''
              }`}
            >
              <p>{b.payment}</p>
              <span className="note-icon">
                {(b.notes && b.notes.filter(a => a !== null).length) ||
                (b.nrgiNotes && b.nrgiNotes.filter(a => a !== null).length) ? (
                  <React.Fragment>
                    {this._renderNote(`${y}${z}`, b.notes, b.nrgiNotes)}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </span>
              {b.notes || b.nrgiNotes
                ? this._renderReport(i, {
                    name: d.reportingCompany,
                    reportingCompany: d.reportingCompany,
                    country: d.reportingCountry,
                    paymentType: component === 'source' ? '' : 'Tax',
                    // startDate,
                    // endDate,
                    value:
                      component === 'source' ? '' : d[component + 'Payment']
                  })
                : null}
            </div>
          ))}
      </div>
    ));
  }

  _renderPaymentTotal(payments, maxYear) {
    let years = payments.map(d => Number(d.year));
    const minYear = Math.min(...years);
    const data = [];
    for (let y = minYear; y <= maxYear; y++) {
      let i = payments.find(p => Number(p.year) === Number(y));
      if (i) {
        data.push(i);
      } else {
        data.push({ year: y.toString(), value: '-' });
      }
    }
    payments = data.sort((a, b) => b.year - a.year);
    return (
      <div className="table__info-row table__footer">
        {payments.map(p => (
          <div>
            <p>{p.value !== '-' ? numberFormatter(p.value) : '-'}</p>
          </div>
        ))}
      </div>
    );
  }

  renderOnboarding = component => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('filter-onBoarding-render')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" />{' '}
            {`on a row to view payments by year and type for a ${component}.`}
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'filter-onBoarding-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  // handleDropdownMenu(dropdownState) {
  //   this.setState({
  //     isOpen: dropdownState
  //   });
  // }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }
  handleDropdownOtherSource(dropdownState) {
    this.setState({
      isOtherSourceOpen: dropdownState
    });
  }
  handleSubTable() {
    this.setState({
      showSubTable: !this.state.showSubTable,
      onboardingHide: true
    });
  }

  getExtendedTableCoordinate(e) {
    const coordinate = e.currentTarget.getBoundingClientRect();
    this.setState({ extendedTablePos: coordinate });
  }
  handleTableHover(e) {
    const coordinate = e.currentTarget.getBoundingClientRect();

    this.setState({ tablePos: coordinate });
  }

  _renderTableHead(){
    return (
      <thead>
        <tr>         
            <TH
              width="10px"
              title="Published Date"
              // className={header.class}
            />
             <TH
              width="10px"
              title="File Type"
              // className={header.class}
            />
             
        </tr>
      </thead>)

  }

  render() {
    let { component, d, i } = this.props;
    let stDate =
      component === 'source' ? d.reportStartDate : d.source.reportStartDate;
    let enDate =
      component === 'source' ? d.reportEndDate : d.source.reportEndDate;
    let startDate = moment(stDate, 'MM-DD-YYYY').format('MMM, YYYY');
    let endDate = moment(enDate, 'MM-DD-YYYY').format('MMM, YYYY');
    let sourcePublishDate = d.sourcePublishDate
      ? moment(d.sourcePublishDate, 'MM-DD-YYYY').format('MMM DD, YYYY')
      : 'Not Available';

    const sourceText =
      'Original source points to the online link that was used either by the company or some third party to post this mandatory disclosure report. However, over time some of these links may become defunct. We therefore maintain backups for all the sources used on this website so that users can always trace back data to the original payment table.';

    return (
      <React.Fragment>
        {i === 0 && component !== 'source' && this.renderOnboarding(component)}
        <TR
          // onMouseEnter={this._toggleReportIcon.bind(this, i)}
          // onMouseLeave={this._toggleReportIcon.bind(this)}
          onClick={this.handleSubTable.bind(this)}
          className={`${this.state.showSubTable ? 'expanded' : ''} ${
            component === 'source' ? 'row-source' : ''
          }`}
        >
          {component !== 'source' && (
            <TD
              className="project-name"
              data-label={component.toUpperCase() + ' NAME'}
            >
              {/* <button type="button" onClick={this.handleSubTable.bind(this)}>
                Detail
              </button> */}

              <svg
                className="ic-arrow"
                width="7"
                height="10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1l4 4-4 4"
                  stroke="#333"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              {d[component + 'Name'] && (
                <span
                  className="content  content-with-link"
                >
                   
                {/* {this.state.showSubTable ? '' : <span>...</span>} */}

                {/* d[component + 'Name'].length <= 20 ? '' : <span>...</span> */}

                  {/* {d[component + 'Name'].substr(0, 20)} */}
               
                {this.state.showSubTable ? 
                  d[component + 'Name'] : 
                  d[component + 'Name'].substr(0, 20)
                }

                  {d[component + 'Name'].length <= 20 ? (
                    ''
                  ) : this.state.showSubTable ? ('') : (
                    <span className="popup-content">
                      {d[component + 'Name']}
                    </span>
                  )}

                  {d[component + 'Name'].length <= 20 ? '' : <span class="more-dots">...</span>}
                </span>
              )}
            </TD>
          )}
          <TD
            className={component === 'source' ? 'project-name' : 'company'}
            data-label="REPORTING COMPANY"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                to={`company/${encodeURIComponent(d.reportingCompany)}`}
                style={{ overflow: 'hidden' }}
              >
                <span
                  className="content"
                  // title={d.reportingCompany}
                >
                  {/* {d.reportingCompany.substr(0, 24)}
                  {d.reportingCompany.length <= 24 ? '' : <span>...</span>}
                  {d.reportingCompany.length <= 24 ? (
                    ''
                  ) : (
                    <span className="popup-content">{d.reportingCompany}</span>
                  )}  */}

                  {this.state.showSubTable ? 
                    d.reportingCompany : 
                    d.reportingCompany.substr(0, 26)
                  } 
                  {d.reportingCompany.length <= 26 ? (
                    ''
                  ) : this.state.showSubTable ? ('') : (
                    <span className="popup-content">
                      {d.reportingCompany}
                    </span>
                  )}
                  {d.reportingCompany.length <= 26 ? '' : <span class="more-dots">...</span>}
                </span>
              </Link>
              {component === 'source' && (
                <Dropdown
                  onMouseEnter={() => this.handleDropdownMenu(true)}
                  onMouseLeave={() => this.handleDropdownMenu(false)}
                >
                  <DropdownButton>
                    <Title className="title">Source</Title>
                    <DropdownIcon isOpen={this.state.isOpen} />
                  </DropdownButton>

                  <Transition
                    in={this.state.isOpen}
                    timeout={100}
                    mountOnEnter={true}
                  >
                    {state => (
                      <DropdownMenu
                        style={{
                          ...transitionStyles[state]
                        }}
                      >
                        <ul className="dropdown-list" style={{ minWidth: 220 }}>
                          <li className="dropdown-list-item dropdown-list-item-link">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={d.sourceURL}
                              title="Go to source site."
                            >
                              Backup source ({d.format ? d.format.split('.')[1] : 'N/A'})
                              <span className="external-link">link</span>
                            </a>
                          </li>
                          <li className="dropdown-list-item dropdown-list-item-link">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={d.originalURL}
                              title="Go to source site."
                            >
                              Original source ({d.format ? d.format.split('.')[1] : 'N/A'})
                              <span className="external-link">link</span>
                            </a>
                          </li>
                          <li className="source-text">
                            <img
                              src={infoIcon}
                              alt="info"
                              style={{ marginTop: 4, marginRight: 4 }}
                            />
                            <TextTruncate
                              text={sourceText}
                              truncateLength={72}
                            />
                          </li>
                        </ul>
                      </DropdownMenu>
                    )}
                  </Transition>
                </Dropdown>
                // <a
                //   className="external-link"
                //   target="_blank"
                //   href={d.sourceURL}
                //   title="Go to source site."
                // >
                //   link
                // </a>
              )}
             
            </div>
          </TD>
          <TD
            className="country"
            data-label={component.toUpperCase() + ' COUNTRY'}
          >
            <Link
              to={
                d.reportingCountry
                  ? 'country/' + d.countryCode
                  : 'country/' + d[component + 'CountryCode']
              }
            >
              <span
                className="content"
                // title={
                //   d.reportingCountry
                //     ? d.reportingCountry
                //     : d[component + 'Country']
                // }
              >
                {this._renderFlag(
                  d.reportingCountry
                    ? d.reportingCountry
                    : d[component + 'Country'],
                  d.countryCode
                )}
                {d.reportingCountry ? (
                  d.reportingCountry.length <= 12 ? (
                    ''
                  ) : (
                    <span>
                      <span>...</span>
                      <span className="popup-content">
                        {d.reportingCountry}
                      </span>
                    </span>
                  )
                ) : d[component + 'Country'].length <= 12 ? (
                  ''
                ) : (
                  <span>
                    <span>...</span>
                    <span className="popup-content">
                      {d[component + 'Country']}
                    </span>
                  </span>
                )}
              </span>
            </Link>
          </TD>
          {/* {d.paymentType && (
          <TD data-label="PAYMENT TYPE">
            <span className="content" title={d.paymentType}  style={{maxWidth: '80px'}}>
              {d.paymentType.substr(0,10)}
              {d.paymentType.length <= 10 ? (''): <span>...</span>}
              {d.paymentType.length <= 10 ? (''): <span className="popup-content"> {d.paymentType}</span>}
            </span>
          </TD>
        )}*/}

          {component === 'source' ? (
            <React.Fragment>
              <TD data-label="START DATE">{startDate}</TD>
              <TD data-label="END DATE">{endDate}</TD>{' '}
            </React.Fragment>
          ) : (
            <TD data-label="LAST REPORTED">{d.reportYear}</TD>
          )}
          {component === 'source' && (
            <TD
              data-label="PUBLISHED DATE"
              className={!d.sourcePublishDate ? 'noData' : ''}
            >
              {sourcePublishDate}
            </TD>
          )}
          {d.totalPayment && (
            <TD className="value" data-label="Payments (USD)">
              <div className="value-content">
                <span>{d.totalPayment}</span>
                <Sparkline
                  data={d.sparkLineData}
                  minYear={d.minYear}
                  maxYear={d.maxYear}
                />
              </div>
            </TD>
          )}
          {component === 'source' && d.unusedSources ? (
             <TD className="unused-source">
                <Dropdown
                onMouseEnter={() => this.handleDropdownOtherSource(true)}
                onMouseLeave={() => this.handleDropdownOtherSource(false)}
              >
                <DropdownButton>
                  {/* <Title className="title">{d.unusedSources.length}{d.unusedSources.length ===  1 ? ' Other Source' : ' Other Sources'}</Title> */}
                  <Title className="title">Additional Sources</Title>
                  <DropdownIcon isOpen={this.state.isOtherSourceOpen} />
                </DropdownButton>

                <Transition
                  in={this.state.isOtherSourceOpen}
                  timeout={100}
                  mountOnEnter={true}
                >
                  {state => (
                    <DropdownMenu
                      style={{
                        ...transitionStyles[state]
                      }}
                    >
                      <ul className="dropdown-list" style={{ minWidth: 320 }}>                      
                          <TableUnusedSource>
                            <thead>
                              <tr>
                                <th>Published date</th>
                                <th>File Type</th>
                              </tr>
                            </thead>
                            <tbody>
                            {d.unusedSources.map((unUsedData) => {
                              let format = unUsedData.format ? unUsedData.format.split('.')[1] : 'N/A'
                              return (
                                <tr>
                                  <td data-label="PUBLISHED DATE">{unUsedData.sourcePublishDate
                                    ? moment(unUsedData.sourcePublishDate, 'MM-DD-YYYY').format('MMM DD, YYYY')
                                    : 'Not Available'}
                                  </td>
                                  <td>
                                  <span className="published-year">{format.toUpperCase()}</span>
                                  <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={unUsedData.sourceURL}
                                      title="View the source document"
                                    >
                                      Link
                                  {/* <span className="external-link">link</span> */}
                                  </a>
                                  {' |'}
                                     <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={unUsedData.originalURL}
                                      title="Go to external source site"
                                    >
                                      Origin
                                    {/* <span className="external-link">link</span> */}
                                    </a>
                                  </td>
                                </tr>
                              )
                            })
                            }
                             <tr>
                                <td colspan="2" className="unusedsource-text">
                                  <div>
                                    <img
                                      src={infoIcon}
                                      alt="info"
                                      style={{ marginTop: 4, marginRight: 4 }}
                                    />
                                    <p>This company published multiple versions of their payment report. A company may publish multiple reports to satisfy requirements in different jurisdictions or to amend earlier versions. The data found on ResourceProjects.org is not from these versions.</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </TableUnusedSource>                             
                      </ul>
                    </DropdownMenu>
                  )}
                </Transition>
              </Dropdown>
              </TD>
              ): component !== 'source' ? '': (<TD></TD>)}
        </TR>
        {component !== 'source' && (
          <TRExpanded className={`${this.state.showSubTable ? '' : 'hide'}`}>
            <TDExpanded colSpan="5">
              <TableData className="nested-table">
                <div
                  onClick={this.handleSubTable.bind(this)}
                  className="modal-close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6L6 18M6 6l12 12" />
                  </svg>
                </div>
                <div className="table__title add-shadow">
                  <div className="table__heading">
                    <p>Payments Types</p>
                  </div>
                  {d.payments &&
                    d.payments.map(p => (
                      <div className="table__payments">
                        {/* <p>{p.paymentType}</p> */}
                        <span
                          className="content  content-with-link"
                          // title={d[component + 'Name']}
                        >
                          {p.paymentType.substr(0, 16)}
                          {p.paymentType.length <= 20 ? (
                            ''
                          ) : (
                            <span className="popup-content">
                              {p.paymentType}
                            </span>
                          )}
                          {p.paymentType.length <= 20 ? '' : <span>...</span>}
                        </span>
                        <Sparkline
                          data={p.data.filter(d => d.value !== '-')}
                          minYear={d.minYear}
                          maxYear={d.maxYear}
                        />
                      </div>
                    ))}
                  <div>
                    <p className="table__footer">Total</p>
                  </div>
                </div>
                <div
                  className="table__info"
                  onMouseEnter={this.getExtendedTableCoordinate.bind(this)}
                >
                  {this._renderHeaderYear(d.payments)}

                  {this._renderPaymentTable(d.payments, i, d, component)}

                  {this._renderPaymentTotal(
                    d.sparkLineData,
                    d.payments[0].maxYear
                  )}
                </div>
              </TableData>
            </TDExpanded>
          </TRExpanded>
        )}
      </React.Fragment>
    );
  }
}

const TableData = styled.div`
  display: flex;
  /* padding: 20px 30px 10px 40px; */
  font-size: 14px;
  color: #333;
  font-weight: bold;
  position: relative;
  /* padding-bottom: 15px; */

  .modal-close {
    position: absolute;
    right: 10px;
    top: -30px;
    color: #fff;
    display: none;
    cursor: pointer;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .table {
    &__title {
      width: 260px;
      flex-shrink: 0;
      @media (max-width: 767px) {
        width: 180px;
      }

      &.add-shadow {
        box-shadow: 30px 0px 30px -30px #605778;
        z-index: 1;
        position: relative;
      }
      > div {
        padding: 10px 30px 10px 40px;
        border-bottom: 1px solid #d9ccf9;

        @media (max-width: 767px) {
          padding: 10px;
        }

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          border: none;
          padding-bottom: 15px;
        }
      }
    }

    &__heading,
    &__footer {
      color: rgba(51, 51, 51, 0.7);
      font-weight: normal;
      font-size: 12px;
    }

    &__info {
      flex: 1;
      direction: rtl;
      overflow-x: auto;
      padding-bottom: 15px;
      padding-right: 20px;
      /* overflow-y: hidden; */

      &-row {
        display: flex;
        /* position: relative; */
        /* border-bottom: 0.5px solid #d9ccf9; */

        &:last-child {
          border-bottom: none;
        }

        &.table__heading > div {
          padding-top: 20px;
          padding-right: 20px;
        }

        &.table__footer > div {
          border: none;
          padding-right: 20px;
        }

        > div {
          /* margin-left: 20px!important; */
          width: 130px;
          flex-shrink: 0;
          border-bottom: 1px solid #d9ccf9;
          padding: 10px 0 10px 20px;

          /* &:first-child {
            padding-right: 20px;

            @media (max-width: 767px) {
              padding-right: 10px;
            }
          } */
        }
      }
    }

    &__col-content {
      direction: ltr;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        .payment-report {
          opacity: 1;
          visibility: visible;
        }
      }
      &:not(.has-note) {
        padding-right: 20px;
      }
    }

    &__payments {
      display: flex;
      justify-content: space-between;
      position: relative;

      .sparkline {
        height: 16px;
        position: absolute;
        right: 20px;

        @media (max-width: 767px) {
          right: 10px;
        }
      }
    }
  }
`;

const DropdownButton = styled.span`
  position: relative;
  display: inline-block;
  background-color: #ECEAF1;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #333;
  font-size: 9px;
  cursor: pointer;
  padding: 2px 8px 1px;
  margin-left: 4px;
  border-radius: 16px;
  white-space: nowrap;
  &:hover {
    border-color: #333;
  }
`;

const Title = styled.span`
  color: #333;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent #333;`
      : `#333 transparent transparent;`};
  border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  margin-top: -1px;
`;

const FileFormat = styled.span`
  background-repeat: no-repeat;
  display: block;
  background: rgba(51, 51, 51, 0.3);
  text-transform: uppercase;
  color: #fff;
  padding: 1px;
  border-radius: 4px;
  font-size: 10px;
  position: relative;
  z-index: 1;
  width: 33px;
  text-align: center;
  ${media.sm`margin: 0 auto;`}
`;

const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 200px;
  position: absolute;
  /* left: -4px;
  top: 11.5%; */
  left: -2%;
  top: -70px;
  /* bottom: 60px; */
  z-index: 1;
  @media all and (min-width: 1400px) {
    padding: 18px 18px 12px;
    left: -222px;
    top: 0;
    /* bottom: -6px; */
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 134px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width: 1400px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 42px;
      right: -13px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const TableUnusedSource = styled.table`
    color: #333;  
    font-size: 12px;
    th,td {
      padding: 8px 16px;
    }
    th {
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
    }
    tr {
      cursor: auto;
    }
    tr td {
      font-weight: 400;
      padding-bottom: 8px!important;
      > a {
        color: ${primary.primary};
        font-weight: 700;
        margin-left: 6px;
      }
    }
     .published-year {
        display: inline-block;
        font-size: 12px;
        width: 30px;
      }
    .unusedsource-text div{
      display: flex;
      align-items: flex-start;
      p {
        color: #3A424C;
        font-size: 11px;
        font-weight: 400;
      }
    }
`;

export default TableBody;
