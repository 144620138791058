import Api from '../api';

let plotData = [];
class Country {
  async sankeyData(countryName, component, filterBy) {
    let params = { countryName, component, filterBy };
    return await Api.get('country/sankeyData', { params });
  }

  async getStats(country) {
    let params = { country };
    let response = await Api.get('country/stats', { params });
    return response.body;
  }

  async getPlotData(component, years) {
    let params = { component, years };
    if (plotData.length === 0) {
      plotData = await Api.get('country/plotData', { params });
    }

    return plotData.body.result[component];
  }

  async countryData(countryName) {
    let params = { countryName };
    const response = await Api.get('country', { params });
    return response;
  }

  //today here
  async getCountryProjectList(countryName, type) {
    let params = await this.mapCountryNameFromCode(countryName)
    if(type === 'project'){
      let response = await Api.get(`country/${params.countryName}/${type}`);
      return response;

    }
    else{
      let response = await Api.get(`country/${params.countryName}/${type}`);
      return response;
    }
   
  }
  

  async incomeGroup() {
    return await Api.get('/country/incomeGroup');
  }

  async getBarChartData(countryName, component, filterBy) {
    let params = { countryName, component, filterBy };
    return await Api.get('country/barChartData', { params });
  }

  async getCountryList() {
    let response = await Api.get('country/list');
    return response.body;
  }

  async getCountryYears(countryName, component) {
    let params = { countryName, component };
    let response = await Api.get('country/years', { params });
    return response.body;
  }

  async getOverAllStats() {
    return await Api.get('/country/overallStats');
  }

  async getCountryCompanyList() {
    return await Api.get('/list');
  }
}

export default new Country();
