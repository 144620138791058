import styled from 'styled-components';
import Colours from '../../components/styled/theme/colours';
import { media } from '../../components/styled/utils/';
const { primary } = Colours;
export const StaticPage = styled.div``;

export const StaticHeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-size: cover;
  padding: 0 0 48px 0;
  position: relative;
  z-index: 9;

  ${media.sm`
      padding: 50px 0 280px 0;
  `};
`;

export const StaticHeroContent = styled.div`
  position: relative;
  max-width: 640px;
  margin: 35px auto 0 auto;
  text-align: center;
  z-index: 100;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const StaticPageTitle = styled.h1`
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  color: #eceaf1;
  font-weight: 700;
  margin-bottom: 18px;
  padding: 0 20px;
`;

export const StaticPageText = styled.p`
  line-height: 24px;
  font-size: 16px;
  color: #eceaf1;
  margin-bottom: 60px;
  padding: 0 20px;
`;

export const StaticContentWrapper = styled.section`
  position: relative;
  background-color: #eceaf1;

  &:before {
    content: '';
    display: ${props => (props.wide ? 'none' : 'block')};
    height: 50%;
    width: 100%;
    max-width: 768px;
    max-height: 600px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 -34px 50px -10px rgba(0, 0, 0, 0.2);
    z-index: 25;
    background-color: #eceaf1;
    ${media.sm`
    border-radius: 12px;
    margin-top: -120px;
  `};
  }
`;

export const StaticContent = styled.div`
  position: relative;
  max-width: 768px;
  // background: ${props => (props.wide ? 'transparent' : '#eceaf1')};
  border-radius: 12px;
  padding: 8px 18px 54px 18px;
  border-radius: 12px;

  z-index: 100;
  min-height: 400px;

  ${media.sm`
  margin: -120px auto 0 auto;
  padding: 16px 95px 54px 95px;
`};

  > div {
    /* background-color: #eceaf1; */

    ${media.sm`
    margin-top: -120px;
  `};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    padding-top: 74px;
    margin-bottom: 16px;

    @media (max-width: 568px) {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 20px;
    text-align: center;
  }

  p {
    line-height: 26px;
    margin-bottom: 22px;

    @media (max-width: 568px) {
      line-height: 20px;
      margin-top: 0;
    }
  }

  ul {
    padding-left: 0;
    line-height: 26px;
    list-style: none;
    margin-top: 0;

    @media (max-width: 568px) {
      margin: 0;
    }
  }

  li {
    position: relative;
    padding-left: 12px;
    margin: 32px 0 4px 0;

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      position: absolute;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background: ${primary.primary};
    }

    > strong {
      font-size: 18px;
      font-weight: 600;
    }
  }

  a {
    color: ${primary.primary};
    font-weight: bold;
    &:hover {
        color: #1B9ACC;
    }
  }
  
  div {
    line-height: 22px;
  }
`;
