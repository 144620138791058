const initialState = {
  fetching: true,
  fetched: false,
  data: [],
  total: 0,
  totalPayment: '',
  totalPayments: 0,
  perPage: 25,
  currentPage: 1,
  filter: {
    countries: '',
    reportingCompanies: '',
    paymentTypes: '',
    reportingJurisdiction: ''
  },
  sortBy: 'projectName',
  order: 'asc',
  download: 0,
  query: '',
  error: null,
  bubbleData: [],
  countryIncome: null,

  /*******************  Visualization  ****************/
  visualizationData: [],
  mapData: [],
  selectedTotalPayments: 'all',
  selectedTopProjects: 'all',
  totalPaymentsData: {},
  yearsFilter: ['all'],
  markersData: {},
  selectedCountry: 'all',
  mapTooltipData: {},
  markerTooltipData: {},
  barChartTooltipData: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PROJECT_FETCHING':
      state = { ...state, ...{ error: null, fetching: true } };
      break;

    case 'PROJECT_SET_CURRENT_PAGE':
      state = { ...state, ...{ currentPage: parseInt(payload, 10) } };
      break;

    case 'PROJECT_SET_FILTER':
      state = { ...state, ...{ filter: payload } };
      break;

    case 'BUBBLE_DATA_FETCHING':
      state = { ...state, ...{ error: null, fetching: true } };
      break;

    case 'BUBBLE_DATA_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          bubbleData: payload,
          fetching: false,
          fetched: true
        }
      };
      break;

    case 'BUBBLE_DATA_ERROR':
      state = {
        ...state,
        ...{ error: payload, fetching: false, fetched: true }
      };
      break;

    case 'PROJECT_SET_SORT':
      state = { ...state, ...{ sortBy: payload.sortBy, order: payload.order } };
      break;

    case 'PROJECT_SET_QUERY':
      state = { ...state, ...{ query: payload } };
      break;

    case 'PROJECT_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          data: payload.result,
          total: payload.total,
          totalPayment: payload.totalPayment,
          totalPayments: payload.totalPayments,
          fetching: false,
          fetched: true
        }
      };
      break;

    case 'PROJECT_ERROR':
      state = {
        ...state,
        ...{ error: payload, fetching: false, fetched: true }
      };
      break;

    case 'COUNTRY_INCOME':
      state = {
        ...state,
        ...{ countryIncome: payload }
      };
      break;

    /******************** Visualization ******************************/
    case 'VISUALIZATION_DATA_FETCHING':
      state = {
        ...state,
        ...{ error: null, fetching: true }
      };
      break;
    case 'VISUALIZATION_DATA_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          visualizationData: payload,
          fetching: false,
          fetched: true
        }
      };
      break;
    case 'VISUALIZATION_DATA_ERROR':
      state = {
        ...state,
        ...{
          error: payload,
          fetching: false,
          fetched: true
        }
      };
      break;

    case 'MAP_DATA':
      state = {
        ...state,
        ...{ mapData: payload }
      };
      break;
    case 'SELECTED_TOTAL_PAYMENTS':
      state = {
        ...state,
        ...{ selectedTotalPayments: payload }
      };
      break;
    case 'TOTAL_PAYMENTS_DATA':
      state = {
        ...state,
        ...{ totalPaymentsData: payload }
      };
      break;
    case 'MARKERS_DATA':
      state = {
        ...state,
        ...{ markersData: payload }
      };
      break;
    case 'SELECTED_COUNTRY':
      state = {
        ...state,
        ...{ selectedCountry: payload }
      };
      break;
    case 'MARKER_TOOLTIP_DATA':
      state = {
        ...state,
        ...{ markerTooltipData: payload }
      };
      break;
    case 'MAP_TOOLTIP_DATA':
      state = {
        ...state,
        ...{ mapTooltipData: payload }
      };
      break;
    case 'BAR_CHART_TOOLTIP_DATA':
      state = {
        ...state,
        ...{ barChartTooltipData: payload }
      };
      break;
    case 'SELECTED_TOP_PROJECTS':
      state = {
        ...state,
        ...{ selectedTopProjects: payload }
      };
      break;
    case 'YEARS_FILTER':
      state = {
        ...state,
        ...{ yearsFilter: payload }
      };
      break;
    default:
      break;
  }
  return state;
};

export default reducer;
