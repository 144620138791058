import styled from 'styled-components';

const Card = styled.div`
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 25px 40px -20px rgba(0, 0, 0, 0.5);
  padding: 8px;
  position: relative;

  @media (max-width: 620px){
    padding: 16px !important;
  }
`;

Card.displayName = 'Card';

export default Card;
