import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { Dropdown, DropdownMenu, transitionStyles } from './dropdownStyles';
import styled from 'styled-components';
import Colours from '../../../components/styled/theme/colours';
const { primary } = Colours;

class NavDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedValue: this.props.items[0].label
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  handleChange(dropdownValue, dropdownLabel) {
    this.setState(
      {
        isOpen: false,
        selectedValue: dropdownLabel
      },
      () => {
        this.props.history.push({
          pathname: '/' + dropdownValue,
          search: 'tab=' + this.props.tabIndex
        });
      }
    );
  }

  componentDidMount() {
    this.setState({ selectedValue: this.props.selected });
  }

  render() {
    const { items } = this.props;

    return (
      <Dropdown
        onMouseOver={() => this.handleDropdownMenu(true)}
        onMouseLeave={() => this.handleDropdownMenu(false)}
      >
        <DropdownLink>
          <Title className="title" isOpen={this.state.isOpen}>
            {this.state.selectedValue}
          </Title>
          <DropdownIcon isOpen={this.state.isOpen} />
        </DropdownLink>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <ul className="dropdown-list">
                {items.map((val, key) => (
                  <li
                    className="dropdown-list-item"
                    key={key}
                    onClick={() => this.handleChange(val.value, val.label)}
                  >
                    {val.label}
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

export default withRouter(NavDropdown);

const DropdownLink = styled.div`
  display: inline-block;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: ${props => props.theme.fontSize.lg}px;
  font-weight: ${props => props.theme.fontWeight.bold};
  cursor: default;
  background: transparent;
  text-decoration: none;
  padding: 8px 36px 8px 0;
  transition: 0.15s ease-in-out;
  white-space: nowrap;
  &:hover {
    .title {
      color: rgba(255, 255, 255,0.8);
    }
  }
  @media all and (max-width: 480px) {
    font-size: 21px;
  }
`;

const Title = styled.span`
  cursor: pointer;
  display: inline-block;
  /* color: ${props => (props.isOpen ? primary.primary : primary.white)}; */
`;

// const DropdownMenu = styled.ul`
//   padding-top: 15px;
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   transform: translateX(-50%);
//   width: auto;
//   min-width: 184px;
//   z-index: 1000;

//   .dropdown-list {
//     background-color: #fff;
//     border-radius: 4px;
//     box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.15);

//     &:before {
//       content: '';
//       display: block;
//       width: 12px;
//       height: 12px;
//       background: #ffffff;
//       transform: rotate(45deg) translateX(-50%);
//       position: absolute;
//       top: 17px;
//       left: 50%;
//     }

//     .dropdown-list-item {
//       display: block;
//       padding: 18px 20px;
//       border-bottom: 1px solid #e4eaeb;
//       color: #333333;
//       font-size: 12px;
//       transition: all 0.2s ease-in;
//       &:hover {
//         color: teal;
//         cursor: pointer;
//       }
//     }
//   }
// `;

const DropdownIcon = styled.span`
border-color: ${props =>
  props.isOpen
    ? `transparent transparent rgba(255,255,255,0.8);`
    : `${primary.white} transparent transparent;`}
  border-width: ${props => (props.isOpen ? '0 5px 5px' : '5px 5px 0')};
border-style: solid;
display: inline-block;
vertical-align: middle;
margin-left: 6px;
transition: all 200ms ease-in-out;
`;
