import React from 'react';
import styled from 'styled-components';
import ResponsiveWrapper from '../../../components/responsive-wrapper/responsive-wrapper';
// import * as ProjectActions from '../../actions/project/index';
import { geoPath } from 'd3-geo';
import { geoRobinson } from 'd3-geo-projection';
import Tooltip from './tooltip';
import MarkerTooltip from './markerTooltip';
import Legend2 from './legend2';
import countriesGeojson from './countries-geojson.json';
import CountriesLayer from './countries-layer';
import CountriesStrokeLayer from './country-stroke-layer';
import Markers from './markers';
import { zoom, zoomTransform } from 'd3-zoom';
import { select, event } from 'd3-selection';

// let debounceCountryName;
const mouseEventDelay = {
  onCountrySelect: 250,
  onAllCountrySelect: 300,
  onSvgLeave: 350
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentWidth: 0,
      zoomTransform: '',
      k: 1
    };
    this.svg = React.createRef();
    this.zoom = zoom()
      .scaleExtent([1, 8])
      .on('zoom', this.zoomed.bind(this));
  }

  componentDidMount() {
    select(this.svg.current).call(this.zoom);
  }

  componentDidUpdate() {
    select(this.svg.current).call(this.zoom);
  }

  zoomed(t) {
    let { x, y, k } = event ? event.transform : t;
    let zoomTransform = `translate(${x},${y}) scale(${k})`;
    this.setState({ zoomTransform, k });
  }

  zoomClick(type) {
    const { parentWidth } = this.props;
    const width = Math.floor(parentWidth); // float number will cause performance issue
    const height = Math.floor(width * 0.75);
    let center = [width / 2, height / 2];

    let t = zoomTransform(this.svg.current);
    let extent = this.zoom.scaleExtent();
    let scale = t.k;
    let x = t.x;
    let y = t.y;
    let factor = type === 'in' ? 1.2 : 1 / 1.2;
    let target_scale = scale * factor;

    if (type === 'in' && scale === extent[1]) {
      return false;
    }

    if (type === 'out' && scale === extent[0]) {
      return false;
    }
    let clamped_target_scale = Math.max(extent[0], Math.min(extent[1], target_scale));
    if (clamped_target_scale !== target_scale) {
      target_scale = clamped_target_scale;
      factor = target_scale / scale;
    }
    x = (x - center[0]) * factor + center[0];
    y = (y - center[1]) * factor + center[1];

    t.x = x;
    t.y = y;
    t.k = target_scale;
    this.zoomed(t);
  }z

  render() {
    const { mapData, parentWidth, projects } = this.props;
    const width = Math.floor(parentWidth); // float number will cause performance issue
    const height = Math.floor(width * 0.75);

    const projection = geoRobinson()
      .scale(width * 0.18)
      .translate([width / 2.3, height / 2]);

    const path = geoPath().projection(projection);

    return (
      <MapWrap id="company-details">
        {/* <div className="map__bg" /> */}
        <svg width={width} height={height} ref={this.svg}>
          <CountriesStrokeLayer
            path={path}
            name="second-stroke-layer"
            countriesGeojson={countriesGeojson}
            parentWidth={parentWidth}
            zoomTransform={this.state.zoomTransform}
            k={this.state.k}
          />
          <CountriesStrokeLayer
            path={path}
            name="third-stroke-layer"
            countriesGeojson={countriesGeojson}
            parentWidth={parentWidth}
            zoomTransform={this.state.zoomTransform}
            k={this.state.k}
          />
          <CountriesLayer
            path={path}
            mouseEventDelay={mouseEventDelay}
            countriesGeojson={countriesGeojson}
            component={this.props.component}
            mapData={mapData}
            zoomTransform={this.state.zoomTransform}
            k={this.state.k}
          />
          <Markers
            k={this.state.k}
            zoomTransform={this.state.zoomTransform}
            projection={projection}
            projects={projects}
            parentWidth={parentWidth}
          />
        </svg>
        <Legend2 parentWidth={parentWidth} mapData={mapData} />
        <Tooltip />
        <MarkerTooltip/>
        <div className="zoomWrap">
          <button onClick={e => this.zoomClick('in')} className="zoomBtn">
            +
          </button>
          <button onClick={() => this.zoomClick('out')} className="zoomBtn">
            {' '}
            -{' '}
          </button>
        </div>
      </MapWrap>
    );
  }
}

export default ResponsiveWrapper(Map);

const MapWrap = styled.div`
  background: #eef2f3;
  border-radius: 4px;
  position: relative;
  .zoomWrap {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .zoomWrap button:hover {
    background: #f4f4f4;
  }
  .zoomBtn {
    cursor: pointer;
    display: block;
    background: #fff;
    border: 0;
    font-size: 22px;
    height: 30px;
    width: 28px;
    font-weight: bold;
    line-height: 20px;
    outline: none;
    & + .zoomBtn {
      border-top: 1px solid #ccc;
    }
  }
  .first-stroke-layer path {
    stroke: black;
    stroke-width: 200;
    stroke-opacity: 0;
  }
  .second-stroke-layer path {
    stroke: #d0dfe5;
    stroke-width: 20;
    stroke-opacity: 0.3;
  }
  .third-stroke-layer path {
    stroke: #d0dfe5;
    stroke-width: 7;
    stroke-opacity: 1;
  }
  .countries-path {
    stroke: grey;
    stroke-width: 0.5 !important;
    stroke-opacity: 0.3;
    &.active {
      stroke-opacity: 1;
      stroke-width: 1.5 !important;
    }
  }
`;
