import React, { Component } from 'react';
import { FilterWrapper, SearchInput } from '../../styled';

export class Search extends Component {

	constructor(props) {
		super(props);
		this.state = { selected: '' };
		this.timer = '';
	}

	componentWillMount() {
		let { selected } = this.props;
		selected = selected || '';
		this.setState({ selected });
	}

	componentWillReceiveProps(props) {
		let { selected } = props;
		selected = selected || '';
		this.setState({ selected });
	}

	onChange(e) {
		clearTimeout(this.timer);

		var delay = 200;
		var value = e.target.value;

		this.timer = setTimeout(() => {
			this.props.handleKeyPress(value);
		}, delay);

		this.setState({ selected: value });
	}

	render() {
		let { selected } = this.state;
		let { placeholder } = this.props;

		return (
			<FilterWrapper>
				<SearchInput onChange={this.onChange.bind(this)} value={selected} type="text" placeholder={`Filter by ${placeholder} ...`} />
			</FilterWrapper>
		);
	}
}