/* global process */
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { getAppImages } from '../helpers';
import { Text } from '../components/styled/ui/';
import styled from 'styled-components';
const { noDataIcon } = getAppImages;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (process.env.REACT_APP_ENV !== 'local') {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div style={{ position: 'relative', backgroundColor: '#fff', borderRadius: 4, display: 'table-row'}}>
          <NoComponentWrap>
            <img className="icon" src={noDataIcon} alt="No Data" />
            <Text className="text" size={16} align="center">
              Unable to load this component
            </Text>
          </NoComponentWrap>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;

const NoComponentWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .icon {
    margin-bottom: 8px;
  }

  .text {
    color: #adadad;
  }
`;
