import React from 'react';
import Axis from './axis';

class XYAxis extends React.Component {
	render() {
		return (
			<g className="xy-axis">
				{this.props.xScale &&
				<Axis
					translate={`translate(0, ${parseInt(this.props.height, 10) - parseInt(this.props.padding, 10)})`}
					scale={this.props.xScale}
					orient="bottom"
					tickFormat={this.props.xTickFormat}
					tickValues={this.props.xTickValues}
					hideLine={this.props.xHideLine}
					className='x axis'
				/>
				}
				{this.props.yScale &&
				<Axis
					translate={'translate(10, 0)'}
					scale={this.props.yScale}
					orient="left"
					tickFormat={this.props.yTickFormat}
					tickValues={this.props.yTickValues}
					hideLine={this.props.yHideLine}
					className='y axis'
				/>
				}
			</g>
		);
	}
}

export default XYAxis;
