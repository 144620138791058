import styled from 'styled-components';

import { getAppImages } from '../../helpers';
import Colours from '../../theme/colours';
const { ReportIcon } = getAppImages;
const { primary, sec } = Colours;

// console.log(props)

export const ReportWrapper = styled.span`
    vertical-align: middle;
    cursor: pointer;
    height: 14px;
    width: 14px;
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -14px; */
    z-index: ${props => (props.active ? '1000' : '100')};
    margin-left: 5px;
    visibility: hidden;
    opacity: 0;
    direction: rtl;

    @media (max-width: 767px) {
      opacity: 1;
      visibility: visible;
    }

    /* @media (max-width: 767px){
        display: inline-block;
        width: 100%;
        height: 46px;
        position: relative;
        top: auto;
        right: auto;
        padding: 14px;
        border: none;
        transform: translateY(0);
        border-bottom-right-radius: 4px;

		.chevron-down{
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			top: 49px;
			left: 50%;
			transform: translateX(-50%);
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent ${primary.gray} transparent;
			border-style: solid;
			transition: all 0s ease-in;
        }
        
        &:hover{
            color: ${primary.gray};
        }
    } */

    .report-icon{
        display: inline-block;
        height: 14px;
        width: 14px;
        background: ${
          primary.white
        } url('${ReportIcon}') no-repeat center center;
        background-size: 7px;
        border: 2px solid ${primary.cyan};
        border-radius: 50%;
        
        &:hover{
            @media (min-width: 768px){
                box-shadow: 0 2px 10px 0 #87DEDA;
            }
        }

        /* @media (max-width: 767px){
            vertical-align: middle;
            width: 16px;
            height: 16px;
            background-color: transparent;
            border: none;
            opacity: 0.65;
        } */
    }

    .label{
            display: none;
        /* font-size: 12px;
        font-weight: bold;
        margin-right: 4px;

        @media (min-width: 768px){
        } */
    }
`;

export const ReportContent = styled.div`
  position: absolute;
  /* top: 0;
    right: 0; */
  width: 400px;
  max-width: calc(100vw - 100px);
  color: ${primary.white};
  font-weight: 300;
  /* padding: 24px; */
  padding: 10px 0;
  overflow: hidden;
  text-align: left;

  @media (max-width: 767px) {
    width: 300px;
    top: 30px;
    right: 0;
    left: auto;
    transform: translateX(0);
    padding: 24px 0;
  }

  @media (max-width: 480px) {
    max-width: none;
  }

  .close-report-form-btn {
    display: block;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 28px;
    right: 28px;
    opacity: 0.7;
    cursor: pointer;
    z-index: 10;

    @media (max-width: 767px) {
      right: 8px;
    }

    &:before,
    &:after {
      content: '';
      height: 10px;
      width: 2px;
      background: ${primary.white};
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 6px;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const ReportForm = styled.div`
  background: ${primary.gray};
  border-radius: 4px;
  padding: 36px;
  position: relative;
  direction: ltr;

  @media (max-width: 480px) {
    padding: 24px 16px;
  }

  .report-title {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    line-height: normal;
    margin-top: 0;
    margin-bottom: 16px;

    strong {
      color: ${primary.white};
    }
  }

  .report-text {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
    font-size: 12px;
    font-style: italic;
  }

  .report-message {
    margin-top: 0;
    font-weight: bold;
    font-style: italic;
    opacity: 0.8;
    margin-bottom: 16px;

    &.error {
      color: ${sec.red};
    }

    &.success {
      color: #4caf50;
    }
  }

  .error-text {
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    color: ${sec.red};
    margin-top: 6px;
    opacity: 0.7;
  }

  .report-field {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    background: transparent;
    color: ${primary.white};
    line-height: 20px;
    padding: 6px 0;
    border: none;
    margin-top: 20px;
    border-bottom: 1px solid ${primary.white};
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .report-button {
    color: ${primary.white};
    font-size: 14px;
    font-weight: bold;
    background: ${primary.cyan};
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 32px;
    border: none;
    cursor: pointer;
    letter-spacing: 0.5px;

    &:hover {
      background: #02c1b6;
    }

    &:focus {
      outline: none;
    }
  }
`;
