import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

class CountryStats extends Component {
  render() {
    let { data = {} } = this.props.stats || {};

    return (
      <StatsList>
        <StatsListItem>
          <span className="payment__highlight">
            <span className="payment__cost">
              {data.totalProjectPayments && (
                <strong>
                  {data.totalProjectPayments.indexOf('-') === -1
                    ? `$${data.totalProjectPayments}`
                    : `-$${data.totalProjectPayments.slice(1)}`}
                </strong>
              )}
            </span>
          </span>
          <span className="payment__type">Project Payments</span>
          <span className="payment__count">({data.projectCount} Payments)</span>
        </StatsListItem>
        <StatsListItem>
          <span className="payment__highlight">
            <span className="payment__cost">
            {data.totalEntityPayments && (
                <strong>
                  {data.totalEntityPayments.indexOf('-') === -1
                    ? `$${data.totalEntityPayments}`
                    : `-$${data.totalEntityPayments.slice(1)}`}
                </strong>
              )}
            </span>
          </span>
          <span className="payment__type">Entity Payments</span>
          <span className="payment__count">({data.entityCount} Payments)</span>
        </StatsListItem>
        <StatsListItem>
          <span className="payment__highlight">
            <span className="payment__cost">
              <strong>{data.companiesCount}</strong>
            </span>
          </span>
          <span className="payment__type">Reporting Companies</span>
        </StatsListItem>
      </StatsList>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.countryStats
});

export default connect(mapStateToProps)(CountryStats);

const StatsList = styled.ul`
  list-style-type: none;
`;

const StatsListItem = styled.li`
  color: ${props => props.theme.neutral.darkWhite};
  display: inline-block;
  vertical-align: top;
  @media all and (min-width: 768px) {
    vertical-align: middle;
  }
  &:first-child {
    @media all and (max-width: 460px) {
      margin-bottom: 20px;
    }
  }
  &:last-child {
    margin-right: 0;
    vertical-align: top;
  }
  &:not(last-child) {
    margin-right: 30px;
  }

  .payment {
    &__highlight,
    &__type {
      display: block;
    }

    &__highlight {
      margin-bottom: 6px;
    }

    &__count {
      display: block;
      font-size: 10px;
      margin: 4px 3px 0;
    }
    &__cost {
      font-size: 28px;
      > strong {
        font-weight: 600;
      }
    }
    &__type {
      text-transform: uppercase;
      font-weight: ${props => props.theme.fontWeight.bold};
      font-size: ${props => props.theme.fontSize.xs + 'px'};
    }
  }
`;
