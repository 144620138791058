export function fetching() {
  return {
    type: 'SOURCE_FETCHING'
  };
}

export function fetched(payload) {
  return {
    type: 'SOURCE_FETCHED',
    payload
  };
}

export function error(payload) {
  return {
    type: 'SOURCE_ERROR',
    payload
  };
}

export function setFilter(payload) {
  return {
    type: 'SOURCE_SET_FILTER',
    payload
  };
}

export function setSort(payload) {
  return {
    type: 'SOURCE_SET_SORT',
    payload
  };
}

export function setQuery(payload) {
  return {
    type: 'SOURCE_SET_QUERY',
    payload
  };
}

export function setCurrentPage(payload) {
  return {
    type: 'SOURCE_SET_CURRENT_PAGE',
    payload
  };
}
