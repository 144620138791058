/* global process */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './screens/ErrorBoundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store';
import Index from './screens/index';

if (
  process.env.REACT_APP_ENV !== 'local' &&
  process.env.NODE_ENV !== 'development'
) {
  let environment = window.location.hostname.includes('staging')
    ? 'staging'
    : 'prod';

  Sentry.init({
    environment: environment,
    dsn: 'https://320522098a2f419f9141a877c6fc5fba@sentry.io/1338528'
  });
}

const initialState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = configureStore(initialState);
ReactDOM.hydrate(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <Index />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
