import React from 'react';
import { Helmet } from 'react-helmet';
import {
  StaticPage,
  StaticHeroSection,
  StaticHeroContent,
  StaticPageTitle,
  StaticPageText,
  StaticContentWrapper,
  StaticContent
} from '../styled/staticPage';

const Page = ({ title, header, description, children, wide = false }) => {
  console.log(description);

  return (
    <StaticPage>
      <Helmet>
        <title>{title || ''}</title>
        <meta name="description" content={ description || ''} />
      </Helmet>
      <StaticHeroSection>
        <StaticHeroContent>
          <StaticPageTitle>{header || ''}</StaticPageTitle>
          <StaticPageText><span dangerouslySetInnerHTML={{__html:description}}/> </StaticPageText>
        </StaticHeroContent>
      </StaticHeroSection>
      <StaticContentWrapper wide={wide}>
        <StaticContent className={'static-page ' + title} wide={wide}>
          {children || ''}
        </StaticContent>
      </StaticContentWrapper>
    </StaticPage>
  );
};

export default Page;
