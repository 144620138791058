import styled from 'styled-components';
import { css } from 'styled-components';
import PropTypes from 'prop-types';
import getTransition from '../../utils/transition';

const ButtonSize = (paddingX, paddingY, fontSize, borderRadius) =>
  css`
    padding: ${paddingX + 'px'} ${paddingY + 'px'};
    font-size: ${fontSize + 'px'};
    border-radius: ${borderRadius + 'px'};
  `;
const ButtonVariant = (background, color) => css`
  color: ${color};
  background: ${background};
  cursor: pointer;

  &:focus,
  &:hover {
  }
`;

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;

  ${props => getTransition(props.theme.transition.fade)};

  ${({ uppercase }) =>
    uppercase &&
    `
  	text-transform: uppercase;
  `};

  ${props =>
    props.bold &&
    `
  	font-weight: ${props.theme.fontWeight.bold};
  `};
`;
Button.displayName = 'Button';

// Button.propTypes = {
// 	uppercase: PropTypes.boolean,
// 	bold: PropTypes.boolean
// };

Button.propTypes = {
  uppercase: PropTypes.bool,
  bold: PropTypes.bool
};

Button.Link = Button.withComponent('a');
Button.Link.displayName = 'Button.Link';

export const DownloadButton = Button.Link.extend`
  ${ButtonSize(8, 32, 12, 35)};
  padding-right: 0;
  ${ButtonVariant('transparent', '#fff')};
  text-align: left;
  transition: ${props => props.theme.transition.base};
  display: flex;
  align-items: center;
  
  div{
  	display: inline-block;
  		> span{
  			display: block;
  			text-transform: uppercase;
  			&:nth-child(1){
  				font-size: 14px;
  				font-weight: 600;
  			}
  			&:nth-child(2){
  				font-size: 11px;
  			}
  		}
  }
  
  &:focus{
        outline: none;
    }

    &:hover{
        color: rgba(255,255,255,0.8);
    }

  
`;

export const HomeHeroButton = Button.extend`
  ${ButtonSize(6, 30, 12, 35)};
  ${ButtonVariant('linear-gradient(to bottom,#43dffd,#20bcfb);', '#fff')};
  display: inline-block;
  transition: ${props => props.theme.transition.base};

  &:focus{
        outline: none;
    }
    &:hover{
        background: linear-gradient(to bottom,#43dffd,#259fd0);
        &:after{
          filter: blur(30px);
        }
    }
  
`;

export const DownloadImageButton = Button.extend `
  align-items: center;
  background: rgba(255,255,255,0);
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 24px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -2px;
  width: 24px;
  span {
    color: #eceaf1;
    display: none;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  &:hover,
  &:focus {
    outline: 0;
  }
  &:hover {
    background: #978aa8;
    padding: 0 8px 0 4px;
    transition: width 0.3s ease-in-out;
    width: 132px;
    span {
      line-height: 24px;      
      display: block;
      /* opacity: 1; */
    }
  }
  img {
    margin-right: 3px;
    opacity: 0.75;
    height: 16px;
    width: 16px;
  }
  &.download-image__map:hover {
    width: 120px;
  }
  &.download-image__bubble {
    bottom: 104px;
    position: absolute;
    right: 0;
    top: auto;
    @media all and (max-width: 992px) {
      bottom: 150px;
    }
    @media all and (max-width: 500px) {
      bottom: 200px;
    }
    @media all and (min-width:600px) and (max-width:767px) {
      left: 80%;
    }
    @media all and (min-width:768px) and (max-width:992px) {
      left: 70%;
    }
     @media all and (min-width:768px) and (max-width:850px) {
      bottom: 110px;
    }
  }
  
`;

DownloadButton.displayName = 'DownloadButton';

HomeHeroButton.displayName = 'HomeHeroButton';

DownloadImageButton.displayName = 'DownloadImageButton';
