import React, { Component } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import './modal.css';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showModal: nextProps.isOpen
    });
  }

  handleClose() {
    this.props.handleClose();
  }

  render() {
    const { shouldCloseOnOverlayClick, children, style } = this.props;
    // const {
    // 	isOpen,
    // 	onRequestClose,
    // 	shouldCloseOnOverlayClick,
    // 	themeColor,
    // 	children,
    // 	handleClose
    // } = this.props;

    return (
      <Content>
        <ReactModal
          className="modal-content"
          isOpen={this.state.showModal}
          onRequestClose={this.handleClose}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          ariaHideApp={false}
          style = {style}
        >
          <CloseIcon onClick={this.handleClose} />
          <Body>{children}</Body>
        </ReactModal>
      </Content>
    );
  }
}

export default Modal;

const Content = styled.div`
  background: red;
`;

const Body = styled.div`
  padding: 56px 30px 36px;
  background: #fff;
  border-radius: 8px;
`;

const CloseIcon = styled.span`
    border-radius: 50%;
    background-color: rgba(22, 45, 61, 0.2);
  	display: block;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 30px;
    transform: translateY(-50%);
    right: 28px;
    opacity: 0.6;
    cursor: pointer;
    transition: all .2s ease-in;
    
    &:hover{
       background-color: rgba(22, 45, 61, 0.5);
    }   
    
    &:before,&:after{
    	content: '';
    height: 12px;
    width: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 6px;
    }
    
    &:before{
    rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
    }
    
    &:after{
    rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
    }
}
`;
