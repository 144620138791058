import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import ReportService from '../../services/report';
import { ReportWrapper, ReportContent, ReportForm } from '../styled/report';

class Report extends Component {
	constructor(props) {
		super(props);
		this.state = ({ loading: false, email: '', message: '', errors: { email: '', message: '' }, alert: '', status: false });
	}

	_validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(email);
	}

	_validateRequest() {
		let { email, message } = this.state;
		email = email.trim();
		message = message.trim();

		let error = 0;
		let emailError = '', messageError = '';

		if (email.length < 1) {
			emailError = 'Please provide an email address';
			error++;
		} else if (!this._validateEmail(email)) {
			emailError = 'Please provide a valid email address';
			error++;
		}

		if (message.length === 0) {
			messageError = 'Please enter message.';
			error++;
		} else if (message.length < 20) {
			messageError = 'Message must be atleast 20 characters.';
			error++;
		}

		this.setState({ errors: { email: emailError, message: messageError } });

		return error === 0;
	}

	_submitHandler(data, reportType) {
		let { email, message, loading } = this.state;

		email = email.trim();
		message = message.trim();

		if (loading === false && this._validateRequest()) {
			this.setState({ loading: true });
			ReportService.send({ email, message, ...data, reportType }).then((res) => {
				if (res && res.status) {
					this.setState({ loading: false, email: '', message: '', alert: 'Thank you! Your message has been sent successfully.', status: true });
				} else {
					this.setState({ loading: false, alert: 'Oops!!! Something went wrong. Please try again later.', status: false });
				}
			});
		}
	}

	render() {
		let { index, activeReport, showForm, hideForm, reportFormShown, position, data, reportType } = this.props;
		let { name } = data;
		let { email, message, loading, errors, alert } = this.state;
		return (
			<ReportWrapper
				onClick={showForm}
				active={activeReport === index}
				className="payment-report"
			>

				<span className="label">REPORT</span>
				<span className="report-icon"></span>
				{
					(reportFormShown && activeReport === index) &&
					<Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
						{
							newStyle => {
								return <span className="chevron-down" style={newStyle} ></span>;
							}
						}
					</Motion>
				}
				{
					(reportFormShown && activeReport === index) &&
					<Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
						{
							newStyle => {
								return (
									<ReportContent style={newStyle} position={position} className="report-content">
										<span className="close-report-form-btn" onClick={hideForm}></span>
										<ReportForm>
											{
												this.state.alert &&
												<p className={this.state.status ? 'report-message success' : 'report-message  error'}>{alert}</p>
											}
											<p className="report-title">Send a report about <strong>{name}</strong> data</p>
											<p className="report-text">If you feel this data has some issues, please let us know.</p>
											<input onChange={(e) => this.setState({ email: e.target.value })} type="text" placeholder="Your email" className="report-field" value={email} />
											{errors.email !== '' && (<p className="error-text">{errors.email}</p>)}
											<textarea onChange={(e) => this.setState({ message: e.target.value })} type="text" placeholder="Your message" className="report-field" value={message} />
											{errors.message !== '' && (<p className="error-text">{errors.message}</p>)}
											<button type="button" onClick={this._submitHandler.bind(this, data, reportType)} className="report-button">{loading ? 'Sending...' : 'Report this'}</button>
										</ReportForm>
									</ReportContent>
								);
							}
						}
					</Motion>
				}
			</ReportWrapper>
		);
	}
}

export default Report;