const initialState = {
	fetching: false,
	fetched: false,
	data: [],
	total: 0,
	error: null
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'PROJECT_ATTRIBUTE_FETCHING':
		state = { ...state, ...{ error: null, fetching: true } };
		break;

	case 'PROJECT_ATTRIBUTE_FETCHED':
		state = { ...state, ...{ error: null, data: payload.result, total: payload.total, fetching: false, fetched: true } };
		break;

	case 'PROJECT_ATTRIBUTE_ERROR':
		state = { ...state, ...{ error: payload.error, fetching: false, fetched: true } };
		break;
	default:
		break;

	}
	return state;
};

export default reducer;
