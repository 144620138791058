import PropTypes from 'prop-types';
import L from 'leaflet-headless';
import 'leaflet/dist/leaflet.css';
import harborIcon from './harbor-15.svg';
import harborData from './harbar.json';

const icon = L.icon({
  iconUrl: harborIcon,
  iconSize: [12, 12]
});

const LayerControl = ({ countryData, map }) => {
  const { location, countryName } = countryData;
  if(countryName=== 'North Macedonia'){
    console.log('--------------',countryName,location)
  }
  const { latitude, longitude } = location;

  const satellite = L.tileLayer(
    'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox.satellite',
      accessToken:
        'pk.eyJ1IjoiYW5qaGVybyIsImEiOiJjaWxkNjI4YXAwN3I4dmVtMHY4ZDVndDhtIn0.NxyF9D4FICghDY9TurUH4g'
    }
  );

  const streets = L.tileLayer(
    'https://api.mapbox.com/styles/v1/{id}/{style}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}',
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'anjhero',
      style: 'ckh00guua1sz81aoh8sj1ahec',
      accessToken:
        'pk.eyJ1IjoiYW5qaGVybyIsImEiOiJjaWxkNjI4YXAwN3I4dmVtMHY4ZDVndDhtIn0.NxyF9D4FICghDY9TurUH4g'
    }
  );

  const grayScale = L.tileLayer(
    'https://api.mapbox.com/styles/v1/{id}/{style}/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}',
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'anjhero',
      style: 'ckh00guua1sz81aoh8sj1ahec',
      accessToken:
        'pk.eyJ1IjoiYW5qaGVybyIsImEiOiJjaWxkNjI4YXAwN3I4dmVtMHY4ZDVndDhtIn0.NxyF9D4FICghDY9TurUH4g'
    }
  );

  map.setView([latitude, longitude], 3);
  streets.addTo(map);

  const pointToLayer = (feature, latlng) => {
    return L.marker([latlng.lat, latlng.lng], { icon });
  };
  const harbor = L.geoJSON(harborData, {
    filter: function(feature, layer) {
      const { country } = feature.properties;
      return country.toLowerCase() === countryName.toLowerCase();
    },
    pointToLayer
  });

  const baseMaps = {
    Satellite: satellite,
    Streets: streets,
    Grayscale: grayScale
  };
  const overlayMaps = {
    Harbor: harbor
  };

    L.control
    .layers(baseMaps, overlayMaps, {
      position: 'bottomright'
    })
    .addTo(map);

  return null;
};

LayerControl.propTypes = {
  map: PropTypes.object,
  countryData: PropTypes.object,
  projectData: PropTypes.array
};

export default LayerControl;
