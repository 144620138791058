export function fetching() {
	return {
		type: 'ENTITY_FETCHING',
	};
}

export function fetched(payload) {
	return {
		type: 'ENTITY_FETCHED',
		payload
	};
}

export function error(payload) {
	return {
		type: 'ENTITY_ERROR',
		payload
	};
}

export function setFilter(payload) {
	return {
		type: 'ENTITY_SET_FILTER',
		payload
	};
}

export function setSort(payload) {
	return {
		type: 'ENTITY_SET_SORT',
		payload
	};
}

export function setQuery(payload) {
	return {
		type: 'ENTITY_SET_QUERY',
		payload
	};
}

export function setCurrentPage(payload) {
	return {
		type: 'ENTITY_SET_CURRENT_PAGE',
		payload
	};
}
