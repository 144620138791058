import PropTypes from 'prop-types';
import L from 'leaflet-headless';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import oilPin from '../../../images/location-marker_oil-gas@2x.png';
import minePin from '../../../images/location-marker_mining@2x.png';
import corporatePin from '../../../images/location-marker_corporate@2x.png';
import numberFormatter from '../../../utils/numberFormatter';

const MarkerCluster = ({ map, projectData }) => {
  let formatCommodity = function(commodity = '') {
    if (!commodity.trim().length) return 'n/a';
    let separator = commodity.includes(';')
      ? ';'
      : commodity.includes(',')
      ? ','
      : '';
    if (separator.length) {
      return commodity
        .split(separator)
        .map(c => {
          return formatCommodity(c.trim());
        })
        .toString();
    }
    return ' ' + commodity.charAt(0).toUpperCase() + commodity.slice(1);
  };

  let buildingIcon = (type, w, h) => {
    let iconUrl;
    if (type === 'Mining') {
      iconUrl = minePin;
    } else if (type === 'Corporate') {
      iconUrl = corporatePin;
    } else {
      iconUrl = oilPin;
    }
    return L.icon({
      iconUrl,
      iconSize: [w, h],
      shadowAnchor: [4, 62]
    });
  };

  // let markers = L.markerClusterGroup();
  let markers = [];
  projectData.forEach(p => {
    if (p.lat && p.lon) {
      let point = [p.lat, p.lon];
      let popUp = `
      <div>
      <table class='table'>
        <tbody>
        <tr>
        <th>Project</th>
        <th class='title'>${p.projectName}</th>
      </tr>`;

      popUp = p.projectType
        ? popUp +
          `<tr>
          <td>Project type: </td>
          <td>${p.projectType}</td>
        </tr>`
        : popUp;
      popUp = p.commodity
        ? popUp +
          `<tr>
          <td>Commodity: </td>
          <td>${formatCommodity(p.commodity)}</td>
        </tr>`
        : popUp;
      popUp = p.payment
        ? popUp +
          `<tr>
          <td>Payment: </td>
          <td>USD ${numberFormatter(p.payment)}</td>
        </tr>`
        : popUp;
      popUp = p.reportingCompany
        ? popUp +
          `<tr>
          <td>Company(s): </td>
          <td>` +
          p.reportingCompany.map(company => ` ${company}`) +
          `</td>
        </tr>`
        : popUp;
      popUp =
        popUp +
        `</tbody>
    </table>
  </div>`;

      let marker = L.marker(point, {
        icon: buildingIcon(p.projectType, 20, 22)
      }).bindPopup(popUp);
      map.addLayer(marker);

      map.on('zoomstart', e => {
        // console.log('start',e);
        map.removeLayer(marker);
      });
      // map.addLayer(marker);

      map.on('zoomend', () => {
        // console.log('end');
        window.previousZoom = window.currentZoom;
        var currentZoom = map.getZoom();

        let width =
          currentZoom > 4
            ? (20 / parseInt(window.previousZoom)) * currentZoom
            : 20;
        let height =
          currentZoom > 4
            ? (22 / parseInt(window.previousZoom)) * currentZoom
            : 22;

        marker = L.marker(point, {
          icon: buildingIcon(p.projectType, width, height)
        }).bindPopup(popUp);
        map.addLayer(marker);
      });

      markers.push(point);
    }
  });

  // map.addLayer(markers);
  // const bounds = L.latLngBounds(coordinates);
  // map.fitBounds(bounds);

  return null;
};

MarkerCluster.propTypes = {
  map: PropTypes.object,
  projectData: PropTypes.array
};

export default MarkerCluster;
