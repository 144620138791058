import styled, { keyframes } from 'styled-components';
import { getAppImages } from '../../helpers';
import Colours from '../../theme/colours';
import { rgba, media } from '../../components/styled/utils/';
import NoteIcon from '../../images/note-icon.svg';

const { primary, neutral, type } = Colours;
const { ExternalLinkIcon } = getAppImages;

const LoadingBg = keyframes`
    from {
        background-position: -1000px 0;
    }
    to { 
        background-position: 2000px 0;
    }
`;

export const TableTitle = styled.div`
  margin-top: 0;

  @media (max-width: 568px) {
    margin-bottom: 0;
  }

  .title-dropdown-wrapper {
    display: inline-block;

    @media (max-width: 568px) {
      margin-top: 4px;
    }
  }

  .data-title-dropdown {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    transition: all 0.15s ease-in-out;
    cursor: default;

    .data-title {
      font-size: 22px;
      font-weight: normal;

      @media (max-width: 568px) {
        font-size: 14px;
      }

      .down-arrow {
        vertical-align: middle;
        margin-left: 6px;
        height: 0;
        width: 0;
        border-width: 5px 4px 0 4px;
        border-style: solid;
        border-color: ${primary.gray} transparent transparent transparent;
        top: 18px;
        position: relative;
        transition: all 0.15s ease-in-out;

        @media (max-width: 568px) {
          top: 14px;
        }
      }
    }

    .title-dropdown {
      display: none;
      min-width: 200px;
      position: absolute;
      top: 100%;
      background: ${primary.white};
      border-radius: 4px;
      box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.15);
      z-index: 100;

      a,
      span {
        display: block;
        color: ${primary.gray};
        padding: 18px 20px;
        border-bottom: 1px solid #eee;
        transition: all 0.15s ease-in-out;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          color: ${primary.primary};
        }

        &.active {
          cursor: default;
          color: ${primary.primary};
          pointer-event: none;
        }
      }
    }

    &:hover {
      .data-title {
        color: ${primary.primary};
      }

      .down-arrow {
        border-color: ${primary.primary} transparent transparent transparent;
      }

      .title-dropdown {
        display: block;
      }
    }
  }

  small.total-projects {
    font-size: 14px;
    color: #777;
    font-weight: 300;

    strong {
      color: ${primary.gray};
      margin-right: 4px;
      font-weight: 700;
    }

    @media (max-width: 1100px) and (min-width: 993px) {
      &.block {
        display: block;
      }
    }

    @media (max-width: 860px) and (min-width: 768px) {
      display: block;
    }

    @media (max-width: 720px) {
      display: block;
    }

    @media (max-width: 568px) {
      font-size: 12px;
    }

    @media (max-width: 350px) {
      font-size: 11px;
    }
  }
`;

export const TableWrapper = styled.div`
  //margin-right: 32px;
  /* flex-grow: 1; */
  position: relative;
  /* display: flex; */

  @media (max-width: 992px) {
    margin-right: 0;
  }
  .scrollarea-content {
    background-color: #fff;
    /* padding-bottom: 20px; */
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: none;
  border-collapse: separate;
  &.table__data {
    tr:last-child {
        /* td {
          ${media.sm`padding-bottom: 50px;`}
        } */
    }
  }


  @media (max-width: 767px) {
    border-collapse: separate;
    border-spacing: 0 8px;
    margin-top: 0;
  }

  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #eceaf1;
    }

    tr{
      &:nth-last-child(2) td{
        padding-bottom: 60px;
        
        @media (max-width: 767px) {
          padding-bottom: 6px;
        }
      }

      &.expanded:nth-last-child(2) td{
        padding-bottom: 10px;
      }

      &.row-source{
        &:nth-last-child(2) td{
          padding-bottom: 11px;
        }
        &:last-child td{
          padding-bottom: 60px;
        }
      }
    }

    tr:last-child td{
      padding-bottom: 35px;
    }

    tr:first-child {
      -moz-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px;
      td {
        &:first-child {
          @media screen and (min-width: 767px) {
            -moz-border-radius: 4px 0 0 0;
            border-radius: 4px 0 0 0;
          }
        }
        &:last-child {
          @media screen and (min-width: 767px) {
            -moz-border-radius: 0 4px 0 0;
            border-radius: 0 4px 0 0;
          }
        }
      }
    }
    tr:last-child {
      td:last-child {
        @media screen and (min-width: 767px) {
          -moz-border-radius: 0 0 4px 0;
          border-radius: 0 0 4px 0;
        }
      }
    }
  }

  tbody:first-child tr:first-child td:first-child {
  }
  tbody:last-child tr:last-child td:first-child {
    -moz-border-radius: 0 0 0 4px;
    border-radius: 0 0 0 4px;
  }
  tr td:last-child div {
    margin-left: 0;
  }
  .hide{
    display: none;
  }
`;

export const TR = styled.tr`
  // background: ${primary.white};
  ${props =>
    props.expanded
      ? `background: ${type.purple} ;`
      : `background: ${primary.white} ;`}

  border: none;
  padding: 0 10px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(118, 170, 187, 0.05);
  cursor: pointer;

  &:hover {
    /* box-shadow: 0 2px 15px rgba(118, 170, 187, 0.4); */
    td {
      ${media.sm`
      /* background: lighten(${neutral.darkWhite}; */
      background: #f5f5ff;
    `}
    }
    a {
      color: ${primary.primary};
    }
    /* .report > span {
      opacity: 1;
    } */
  }

  &.message-row {
    margin-bottom: 48px;
    td {
      padding-top: 32px;
      padding-left: 10px !important;
      padding-bottom: 32px;
    }
  }

  td:first-child {
    //border-top-left-radius: 4px;
    //border-bottom-left-radius: 4px;

    @media (max-width: 767px) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
    }
  }
  td:last-child {
    //border-top-right-radius: 4px;
    //border-bottom-right-radius: 4px;

    @media (max-width: 767px) {
      // border-radius: 0 0 4px 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .ic-arrow{
    position: relative;
    left: -21px;
    top: 0;
    transition: transform 0.2s ease;
    transform: rotate(0deg);

    @media (max-width: 767px){
      display: none;
    }

    &--alternate{
      top: 1px;
    }
  }

  &.expanded{
    background: #E6DCFF !important;
    transition: none;

    &.row-source{
      background: #fff !important;
    }

    td{
      background: none;
      vertical-align: top;
    }

    .more-dots {
      display: none;
    }

    .ic-arrow{
      transform: rotate(90deg)
    }
    .content {
      white-space: initial;
    }
  }

  a {
    color: rgba(51, 51, 51, 0.7);
  }

  @media (max-width: 767px) {
    background: transparent;
    box-shadow: 0 10px 25px -10px rgba(9, 215, 203, 0.1);
  }
`;

export const TRExpanded = styled.tr`
  background: ${type.purpleLight} ;
  border: none;
  padding: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 10px 0 rgba(118, 170, 187, 0.05);
  /* overflow-x: auto; */

  @media (max-width: 767px){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7) !important;
    z-index: 999999;
    /* display: block; */
    display: flex;
    align-items: center;
  }

  a {
    color: rgba(51, 51, 51, 0.7);
  }

  @media (max-width: 767px) {
    background: transparent;
    box-shadow: 0 10px 25px -10px rgba(9, 215, 203, 0.1);
  }

  table{
    table-layout: fixed;
    /* border-collapse: separate; */

    th, td{
      text-align: right;
      width: 120px;


      &:first-child{
        text-align: left;
      }
    }
    td{
      font-size: 14px;
      font-weight: bold;
      color: ${primary.gray};
      padding: 0 0 0 20px;

      &:last-child{
        width: 130px;
        padding-right: 30px;
      }
    }

    th{
      .title{
        display: flex;
        justify-content: space-between;

        .sparkline{
          height: 16px !important;
        }
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background: ${type.purpleLight};
        width: 250px;
        padding: 10px 20px 8px 45px;
        font-weight: bold;
      }
    }
    thead th:first-child{
      font-weight: normal;
      padding-top: 24px;
      padding-bottom: 0;
    }
    thead th:last-child{
      padding-right: 30px;
      width: 130px;
    }

    tbody {
      tr{
        border-bottom: 1px solid #D9CCF9 !important;
        &:last-child{
          border: none;
          th{
            padding-bottom: 24px;
          }
        }
      }
    }

    thead th{
      font-size: 12px;
    }

    tr{
      &:last-child{
        td{
          padding-bottom: 0!important;
        }
      }
    }
  }

  /* .note-icon{
		display: inline-block;
		vertical-align: top;
		height: 10px;
		width: 7px;
		transition: all 0.2s ease-in-out;
		background: url('${NoteIcon}') no-repeat center center/contain;
		opacity: 0.6;
    margin-left: 2px;

		&:hover{
			opacity: 1;
		}
	} */

  .posLine {
    fill: #DACDF9;
  }

  .content{
        display: inline-block;
        /* max-width: 154px; */
        white-space: nowrap; 
        overflow: hidden;
        /* text-overflow: ellipsis; */
        @media all and (max-width: 767px) {
          padding-top: 4px;
        }
        &.content-with-link{
            /* max-width: calc(100% - 24px); */
        }
        &:hover .popup-content {
          display: block;
        }
    }

  .popup-content {
      display: none;
      background: #fff;
      padding: 6px 12px;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      color: #3A424C;
      font-size: 14px;
      font-weight: bold;
      max-width: 300px;
      position: absolute;
      white-space: pre-wrap;
      z-index: 9999999;
      line-height: 20px;
      /* top: -20px;
      transform: translateX(60%); */
    }


`;

export const TH = styled.th`
  border: none;
  font-weight: normal;
  font-size: 12px;
  padding: 0 10px 8px 10px;
  text-transform: uppercase;
  text-align: left;
  color: ${rgba('#eceaf1', 0.7)};
  &[title='Sort by reporting company'],
  &[title='Sort by payment type'] {
    @media all and (min-width: 960px) {
      white-space: nowrap;
    }
  }

  /* &[title='Sort by project country'],
  &[title='Sort by agency country'],
  &[title='Sort by reporting country'] {
    padding-left: 20px;
  } */
  .sortable {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 11px;
    line-height: normal;
    img {
      margin-right: 2px;
      position: absolute;
      left: -13px;
      background: #eceaf1;
      width: 10px;
      height: 14px;
      padding: 2px;
      border-radius: 1px;
      top: 2px;
    }
  }

  /* &.value {
    text-align: right;
  } */

  &:first-child {
    padding-left: 45px;
  }

  /* &:last-child {
    padding-right: 0;
    width: 1%;
  } */

  .project-table &:first-child {
    width: 50%;
  }
`;

export const TD = styled.td`
    /* ${props =>
      props.expanded ? `background: ${type.purpleLight} ;` : ''} */
    border-bottom: 1px solid #eceaf1;
    position: relative;
    padding: 17px 10px 11px;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.7);
    vertical-align: middle;
    @media (max-width: 767px){
        display: block;
        padding: 6px 10px;
        margin-bottom: 0;
        position: relative;
        padding-left: 200px!important;
        background: ${primary.white};
        width: 100%!important;
        &:before{
            content: attr(data-label);
            display: block;
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translateY(-50%);
            vertical-align: top;
            margin-right: 12px;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: normal;
            color: ${primary.gray};
            text-transform: uppercase;
        }
    }

    @media (max-width: 480px){
        padding-left: 140px!important;

        &.message-cell{
            padding-left: 10px!important;
        }
    }

    &.project-name,&.value,&.project-country{
        font-size: 14px;
        font-weight: bold;
        color: ${primary.gray};
    }

    &.unused-source  {
      >span > ul {
        left: auto;
        right: 0;
      }
      .dropdown-list {
        @media all and (max-width: 500px) {
          min-width: 280px!important;
        }
      }

  }

    &.value{
        min-width: 120px;
        text-align: right;

        @media (max-width: 767px){
            text-align: left;
        }

        .value-content{
          display: flex;
          align-items: center;
          /* justify-content: flex-end; */

          @media (max-width: 767px){
            justify-content: flex-start;
          }

          .sparkline{
            margin-left: 14px;
            height: 16px;
            width: 74px;

            svg{
              left: 0;
            }
          }
        }
    }

    &.noData {
      font-style: italic;
      color: rgba(51,51,51,0.5);
    }

    .content{
        /* display: inline-block; */
        /* max-width: 154px; */
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
       
        @media all and (max-width: 767px) {
          padding-top: 4px;
        }
        &.content-with-link{
            /* max-width: calc(100% - 24px); */
        }
        &:hover .popup-content {
          display: block;
        }
    }

    &.project-name > .content{
      margin-left: -7px;

      &.not-available{
        font-weight: normal;
        font-style: italic;
      }
    }

    .popup-content {
      display: none;
      background: #fff;
      padding: 6px 12px;
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      color: #3A424C;
      font-size: 14px;
      font-weight: bold;
      max-width: 300px;
      position: absolute;
      white-space: pre-wrap;
      z-index: 9999999;
      line-height: 20px;
      /* bottom: 35px; */
      /* top: -20px;
      transform: translateX(60%);
      right: 0; */
    }

    .flag{
        height: 12px;
        width: auto;
        margin-right: 4px;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
    }

    .external-link{
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 6px;
        top: 1px;
        position: relative;
        background: url('${ExternalLinkIcon}') no-repeat center center;
        opacity: 0.7;
        transition: all 0.15s ease-in-out;
        text-indent:1000px;
        overflow:hidden;
        cursor: pointer;

        &:hover{
            opacity: 1;
        }
    }

    &.note, &.report{
        @media (max-width: 767px){
            padding-left: 0!important;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            width: 50%;
            float: left;
            text-align: center;
            border-radius: 0;
            border-top: 1px solid #eee;
            width: 50%!important;
            &:before{
                content: '';
                display: none;
            }
        }
    }

    &.note{
        @media (max-width: 767px){
            border-bottom-left-radius: 4px;
            height: 46px;
            & + .report {
              width: 50%!important;
            }
        }
    }

    &.report{
        @media (max-width: 767px){
            border-bottom-right-radius: 4px;
            border-left: 1px solid #eceaf1;
            height: 47px;
            width: 100%!important;
            .source-table & {
                width: 100%;
                text-align: right;

                > span{
                    width: 50%;
                    text-align: center;
                }
            }
        }
        > span {
          ${media.sm`
                  opacity: 0;
          `}; 
        }
      
    }

    &:first-child{
        padding-left: 45px;
    }

    /* &:last-child{
        padding-right: 0;
    } */

    a{
      color: ${primary.dark};
    }

    .posLine {
      fill: #DACDF9;
    }
`;

export const TDExpanded = styled.td`
  padding: 0;

  @media (max-width: 767px) {
    display: block;
    width: calc(100% - 20px);
    margin: 0 auto;
    background: #fff;
    border-radius: 4px !important;
  }
`;

export const LoadingTD = styled.td`
  height: 40px;
  padding: 0;

  @media (max-width: 767px) {
    height: 220px;
  }

  .loading-row {
    height: 40px;
    background: linear-gradient(
        -75deg,
        #f5f5f5,
        #f5f5f5 30%,
        #e9e9e9 50%,
        #f5f5f5 70%,
        #f5f5f5 100%
      )
      repeat;
    background-position: -1000px 0;
    bacground-size: 2000px 100%;
    // border-radius: 4px;
    // margin-bottom: 8px;
    animation: ${LoadingBg} 2.2s linear infinite;
    position: relative;
    padding: 0;
    overflow: hidden;

    @media (max-width: 767px) {
      height: 220px;
      background: linear-gradient(
          -75deg,
          #f5f5f5,
          #f5f5f5 40%,
          #e9e9e9 50%,
          #f5f5f5 65%,
          #f5f5f5 100%
        )
        repeat;
      animation-duration: 3.5s;
      margin-bottom: 24px;
      box-shadow: 0 10px 25px -10px rgba(9, 215, 203, 0.1);
    }

    @media (max-width: 480px) {
      animation-duration: 4.5s;
    }

    .horizontal {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      width: 100%;
      border-top: 13px solid #fff;
      border-bottom: 13px solid #fff;

      @media (max-width: 767px) {
        display: none;
      }

      .overlay {
        background: red;
        height: 12px;
        width: 100%;
      }
    }
    .vertical {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      width: 100%;

      @media (max-width: 767px) {
        height: 220px;
        flex-direction: column;
        border-left: 24px solid ${primary.white};
        border-right: 24px solid ${primary.white};

        &:after {
          content: '';
          display: block;
          height: 100%;
          width: 8%;
          max-width: 36px;
          background: ${primary.white};
          position: absolute;
          top: 0;
          left: 30%;
          transform: translateX(-50%);
          z-index: 10;
        }
      }

      .overlay {
        background: #fff;
        height: 40px;
        width: 24px;

        @media (max-width: 767px) {
          height: 19px;
          width: 100%;
        }
      }
    }
  }

  .loading-text {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin: 32px 0;
  }
`;
