import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colours from '../../theme/colours';
import { getAppImages } from '../../helpers';

const { primary, sec, neutral } = Colours;
const { FilterIconSvg } = getAppImages;

export const FilterSection = styled.div`
	  &.filter-open{
		  opacity: 1;
	  }
	  
	  .close-filter {
	    background: #d8d8d8;
	    border-radius: 50%;
	    height: 28px;
	    width: 28px;
	    i {
            font-size: 9px;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 32px;
            display: block;
	    }
	  }


// @media screen and (max-width: 1366px){
//   opacity: 1 !important;
// }

  // opacity: 0;

  // flex: 0 0 224px;
  // min-width: 224px;
  // width: 224px;
  // transition: all 0.15s ease-in-out;

  // @media (max-width: 992px){
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 320px;
  //   z-index: 999;
  //   background: ${sec.lightGreen};  
  //   padding: 36px;
  //   height: 100vh;
  //   overflow: auto;
  // }
  

  // @media (max-width: 992px) {
  //   position: fixed;
  //   top: 65px;
  //   right: 0px;
  //   flex: 0 0 320px;
  //   width: 320px;
  //   height: calc(100vh - 65px);
  //   overflow: auto;
  //   background: ${sec.lightGreen};
  //   margin-top: 0;
  //   padding: 36px;
  //   z-index: 1100;
  //   box-shadow: -2px 0px 51px -31px rgba(0, 0, 0, 0.75);

  //   &.close {
  //     transform: translateX(100%);
  //     transition: all 0s ease-in-out;
  //   }

  //   &.open {
  //     transform: translateX(0);
  //     transition: all 0.3s ease-in-out;
  //   }
  // }

  // @media (max-width: 568px) {
  //   top: 116px;
  //   height: calc(100vh - 116px);
  // }
`;

export const DownloadAllData = styled(Link)`
  display: block;
  color: ${primary.white};
  padding: 12px 16px;
  border: 1px solid #d8e1e6;
  border-radius: 35px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(#fff, #e8f3f8);
  text-shadow: 0 2px 0 rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease-in-out;

  @media (max-width: 992px) {
    display: none;
  }

  img {
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: linear-gradient(#fff, #d1e3eb);
  }

  &:active {
    background: linear-gradient(#fff, #e8f3f8);
  }
`;

export const MultiDownload = styled.div`
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 10px;

  @media (min-width: 993px) {
    display: none;
  }

  .text {
    @media (max-width: 767px) {
      display: none;
    }
  }

  a.multi-download-btn {
    display: inline-block;

    .download-icon {
      height: 14px;
      margin-right: 2px;
    }

    * {
      pointer-events: none;
    }

    &:after {
      content: '';
      display: ${props => (props.hasFiltered ? 'inline-block' : 'none')};
      width: 0;
      height: 0;
      border-width: 4px 4px 0 4px;
      border-color: ${primary.gray} transparent transparent transparent;
      border-style: solid;
      position: relative;
      top: -1px;
      vertical-align: middle;
      margin-left: 4px;
    }

    @media (max-width: 568px) {
      padding: 0 7px;
    }
  }

  #download-dropdown {
    display: block;
    position: absolute;
    top: 105%;
    right: 0;
    width: 100%;
    min-width: 160px;
    background: ${primary.white};
    border-radius: 4px;
    box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.15);
    z-index: 100;

    @media (min-width: 993px) {
      display: none;
    }

    a {
      display: block;
      padding: 10px 12px;
      font-size: 12px;
      color: ${primary.gray};
      border-bottom: 1px solid #eee;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: ${primary.primary};
      }
    }
  }
`;

export const FilterKeyWord = styled.span`
  background: rgba(255,255,255,0.1);
  border-radius: 13px!important;
  display: block;
  font-size: 12px;
  margin-right: 4px;
  line-height: normal;
  overflow: hidden;
  color: ${primary.white};
  width: 100%; 
  margin-bottom: 4px;
  padding: 4px 10px;
  &:hover .remove-keyword {
    background: rgba(255,255,255,0.2);
  }
  .remove-keyword {
    border-radius: 50%;
    position: relative;
    float: right;
    cursor: pointer;
    // background: linear-gradient(#fff, #e8f3f8);
    // border-left: 1px solid #d8e1e6;
    transition: all 0.15s ease-in-out;
    padding: 9px 18px 9px 0px;
    margin-top: 4px;
    &:hover {
      background:  ${primary.primary};
    }
    
    &:before,
    &:after {
      content: '';
      height: 10px;
      width: 2px;
      background: ${primary.white};
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 6px;

      // @media screen and (max-width: 1366px){
      //   background: ${primary.dark};
      // }

    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`;
export const FilterLabel = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  background: ${neutral.darkWhite};
  border: 1px solid #b0b6be;
  border-radius: 18px;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;

export const FilterWrapper = styled.div`
  padding: 14px 0;
  background: ${primary.white};
  border-radius: 4px;
  margin-bottom: 12px;

  .filter-header {
    align-items: center;
    display: flex;
    /* padding: 14px; */
    cursor: pointer;
    justify-content: space-between;
    padding: 0 14px;
    .hamburger-icon {
      .line-1,
      .line-2 {
        width: 8px;
      }
      .line-1 {
        top: 3px!important;
      }
      .line-2 {
        transform: rotate(90deg);
        top: 30%!important;
      }
      .line-3 {
        display: none;
      }
      &.active .line-1 {
        transform: rotate(0deg);
      }
    }
  }
  .filter-header--right {
    display: flex;
    .hamburger-icon {
      width: 7px;
    }
  }
  .clear {
    color: ${primary.primary};
    font-size: 12px;
    line-height: 12px;
    margin-right: 5px;
  }
  .filter-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin: 0 -14px 0 -14px !important;
    span {
      float: left;
      margin: -1px 2px 0 0px;
    }
    .icon {
      height: 18px;
    }
    ${props =>
      !props.showSearch ? 'margin-bottom: 0;' : ''} padding: 0 14px 0;
    /* border-bottom: 1px solid #e4eaeb; */
  }

  .filter-body {
    background: ${primary.white};
    display: none;
    padding: 14px;
    border-top: 1px solid #e4eaeb;
    margin-top: 7px;
    input {
      margin-bottom: 10px;
    }
  }

  .options {
    padding: 0 14px;
    //margin: 14px 0;
    max-height: ${props => (props.scrollable ? '120px' : 'auto')};

    label {
      display: block;
      position: relative;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 6px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      input {
        position: absolute;
        opacity: 0;
        z-index: 20;

        &:checked + .custom-checkbox {
          background: ${primary.primary};
          box-shadow: 0 0 0 0 rgba(9, 215, 203, 0.35);

          &:after {
            opacity: 1;
            transform: scale(1) rotate(-45deg) translate(-50%, -50%);
          }
        }
      }
    }
  }
`;

export const ToggleFilter = styled.button`
    float: right;

    @media (max-width: 568px){
        padding: 0 7px!important;
        font-size: 14px;
    }

    @media (max-width: 767px){
        .text{
            display: none;
        }
    }

    .filter-icon{
        display: inline-block;
        vertical-align: middle;
        height: 14px;
        width: 14px;
        position: relative;
        top: -1px;
        background: url('${FilterIconSvg}') no-repeat center center;
        background-size: contain;
    }

    @media (min-width: 993px){
        display: none;
    }
`;

export const CloseFilter = styled.span`
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 244px;
  right: 14px;
  opacity: 0.6;
  cursor: pointer;

  // @media (min-width: 1366px) {
  //   display: none;
  // }

  &:before,
  &:after {
    content: '';
    height: 12px;
    width: 2px;
    background: ${primary.gray};
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 6px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    opacity: 1;
  }
`;

export const FilterBackdrop = styled.div`
  display: none;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 65px);
  top: 65px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  @media (max-width: 992px) {
    &.open {
      display: block;
    }

    &.close {
      display: none;
    }
  }

  @media (max-width: 568px) {
    top: 116px;
    height: calc(100vh - 114px);
  }
`;

export const DownloadFilteredBtn = DownloadAllData.extend`
  display: inline-block;
  float: right;
  position: relative;

  .download-icon {
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${primary.primary};
      position: absolute;
      top: -4px;
      right: -3px;
      border: 2px solid ${primary.white};
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
