import React, { Component } from 'react';
import styled from 'styled-components';

class Stats extends Component {
  render() {
    let { total, totalPayment, totalPayments } = this.props.project;
    return (
      <StatsList>
        {total > 0 && (
          <StatsListItem>
            <span className="payment__highlight">
              {this.props.component !== 'Source' ? (
                <span className="payment__cost">
                  <strong>
                    {totalPayment.indexOf('-') === -1
                      ? `$${totalPayment}`
                      : `-$${totalPayment.slice(1)}`}
                  </strong>
                </span>
              ) : (
                <span className="payment__cost">
                  <strong>{total}</strong>
                </span>
              )}
            </span>
            <span className="payment__type">
              {this.props.component !== 'Source'
                ? this.props.component + ' Payments'
                : 'disclosure reports'}
            </span>
            {this.props.component !== 'Source' && (
              <span className="payment__count">({totalPayments} Payments)</span>
            )}
          </StatsListItem>
        )}
      </StatsList>
    );
  }
}

export default Stats;

const StatsList = styled.ul`
  list-style-type: none;
`;

const StatsListItem = styled.li`
  color: ${props => props.theme.neutral.darkWhite};
  display: inline-block;
  vertical-align: middle;

  &:not(last-child) {
    margin-right: 30px;
  }
  &:last-child {
    margin-right: 0;
  }

  .payment {
    &__highlight,
    &__type {
      display: block;
    }

    &__highlight {
      margin-bottom: 6px;
    }

    &__count {
      font-size: 10px;
      /* font-weight: 600; */
      margin: 0 3px;
    }
    &__cost {
      font-size: 28px;
      > strong {
        font-weight: 600;
      }
    }
    &__type {
      text-transform: uppercase;
      font-weight: ${props => props.theme.fontWeight.bold};
      font-size: ${props => props.theme.fontSize.xs + 'px'};
    }
  }
`;
