const initialState = {
  fetching: true,
  fetched: false,
  sankeyFetching: true,
  sankeyFetched: false,
  barFetching: true,
  barFetched: false,
  plotFetching: true,
  plotFetched: false,
  countryFetching: true,
  countryFetched: false,
  countryListFetching: true,
  countryListFetched: false,
  sankeyData: [],
  selectedValue: null,
  barData: [],
  plotData: [],
  countryData: [],
  paymentData: {},
  barChartData: [],
  countryList: [],
  overallStats: {},
  query: '',
  projectList: [],
  error: null,
  countryProjectList: {
    project: [],
    projectType: [],
    entity: [],
    entityType: []
  },
  snapShotData: {},
  countryCompanyList: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'COUNTRY_FETCHING':
      state = {
        ...state,
        ...{ error: null, countryFetching: true, countryFetched: false }
      };
      break;
    case 'COUNTRY_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          countryData: payload.result,
          snapShotData: payload.snapShotData,
          paymentData: {
            project: payload.project,
            projectTable: payload.projectTable,
            projectType: payload.projectType,
            entity: payload.entity,
            entityTable: payload.entityTable,
            entityType: payload.entityType
          },
          countryFetched: true,
          countryFetching: false
        }
      };
      break;

    case 'COUNTRY_PROJECT_LIST_FETCHING':
      state = {
        ...state,
        ...{
          error: null,
          countryListFetched: false,
          countryListFetching: true
        }
      };
      break;
    case 'COUNTRY_PROJECT_LIST_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          countryProjectList: {
            project: payload.project,
            projectType: payload.projectType,
            entity: [],
            entityType: []
          },
          countryListFetched: true,
          countryListFetching: false
        }
      };
      break;
    case 'COUNTRY_ENTITY_LIST_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          countryProjectList: {
            entity: payload.entity,
            entityType: payload.entityType,
            project: state.countryProjectList.project,
            projectType: state.countryProjectList.projectType
          },
          countryListFetched: true,
          countryListFetching: false
        }
      };
      break;

    case 'COUNTRY_ERROR':
      state = {
        ...state,
        ...{ error: payload, countryFetching: false, countryFetched: true }
      };
      break;

    case 'SANKEY_FETCHING':
      state = {
        ...state,
        ...{ error: null, sankeyFetching: true, sankeyFetched: false }
      };
      break;
    case 'SANKEY_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          sankeyData: payload.result,
          sankeyFetched: true,
          sankeyFetching: false
        }
      };
      break;
    case 'SANKEY_ERROR':
      state = {
        ...state,
        ...{ error: payload, sankeyFetching: false, sankeyFetched: true }
      };
      break;
    case 'PLOT_FETCHING':
      state = {
        ...state,
        ...{ error: null, plotFetching: true, plotFetched: false }
      };
      break;
    case 'PLOT_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          plotData: payload,
          plotFetched: true,
          plotFetching: false
        }
      };
      break;
    case 'PLOT_ERROR':
      state = {
        ...state,
        ...{ error: payload, plotFetching: false, plotFetched: true }
      };
      break;
    case 'BAR_CHART_FETCHING':
      state = {
        ...state,
        ...{ error: null, barFetching: true, barFetched: false }
      };
      break;
    case 'BAR_CHART_FETCHED':
      state = {
        ...state,
        ...{
          error: null,
          barChartData: payload.result,
          barFetched: true,
          barFetching: false
        }
      };
      break;
    case 'BAR_CHART_ERROR':
      state = {
        ...state,
        ...{ error: payload, barFetching: false, barFetched: true }
      };
      break;
    case 'SELECTED_SANKEY_NODE':
      state = { ...state, selectedValue: payload };
      break;
    case 'COUNTRY_LIST_FETCHED':
      state = { ...state, ...{ error: null, countryList: payload } };
      break;
    case 'OVERALL_STATS_FETCHED':
      state = { ...state, ...{ error: null, overallStats: payload } };
      break;
    case 'SET_BAR_DATA':
      state = { ...state, barData: payload };
      break;
      case 'COUNTRY_AND_COMPANY_LIST_FETCHED':
        state = { ...state, ...{ error: null, countryCompanyList: payload } };
        break;
    default:
      break;
  }
  return state;
};

export default reducer;
