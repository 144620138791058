import React from 'react';
import { select, selectAll, mouse } from 'd3-selection';
import { bisector } from 'd3-array';

class Tooltip extends React.Component {
  constructor() {
    super();
    this.state = {
      tooltipX: [],
      tooltipY: [],
      val: [],
      visibility: 'hidden',
      showTooltip: false,
      selected: null
    };
  }
  componentDidMount() {
    this.renderHover();
  }

  componentDidUpdate() {
    selectAll('.circle').on('mouseenter', this.toolTipEnter.bind(this));
    selectAll('.circle').on('mouseleave', this.toolTipLeave.bind(this));
    selectAll('.line').on('mouseenter', () => {
      this.setState({
        showTooltip: true,
        visibility: 'visible'
      });
    });
  }
  mousemove() {
    const { node } = this;
    const { data, x, y } = this.props;
    let values = [];
    data.forEach(d => {
      values.push(d.values);
    });
    // const values0 = data[0].values;
    // const values1 = data[1].values;
    // const values2 = data[2].values;
    // const values3 = data[3].values;
    // const values4 = data[4].values;
    // const values5 = data[5].values;

    // returns index of value from array
    const bisectDate = bisector(d => d.date).left;

    const x0 = x.invert(mouse(node)[0]);
    const i = bisectDate(values[0], x0, 1);

    const x1 = values[0][i - 1];
    const x2 = values[0][i];

    let toolTipData = [];

    values.forEach((d, j) => {
      if (x0 - x1.date > x2.date - x0) {
        toolTipData.push(d[i]);

        // d1 = values1[i];
        // d2 = values2[i];
        // d3 = values3[i];
        // d4 = values4[i];
        // d5 = values5[i];
      } else {
        toolTipData.push(d[i - 1]);

        // d0 = values0[i - 1];
        // d1 = values1[i - 1];
        // d2 = values2[i - 1];
        // d3 = values3[i - 1];
        // d4 = values4[i - 1];
        // d5 = values5[i - 1];
      }
    });

    if (toolTipData.length > 0) {
      let tooltipX = [],
        tooltipY = [],
        val = [];

      toolTipData.forEach(t => {
        tooltipX.push(x(t.date));
        tooltipY.push(y(t.payload));
        val.push(t.payload);
      });

      this.setState({
        visibility: 'visible',
        showTooltip: false,
        tooltipX,
        tooltipY,
        val
      });
    }
  }
  mouseout() {
    setTimeout(() => {
      this.setState({
        visibility: 'hidden'
      });
    }, 10);
  }

  toolTipEnter(e, i) {
    this.setState({
      showTooltip: true,
      selected: i
    });
    this.props.handleChangeColor(i);
    // console.log(this.state.val[i]);
  }

  toolTipLeave() {
    setTimeout(() => {
      this.setState({
        showTooltip: false,
        selected: null
      });
      this.props.handleResetColor();
    }, 10);
  }

  renderHover() {
    const { node } = this;
    select(node)
      .on('mousemove', this.mousemove.bind(this))
      .on('mouseout', this.mouseout.bind(this));
  }

  renderRect(x) {
    return (
      <rect
        rx="10"
        ry="10"
        x={x - 5}
        y="-7"
        height="15"
        width="35"
        stroke="#006600"
        fill="#b0b6be"
        opacity="0.3"
        // className="line"
      />
    );
  }

  render() {
    const { width, height, colors } = this.props;
    const {
      tooltipX,
      tooltipY,
      val,
      visibility,
      showTooltip,
      selected
    } = this.state;

    const data = this.props.data[0].values;
    const x0 = this.props.x.invert(this.state.tooltipX[0]);

    let x; // tooltip text position
    if (data.length && x0 >= data[data.length - 3].date) {
      x = -30;
    } else {
      x = 10;
    }
    return (
      <g>
        <rect
          width={width}
          height={height}
          ref={node => {
            this.node = node;
          }}
          fill="black"
          opacity="0"
        />
        <g style={!showTooltip ? { visibility } : { visibility: 'visible' }}>
          <line
            x1={tooltipX[0]}
            x2={tooltipX[0]}
            y1="0"
            y2={height}
            stroke="lightgrey"
            strokeOpacity="0.7"
            shapeRendering="crispEdges"
            className="line"
          />
          {tooltipX.map((t, i) => {
            return selected === null || selected === i ? (
              <g key={i} transform={`translate(${t}, ${tooltipY[i]})`} className="circle">
                <circle r="6" fill={colors[i]} />
                {this.renderRect(x)}
                <text y="5" x={x} style={{cursor: 'default'}}>
                  {val[i] && parseFloat(val[i]).toFixed(2)}
                </text>
              </g>
            ) : null;
          })}
        </g>
      </g>
    );
  }
}

export default Tooltip;
