import API from '../api';

class Subscribe {
  async subscribe(data) {
    try {
      let res = await API.post('subscribe', data);
      return res.body;
    } catch (error) {
      return false;
    }
  }

  async unsubscribe(id) {
    try {
      let res = await API.post('subscribe/delete', id);
      return res.body;
    } catch (error) {
      return false;
    }
  }

  async updateSubscribe(data) {
    try {
      let res = await API.post('subscribe/update', data);
      // console.log(res)
      return res.body;
    } catch (error) {
      return false;
    }
  }

  async getUser(id) {
    try {
      let res = await API.get('getUser', { params: { id } });
      return res.body;
    } catch (error) {
      return false;
    }
  }
  async getMailCheck(email) {
    try {
      let res = await API.get('subscribe/checkMail', { params: { email} });
      return res.body;
    } catch (error) {
      return false;
    }
  }
}

export default new Subscribe();
