import React from 'react';

export const Checkbox = ({ label, style, checked = false, value, onCheck, ...rest }) => {
	return (
		<label title={label} style={style}>
			<input onChange={onCheck} checked={checked} value={value} type="checkbox" {...rest} />
			<span className="custom-checkbox"></span>
			{label}
		</label>
	);
};
