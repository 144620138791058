import React, { Component } from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { Heading } from '../../components/styled/ui';
import SubscribeService from '../../services/subscribe/index';
import Colours from '../../components/styled/theme/colours';
import { media } from '../../components/styled/utils';
import { getAppImages } from '../../helpers';
const { primary } = Colours;
const {successIcon, sadIcon} = getAppImages;

class SubscriptionMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      messageHeader: ''
    };
    this.onUnsubscribe = this.onUnsubscribe.bind(this);
  }

  componentWillMount() {
    if (this.props.match.path === '/subscribe/verify/:id') {
      this.setState({
        messageHeader: 'Success',
        message: 'Thank you. Your Subscription has been verified.'
      });
    }
  }

  onUnsubscribe() {
    const { id } = this.props.match.params;
    SubscribeService.unsubscribe({
      id
    }).then(res => {
      if (res && res.status) {
        this.setState({
          message: res.message,
          messageHeader: res.messageHeader
        });
      } else {
        this.setState({
          messageHeader: 'Error!',
          message: 'Oops!!! Something went wrong. Please try again later.'
        });
      }
    });
  }

  render() {
    const { message, messageHeader } = this.state;
    return (
      <UnsubscribeWrapper>
        <Heading.h1 uppercase>Subscribe to updates</Heading.h1>
        <Content>
          {!message ? (
            <div>
              <h2>Unsubscribe?</h2>
              <p>
                Are you sure you want to unsubscribe? If this was done mistakely, Please click on Cancel button.
              </p>
              <button
                type="button"
                className="btn report-button"
                onClick={this.onUnsubscribe}
              >
                Unsubscribe
              </button>
              <Link to="/" title="Cancel" className="btn btn-cancel">
                Cancel
              </Link>
            </div>
          ) : (
            <NotifyMessage className="success">
              <h2>{messageHeader}</h2>
              <p>{message}</p>
              <Link to="/" title="Continue" className="btn">
                Continue
              </Link>
            </NotifyMessage>
          )}
        </Content>
      </UnsubscribeWrapper>
    );
  }
}

export default SubscriptionMessage;

const UnsubscribeWrapper = styled.div`
  max-width: 1008px;
  margin: 30px auto;
  padding: 0 18px;
  width: 100%;
  h1 {
    color: ${props => primary.white};
    font-size: 24px;
    margin-bottom: 30px;
  }
  h2 {
    color: #fad43c;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 100px;
    position: relative;
    &:before {
      background: url(${sadIcon}) no-repeat center/70%;
      content: '';
      left: 50%;
      height: 100px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100px;
    }
  }
  .alert-danger {
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    background: rgb(255, 128, 145);
    padding: 8px 10px;
  }
  input + .alert-danger {
    margin-top: -14px;
  }
`;

const Content = styled.div`
  background-color: ${props => primary.white};
  text-align: center;
  p {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 20px;
    line-height: 26px;
    font-size: 16px;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  border-radius: 4px;
  padding: 40px 20px;
  ${media.sm`
  padding: 40px;
  `};
  .btn {
    background: linear-gradient(rgb(67, 223, 253), rgb(32, 188, 251));
    border: 0;
    border-radius: 26px;
    color: ${props => primary.white};
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    padding: 15px 24px;
    width: 150px;
    &:hover {
      background: linear-gradient(rgb(67, 223, 253), rgb(37, 159, 208));
    }
    &:focus {
      outline: 0;
    }
  }
  .btn-cancel {
    background: #e5e5e5;
    color: ${props => primary.dark};
    margin-left: 20px;
    &:hover {
      background: #ddd;
    }
  }
`;

const NotifyMessage = styled.div`
  text-align: center;
  h2 {
    color: #33de33;
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 100px;
    &:before {
      background: url(${successIcon}) no-repeat center/70%;
      content: '';
      left: 50%;
      height: 100px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100px;
    }
  }
  p {
    color: rgba(51, 51, 51, 0.5);
    font-size: 16px;
    line-height: 25px;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  .btn {
    background: linear-gradient(rgb(67, 223, 253), rgb(32, 188, 251));
    border: 0;
    border-radius: 26px;
    color: ${props => primary.white};
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    padding: 15px 24px;
    width: 150px;
    &:hover {
      background: linear-gradient(rgb(67, 223, 253), rgb(37, 159, 208));
    }
    &:focus {
      outline: 0;
    }
  }
`;
