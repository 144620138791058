import React, { Component } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

const customStyles = {
  input: () => ({
    // height: 50,
    color: '#3A424C',
    overflow: 'hidden'
  }),

  control: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: '#F8F7FA',
      borderColor: '#979797!important',
      borderRadius: 24,
      minHeight: 30,
      // minHeight: isDisabled ? 40 : isFocused ? 38 : 40,log
      flexDirection: 'row-reverse',
      boxShadow: 'none'
    };
  },

  option: provided => ({
    ...provided,
    color: '#3A424C',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: '20px',
    borderBottom: '1px solid #eee'
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#f7f6f9',
    lineHeight: '25px',
    borderRadius: 4,
    border: '1px solid #ccc'
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    opacity: '0.7',
    ':hover': {
      backgroundColor: data.color,
      opacity: 1
      // color: 'white'
    }
  })
};

class FilterCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: [],
      reportingJurisdiction: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption, type) {
    this.setState({ [type]: selectedOption }, () => {
      let country = [],
        reportingJurisdiction = [];
      this.state.country.forEach(element => {
        country.push(element.value);
      });

      this.state.reportingJurisdiction.forEach(element => {
        reportingJurisdiction.push(element.value);
      });

      if (type === 'country') this.props.onChangeCountry(country);
      else this.props.onChangeJurisdiction(reportingJurisdiction);
    });
  }

  render() {
    let { countryData, jurisdictionData } = this.props;

    const country = new Set();
    const jurisdiction = new Set();

    countryData.forEach(d => {
      d.country.forEach(dc => country.add(dc));
    });

    jurisdictionData.forEach(d => {
      jurisdiction.add(d.reportingJurisdiction);
    });

    // console.log([...c], j)

    const jlist = [...jurisdiction].map(d => {
      return { value: d, label: d };
    });
    const clist = [...country].map(d => {
      return { value: d, label: d };
    });

    return (
      <FilterCountrySection>
        <div>
          <label>filed in</label>
          <FilterSelect>
            <Select
              styles={customStyles}
              isMulti
              inputDisabled
              value={this.state.reportingJurisdiction}
              onChange={selectedOption =>
                this.handleChange(selectedOption, 'reportingJurisdiction')
              }
              options={jlist}
              placeholder={'All countries'}
            />
          </FilterSelect>
        </div>
        <div>
          <label>payments made in</label>
          <FilterSelect>
            <Select
              styles={customStyles}
              isMulti
              inputDisabled
              value={this.state.country}
              onChange={selectedOption =>
                this.handleChange(selectedOption, 'country')
              }
              options={clist}
              placeholder={'All countries'}
            />
          </FilterSelect>
        </div>
      </FilterCountrySection>
    );
  }
}

export default FilterCountry;

const FilterCountrySection = styled.div`
  background-color: #faf8ff;
  border-bottom: 1px solid #eceaf1;
  @media all and (min-width: 480px) {
    align-items: flex-end;
    display: flex;
  }
  > div {
    width: 100%; 
    & + div {
      border-top: 1px solid #eceaf1;
    }
    @media all and (min-width: 480px) {
      width: 50%;
       & + div {
        border-left: 1px solid #eceaf1;
        border-top: 0;
      }
    }
  }
  label {
    color: ${props => props.theme.primary.dark};
    display: block;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 24px 0;
    text-transform: uppercase;
  }
  svg {
    fill: ${props => props.theme.primary.dark};
  }
`;

const FilterSelect = styled.div`
  > div > div:first-of-type {
    background-color: transparent;
    border: none;
    border-radius: 24px;
    display: flex;
    flex-direction: row-reverse;
    padding: 2px 24px 12px 20px;
    & + div {
      margin-top: 1px!important;
      border-radius: 0!important;
      border-top: 0!important;
    }
    > div {
      padding-left: 4px;
      > div {
        color: #3a424c;
        &:first-child {
          padding: 0;
        }
      }

      > span {
        display: none;
        & + div {
          /* margin: 0 3px 0 -3px; */
          padding: 0;
        }
      }
    }
  }
`;
