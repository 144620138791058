import { css } from 'styled-components';
import Colours from '../../../theme/colours';
const { primary } = Colours;

const pagination = css`
  .pagination {
    list-style: none;
    text-align: center;
    padding: 40px 16px 80px;
    @media all and (max-width: 767px) {
      height: 91px;
      padding: 20px 0;
    }
    @media all and (max-width: 640px) {
      li {
        a,
        span {
          background-color: transparent !important;
          border: 0 !important;
          border-radius: 50%;
          color: ${primary.white}!important;
          line-height: 38px !important;
        }
        &.active a {
          padding: 0;
        }
        &:nth-last-child(2) > a,
        &:nth-child(2) > a {
          border-radius: 50% !important;
          padding: 0 !important;
          text-align: center;
        }
        a.page-link:hover,
        &.active a {
          background-color: ${primary.primary}!important;
        }
        a {
          margin: 0 1px 12px 1px !important;
        }
        &.page {
          display: none;
        }
        &.page {
          &.active,
          &.active + .page {
            display: inline-block;
          }
          &:nth-last-child(2),
          &:nth-last-child(3) {
            display: inline-block;
          }
        }
      }
    }

    /* @media (max-width: 480px) {
      li {
        a {
          margin: 0 1px 12px 1px !important;
        }
        &.page {
          &:nth-last-child(3) {
            display: none;
          }
        }
      }
    } */

    li {  
      display: inline-block;
      margin-top: 0;
      padding-left: 0;
      &:before {
        display: none;
      }
      a {
        display: inline-block;
        height: 38px;
        min-width: 38px;
        padding: 0 6px;
        font-size: 14px;
        text-align: center;
        line-height: 34px;
        border: 2px solid transparent;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        color: ${primary.dark};
        background-color: ${primary.white};
        font-weight: 600;

        @media (max-width: 480px) {
          margin: 0 3px 12px 3px;
        }

        &.next,
        &.prev {
          font-size: 12px;
          background: transparent;
          box-shadow: none;
          color: ${primary.white};
          text-transform: uppercase;
        }

        &.page-link:hover {
          background: ${primary.primary};
          color: ${primary.white};
          border-color: ${primary.primary}!important;
        }

        &:focus {
          outline: none;
        }

        &:active {
          opacity: 0.5;
        }
        &.disabled a {
          cursor: not-allowed;
        }
      }

      &:not(.previous):not(.next) {
        > a {
          border-width: 1px 1px 1px 0px;
          border-color: #eceaf1;
        }
      }

      &:nth-child(2) {
        > a {
          border-radius: 24px 0 0 24px;
          padding-left: 20px;
          /* border-right: none !important; */
        }
      }

      &:nth-last-child(2) {
        > a {
          border-radius: 0 24px 24px 0;
          padding-right: 20px;
          /* border-right: none !important; */
        }
      }

      &.previous {
        &+.active a {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }
        & + .page a {
          border-left-width: 1px;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        a {
          cursor: not-allowed;
        }
      }

      &.more {
        span {
          display: inline-block;
          vertical-align: middle;
          height: 38px;
          /* margin-top: -2px; */
          min-width: 38px;
          padding: 0 6px;
          font-size: 14px;
          line-height: 34px;
          font-weight: bold;
          color: ${primary.dark};
          background: ${primary.white};
          border-right: 1px solid #eceaf1;
          border-bottom: 1px solid #eceaf1;
          border-top: 1px solid #eceaf1;
        }
      }

      &.active {
        a {
          background-color: ${primary.primary};
          border-color: #20bcfb !important;
          color: ${primary.white}!important;
          cursor: default;
          cursor-events: none;
          /* &:hover {
            background: #e8fcff;
            border-color: #e8fcff !important;
          } */
        }
      }
    }
  }
`;

export default pagination;
