//Font, lineheight, headings
const fontFamilySansSerif = 'Open Sans, sans-serif';
const fontFamilyBase = fontFamilySansSerif;

const fontSizeXs 		= 	12;
const fontSizeSm 		= 	14;
const fontSizeMd 		= 	16;
const fontSizeLg 		= 	24;
const fontSizeHg 		= 	32;
const fontSizeBase 		= 	fontSizeSm;

const fontWeightBase 	= 	400;
const fontWeightLight 	= 	300;
const fontWeightMedium 	= 	500;
const fontWeightBold 	= 	600;
const fontWeightStrong 	= 	700;

const lineHeightBase 	= 	1.5;
const lineHeightHeading = 	1.1;

const transitionBase = 'all .2s ease-in-out';
const transitionFade = 'opacity .15s linear';

const Defaults = {
	fontFamily: {
		sansSerif: fontFamilySansSerif,
		base: fontFamilyBase
	},
	fontSize: {
		base: fontSizeBase,
		xs: fontSizeXs,
		sm: fontSizeSm,
		md: fontSizeMd,
		lg: fontSizeLg,
		hg: fontSizeHg
	},
	fontWeight: {
		base: fontWeightBase,
		light: fontWeightLight,
		medium: fontWeightMedium,
		bold: fontWeightBold,
		strong: fontWeightStrong
	},
	lineHeight:{
  		base: lineHeightBase,
		heading: lineHeightHeading
	},
	transition: {
		base: transitionBase,
		fade: transitionFade
	}

};

export default Defaults;
