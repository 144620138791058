import React, { Component } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import * as _ from 'lodash';

import ResponsiveWrapper from '../../../components/responsive-wrapper/responsive-wrapper';
import { getAppImages } from '../../../helpers';
import { Hint, SimpleDropdown } from '../../../components/layouts';
import { Text, Heading } from '../../../components/styled/ui';
import numberFormatter from '../../../utils/numberFormatter';
import Select2Component from './select-2';
import pageSwitcher from '../../../components/layouts/dropdown/pageSwitcher';

const { helpIcon } = getAppImages;

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedDropdown: 'project',
      stack: [],
      countries: [],
      filterOpen: false,
      selectedCountries: ['all'],
      stackChartWidth: 700,
      stackLegendWidth: 260,
      legendHeight: 100
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    const { data, fetched } = this.props;
    const { project, entity } = data;

    if (fetched && !data.project.length) {
      // this.setState({ selectedDropdown: 'entity' });
      this.formatData(entity);
    } else {
      this.formatData(project);
    }
    this.calculateWidth();
    window.addEventListener('resize', this.calculateWidth);
  }

  formatData(data) {
    // let type = this.state.selectedDropdown;
    let type = this.props.type 

    let selectedCountries = this.state.selectedCountries;
    let countryData = data;
    if (selectedCountries.indexOf('all') === -1) {
      countryData = _.filter(_.cloneDeep(data), d =>
        selectedCountries.includes(d.country)
      );
    }
    let filteredData = this.getFilteredData(_.cloneDeep(countryData));
   
    let typeData = _.groupBy(filteredData.length > 0 ? filteredData : countryData, 'reportYear');
    const stack = [];
    for (const a in typeData) {
      let d = { year: a };
      let paymentTypes = _.groupBy(typeData[a], 'paymentType');
      for (const p in paymentTypes) {
        let pay;
          pay = _.sumBy(paymentTypes[p], `${type}Payment`);
          // console.log(pay);
          d[p] = pay;
      }

      stack.push(d);
    }
    // const countries = _.uniq(_.map(_.cloneDeep(data), 'country'));
    // this.setState({ stack, countries });
    this.setState({ stack });

    // console.log(stack);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      const { data, fetched } = nextProps;
      const { project, entity } = data;

      if (fetched && !data.project.length) {
        // this.setState({ selectedDropdown: 'entity' });
        this.formatData(entity);
      } else {
        this.formatData(project);
      }
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('---did update---',prevProps,this.props)
    // console.log(
    //   this.state.stack,
    //   prevState.stack,
    //   this.state.stack === prevState.stack
    // );
    // if (this.state.stack !== prevState.stack) {
    //   this._renderStackedChart(prevState.stack);
    // }
    if (document.getElementById('chart-legend')) {
      const height = document
        .getElementById('chart-legend')
        .getBoundingClientRect().height;
      // console.log(this.state.legendHeight, height);
      if (this.state.legendHeight !== height) {
        this.setState({ legendHeight: height });
      }
    }
    if (this.props.type !== prevProps.type || 
      this.props.paymentTypes !== prevProps.paymentTypes || 
      this.props.selectedProject !== prevProps.selectedProject || 
      this.props.selectedCountry !== prevProps.selectedCountry || 
      this.props.selectedReportingCompany !== prevProps.selectedReportingCompany || 
      this.props.selectedReportingJurisdiction !== prevProps.selectedReportingJurisdiction || 
      this.props.selectedYears !== prevProps.selectedYears || 
      this.props.selectedType !== prevProps.selectedType
       )
       {
      this.formatData(this.props.data[this.props.type])
    }
  }

  calculateWidth() {
    const { stackChartWidth, stackLegendWidth } = this.state;

    // console.log('stack chart width', this.StackParent.getBoundingClientRect());

    let currentWidth = this.StackParent
      ? this.StackParent.getBoundingClientRect().width
      : stackChartWidth;

    const shouldResize = stackChartWidth !== currentWidth;

    if (shouldResize) {
      this.setState({
        stackChartWidth: currentWidth > 1039 ? 700 : currentWidth - 40
      });
    }
  }

  _renderLegend(z, keys) {
    const legendComponent = this.StackChartLegend;
    const pWidth = this.state.stackLegendWidth;

    d3.select(legendComponent)
      .select('svg')
      .remove();

    let svg = d3.select(legendComponent).append('svg'),
      margin = { top: 20, right: 20, bottom: 30, left: 40 },
      width = pWidth - margin.left - margin.right,
      g = svg
        .attr('width', pWidth)
        // .attr('height', 340)
        .style('overflow', 'visible')
        // .style('padding-top', 30)
        .style('margin-left', 15)
        .append('g')
        .attr('id', 'chart-legend')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    keys.sort();
    // console.log(keys);

    g.append('text')
      .attr('class', 'legend-title')
      .text('Payment type')
      .attr('font-family', 'Open Sans')
      .attr('font-size', 14)
      .attr('color', '#333')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .attr('x', -40)
      .attr('y', -24);

    var legend = g
      .append('g')
      .attr('font-family', 'Open Sans')
      .attr('font-size', 12)
      .attr('color', '#333')
      .attr('text-anchor', 'start')
      .selectAll('g')
      .data(keys)
      .enter()
      .append('g')
      .attr('transform', function(d, i) {
        return 'translate(-220,' + i * 40 + ')';
      });

    legend
      .append('rect')
      .attr('x', width - 19)
      .attr('width', 12)
      .attr('height', 12)
      .attr('fill', z);

    legend
      .append('text')
      .attr('x', function(d) {
        return width + 2;
      })
      .attr('y', 6)
      .attr('dy', '0.32em')
      .text(function(d) {
        return d;
      });
  }

  _renderStackedChart(data) {
    const chartComponent = this.StackChart;
    const pWidth = this.state.stackChartWidth,
      pHeight = 340;
      // fix pre-processing
    const paymentTypes = [];
    for (const key in data[0]) {
      if (key != 'year' && key !== 'total') paymentTypes.push(key);
    }

    // const keys = _.cloneDeep(this.props.paymentTypes!== '' ? this.props.paymentTypes.split(':') : paymentTypes);
    const keys = _.cloneDeep(paymentTypes);
    const formattedData = _.cloneDeep(data);


    keys.sort();
    keys.reverse();

    formattedData.forEach(function(d) {
      d.total = 0;
      keys.forEach(function(k) {
        d[k] = d[k] ? d[k] : 0;
        if(d[k] < 0){
          d[k] = 0
        }
        d.total += d[k] || 0;
      });
    });

  if(formattedData.length > 0 && formattedData.filter((d)=>d.total > 0).length === 0){
    const legendComponent = this.StackChartLegend;

    d3.select(legendComponent)
      .select('svg')
      .remove();

    d3.select(chartComponent)
      .select('svg')
      .remove();

    return (
      <div className="chart-negative-wrapper">
        <div className="chart-negative-image">
          <img src="../../../images/img_chart-negative.svg" alt="" width="618" height="266"/>
        </div>
        <div className="chart-negative-message">
          This company reported only negative payments values, therefore no visualization is shown.
        </div>
      </div>
    );}
  else {
    d3.select(chartComponent)
      .select('svg')
      .remove();

    let svg = d3.select(chartComponent).append('svg'),
      margin = { top: 20, right: 20, bottom: 30, left: 40 },
      width = pWidth - margin.left - margin.right,
      height = pHeight - margin.top - margin.bottom,
      g = svg
        .attr('width', pWidth)
        .attr('height', pHeight)

        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // console.log(data[0]);
    let tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip');

    // set x scale
    var x = d3
      .scaleBand()
      .rangeRound([50, width - 50])
      .paddingInner(0.5)
      .align(0.1);

    // set y scale
    var y = d3.scaleLinear().rangeRound([height, 0]);

    // set the colors
    var z = d3
      .scaleOrdinal()
      .range([
        '#B99898',
        '#7DD3D0',
        '#B0DC87',
        '#85AEE0',
        '#E49868',
        '#A0B7C2',
        '#4980BF'
      ]);

   

    formattedData.sort(function(a, b) {
      return Number(a.year) - Number(b.year);
    });

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(5);
    }

    x.domain(
      formattedData.map(function(d) {
        return d.year;
      })
    );

    y.domain([
      0, 
      d3.max(formattedData, function(d) {
        return d.total;
      })
    ]).nice();

    z.domain(keys);

    // add the Y gridlines
    g.append('g')
      .attr('class', 'grid')
      .call(
        make_y_gridlines()
          .tickSize(-width)
          .tickFormat('')
      );

    g.append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys)(formattedData))
      .enter()
      .append('g')
      .attr('fill', function(d) {
        return z(d.key);
      })
      .selectAll('rect')
      .data(function(d) {
        return d;
      })
      .enter()
      .append('rect')
      .attr('x', function(d) {
        return x(d.data.year);
      })
      .attr('y', function(d) {
        return y(d[1]);
      })
      .attr('height', function(d) {
        // return y(d[0]) - y(d[1]) === 0  ? 2 : y(d[0]) - y(d[1]) ;
        return y(d[0]) - y(d[1])
      })
      .attr('width', Math.min(x.bandwidth(), 160))
      .on('mouseover', function() {
        tooltip.style('display', null);
      })
      .on('mouseout', function() {
        tooltip.style('display', 'none');
      })
      .on('mousemove', function(d) {
        const payment = d[1] - d[0];
        const paymentType = Object.keys(d['data']).find(
          z => numberFormatter(d['data'][z]) === numberFormatter(payment)
        );

        let list = "";
        for(const i in keys){
          list += `<li ${keys[i] == paymentType ? `style="font-weight: bold;"` : ``}><div style="display: inline-block; height: 10px; width: 10px; background-color:${z(keys[i])};""></div> ${keys[i]}: ${numberFormatter(d['data'][keys[i]])}</li>` 
        }
        const html = `<div><ul>${list}</ul></div>`;

    //     const html = `
    //     <div>
    //         <p class='title'>
    //         ${keys.map((keyVal)=>`${keyVal}:${numberFormatter(d['data'][keyVal])}`)}
    //         <!--${paymentType}: ${numberFormatter(payment)}</p>-->
    //     </div>
    // `;
        tooltip
          .style('left', d3.event.pageX + 10 + 'px')
          .style('top', d3.event.pageY + 10 + 'px')
          .style('display', 'inline-block')
          .style('font-size', '12px')
          .style('line-height','18px')
          .html(html);
      });

    g.append('g')
      .append('text')
      .attr('class', 'label-usd')
      .attr('text-anchor', 'middle')
      .text('USD');

    g.append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x));

    let formatValue = d3.format('.2s');
    g.append('g')
      //   .attr('class', 'axis')
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickFormat(function(d) {
            return formatValue(d).replace('G', 'Bn');
          })
      );
    //   .append('text')
    //   .attr('x', 2)
    //   .attr('y', y(y.ticks().pop()) + 0.5)
    //   .attr('dy', '0.32em')
    //   .attr('fill', '#000')
    //   .attr('font-weight', 'bold')
    //   .attr('text-anchor', 'start');
    //   .text('Population');

    formattedData.length >0 && formattedData.filter((d)=>d.total > 0).length !== 0 && this._renderLegend(z, keys);
  }
 }

  handlePaymentChange(selectedDropdown) {
    const { data } = this.props;
    const { project, entity } = data;

    const countries = _.uniq(
      _.map(_.cloneDeep(data[selectedDropdown]), 'country')
    );
    this.setState(
      {
        selectedDropdown,
        countries
      },
      () => {
        if (selectedDropdown === 'project') this.formatData(project);
        else this.formatData(entity);
      }
    );
  }

  handleCountryChange(countries) {
    const { data } = this.props;
    const type = this.state.selectedDropdown;

    if (countries === 'All countries') {
      this.setState(
        {
          selectedCountries: 'all'
        },
        () => this.formatData(data[type])
      );
      return;
    }

    if (
      JSON.stringify(countries) !== JSON.stringify(this.state.selectedCountries)
    ) {
      this.setState(
        {
          selectedCountries: countries
        },
        () => this.formatData(data[type])
      );
    }
  }

  getFilteredData(countryData){
    let type = this.props.type
    let selectedPaymentType = this.props.paymentTypes !== '' ? this.props.paymentTypes.split(':') : ''
    let selectedProject = this.props.selectedProject !== '' ? this.props.selectedProject.split(':') : ''
    let selectedYears = this.props.selectedYears !== '' ? this.props.selectedYears.split(':') : ''
    let selectedCountry = this.props.selectedCountry !== '' ? this.props.selectedCountry.split(':') : ''
    let selectedReportingCompany = this.props.selectedReportingCompany !== '' ? this.props.selectedReportingCompany.split(':') : ''
    let selectedReportingJurisdiction = this.props.selectedReportingJurisdiction !== '' ? this.props.selectedReportingJurisdiction.split(':') : ''    
    let selectedType = this.props.selectedType !== '' ? this.props.selectedType.split(':') : ''  
    let filteredData = [];
    if(selectedProject !== ''){
      filteredData = countryData.filter(data =>
        selectedProject.includes(data[`${type}Name`])
      );      
    }
   if(selectedCountry !== ''){
    filteredData = countryData.filter(data =>
      selectedCountry.includes(data[`${type}Country`])
    );
   }
   if(selectedReportingCompany !== ''){
    filteredData = countryData.filter(data =>
      selectedReportingCompany.includes(data.reportingCompany)
    );
   }
   if(selectedReportingJurisdiction !== ''){
    filteredData = countryData.filter(data =>
      selectedReportingJurisdiction.includes(data.reportingJurisdiction)
    );
   }
   if(selectedType !== ''){
    filteredData = countryData.filter(data =>
      selectedType.includes(data.type)
    );
   }
   if(selectedPaymentType !== ''){
    filteredData = countryData.filter(data =>
      selectedPaymentType.includes(data.paymentType)
    );
   }
   if(selectedYears !== ''){
    filteredData = countryData.filter(data =>
      selectedYears.includes(data.reportYear)
    );
   }
    return filteredData
  }

  // renderFilters() {
  //   let value = [];
  //   let countries = this.state.countries;
  //   if (this.state.selectedCountries.indexOf('all') === -1) {
  //     this.state.selectedCountries.forEach(country => {
  //       value.push(country);
  //     });
  //   }
  //   let sortedCountries = countries.sort();
  //   sortedCountries = ['All countries', ...sortedCountries];
  //   value = value.length > 0 ? value : null;

  //   // console.log(value);
  //   return (
  //     <FilterOptions>
  //       <div
  //         className={
  //           this.state.filterOpen ? 'filter-options is-open' : 'filter-options'
  //         }
  //       >
  //         <Select2Component
  //           reference="countries"
  //           data={sortedCountries}
  //           value={value}
  //           onSelectChange={this.handleCountryChange}
  //           placeholder={'All countries'}
  //           multiple={false}
  //         />
  //       </div>
  //     </FilterOptions>
  //   );
  // }

  render() {
    const { fetched, name, data } = this.props;
    let formattedData = [];
    let checkNegativeMesgDisplay;
    let checkAnyNegativePayment = false;
    let dropdownItems;
    if (fetched && !data.project.length)
      dropdownItems = [{ label: "To Gov't entities", value: 'entity' }];
    else
      dropdownItems = [
        { label: 'per project', value: 'project' },
        { label: "To Gov't entities", value: 'entity' }
      ];

    if (fetched && !data.entity.length)
      dropdownItems = [{ label: 'per project', value: 'project' }];

    // let value = [];
    let countries = this.state.countries;
    // if (this.state.selectedCountries.indexOf('all') === -1) {
    //   this.state.selectedCountries.forEach(country => {
    //     value.push(country);
    //   });
    // }
    let sortedCountries = countries.sort();
    sortedCountries = ['All countries', ...sortedCountries];
    // value = value.length > 0 ? value : null;
    let countriesDropdown = [];
    sortedCountries.map(country => {
      let obj = {};
      obj.label = country;
      obj.value = country;
      countriesDropdown = [...countriesDropdown, obj];
    });

    if (this.state.stack.length > 0) {
      const paymentTypes = [];
      for (const key in this.state.stack[0]) {
        if (key != 'year' && key !== 'total') paymentTypes.push(key);
      }
      const keys = _.cloneDeep(paymentTypes);
      formattedData = _.cloneDeep(this.state.stack);
      formattedData.forEach(function (d) {
        d.total = 0;
        keys.forEach(function (k) {
          d[k] = d[k] ? d[k] : 0;
          if(d[k] < 0){
            checkAnyNegativePayment = true
          }
          d.total += d[k] || 0;
        });
        checkNegativeMesgDisplay = formattedData.filter((d) => d.total > 0).length
      });
    }

    return (
      <StackChartComponent>
        {/* <CompanyListHelpText>
          <Hint
            title={<img src={helpIcon} alt="Outlier help" />}
            text={`The tables and charts below show the complete list of payments made by ${name}. The charts show different payment characteristics. Use the drop downs to switch between projects and government agencies.`}
          />
          <Heading.h3 uppercase light style={{ marginRight: '8px' }}>
            Payments from the company
          </Heading.h3>
          <SimpleDropdown
            onChange={this.handlePaymentChange.bind(this)}
            items={dropdownItems}
          />
          <Heading.h3
            uppercase
            light
            style={{ marginRight: '8px', marginLeft: '8px' }}
          >
            For
          </Heading.h3>
          {/* <SimpleDropdown
            onChange={this.handleCountryChange.bind(this)}
            items={dropdownItems}
          /> */}
          {/* <Filters> */}
          {/* {this.renderFilterIcon()} */}
          {/* {this.renderFilters()}
          </Filters> */}

          {/*<SimpleDropdown
            onChange={this.handleCountryChange.bind(this)}
            items={countriesDropdown}
            limitHeight
          />

          <Text.span>Showing all payment data.</Text.span>
        </CompanyListHelpText> */}
        <div className={checkNegativeMesgDisplay ? "chart-container": "chart-container chart-negative-container"} ref={d => (this.StackParent = d)}>
          <div
            ref={d => (this.StackChart = d)}
            style={{
              width: `${this.state.stackChartWidth}px`,
              height: '340px',
              // minWidth: '700px',
              overflow: 'visible'
            }}
          >
            {this._renderStackedChart(this.state.stack)}
            {/* <svg style={{ minWidth: '700px', overflow: 'visible' }}></svg> */}
          </div>
          <div className={checkNegativeMesgDisplay ? "chart-legend": "chart-legend chart-negative-legend"}
            style={{ position: 'relative', width: '260px', height: '340px' }}
          >
            <div
              ref={d => (this.StackChartLegend = d)}
              style={{
                width: `${this.state.stackLegendWidth}px`,
                height: `${this.state.legendHeight}px`,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              {/* <svg
            width="260"
            height="400"
            style={{
              marginLeft: '15px',
              paddingTop: '30px',
              overflow: 'visible'
            }}
            
          ></svg> */}
            </div>
          </div>     
          {checkAnyNegativePayment ? <div className="chart-message">Negative payment values not reflected in chart</div> : ''}
        </div>
      </StackChartComponent>
    );
  }
}

export default ResponsiveWrapper(StackChart);

const StackChartComponent = styled.div`
  margin-bottom: 50px;
  /* .axis .domain {
    display: none;
  } */

  /* .tick line {
    visibility: hidden;
  } */
  svg {
    overflow: visible;

    text {
      font-size: unset;

      &.label-usd {
        transform: translate(-54px, 46%) rotate(-90deg);
        font-size: 11px;

        @media (max-width: 767px) {
          transform: translate(-45px, 46%) rotate(-90deg);
        }
      }
    }
  }

  g.tick line,
  .grid .domain,
  .axis .domain,
  g .domain {
    stroke: #eceaf1;
  }

  .legend-title {
    text-transform: uppercase;
  }

  g.tick {
    font-size: 12px;
  }

  .chart-container {
    background-color: white;
    padding: 20px 40px 15px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    box-shadow: 0px 25px 40px -20px rgba(0, 0, 0, 0.5);

    @media (max-width: 767px) {
      padding: 20px 20px 15px;
    }
  }

  .chart-negative-container {
    padding: 44px 56px 44px 70px;
    @media all and (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    > div {
      @media all and (min-width: 769px) {
        height: 266px!important;
      }
      width: 100%!important;
      &:last-of-type {
        display: none;
      }
    }
  }

  .chart-negative-legend {
    display: none;
  }

  .chart-message {
    color: #333;
    font-size: 12px;
    font-style: italic;
    margin-top: -50px;
    padding-bottom: 10px;
    width: 100%;
    @media all and (min-width: 1076px) {
      margin-top: 8px;
      text-align: right;
    }
  }

  .chart-negative-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .chart-negative-image {
    @media all and (max-width: 1024px) {
    max-width: 54%;
    }
    @media all and (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 30px;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .chart-negative-message {
    color: #333;
    font-size: 18px;
    line-height: 26px;
    max-width: 252px;
    @media all and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      max-width: 100%;
    }
  }
`;

const CompanyListHelpText = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;

  & > * {
    margin-bottom: 10px;
  }

  li {
    text-transform: uppercase;
  }
  > span {
    &:nth-child(1) {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
    &:last-child {
      color: ${props => props.theme.primary.white};
      font-size: 12px;
      padding-left: 12px;
    }
  }
`;

// const Filters = styled.div`
//   display: inline-block !important;
//   position: relative;
//   /* margin-top: 10px; */
//   .is-hidden {
//     visibility: hidden;
//     transition: all 0.3s ease-in-out;
//   }
// `;

// const FilterOptions = styled.div`
//   /* margin-bottom: 3px; */
//   margin-left: 5px;

//   .filter-options {
//     opacity: 1;
//     position: relative;
//     transform: translateY(0);
//     transition: all 0.3s ease-in-out;
//     .select2-container {
//       width: auto !important;
//       min-width: 142px;
//       max-width: 300px;
//       .select2-search--inline {
//         color: #fff;
//         width: 100%;
//         &:after {
//           border-top: 3px solid #fff;
//           border-left: 3px solid transparent;
//           border-right: 3px solid transparent;
//           content: '';
//           position: absolute;
//           right: 14px;
//           top: 12px;
//         }
//         .select2-search__field {
//           cursor: pointer !important;
//         }
//       }
//       .select2-selection--multiple {
//         min-height: 26px;
//       }
//     }
//     .select2-container--default .select2-selection--multiple,
//     .select2-selection--single {
//       background-color: rgba(255, 255, 255, 0.2);
//       border: 0;
//       cursor: pointer;
//       margin-bottom: 0;
//       outline: none;
//     }

//     .select2-container--default
//       .select2-selection--single
//       .select2-selection__rendered,
//     .select2-container--default
//       .select2-selection--single
//       .select2-selection__placeholder {
//       color: #fff;
//       font-weight: 600;
//     }

//     .select2-container--default
//       .select2-selection--single
//       .select2-selection__arrow
//       b {
//       border-color: #fff transparent transparent transparent;
//       margin-top: 0;
//       border-width: 3px 3px 0;
//     }

//     .select2-container--default.select2-container--open
//       .select2-selection--single
//       .select2-selection__arrow
//       b {
//       border-color: transparent transparent #fff transparent;
//       border-width: 0 3px 3px;
//     }

//     .select2-container--default .select2-search--inline .select2-search__field {
//       color: #fff;
//       font-weight: 600;
//       margin-top: 0;
//       ::-webkit-input-placeholder {
//         /* Chrome/Opera/Safari */
//         color: #fff;
//       }
//       ::-moz-placeholder {
//         /* Firefox 19+ */
//         color: #fff;
//       }
//     }
//   }

//   /* @media screen and (min-width: 1340px) {
//     position: absolute;
//     left: -176px;
//     top: 84px;

//     .filter-options {
//       opacity: 0;
//       transform: translateY(-4px);

//       &.is-open {
//         transform: translateY(0);
//         opacity: 1;
//       }
//     }
//   } */
// `;
