import React, { Component } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseLayer from './baseLayer';
import LayerControl from './layerControl/layerControl';
import MarkerCluster from './markerCluster';
import Overlay from './overlay/overlay';
import Loader from '../../../components/layouts/loader';

class CountryMap extends Component {

  render() {
    if (!this.props.countryFetched) {
      return <Loader />;
    } else {
      return (
        <BaseLayer {...this.props}>
          <LayerControl />
          <MarkerCluster />
          <Overlay />
        </BaseLayer>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  countryData: state.country.countryData,
  countryFetching: state.country.countryFetching,
  countryFetched: state.country.countryFetched,
})

CountryMap.propTypes = {
  countryData: propTypes.array
};

export default connect(mapStateToProps)(CountryMap);
