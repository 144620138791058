import API from './api';

class Report {

	async send(data) {
		try {
			let res = await API.post('report', data);
			return res.body;
		} catch (error) {
			return false;
		}
	}

}

export default new Report();