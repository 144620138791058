import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/styled/ui/';
import { getAppImages } from '../../../helpers';
const { noDataIcon } = getAppImages;

const NoData = ({ countryName, id }) => (
  <NoDataWrap>
    <img className="icon" src={noDataIcon} alt="No Data" />
    <Text id={id} className="text" size={16} align="center">
      No data available for {countryName}
    </Text>
  </NoDataWrap>
);

export default NoData;

const NoDataWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .icon {
    margin-bottom: 8px;
  }

  .text {
    color: #adadad;
  }
`;
