import React from 'react';
import numberFormatter from '../../../utils/numberFormatter';
import styled from 'styled-components';

class Legend extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { mapData } = this.props;

    const barWidth = 60;

    // const topFiveThresholdIndex = Math.round(0.05 * mapData.length);

    if (mapData.length === 0) return null;

    // const oneFourthPayment = Math.floor(
    //   mapData[topFiveThresholdIndex].totalPayment / 4
    // );

    const oneSixthPayment = Math.floor(mapData[0].totalPayment / 6);

    const domain = [
      0,
      oneSixthPayment,
      2 * oneSixthPayment,
      3 * oneSixthPayment,
      4 * oneSixthPayment,
      5 * oneSixthPayment,
      mapData[0].totalPayment // max value
    ];

    const colors = [
      '#8CC1D9',
      '#67A9CF',
      '#3690BF',
      '#1D8A76',
      '#006C59',
      '#035945'
    ];

    return (
      <LegendWrap>
        <div className="legend-bar">
          {colors.map(color => (
            <div
              key={color}
              style={{
                width: barWidth,
                height: 8,
                backgroundColor: color
              }}
            />
          ))}
        </div>

        <div className="legend-label">
          {domain.map((value, key) => (
            <div
              key={key}
              style={{
                width: barWidth,
                height: 10
              }}
            >
              <span
                key={value}
                style={{
                  display: 'flex',
                  fontSize: 11,
                  marginLeft: value === 0 ? 0 : -10,
                  marginTop: 3,
                  fontWeight: 'bold',
                  width: 100
                }}
              >
                {key < domain.length - 2
                  ? numberFormatter(value)
                  : `${numberFormatter(value)} and above`}
              </span>
            </div>
          ))}
        </div>
      </LegendWrap>
    );
  }
}

export default Legend;

const LegendWrap = styled.div`
  bottom: 40px;
  position: absolute;
  z-index: 9999;
  transform: translateX(-50%);
  left: 50%;
   @media all and (max-width: 420px) { 
     bottom: 0;
   }
  .legend-bar {
    display: flex;
    justify-content: center;
    @media all and (max-width: 420px) {
      > div {
        width: 40px !important;
      }
    }
  }
  .legend-label {
    display: flex;
    > div:last-child {
      display: none;
    }
    @media all and (max-width: 420px) {
      > div {
        width: 36px !important;
        > span {
          font-size: 10px !important;
        }
        &:first-child > span {
          margin-left: 0 !important;
        }
      }
    }
  }
`;
