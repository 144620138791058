import React, { Component } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { rgba } from '../../../components/styled/utils/colors';
import Colours from '../../../components/styled/theme/colours';
import { Dropdown, DropdownMenu, transitionStyles } from './dropdownStyles';
const { primary } = Colours;

class SimpleDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: this.props.items[0].label,
      isOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  handleChange(dropdownValue, dropdownLabel) {
    this.setState(
      {
        isOpen: false,
        selectedValue: dropdownLabel
      },
      () => {
        this.props.onChange(dropdownValue);
      }
    );
  }

  render() {
    const { items, limitHeight } = this.props;

    return (
      <Dropdown
        onMouseEnter={() => this.handleDropdownMenu(true)}
        onMouseLeave={() => this.handleDropdownMenu(false)}
      >
        <DropdownButton>
          <Title className="title">{this.state.selectedValue}</Title>
          <DropdownIcon isOpen={this.state.isOpen} />
        </DropdownButton>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <ul className={`dropdown-list ${limitHeight ? 'limit-height' : ''}`}>
                {items.map((val, key) => (
                  <li
                    className="dropdown-list-item"
                    key={key}
                    onClick={() => this.handleChange(val.value, val.label)}
                  >
                    {val.label}
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

export default SimpleDropdown;

const DropdownButton = styled.span`
  position: relative;
  display: inline-block;
  background-color: ${rgba('#fff', 0.2)};
  border-radius: 2px;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  line-height: 12px;
  padding: 7px 16px;
  border-radius: 16px;

  &:hover {
    background-color: ${rgba('#fff', 0.4)};
  }
`;

const Title = styled.span`
  color: ${primary.white};
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent ${primary.white};`
      : `${primary.white} transparent transparent;`}
    border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
`;
