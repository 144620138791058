const project = [
  { title: 'project name', width: '22%', name: 'projectName' },
  { title: 'reporting company', width: '22%', name: 'reportingCompany' },
  { title: 'project country', width: '14%', name: 'projectCountry' },
  { title: 'last reported', width: '10%', name: 'lastReported' },
  // { title: 'payment type', width: '12%', name: 'paymentType' },
  // {
  //   title: 'start date',
  //   width: '10%',
  //   name: 'reportStartDate',
  //   class: 'column-date'
  // },
  // {
  //   title: 'end date',
  //   width: '10%',
  //   name: 'reportEndDate',
  //   class: 'column-date'
  // },
  {
    title: 'Payments (USD)',
    width: '17%',
    name: 'projectPayment',
    class: 'value column-value'
  }
];
const source = [
  { title: 'reporting company', width: '35%', name: 'reportingCompany' },
  { title: 'reporting country', width: '18%', name: 'reportingCountry' },
  {
    title: 'start date',
    width: '10%',
    name: 'reportStartDate'
  },
  {
    title: 'end date',
    width: '10%',
    name: 'reportEndDate'
  },
  {
    title: 'published date',
    width: '15%',
    name: 'sourcePublishDate'
  },
   {
    title: ' ',
    width: '20%',
    name: ' '
  },
  // {
  //   title: '',
  //   width: '8%',
  //   name: 'fileFormat'
  // }
];
const entity = [
  { title: 'Entity name', width: '22%', name: 'entityName' },
  { title: 'reporting company', width: '22%', name: 'reportingCompany' },
  { title: 'Entity country', width: '14%', name: 'entityCountry' },
  { title: 'last reported', width: '10%', name: 'lastReported' },
  // { title: 'payment type', width: '12%', name: 'paymentType' },
  // {
  //   title: 'start date',
  //   width: '10%',
  //   name: 'reportStartDate'
  // },
  // {
  //   title: 'end date',
  //   width: '10%',
  //   name: 'reportEndDate'
  // },
 
  {
    title: 'value (USD)',
    width: '15%',
    name: 'entityPayment',
    class: 'value'
  }
];

export const header = { project, entity, source };
