export function fetching() {
	return {
		type: 'ENTITY_ATTRIBUTE_FETCHING',
	};
}

export function fetched(payload) {
	return {
		type: 'ENTITY_ATTRIBUTE_FETCHED',
		payload
	};
}

export function error(payload) {
	return {
		type: 'ENTITY_ATTRIBUTE_ERROR',
		payload
	};
}
