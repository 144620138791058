import React from 'react';
import styled from 'styled-components';

const Loader = () => {
	return (
		<Content>
			<div className="animated-background">
				<div className="background-masker content-top" />
				<div className="background-masker content-first-end" />
				<div className="background-masker content-second-line" />
				<div className="background-masker content-second-end" />
				<div className="background-masker content-third-line" />
				<div className="background-masker content-third-end" />
			</div>
		</Content>
	);
};

export default Loader;

const Content = styled.div`
	padding: 18px;

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	.animated-background {
		animation-duration: 1.5s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-name: placeHolderShimmer;
		background: #f6f7f8;
		background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
		background-size: 800px 104px;
		height: 76px;
		position: relative;
	}

	.background-masker {
		background: #fff;
		position: absolute;
	}

	.background-masker.content-top,
	.background-masker.content-second-line,
	.background-masker.content-third-line,
	.background-masker.content-second-end,
	.background-masker.content-third-end,
	.background-masker.content-first-end {
		top: 40px;
		left: 0;
		right: 0;
		height: 6px;
	}

	.background-masker.content-top {
		height: 40px;
		top: 0;
	}

	.background-masker.content-first-end,
	.background-masker.content-second-end,
	.background-masker.content-third-end {
		width: auto;
		left: 380px;
		right: 0;
		top: 40px;
		height: 8px;
	}

	.background-masker.content-second-line {
		top: 48px;
	}

	.background-masker.content-second-end {
		left: 420px;
		top: 54px;
	}

	.background-masker.content-third-line {
		top: 62px;
	}

	.background-masker.content-third-end {
		left: 300px;
		top: 68px;
	}
`;
