import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Container,
  Heading,
  DownloadButton,
  Card,
  Text,
  DownloadImageButton
} from '../../components/styled/ui';
import {
  Map,
  // TopCompanyList,
  CompanyStats,
  CompanySwitcher,
  // AllCompanyList,
  CompanyDetail,
  PaymentTable,
  StackedChart
} from './index';
import CompanyService from '../../services/company';
import * as companyActions from '../../actions/company';
import numberFormatter from '../../utils/numberFormatter';
import { flex, media } from '../../components/styled/utils/';
import { getAppImages } from '../../helpers';
import { Hint, Loader } from '../../components/layouts/';
import { API_URL, downloadImage } from '../../helpers';
import { Helmet } from 'react-helmet';
import CardBoundary from '../../screens/CardBoundary';
import {countries} from '../../helpers/helper';
// import fiscalYearIcon from '../../images/fiscal_year.svg';

const { helpIcon, downloadChartIcon, companySpriteIcon, fiscalYearIcon } = getAppImages;

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      selectedIndex: 0,
      companyName: null,
      selectedDropdown: 'project',
      filteredSelectedPaymentType: '',
    };
    // this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.match.params.companyName !== nextProps.match.params.companyName
    ) {
      const {
        dispatchCompanyData,
        match: {
          params: { companyName }
        }
      } = this.props;

      this.setState({
        companyName: decodeURIComponent(companyName)
      });
      dispatchCompanyData(decodeURIComponent(companyName));
    }
  }

  componentWillReceiveProps(props) {
    const { companyList } = props.companyData;
    props.match.params.companyName = decodeURIComponent(
      props.match.params.companyName
    );
    if (
      companyList.length &&
      !companyList
        .map(d => d.toLowerCase())
        .includes(props.match.params.companyName.toLowerCase())
    ) {
      props.history.push('/404', {
        keyword: props.match.params.companyName,
        backUrl: '/company-profiles',
        backText: 'Company Profile',
        name: 'Company'
      });
    }
  }

  componentWillMount() {
    if (window) {
      window.scrollTo(0, 0);
    }
    let companyName = this.props.match.params.companyName;
    companyName = companyName.charAt(0).toUpperCase() + companyName.slice(1);
    this.setState({
      companyName: companyName
    });
    this.props.dispatchCompanyData(decodeURIComponent(companyName));
  }

  componentDidMount() {
    const {
      dispatchCompanyData,
      match: {
        params: { companyName }
      }
    } = this.props;

    if (this.props.match.url.substr(-1) !== '/')
      this.props.history.push(this.props.match.url + '/');

    this.setState({
      companyName: decodeURIComponent(companyName)
    });
    dispatchCompanyData(decodeURIComponent(companyName));
  }

  downloadData(companyName) {
    const url =
      API_URL + 'projects?reportingCompanies=' + companyName + '&download=1';

    window.open(url, '_blank');
  }

  getDatasetSchema() {
    let url = 'https://resourceprojects.org/company/' + this.state.companyName;
    return {
      '@context': 'http://schema.org',
      '@type': 'Dataset',
      name: 'Resource Projects',
      author: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute'
      },
      description:
        'Explore payments made by companies for extracting oil, gas and mining resources around the world.',
      url: url,
      keywords: [
        'MANDATORY DISCLOSURE > PAYMENTS',
        'PAYMENTS TO GOVERNMENT',
        'PROJECT LEVEL PAYMENTS',
        'EXTRACTIVE SECTOR PAYMENTS',
        'MINING AND OIL GAS PAYMENTS'
      ],
      creator: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute',
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'public engagement',
          email: 'admin@resourceprojects.org',
          url: 'https://resourcegovernance.org/'
        }
      },
      distribution: [
        {
          '@type': 'DataDownload',
          encodingFormat: 'application/zip',
          contentUrl: url
        }
      ],
      temporalCoverage: '2014-01-01/..',
      spatialCoverage: {
        '@type': 'Place',
        name: 'Worldwide'
      }
    };
  }

  getCountryCode(name){
    let filteredCountry = countries.filter(countryInfo=>countryInfo.name === name)
    return filteredCountry[0].code
  }

  render() {
    const { companyName } = this.state;
    const { companyData, fetching, fetched } = this.props;

    const {
      project,
      entity,
      companyList,
      entityType = [],
      projectType = []
    } = companyData;
    // if (project === null && entity === null) {
    //   return <h1>Company not found.</h1>;
    // }
    // if (project.length === 0 && entity.length === 0) {
    //   return (
    //       <Loading />
    //   );
    // }
    const totalProjectPayment = _.sumBy(project, 'payment');
    const totalEntityPayment = _.sumBy(entity, 'payment');

    const totalPayment = Math.max(totalProjectPayment, totalEntityPayment);
    const maxComponent =
      totalPayment === totalProjectPayment ? project : entity;
    const mapData = _(maxComponent)
      .groupBy('country')
      .map((d, key) => {
        const d2 = entity.filter(d => d.country === key);
        const totalEntityPayment = d2 ? _.sumBy(d2, 'payment') : 0;
        const totalProjectPayment = _.sumBy(d, 'payment');

        return {
          country: key,
          countryCode: this.getCountryCode(key),
          totalPayment: Math.max(totalEntityPayment, totalProjectPayment)
        };
      })
      .sort((a, b) => b.totalPayment - a.totalPayment)
      .value();

    return (
      <PageWrap>
        <Helmet>
          <title>{companyName}</title>
          <meta
            name="og:description"
            content={`View project-level payment to government data from ${companyName}`}
          />
          <meta
            name="og:title"
            content={`${this.state.companyName} - Resource Projects : An open-source data repository on oil, gas and mining projects`}
          />
          <script type="application/ld+json">
            {JSON.stringify(this.getDatasetSchema())}
          </script>
        </Helmet>
        <Container>
          <TopBar>
            <div>
              <CompanySwitcher
                companyName={companyName}
                companyList={companyList}
              />
              <CompanyStats
                totalPayment={numberFormatter(totalPayment)}
                reportNumber={companyData.reportNumber}
                companyName={this.state.companyName}
              />
            </div>
            <DownloadButton
              to="#"
              target="_blank"
              className="download-button"
              onClick={() => this.downloadData(companyName)}
            >
              <span className="download-icon" />
              <div>
                <span>Download</span>
                <span>Company Data</span>
              </div>
            </DownloadButton>
          </TopBar>

          {/* <button onClick={()=>downloadImage('map', 'map')}>download</button> */}
          <CompanyMapWrap>
            <FlexDownload>
              <HelpText>
                <Hint
                  title={<img src={helpIcon} alt="Outlier help" />}
                  text={`The map below shows the geographic spread of the payments made by ${companyName} along with the location of the project names that we were able to identify Please interact with the map to see more details. `}
                />
                <Heading.h3 uppercase light style={{ marginRight: '12px' }}>
                  Payments made by {companyName} <Text.span>(in USD)</Text.span>
                </Heading.h3>
              </HelpText>
              <DownloadImageButton
                className="download-image download-image__map"
                onClick={() =>
                  downloadImage(
                    'map',
                    `${companyName}-Payment-Map-Resource-Projects`
                  )
                }
              >
                <img src={downloadChartIcon} alt="Download Map" />
                <span> Download Map </span>
              </DownloadImageButton>
            </FlexDownload>
            <VisWrap>
              <div className="column">
                <Card id="map">
                  <CardBoundary>
                    <Map projects={project} mapData={mapData} />
                  </CardBoundary>
                </Card>
              </div>
              <div className="column">
                <Card className="company-info">
                  {!fetching && fetched ? (
                    <CardBoundary>
                      <CompanyDetail
                        companyDetail={companyData.companyDetail}
                        source={companyData.source}
                      />
                    </CardBoundary>
                  ) : (
                    <Loader />
                  )}
                </Card>
              </div>
            </VisWrap>
          </CompanyMapWrap>

          {/* {this.props.fetched && (
            // <StackedChart
            //   fetched={this.props.fetched}
            //   data={companyData}
            //   name={companyName}
            //   paymentTypes={this.state.filteredSelectedPaymentType}
            // />
          )} */}
          
          <PaymentTable
            fetched={this.props.fetched}
            data={companyData}
            name={companyName}
            profile="company"
            // handlePaymentTypeFilterChange={this.handlePaymentTypeChange}
          />
          {/* <TopCompanyList data={companyData} fetching={fetching} /> */}
          {/* {!fetching && (
            <AllCompanyList
              projectType={projectType}
              entityType={entityType}
              data={companyData}
            />
          )} */}
        </Container>
      </PageWrap>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.company.detailFetching,
  fetched: state.company.detailFetched,
  companyData: state.company.companyData
});

const mapDispatchToProps = dispatch => ({
  dispatchCompanyData: companyName => {
    dispatch(companyActions.fetching());
    CompanyService.companyData(companyName).then(response => {
      dispatch(companyActions.fetched(response));
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);

const PageWrap = styled.div``;

const TopBar = styled.div`
  ${flex('column', null, 'flex-start')};
  width: 100%;
  margin: 30px 0;

  ${media.sm`
  	  ${flex('row', 'flex-start', 'center')};
  	  margin: 30px 0 44px 0;
  `};

  > div {
    margin-bottom: 12px;

    > div,
    > ul {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .download-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 21px;
    padding: 6px 14px 6px 8px;
    ${media.sm`
  		margin-left: auto;
  	`};
  }
`;

const HelpText = styled.div`
  display: flex;
  /* margin-bottom: 10px; */

  > span {
    &:nth-child(1) {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      /* margin-top: -3px; */
    }
  }
  h3 {
    display: inline-block;
    line-height: 20px;
    span {
      color: ${props => props.theme.primary.white};
      font-size: 10px;
      font-weight: 400;
    }
  }
`;

const CardGrid = styled.div`
  ${flex('column', null, null)};
  position: relative;
  margin-bottom: 50px;

  ${media.sm`
  	  ${flex('row', 'space-between', 'stretch')};
  `};
`;

const VisWrap = CardGrid.extend`
  min-height: 260px;
  // margin-top: 14px;
  .column {
    ${flex('column', null, null)};
    min-height: 322px;

    &:nth-child(1) {
      background-color: #eef2f3;
      border-radius: 4px;
      box-shadow: 0 25px 40px -20px rgba(0, 0, 0, 0.5);
      margin-bottom: 24px;

      > div {
        background: none;
        box-shadow: none;
      }
      ${media.sm`
    	      width: calc(60.6% - 20px);
    	      margin-bottom: 0;
    `};
    }
    &:nth-child(2) {
      ${media.sm`
    	      width: 39.4%;
    `};
    }

    > div:nth-child(2) {
      position: relative;
      flex: 1;
    }
    .row {
      h3 {
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        margin-bottom: 10px;
      }
      & + .row {
        margin-top: 30px;
      }
      > div:last-child {
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.5);
      }
      .bar-group rect {
        fill: #96bd9e;
      }
    }
    > div {
      padding: 0;
    }
  }
  .company-info {
    height: 100%;
    > div {
      /* background-color: #eef2f3; */
      height: 100%;
      padding: 26px 35px;
    }
    li {
      border-bottom: 1px solid #e8eced;
      margin-bottom: 18px;
      padding-bottom: 14px;
      padding-left: 36px;
      position: relative;
      span {
        display: block;
        font-size: 12px;
        min-height: 10px;
        strong {
          font-weight: 600;
        }
        &:nth-child(1) {
          font-size: 10px;
          margin-bottom: 5px;
          text-transform: uppercase;
        }
        & + span {
          line-height: 16px;
        }
      }
      a {
        color: ${props => props.theme.primary.primary};
        line-height: 1.3;
        word-break: break-all;
        & + a {
          margin-left: 2px;
        }
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &:before {
        background-image: url(${companySpriteIcon});
        background-repeat: no-repeat;
        content: '';
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        width: 24px;
      }
      &.headquarter:before {
        background-position: 0 0;
      }
      &.website:before {
        background-position: 0 -59px;
      }
      &.lei:before {
        background-position: 0 -120px;
        width: 25px;
      }
      &.stock-ticker:before {
        background-position: 0 -182px;
      }
      &.report:before {
        background-position: 0 -245px;
      }
      &.year {
        float: right;
        margin-bottom: 32px;
        :before {
        /* background-position: 0 -307px; */
          display: none;
        }
      }
      .dropdown_year {
        > span {
          align-items: center;
          display: flex;
          line-height: 19px;
          > span {
            &:first-child {
              margin-bottom: 0;
            }
            & + span {
              min-height: initial;
              margin-top: 0;
            }
          }
        }
        .published-year {
          display: inline-block;
          font-size: 12px;
          width: 30px;
        }
        > ul {
          left: auto;
          right: 0;
          @media all and (max-width: 480px) {
            right: -46px;
          }
        }
        .dropdown-list {
          min-width: 230px;
          &.unused {
            min-width: 364px;
          }
          table {
            tr:last-child {
              border-bottom: 0;
            }
            th:last-child,td:last-child {
              padding-right: 16px;
            }
            th {
              color: rgba(51,51,51,0.7);
              font-size: 12px;
              font-weight: 400;
              padding: 16px 0 6px 16px;
            }
            tbody tr {
              border-bottom: 1px solid rgba(205,196,228,0.5);
            }
            td {
              padding-right: 0;
              &[data-label="PUBLISHED DATE"] {
                font-weight: 700;
              }

              &:last-child {
                > div + div {
                  padding-top: 2px;
                  margin-bottom: -4px;
                }
              }

              a {
                font-weight: 700;
                margin-left: 6px;
                text-transform: none;
              }
            }
          }
        }
      }
      &.report-year:before {
        background-image: url(${fiscalYearIcon});
      }
    }
  }
`;

const CompanyMapWrap = styled.div`
  display: inline-block;
  padding-top: 3px;
  width: 100%;
  &:hover .download-image {
    background: #978aa8;
    padding: 0 8px 0 4px;
    transition: width 0.3s ease-in-out;
    width: 132px;
    span {
      line-height: 24px;
      display: block;
    }
    &__map {
      width: 120px;
    }
  }
`;

const FlexDownload = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  position: relative;
`;
