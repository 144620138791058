import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as CountryAction from '../../../actions/country/index';
import CountryService from '../../../services/country/index';
import styled from 'styled-components';
import 'react-vis/dist/style.css';
import ScatterPlot from './scatter-plot';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import numberFormatter from '../../../utils/numberFormatter';
import { debounce } from 'lodash';
import { NoData, Loader } from '../../../components/layouts/';
import { rgba } from '../../../components/styled/utils/';

const settings = {
  plotHeight: 320,
  padding: 30,
  r: 3.5,
  maxRange: () => Math.random() * 1000
};

class JitterPlot extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      includeOutliers: false,
      selectedOption: 'project',
      activeLink: null,
      filteredPlotsData: { types: [], data: [], barData: [] },
      years: [],
      originalPlots: {},
      originalExcludedPlots: {},
      jitterWidth: 500,
      incomeGroup: 'all',
      region: 'all',
      countryData: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
    this._handleYearChange = this._handleYearChange.bind(this);
    this.filterPlot = this.filterPlot.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.includeOutliersChanged = this.includeOutliersChanged.bind(this);
    this._handleComponentChange = this._handleComponentChange.bind(this);

    this.overallColor = 'rgba(236,234,241, 0.2)';
    this.overallStroke = '#CECECE';

    this.overallFilteredColor = 'rgba(231, 231, 231, 0.75)';
    this.overallFilteredStroke = '#cecece';
    //current country
    this.countryColor = 'rgba(58,66,76,.5)';
    this.countryStroke = '#2D2A35';

    this.countryFilteredColor = '#85aa88';
    this.countryFilteredStroke = '#6e6890';

    this.selectedCircle = {};
  }

  componentWillMount() {
    this.props.getPlotData('project');
  }

  componentDidMount() {
    let main = document.getElementById('plot-screen');
    main.style.position = 'relative';
    let toolTip = document.createElement('DIV');
    toolTip.id = 'tooltip-plot';
    toolTip.className = 'tooltip';
    main.appendChild(toolTip);
    this.calculateWidth();
    window.addEventListener('resize', debounce(this.calculateWidth, 150));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateWidth, false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.plotData.length) {
      if(nextProps.countryName !== this.props.countryName) {
        this.setState({originalExcludedPlots: [], originalPlots: []}, () => 
        setTimeout(() => {
          this.excludeOutliers(nextProps.plotData)          
        }, 100)
        );          
      } else {
        setTimeout(() => {
         this.excludeOutliers(nextProps.plotData);          
        }, 100);
      }
    }
    if (nextProps.globalDropDown !== this.props.globalDropDown) {
      this._handleComponentChange(nextProps.globalDropDown);
    }

    if (nextProps.globalFilter.filters) {
      this._handleYearChange(nextProps.globalFilter.filters.years);
    }
    if (nextProps.outliners !== this.props.outliners) {
      this.includeOutliersChanged(nextProps.outliners);
    }

    //for income group
    if (nextProps.incomeGroup !== this.props.incomeGroup) {
      this.setState({ incomeGroup: nextProps.incomeGroup }, () => {
        this.formatDataforPlot(
          this.state.includeOutliers
            ? nextProps.plotData
            : this.state.originalExcludedPlots
        );
      });
    }
    //for region
    if (nextProps.region !== this.props.region) {
      this.setState({ region: nextProps.region }, () => {
        this.formatDataforPlot(
          this.state.includeOutliers
            ? nextProps.plotData
            : this.state.originalExcludedPlots
        );
      });
    }
  }

  filterPlot(node) {
    if (this.state.years.length) {
      return this.state.years.includes(node.year);
    } else {
      return false;
    }
  }

  filterData(data) {
    data.forEach(row => {
      let newData = [];
      row.data.forEach(dt => {
        if (dt.country === this.props.countryName) {
          //make sure selected country is always included
          newData.push(dt);
        } else if (
          this.state.incomeGroup === 'all' &&
          this.state.region === 'all'
        ) {
          newData.push(dt);
        } else if (
          this.state.region === dt.region ||
          this.state.incomeGroup === dt.income
        ) {
          newData.push(dt);
        }
        // } else if (this.state.years.indexOf(dt.year) !== -1) {
        // 	newData.push(dt);
        // }
      });
      row.data = newData;
    });
    return data;
  }

  formatDataforPlot(data) {
    
    data = data.map(d => {
      if (d.paymentType === 'Payments for infrastructure improvements') {
        d.paymentType = 'Infrastructure Improvement';
      }
      return d;
    });
    // console.time('process time');
    let originalPlots = this.state.originalPlots;
    //filter data according to filters
    if (this.state.originalExcludedPlots.length) {
      let cloneData = JSON.parse(JSON.stringify(data));
      data = this.filterData(cloneData);
    }

    let types = [];

    let labels =[];
     data.forEach(d => {
      labels.push({label: d.paymentType, total: sumBy(d.data, (dt) =>  dt.country === this.props.countryName ? dt.total : 0)});
    });

    types = (sortBy(labels, 'total')).map(d => d.label);
    let orderedData = [];
    data.forEach(d => {
      let index = types.indexOf(d.paymentType);
      orderedData[index] = d;
    });
    data = orderedData;
    orderedData = [];

    let temp = [];
    let tempBar = [];
    data.forEach( (d,i)=> {
      let y =i;

      d.data.forEach(r => {
        let key = r.country + r.year + d.paymentType + r.name;
        let yc = originalPlots[key]
          ? originalPlots[key].y
          : y + Math.random() * 0.66;
        let xc = originalPlots[key]
          ? originalPlots[key].x
          : Math.max(0, r.average);
        originalPlots[key] = { y: yc, x: xc };

        //check if currentcountry
        let isCountry = r.country === this.props.countryName;
        let fill = isCountry ? this.countryColor : this.overallColor;
        let color = isCountry ? this.countryStroke : this.overallStroke;

        //filter year
        if (this.state.years.length) {
          if (this.state.years.indexOf(r.year) !== -1) {
            temp.push({
              ...r,
              key,
              x: xc,
              y: yc,
              fill,
              color
            });
          }
        } else {
          temp.push({
            ...r,
            key,
            x: xc,
            y: yc,
            fill,
            color
          });
        }
      });

      //for rendendering black circle at the top
      let countryData = [];
      let filterTemp = temp.filter(d => {
        if(d.country === this.props.countryName) {
          countryData.push(d);
          return false;
        }
        return true;
      });
      temp = filterTemp.concat(countryData);
    });

    data.forEach(d => {
      //for jitter-bar
      let countryData = d.data;
      //filter year
      if (this.state.years.length) {
        countryData = d.data.filter(this.filterPlot);
      }
      countryData = countryData.filter(
        r => r.country === this.props.countryName
      );

      if (countryData.length === 0) {
        this.setState({ countryData: false });
      } else if (!this.state.countryData && countryData.length) {
        this.setState({countryData: true});
      }

      countryData = countryData.length
        ? countryData
        : [{ average: 0, total: 0, d: { paymentType: d.paymentType } }];
      d.data = d.data.length
        ? d.data
        : [{ total: 0, d: { paymentType: d.paymentType } }];
      //calculate  average of country for barchart
      let cagg = countryData.reduce((p, n) => {
        return { total: p.total + n.total, count: p.count + n.count };
      });

      let agg = d.data.reduce((p, n) => {
        return { total: p.total + n.total, count: p.count + n.count };
      });

      tempBar.push({
        otherCountry: agg.total / agg.count || 0,
        country: cagg.total || 0,
        label: d.paymentType
      });
    });

    let filteredPlotsData = { types, data: temp, barData: tempBar };
    // console.timeEnd('process time');
    this.setState({ filteredPlotsData, originalPlots });
    this.props.setBardata({ data: tempBar, otherCountryLabel: this.getOtherCountryLabel() });
  }

  includeOutliersChanged(value) {
    this.setState({ includeOutliers: value, originalPlots: {} });
    this.formatDataforPlot(
      value ? this.props.plotData : this.state.originalExcludedPlots
    );
  }

  excludeOutliers(plotData) {
    if (plotData) {
      /**
       * Remove the excluded country from plotData
       * */
      let countries = this.props.countryList.map(country => country.name);
      plotData = plotData.map(plot => {
        plot.data = plot.data.filter(data => {
          return countries.indexOf(data.country) !== -1;
        });
        return plot;
      });

      let all = [];
      let originalExcludedPlots = [];

      for (let p of plotData) {
        p.data.forEach(row => {
          row.paymentType = p.paymentType;
          all.push(row);
        });
      }

      //for cut off
      let cutoff_percent = 0.02;
      all = sortBy(all, 'total').reverse();
      all = all.slice(
        all.length * cutoff_percent,
        all.length * (1 - cutoff_percent)
      );

      //remove long payment type name and group and sort the data
      all = groupBy(all, 'paymentType');

      for (let paymentType in all) {
        originalExcludedPlots.push({ paymentType, data: all[paymentType] });
      }
      this.setState({ originalExcludedPlots });
      this.formatDataforPlot(
        this.state.includeOutliers ? this.props.plotData : originalExcludedPlots
      );
    }
  }

  calculateWidth() {
    if(!this.jitterParent) return null;
    const { jitterWidth } = this.state;
    const currentWidth = this.jitterParent.getBoundingClientRect().width;

    const shouldResize = jitterWidth !== currentWidth;

    if (shouldResize) {
      this.setState({ jitterWidth: currentWidth });
    }
  }

  _handleYearChange(years) {
    //fix start year
    years = years.map(y => parseInt(y, 10)).filter(y => y);
    this.setState({ years }, () => {
      this.formatDataforPlot(
        this.state.includeOutliers
          ? this.props.plotData
          : this.state.originalExcludedPlots
      );
    });
  }

  _handleComponentChange(value) {
    this.setState({ selectedOption: value });
    this.props.getPlotData(value);
  }

  getCountryImage(country) {
    const currentCountry = this.props.countryList.filter(
      c => c.name === country
    );
    return currentCountry.length
      ? 'https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/' +
          currentCountry[0].code.toLowerCase() +
          '.png'
      : '';
  }

  hideTooltip() {
    let elem = document.getElementById('tooltip-plot');
    this.selectedCircle.el.style.fill = this.selectedCircle.fill;
    elem.style.display = 'none';
  }

  showTooltip(node, event, xScale, yScale) {
    let total = numberFormatter(node.total);
    this.selectedCircle.el = event.target;
    this.selectedCircle.fill = this.selectedCircle.el.style.fill;
    this.selectedCircle.el.style.fill = '#679ae2';
    let elem = document.getElementById('tooltip-plot');
    elem.innerHTML = `
                            <div>
                                <table class='table'>
                                    <tbody>
                                      <tr>
                                        <th>Project</th>
                                        <th class='title'>${node.name}</th>
                                      </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>USD ${total}</td>
                                        </tr>
                                        <tr>
                                            <td>Year</td>
                                            <td>${node.year}</td>
                                        </tr>
                                        <tr>
                                        <td>Country</td>
                                        <td> ${node.country} </td>
                                        </tr>
                                    </tbody>
                                </table>
                               
                            </div>
                            
                            `;
    elem.style.display = 'block';
    elem.style.left = Math.max(0, xScale(node.x) - 150) + 'px';
    elem.style.top = 14 + yScale(node.y) + 'px';
  }

  renderNoData() {
    if (!this.state.countryData && this.props.plotData.length !== 0 && this.props.plotFetched) {
      return (
        <JitterNoData>
          <NoData
            className="jitter-no-data"
            id="no-data"
            countryName={this.props.countryName}
          />
        </JitterNoData>
      );
    }
  }

  getOtherCountryLabel() {
    let {incomeGroup, region} = this.props;
    if (incomeGroup === 'all' && region === 'all'){
      return 'All';
    } 
    return incomeGroup === 'all' ? region : incomeGroup;
  }

  render() {
    let { types, data } = this.state.filteredPlotsData;
    // types = types.sort().reverse();
    let plotHeight = 38;

    return (
      <Content>
        {this.renderNoData()}
        <JitterBarWrap id="plot-screen" style={{marginBottom: -20}}>
        {this.props.plotFetching &&  <Loader/>}

          <div
            ref={el => {
              this.jitterParent = el;
            }}
            id='jitter'
            style={{visibility: this.props.plotFetched ? 'visible' : 'hidden' }}
          >
            <ScatterPlotWrap>
              <ScatterPlot
                yHideLine
                horizontalLines
                types={types}
                yTickValues={Array.from({ length: types.length }).map(
                  (v, k) => k + 0.5
                )}
                yTickFormat={v => types[v - 0.5]}
                xTickFormat={v => numberFormatter(v)}
                data={data || []}
                {...settings}
                height={(types.length + 1) * plotHeight}
                width={this.state.jitterWidth - 10}
                onValueMouseOver={this.showTooltip}
                onValueMouseOut={this.hideTooltip}
              />
            </ScatterPlotWrap>
          </div>
        </JitterBarWrap>
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.country.countryList,
  plotData: state.country.plotData,
  plotFetched: state.country.plotFetched,
  plotFetching: state.country.plotFetching
});

const mapDispatchToProps = dispatch => ({
  getPlotData: (component, years) => {
    dispatch(CountryAction.plotDataFetching());
    CountryService.getPlotData(component, years)
      .then(response => {
        dispatch(CountryAction.plotDataFetched(response));
      })
      .catch(err => {
        //handle error
        dispatch(CountryAction.plotDataError(err));
      });
  },
  setBardata: barData => {
    dispatch(CountryAction.setBarData(barData));
  }
});
//add mapStateToProps and mapDispatchToProps later
export default connect(mapStateToProps, mapDispatchToProps)(JitterPlot);

const Content = styled.div`
  padding: 8px 4px 4px 4px;

  .jitter-no-data {
    background: white;
    opacity: 0.5;
  }
`;

const ScatterPlotWrap = styled.div`
  .jitter-plots {
    transform: translate(60px, 0);
  }

  .xy-axis {
    transform: translateX(55px);
    .axis {
      &:nth-child(2) {
        transform: translateX(20px);
      }
    }
  }
`;

const JitterNoData = styled.div`
  background: ${props => rgba(props.theme.primary.white, 0.8)};
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`;

const JitterBarWrap = styled.div``;
