import React, { Component } from 'react';
import { withRouter } from "react-router";
import CountryService from '../../services/country/index';
import * as CountryAction from '../../actions/country/index';
import { connect } from 'react-redux';
import Select,{components} from 'react-select';
import styled from 'styled-components'


class CountryCompanySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue : ''
        };
        this.imageBaseURL =
      'https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/';
    }
    componentWillMount() {
        this.props.getCountryCompanyList();

    }
    handleSelect(selectedOption) {
        this.props.history.push(selectedOption.type === 'Country' ? `/country/${selectedOption.value}` : `/company/${selectedOption.value}`);
    }
    getCountryImage(countryCode) {
        return this.imageBaseURL + countryCode.toLowerCase() + '.png';
    }

    formatData(data){
        let newList = [{label:"Country",options:[]},{label:"Company",options:[]}]
        let list  = data && [...data].length > 0 && data.map((d)=>{
            if(d.type === 'Country'){
                newList[0].options.push({value:d.code, label: d.name, type: d.type ,image: this.getCountryImage(d.code)})
            }
            if(d.type === 'Company'){
                newList[1].options.push({value:d.name, label: d.name, type: d.type })

            }
        })
        return newList;
    }

    checkTrim = (data) => {
        if(this.props.isHeader && data.label.length >=15){
            return data.image ? {show:data.label.length >= 15,trimUpto: 15} : {show:data.label.length >=21,trimUpto: 21}
        }
        return data.image ?  {show:data.label.length >=45,trimUpto: 45}  : {show:data.label.length >=50,trimUpto: 50} 
    }

    iconOption = (props) => {
        const { Option } = components;
        return (
        <Hover className="name-wrap">
            {this.checkTrim(props.data).show ?  <p className="hover-content">{props.data.label}</p> : ''}
            <Option {... props}>
            <div className="label-wrap">
            {props.data.image ? <img src={props.data.image} width="28" height="20"/> : ''}<span>{this.checkTrim(props.data).show ? props.data.label.substr(0,this.checkTrim(props.data).trimUpto) : props.data.label}{this.checkTrim(props.data).show ? '...' : ''}</span>
            </div>
            </Option>
        </Hover>
      )};
      
    handleInput = (value)=>{
        this.setState({searchValue:value})
    }

    handleSelect(selectedOption) {
        this.props.isHeader && this.props.initialState()
        this.props.history.push(selectedOption.type === 'Country' ? `/country/${selectedOption.value}` : `/company/${selectedOption.value}`);
    } 


    render() {
        const { countryCompanyList } = this.props;
        const formattedData = this.formatData(countryCompanyList)
        return (
            <React.Fragment>
            {/* {this.props.isHeader && <button onClick={()=>this.props.collapseSearch()}>Close</button>} */}
             <Select
                onChange={selectedOption =>
                    this.handleSelect(selectedOption)
                }
                options={
                  this.state.searchValue.length < 2 ? [] : formattedData
                }
                placeholder="Search for a country or a company ..."
                components={{ Option: this.iconOption}}
                onInputChange= {(value)=>this.handleInput(value)}
                openMenuOnClick={false}
                value={''}
                // menuIsOpen={true}
                noOptionsMessage={() => this.state.searchValue.length < 2 ? null : 'No options'}
            />
                {/* <Select
                onChange={selectedOption =>
                    this.handleSelect(selectedOption)
                }
                options={
                    formattedData
                }
                placeholder="Search for countries and companies"
                components={{ Option: this.iconOption}}
                menuIsOpen={true}
            /> */}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getCountryCompanyList: () => {
        CountryService.getCountryCompanyList().then(response => {
            dispatch(CountryAction.countryCompanyList(response.body.data));
        });
    }
});

const mapStateToProps = state => {
    return {
        countryCompanyList: state.country.countryCompanyList
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryCompanySelect))


const Hover=styled.div`
.hover-content{  
    display: none;
    /* height: 0;  */
    background: #fff;
    top: 28px;
    padding: 6px 12px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 15px;
    border-radius: 4px;
    color: rgb(58, 66, 76);
    left: 10px;
    font-size: 12px;
    position: absolute;
    white-space: pre-wrap;
    z-index: 9999999;
    line-height: 14px;
    max-width: 90%;
}`;
