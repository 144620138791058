import Api from '../api';

class Project {
  async paginate(currentPage, perPage, filter, sortBy, order, download) {
    let params = { currentPage, perPage, ...filter, sortBy, order, download };

    return await Api.get('projects', { params });
  }
  async getMarkersData() {
    return await Api.get('country/projects');
  }
  async bubbleData(component) {
    let params = { component };
    let response = await Api.get('bubbleData', { params });
    return response.body;
  }
  async getVisualizationData(component) {
    const params = { component };
    const response = await Api.get('project/Visualization', { params });
    return response.body;
  }

  async checkNewData() {
    const response = await Api.get('checkNewData');
    return response.body;
  }
}

export default new Project();
