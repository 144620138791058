import React from 'react';
import { Text } from '../../../components/styled/ui/';

import styled from 'styled-components';

import { getAppImages } from '../../../helpers';

const { noDataIcon } = getAppImages;

const NoDataReported = () => (
  <div style={{ position: 'relative' }}>
    <NoDataWrap>
      <img className="icon" src={noDataIcon} alt="No Data" />
      <Text className="text" size={16} align="center">
        No data reported
      </Text>
    </NoDataWrap>
  </div>
);

export default NoDataReported;

const NoDataWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .icon {
    margin-bottom: 8px;
  }

  .text {
    color: #adadad;
  }
`;
