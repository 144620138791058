import React, { Component } from 'react';
import * as d3 from 'd3';
import numberFormatter from '../../utils/numberFormatter';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
let width = 500,
  height = 500;

class Bubble extends Component {
  constructor(props) {
    super(props);
    this.renderBubbleChart = this.renderBubbleChart.bind(this);
  }
  componentDidMount() {
    let bubbleData = this.formatBubbleData(this.props.bubbleData);
    this.renderBubbleChart(bubbleData);
  }

  formatBubbleData(bubbleData) {
    let filteredData = [];
    Object.keys(bubbleData).map(bubble => {
      let country = bubbleData[bubble]._id.country;
      let payment = bubbleData[bubble].sum;
      let project = bubbleData[bubble]._id.project;
      let income = bubbleData[bubble].data[0].income;
      filteredData.push({
        country: country,
        payment: payment,
        project: project,
        income: income
      });
      return true;
    });
    filteredData = sortBy(filteredData, 'payment').reverse();
    return filteredData;
  }

  componentWillReceiveProps(nextProps) {
    const { countryIncome } = nextProps;
    const node = d3.select(this.bubbleChart);

    if (countryIncome) {
      // fade in selected bubble
      node
        .selectAll(`#${countryIncome}`)
        .transition()
        // .ease(d3.easeCircle)
        .duration(700)
        .style('opacity', '0.8');

      // fade out not selected bubble
      node
        .selectAll(`.circle-group:not(#${countryIncome})`)
        .transition()
        // .ease(d3.easeCircle)
        .duration(700)
        .style('opacity', '0.2');
    } else {
      // fade in all bubble
      node
        .selectAll('.circle-group')
        .transition()
        // .ease(d3.easeCircle)
        .duration(700)
        .style('opacity', '0.8');
    }

    if (this.props.bubbleData !== nextProps.bubbleData) {
      this.renderBubbleChart(this.formatBubbleData(nextProps.bubbleData));
    }
  }
  renderBubbleChart(dataset) {
    if (d3.select('.chart')) {
      d3.select('.chart').remove();
    }
    const self = this;
    let svg = d3
      .select(this.bubbleChart)
      .append('svg')
      .attr('class', 'chart')
      .attr('viewBox', '-74 -50 640 640');
    let maxValue = d3.max(dataset, function(d) {
      return d.payment;
    });
    let minValue = d3.min(dataset, function(d) {
      return d.payment;
    });
    const getRadiusScale = (rMin, rMax) => {
      return d3
        .scaleSqrt()
        .domain([minValue, maxValue])
        .range([rMin, rMax]);
    };

    let diffValue = numberFormatter(maxValue - minValue);
    diffValue = diffValue.replace(/\D./g, '');

    let minScale = 20;
    if (diffValue < 50) {
      minScale = 10;
    }

    let radiusScale = getRadiusScale(minScale, 91);

    let simulation = d3
      .forceSimulation()
      .force('x', d3.forceX(0).strength(0.03))
      .force('y', d3.forceY(0).strength(0.03))
      .force(
        'collide',
        d3.forceCollide(function(d) {
          return radiusScale(d.payment) + 1;
        })
      );
    let g = svg
      .selectAll('.circle-group')
      .data(dataset)
      .enter()
      .append('g')
      .attr('class', 'circle-group')
      .attr('id', d => {
        let income;
        switch (d.income) {
          case 'Low income':
            income = 'low-income';
            break;
          case 'High income':
            income = 'high-income';
            break;
          case 'Lower middle income':
            income = 'lower-middle-income';
            break;
          case 'Upper middle income':
            income = 'upper-middle-income';
            break;
          default:
            income = null;
        }
        return income;
      })
      .attr('opacity', '0.8')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')
      .on('mousemove', function(d) {
        d3.select(this).attr('opacity', '0.6');
        onMouseOver(d);
      })
      .on('mouseout', function() {
        tooltip.style('display', 'none');
        d3.select(this).attr('opacity', '0.8');
      });
    let circle = g
      .append('circle')
      .attr('class', 'circle')
      .attr('r', function(d) {
        return radiusScale(d.payment);
      })
      .attr('fill', function(d) {
        return fillColor(d.income);
      });
    let ProjectText = g
      .append('text')
      .attr('dy', '.2em')
      .style('text-anchor', 'middle')
      .text(function(d, i) {
        return i < 5
          ? d.project.length > 6
            ? d.project.substring(0, 3) + '..'
            : d.project
          : null;
      })
      .style('font-size', function(d) {
        return radiusScale(d.payment) >= 50 ? '18px' : '12px';
      })
      .style('color', '#3a424c')
      .style('font-weight', 'normal');
    let PaymentText = g
      .append('text')
      .attr('dy', '1.3em')
      .style('text-anchor', 'middle')
      .text(function(d, i) {
        return i < 5 ? numberFormatter(d.payment) : null;
      })
      .style('font-size', function(d) {
        return radiusScale(d.payment) >= 50 ? '24px' : '14px';
      })
      .style('color', '#3a424c')
      .style('font-weight', 'bold');
    simulation.nodes(dataset).on('tick', ticked);
    function onMouseOver(d) {
      const component =
        self.props.component === 'projects' ? 'Project' : 'Gov\'t Agency';
      const html = `
    <div>
        <table class='table'>
            <tbody>
                <tr>
                    <td>${component}</td>
                    <td>${d.project}</td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td>USD ${numberFormatter(d.payment)}</td>
                </tr>
                <tr>
                    <td>Country</td>
                    <td>${d.country}</td>
                </tr>
            </tbody>
        </table>
    </div>
    `;
      tooltip
        .style('left', Math.max(0, d3.event.pageX - 30) + 'px')
        .style('top', d3.event.pageY + 10 + 'px')
        .style('display', 'inline-block')
        .html(html);
    }
    let tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip');
    function ticked() {
      circle.attr('cx', d => d.x).attr('cy', d => d.y);
      ProjectText.attr('x', d => d.x).attr('y', d => d.y);
      PaymentText.attr('x', d => d.x).attr('y', d => d.y);
    }
    function fillColor(category) {
      let color;
      switch (category) {
        case 'Low income':
          color = '#b0b6be';
          break;
        case 'High income':
          color = '#ff8d8d';
          break;
        case 'Lower middle income':
          color = '#7dd3d0';
          break;
        case 'Upper middle income':
          color = '#b99898';
          break;
        default:
          color = '#20bcfb';
      }
      return color;
    }
  }
  render() {
    return <div ref={el => (this.bubbleChart = el)} className="bubble" />;
  }
}
const mapStateToProps = state => ({
  bubbleData: state.project.bubbleData,
  countryIncome: state.project.countryIncome
});
export default connect(mapStateToProps)(Bubble);
