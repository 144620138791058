import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { Heading } from '../../../components/styled/ui/';
import { rgba } from '../../../components/styled/utils/colors';
import Colours from '../../../components/styled/theme/colours';
import { DropdownMenu, transitionStyles } from './dropdownStyles';
import ScrollBar from 'react-scrollbar/dist/no-css';

const { primary } = Colours;
const mapObject = (object, callback) =>
  Object.keys(object).map(function(key) {
    return callback(key, object[key]);
  });
class MultiDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: 'All',
      isOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  handleChange(title, dropdownValue, dropdownLabel) {
    this.setState(
      {
        isOpen: false,
        selectedValue: dropdownLabel
      },
      () => {
        this.props.onChange(title, dropdownValue);
      }
    );
  }

  renderItems(items) {
    return (
      <ScrollBar
        className="options"
        contentClassName="content"
        smoothScrolling={true}
        minScrollSize={30}
      >
        <div className="dropdown-list">
          <Heading.h5
            className="heading"
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleChange('Income Group', 'all', 'All')}
          >
            All
          </Heading.h5>
          {mapObject(items, (title, value) => {
            return (
              <div key={title}>
                <Heading.h5 className="heading">{title}</Heading.h5>
                <ul>
                  {value.map((val, key) => {
                    return (
                      <li
                        className="dropdown-list-item"
                        key={key}
                        onClick={() => this.handleChange(title, val, val)}
                      >
                        {val}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </ScrollBar>
    );
  }

  render() {
    const { incomeFilter = [] } = this.props;
    const items = incomeFilter
    return (
      <Dropdown
        onMouseEnter={() => this.handleDropdownMenu(true)}
        onMouseLeave={() => this.handleDropdownMenu(false)}
      >
        <DropdownButton>
          <Title className="title">{this.state.selectedValue}</Title>
          <DropdownIcon isOpen={this.state.isOpen} />
        </DropdownButton>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              className="dropdown-menu"
              style={{
                ...transitionStyles[state]
              }}
            >
              {this.renderItems(items)}
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => ({
    incomeFilter: state.countryStats.incomeFilter
})

export default connect(mapStateToProps)(MultiDropdown);

const Dropdown = styled.span`
  position: relative;

  .dropdown-menu {
    box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.15);
  }

  .scrollarea {
    max-height: 265px;
    border-radius: 4px;
  }

  .heading {
    padding: 10px 20px;
  }

  .dropdown-list {
    .dropdown-list-item {
      border-bottom-style: none !important;
    }
    li {
      padding-left: 30px !important;
    }
  }
  .heading {
    border-bottom: 1px solid #e4eaeb;
  }
`;

const DropdownButton = styled.span`
  position: relative;
  display: inline-block;
  background-color: ${rgba('#fff', 0.2)};
  border-radius: 2px;
  color: #333;
  font-size: 12px;
  cursor: pointer;
  padding: 7px 16px;
  border-radius: 16px;

  &:hover {
    background-color: ${rgba('#fff', 0.4)};
  }
`;

const Title = styled.span`
  color: ${primary.white};
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent ${primary.white};`
      : `${primary.white} transparent transparent;`}
    border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
`;
