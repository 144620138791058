const initialState = {
  companyData: {
    project: [],
    entity: [],
    companyList: [],
    companyDetail: {
      companyName: '',
      hq: '',
      lei: '',
      leiCountry: '',
      stockTickers: '',
      website: ''
    }
  },
  companyTable: {
    stats: {},
    list: []
  },
  fetching: false,
  fetched: false,
  detailFetching: false,
  detailFetched: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'COMPANY_FETCHING':
      state = {
        ...state,
        ...{ detailFetching: true }
      };
      break;
    case 'COMPANY_FETCHED':
      state = {
        ...state,
        ...{ companyData: payload, detailFetching: false, detailFetched: true }
      };
      break;
    case 'COMPANY_TABLE_FETCHED':
      state = {
        ...state,
        ...{ companyTable: payload, fetching: false, fetched: true }
      };
      break;
    case 'COMPANY_TABLE_FETCHING':
      state = {
        ...state,
        ...{ fetching: true }
      };
      break;
    default:
      break;
  }
  return state;
};

export default reducer;
