import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ResponsiveWrapper from '../responsive-wrapper/responsive-wrapper';
import * as ProjectActions from '../../actions/project/index';
import { geoPath } from 'd3-geo';
import { geoRobinson } from 'd3-geo-projection';
import Tooltip from './tooltip';
// import Legend1 from './legend1';
import Legend2 from './legend2';
import countriesGeojson from './countries-geojson.json';
import CountriesLayer from './countries-layer';
import CountriesStrokeLayer from './country-stroke-layer';
// import Markers from './markers';
import { media } from '../../components/styled/utils/';

import { getAppImages } from '../../helpers';

const { clickIcon } = getAppImages;

let debounceCountryName;
const mouseEventDelay = {
  onCountrySelect: 250,
  onAllCountrySelect: 300,
  onSvgLeave: 350
};

class Map extends React.Component {
  constructor() {
    super();
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      onboardingHide: false
    };
  }

  renderOnboarding = () => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('country-profile-render')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" /> on a Country to access
            summarized information about their activities and payments.
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'country-profile-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  render() {
    const parentWidth = this.props.parentWidth;
    const width = parentWidth;
    const height = width * 0.8;

    const projection = geoRobinson()
      .scale(width * 0.2)
      .translate([width / 2.3, height / 2.2]);

    const path = geoPath().projection(projection);

    return (
      <div>
        {this.renderOnboarding()}
        <MapWrap id="project-payment">
          <svg
            width="100%"
            height={height}
            onMouseLeave={() => this.onMouseLeave()}
          >
            <CountriesStrokeLayer
              path={path}
              name="first-stroke-layer"
              onCountryHover={this.props.onCountryHover}
              mouseEventDelay={mouseEventDelay}
              countriesGeojson={countriesGeojson}
              parentWidth={parentWidth}
              component={this.props.component}
            />
            <CountriesStrokeLayer
              path={path}
              name="second-stroke-layer"
              countriesGeojson={countriesGeojson}
              parentWidth={parentWidth}
            />
            <CountriesStrokeLayer
              path={path}
              name="third-stroke-layer"
              countriesGeojson={countriesGeojson}
              parentWidth={parentWidth}
            />
            <CountriesLayer
              path={path}
              mouseEventDelay={mouseEventDelay}
              countriesGeojson={countriesGeojson}
              component={this.props.component}
            />
            {/* <Markers projection={projection} parentWidth={parentWidth} /> */}
          </svg>
          {/* <Legend1 parentWidth={this.props.parentWidth} /> */}
          <Legend2 parentWidth={this.props.parentWidth} />
          <Tooltip />
        </MapWrap>
      </div>
    );
  }
  onMouseLeave() {
    clearTimeout(debounceCountryName);
    debounceCountryName = setTimeout(() => {
      this.props.selectedCountry('all');
    }, mouseEventDelay.onSvgLeave);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectedCountry: country => {
      dispatch(ProjectActions.selectedCountry(country));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ResponsiveWrapper(Map));

const MapWrap = styled.div`
  background: #eef2f3;
  position: relative;
  &#project-payment {
    background-color: transparent;
    max-width: 768px;
  }
  .first-stroke-layer path {
    stroke: black;
    stroke-width: 200;
    stroke-opacity: 0;
  }
  .second-stroke-layer path {
    stroke: #d0dfe5;
    stroke-width: 20;
    stroke-opacity: 0.3;
  }
  .third-stroke-layer path {
    stroke: #d0dfe5;
    stroke-width: 7;
    stroke-opacity: 1;
  }
  .countries-path {
    stroke: grey;
    stroke-width: 0.5;
    stroke-opacity: 0.3;
    &.active {
      stroke-opacity: 1;
      stroke-width: 1.5;
    }
  }
`;

const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 221px;
  position: absolute;
  left: 10px;
  top: 11.5%;
  z-index: 1;
  ${media.sm`
    top: -5%;
  `}
  @media all and (min-width:1280px) {
    padding: 18px 18px 12px;
    left: -242px;  
    top: 75px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
    ${media.sm`width:22px;`}
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width:480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width:480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 112px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width:1280px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 32px; 
      right: -13px;
    }
  }
     

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
