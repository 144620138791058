import { rgba } from '../../src/utils/colors';

//Primary
const cyan 	        =  	'#09D7CB';
const gray	        =  	'#333333';
const white         =   '#FFFFFF';
const dark			= 	'#3A424C';
const  primary		= 	'#20BCFB';
const mustard		= 	'#F4D849';

//Secondary
const blue          =   '#096CD6';
const skyblue       =   '#09B7D6';
const lightCyan     =   '#09B7D6';
const green         =   '#98e064';
const lightGreen    =   '#F3F9FA';
const yellow        =   '#f98c42';
const orange        =   '#f98c42';
const red           =   '#f44336';

//Neutral

const lightGray     =   rgba(dark,.7);
const lighterGray   =   rgba(dark,.5);
const black         =   '#000';
const darkWhite     =   '#eceaf1';

//Payment Type

const bonus         =   '#4980BF';
const dividend      =   '#A0B7C2';
const fees          =   '#E49868';
const infrastructure=   '#CFDFF3';
const entitle       =   '#E1EDD4';
const royalty       =   '#7DD3D0';
const tax           =   '#B99898';
const other         =   '#E0D9B4';
const purple		= 	"#E6DCFF";
const purpleLight	= 	"#F0EAFF";

const Colours = {
	primary: {
		dark,
		primary,
		white,
		mustard,
		cyan,
		gray
	},

	sec: {
		blue,
		skyblue,
		lightCyan,
		green,
		lightGreen,
		yellow,
		orange,
		red
	},

	neutral: {
		white,
		darkWhite,
		black,
		gray,
		lightGray,
		lighterGray

	},

	type: {
		bonus,
		dividend,
		fees,
		infrastructure,
		entitle,
		royalty,
		tax,
		other,
		purple,
		purpleLight
	}

};

export default Colours;
