import React from 'react';
import { connect } from 'react-redux';
import numberFormatter from '../../../utils/numberFormatter';

class MarkerTooltip extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.markerTooltipData !== nextProps.markerTooltipData;
  }

  formatCommodity(commodity = '') {
    if(!commodity.trim().length) return 'n/a';
    let separator = commodity.includes(';') ? ';' : commodity.includes(',') ? ',' : '';
   if (separator.length) {
   return commodity.split(separator).map(c => {
        return this.formatCommodity(c.trim());
    }).toString();
   }
   return ' '+commodity.charAt(0).toUpperCase() + commodity.slice(1);
  }

  render() {
    const { markerTooltipData } = this.props;
    const {
      shouldShowTooltip,
      x,
      y,
      name,
      payment,
      commodity,
      type
    } = markerTooltipData;

    if (!shouldShowTooltip) return null;

    const style = {
      position: 'fixed',
      left: x-50,
      top: y+15,
      display: 'block',
      zIndex: '99999',
    };
    return (
      <div id="markerTooltip" style={style} className="tooltip">
        <div>
          <table className="table">
            <tbody>
              <tr>
                <th>Project</th>
                <th className="title">{name}</th>
              </tr>
              <tr>
                <td>Project Type</td>
               <td>{type}</td>
              </tr>
              <tr>
                <td>Commodity</td>
               <td>{this.formatCommodity(commodity)}</td>
              </tr>
              <tr>
                <td>Payment</td>
               <td>{'USD ' + numberFormatter(payment)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    markerTooltipData: state.project.markerTooltipData
  };
};
export default connect(mapStateToProps)(MarkerTooltip);
