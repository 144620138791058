import React from 'react';
import { withRouter } from 'react-router-dom';
// import injectTapEventPlugin from 'react-tap-event-plugin';
import { ThemeProvider } from 'styled-components';
import { Main, Content, Container } from './styled';
import { Footer } from './layouts';
import { Helmet } from 'react-helmet';
import Header from './layouts/header';
import './styled/global/global';
import theme from '../components/styled/theme/theme';
import { getAppImages } from '../helpers';
const { rpLogo } = getAppImages;
// injectTapEventPlugin();

class App extends React.Component {
  
  render() {
    const { children, location } = this.props;
    const { pathname } = location;

    let footerClassName;

    if (pathname === '/') {
      footerClassName =  'footer-home';
    }

    return (
      <ThemeProvider theme={theme}>
        <Main>
          <Content>
            <Helmet
              defaultTitle="Resource Projects : An open-source data repository on oil, gas and mining projects"
              titleTemplate="%s - Resource Projects : An open-source data repository on oil, gas and mining projects"
            >
              <meta charset="utf-8" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
              <meta name="theme-color" content="#000000" />
              <link rel="manifest" href="/manifest.json" />
              <link rel="shortcut icon" href="/favicon.ico" />
              <meta
                name="og:description"
                content="ResourceProjects.org provides a platform to collect and search extractive project information using open data. It aims to harvest data on project-by-project payments to governments based on recent mandatory disclosure legislation in the EU, Norway, US and Canada as well as in EITI reports."
              />
              <meta
                name="description"
                content="ResourceProjects.org provides a platform to collect and search extractive project information using open data. It aims to harvest data on project-by-project payments to governments based on recent mandatory disclosure legislation in the EU, Norway, US and Canada as well as in EITI reports."
              />
              <meta
                name="og:title"
                content="Resource Projects : An open-source data repository on oil, gas and mining projects"
              />
              <meta
                name="title"
                content="Resource Projects : An open-source data repository on oil, gas and mining projects"
              />
              <meta name="og:image" content={rpLogo} />
              <meta name="image" content={rpLogo} />
              <meta name="twitter:card" content="summary" />
            </Helmet>
            {/*<NavBar />*/}
            <Header />
            <Container>{children}</Container>
          </Content>
          <Footer footerClassName={footerClassName} />
        </Main>
      </ThemeProvider>
    );
  }
}
export default withRouter(App);
