import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TR, TD, TRExpanded, TDExpanded } from '../styled';
import Sparkline from '../../screens/company/sparkline/sparkline';
import numberFormatter from '../../utils/numberFormatter';
import iso2ToCountryCode from '../../helpers/iso2-countries-code';
import { getFlag, getAppImages } from '../../helpers';
import { Notes } from '../../components/layouts';
import Report from '../../components/layouts/report';

import * as _ from 'lodash';

// const { clickIcon } = getAppImages;

const getCountryFlag = country => {
  const iso2 = Object.keys(iso2ToCountryCode).find(
    key => iso2ToCountryCode[key] === country
  );
  return getFlag(iso2);
};
class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubTable: false,
      onboardingHide: false,
      activeReport: -1,
      position: '',
      pos: {},
      tablePos: {},
      extendedTablePos: {},
      row: null
    };
  }

  componentDidMount() {
    this.setState({ row: this.props.row, showSubTable: false });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.row !== nextProps.row) {
      this.setState({ row: nextProps.row, showSubTable: false });
    }
  }

  showTooltip(e, name) {
    // if (name.indexOf(';')) name = name.replace(new RegExp('; ', 'g'), '<br/>')
    // let div = document.getElementsByClassName('table-wrap');
    let div = document.getElementById('topToolTip');

    div.style.left = e.pageX + 3 + 'px';
    div.style.top = e.pageY + 3 + 'px';
    div.style.lineHeight = '16px';
    div.style.display = 'block';
    div.innerHTML = name;
  }

  hideTooltip() {
    let div = document.getElementById('topToolTip');
    div.style.display = 'none';
  }
  _renderHeaderYear(payments) {
    let minYear = payments[0].minYear;
    let maxYear = payments[0].maxYear;
    const years = [];
    for (let y = maxYear; y >= minYear; y--) {
      years.push(y);
    }
    return (
      <div className="table__info-row table__heading">
        {years.map(y => (
          <div key={y}>
            <p>{y}</p>
          </div>
        ))}
      </div>
    );
  }
  /**
   *
   * Render report
   */
  _renderReport(index, data) {
    let { activeReport, reportFormShown, position } = this.state;
    return (
      <Report
        reportFormShown={reportFormShown}
        showForm={this._showReportForm.bind(this, index)}
        hideForm={this._hideReportForm.bind(this)}
        index={index}
        position={position}
        activeReport={activeReport}
        data={data}
        // reportType={
        //   this.props.component.charAt(0).toUpperCase() +
        //   this.props.component.slice(1) //to make the first letter uppercase
        // }
      />
    );
  }

  _showReportForm(index) {
    this.setState({ activeReport: index, reportFormShown: true });
  }

  _hideReportForm(e) {
    e.stopPropagation();
    this.setState({ reportFormShown: false, activeReport: -1 });
  }

  /**
   *
   * Render note
   */
  _renderNote(index, notes, nrgiNotes) {
    let { activeNote, position } = this.state;

    if (
      (notes !== 'undefined' && notes !== 'NA' && notes) ||
      (nrgiNotes !== 'undefined' && nrgiNotes)
    ) {
      return (
        <Notes
          onHide={() => this.setState({ activeNote: -1 })}
          onSetActive={this._setActiveNote.bind(this)}
          index={index}
          position={position}
          activeNote={activeNote}
          notes={notes}
          nrgiNotes={nrgiNotes}
          pos={this.state.pos}
          tablePos={this.state.tablePos}
          extendedTablePos={this.state.extendedTablePos}
        />
      );
    }

    return '';
  }

  _setActiveNote(e, index = -1) {
    if (!this.state.activeNote !== index) {
      let viewportOffset = e.target.getBoundingClientRect();
      let position = '';
      let topOffset = viewportOffset.top;
      this.setState({ pos: viewportOffset });

      if (viewportOffset.top > window.innerHeight - viewportOffset.bottom) {
        position = 'top';
      } else {
        position = 'bottom';
      }

      this.setState({ activeNote: index }, () => {
        let noteContent = document.getElementsByClassName('note-content');
        let elemHeight = noteContent[0].offsetHeight;
        noteContent[0].addEventListener('mouseover', function(e) {
          e.stopPropagation();
        });

        if (elemHeight > topOffset) {
          position = 'bottom';
        }

        this.setState({ position });
      });
    }
  }

  _renderPaymentTable(payments, i, d, component) {
    let minYear = payments[0].minYear;
    let maxYear = payments[0].maxYear;
    const years = [];
    for (let y = maxYear; y >= minYear; y--) {
      years.push(y);
    }
    // console.log(payments);
    let finalArray = this.dataToBeRendered(payments)
    return finalArray.map((a, x) => (
      <div
        key={x}
        className="table__info-row"
        onMouseEnter={this.handleTableHover.bind(this)}
      >
        {a.data
          .sort((c, d) => Number(d.year) - Number(c.year))
          .map((b, z) => (
            <div
              key={b.year}
              className={`table__col-content ${
                b.notes || b.nrgiNotes ? 'has-note' : ''
              }`}
            >
              <p>{b.payment}</p>
              <span className="note-icon">
                {(b.notes && b.notes.filter(a => a !== null).length) ||
                (b.nrgiNotes && b.nrgiNotes.filter(a => a !== null).length) ? (
                  <React.Fragment>
                    {this._renderNote(`${x}${z}`, b.notes, b.nrgiNotes)}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </span>

              {b.notes || b.nrgiNotes
                ? this._renderReport(i, {
                    name: d.reportingCompany,
                    reportingCompany: d.reportingCompany,
                    country: d.reportingCountry,
                    paymentType: component === 'source' ? '' : 'Tax',
                    // startDate,
                    // endDate,
                    value:
                      component === 'source' ? '' : d[component + 'Payment']
                  })
                : null}
            </div>
          ))}
      </div>
    ));
  }

  _renderPaymentTotal(payments, maxYear,row) {
    let finalData = this.dataToBeRendered(row)
    let years = payments.map(d => Number(d.year));
    let test = finalData.map((paymentData)=>{
      return paymentData.data.map((payment)=>{
        return {year: payment.year, value : payment.value}
      })
    }) 
    let finalTest = test.flat()
    const minYear = Math.min(...years);
    const data = [];
    for (let y = minYear; y <= maxYear; y++) {
      let i = payments.find(p => Number(p.year) === Number(y));
      if (i) {
        data.push(i);
      } else {
        data.push({ year: y.toString(), value: '-' });
      }
    }
    // let finalArr = _.groupBy(finalTest, 'year');
    let finalArr = []
    _.map(_.values(_.groupBy(finalTest, 'year')),arr => { 
      let totalPayment=0; 
      let year;
      arr.map(dat=>{
        if(dat.value !== '-'){
          totalPayment = totalPayment + dat.value;
        }
        year = dat.year
      })
      finalArr.push({year: year,value:totalPayment})      
    })

    payments = finalArr.sort((a, b) => b.year - a.year);
    return (
      <div className="table__info-row table__footer">
        {payments.map(p => (
          <div key={p.year}>
            <p>{p.value !== '-' ? numberFormatter(p.value) : '-'}</p>
          </div>
        ))}
      </div>
    );
  }

  handleTableHover(e) {
    const coordinate = e.target.getBoundingClientRect();

    this.setState({ tablePos: coordinate });
  }

  handleSubTable() {
    this.setState({
      showSubTable: !this.state.showSubTable
    });
    this.props.hideOnboarding();
  }

  displayPaymentData(row){
    const finalData = this.dataToBeRendered(row.payments)

    return finalData.map(p => (
      <div key={p.paymentType} className="table__payments">
        {/* <p>{p.paymentType}</p> */}
        <span
          className="content  content-with-link"
          // title={d[component + 'Name']}
        >
          {p.paymentType.substr(0, 16)}
          {p.paymentType.length <= 20 ? (
            ''
          ) : (
            <span className="popup-content">{p.paymentType}</span>
          )}
          {p.paymentType.length <= 20 ? '' : <span>...</span>}
        </span>
        <Sparkline
          data={p.data.filter(d => d.value !== '-')}
          minYear={row.minYear}
          maxYear={row.maxYear}
        />
      </div>
    ))
  }

  dataToBeRendered(datas){
    let {selectedPaymentType} = this.props
    let allData = [...datas]
    let filteredData = datas.filter((data)=>selectedPaymentType.includes(data.paymentType))
    return  filteredData.length > 0 ? [...filteredData] : [...allData]
  }

  render() {
    const { type, i, profile } = this.props;
    const { row } = this.state;
    // console.log(row, i);
    // console.log(type);

    return (
      row && (
        <React.Fragment>
          <TR
            key={row._id}
            onClick={this.handleSubTable.bind(this)}
            className={`${this.state.showSubTable ? 'expanded' : ''}`}
          >
            <TD className="project-name" width="40%" data-label="Name">
              {/* {i === 0 && this.renderOnboarding(type)} */}
              <svg
                className="ic-arrow ic-arrow--alternate"
                width="7"
                height="10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1l4 4-4 4"
                  stroke="#333"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              <span
                // onMouseEnter={e =>
                //   row[`${type}Name`].length <= 25
                //     ? ''
                //     : this.showTooltip(e, row[`${type}Name`])
                // }
                // onMouseOut={() => this.hideTooltip()}
                className={`content ${
                  row[`${type}Name`] ? '' : 'not-available'
                }`}
              >
              {row[`${type}Name`] ? (
                  <React.Fragment>
                    {this.state.showSubTable ? 
                      row[`${type}Name`] : 
                      row[`${type}Name`].substr(0, 25)
                      }
                      {row[`${type}Name`].length <= 26 ? (
                          ''
                        ) : this.state.showSubTable ? ('') : (
                          <span className="popup-content">
                            {row[`${type}Name`]}
                          </span>
                        )}
                        {row[`${type}Name`].length <= 26 ? '' : <span class="more-dots">...</span>}
                    </React.Fragment>
                    ) : (
                      'Name not available'
                  )}
                {/* {row[`${type}Name`] ? (
                  <React.Fragment>
                    {row[`${type}Name`].substr(0, 25)}
                    {row[`${type}Name`].length <= 25 ? '' : <span>...</span>}
                    {row[`${type}Name`].length <= 25 ? (
                      ''
                    ) : (
                      <span className="popup-content">
                        {row[`${type}Name`]}
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  'Name not available'
                )} */}
              </span>
            </TD>
            {profile === 'company' ? (
              <TD data-label="Country" width="20%">
                <span style={{ display: 'flex' }}>
                  <Link to={`/country/${row[`${type}CountryCode`]}/`}>
                    <span
                      // onMouseEnter={e =>
                      //   this.showTooltip(e, row[`${type}Country`])
                      // }
                      // onMouseOut={() => this.hideTooltip()}
                      className="content"
                    >
                      <img
                        src={getCountryFlag(row[`${type}Country`])}
                        alt={row[`${type}Country`] + 'Flag'}
                        className="flag"
                      />
                      {this.state.showSubTable ? 
                        row[`${type}Country`] : 
                        row[`${type}Country`].substr(0, 20)
                      }
                      {row[`${type}Country`].length <= 20 ? (
                        ''
                      ) : this.state.showSubTable ? ('') : (
                        <span className="popup-content">
                          {row[`${type}Country`]}
                        </span>
                      )}

                      {row[`${type}Country`].length <= 20 ? '' : <span class="more-dots">...</span>}
                
                      {/* {row[`${type}Country`].substr(0, 20)}
                      {row[`${type}Country`].length <= 20 ? (
                        ''
                      ) : (
                        <span>...</span>
                      )}
                      {row[`${type}Country`].length <= 20 ? (
                        ''
                      ) : (
                        <span className="popup-content">
                          {row[`${type}Country`]}
                        </span>
                      )} */}
                    </span>
                  </Link>
                </span>
              </TD>
            ) : (
              <TD data-label="Reporting Company" width="20%">
                <Link to={`/company/${row.reportingCompany}/`}>
                  <span
                    // onMouseMove={e =>
                    //   row.reportingCompany.length <= 20
                    //     ? ''
                    //     : this.showTooltip(e, row.reportingCompany)
                    // }
                    // onMouseOut={() => this.hideTooltip()}
                    className="content"
                  >

                   {this.state.showSubTable ? 
                        row.reportingCompany : 
                        row.reportingCompany.substr(0, 20)
                      }
                      {row.reportingCompany.length <= 20 ? (
                        ''
                      ) : this.state.showSubTable ? ('') : (
                        <span className="popup-content">
                          {row.reportingCompany}
                        </span>
                      )}

                      {row.reportingCompany.length <= 20 ? '' : <span class="more-dots">...</span>}
                
                
                    {/* {row.reportingCompany.substr(0, 20)}
                    {row.reportingCompany.length <= 20 ? '' : <span>...</span>}
                    {row.reportingCompany.length <= 20 ? (
                      ''
                    ) : (
                      <span className="popup-content">
                        {row.reportingCompany}
                      </span>
                    )} */}
                  </span>
                </Link>
              </TD>
            )}

            <TD data-label="Payment Type" width="20%">
              <span
                // onMouseMove={e =>
                //   row.type.length <= 10 ? '' : this.showTooltip(e, row.type)
                // }
                // onMouseOut={() => this.hideTooltip()}
                className="content"
              >
                {row.type.substr(0, 14)}
                {row.type.length <= 14 ? '' : <span>...</span>}
                {row.type.length <= 14 ? (
                  ''
                ) : (
                  <span className="popup-content">{row.type}</span>
                )}
              </span>
            </TD>

            <TD data-label="Year" width="20%">
              {row.reportYear}
            </TD>
            <TD className="value" data-label="Value (In USD)" width="24%">
              <div className="value-content">
                <span>{numberFormatter(row.totalPayment)}</span>
                <Sparkline
                  data={row.sparkLineData}
                  minYear={row.minYear}
                  maxYear={row.maxYear}
                />
              </div>
            </TD>
          </TR>
          <TRExpanded className={`${this.state.showSubTable ? '' : 'hide'}`}>
            <TDExpanded colSpan="5">
              <TableData
                className="nested-table"
                onMouseEnter={this.handleTableHover.bind(this)}
              >
                <div
                  onClick={this.handleSubTable.bind(this)}
                  className="modal-close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6L6 18M6 6l12 12" />
                  </svg>
                </div>
                <div className="table__title add-shadow">
                  <div className="table__heading">
                    <p>Payments Types</p>
                  </div>
                  {this.displayPaymentData(row)}                  
                  <div>
                    <p className="table__footer">Total</p>
                  </div>
                </div>
                <div className="table__info">
                  {this._renderHeaderYear(row.payments)}

                  {this._renderPaymentTable(row.payments, i, row, profile)}

                  {this._renderPaymentTotal(
                    row.sparkLineData,
                    row.payments[0].maxYear,
                    row.payments
                  )}
                </div>
              </TableData>
            </TDExpanded>
          </TRExpanded>
        </React.Fragment>
      )
    );
  }
}

export default TableBody;
const TableData = styled.div`
  display: flex;
  /* padding: 20px 30px 10px 40px; */
  font-size: 14px;
  color: #333;
  font-weight: bold;
  position: relative;
  /* padding-bottom: 15px; */

  .modal-close {
    position: absolute;
    right: 10px;
    top: -30px;
    color: #fff;
    display: none;
    cursor: pointer;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .table {
    &__title {
      width: 260px;
      flex-shrink: 0;
      @media (max-width: 767px) {
        width: 180px;
      }

      &.add-shadow {
        box-shadow: 30px 0px 30px -30px #605778;
        z-index: 10;
        position: relative;
      }
      > div {
        padding: 10px 30px 10px 40px;
        border-bottom: 1px solid #d9ccf9;

        @media (max-width: 767px) {
          padding: 10px;
        }

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          border: none;
          padding-bottom: 15px;
        }
      }
    }

    &__heading,
    &__footer {
      color: rgba(51, 51, 51, 0.7);
      font-weight: normal;
      font-size: 12px;
    }

    &__info {
      flex: 1;
      direction: rtl;
      overflow-x: auto;
      padding-bottom: 15px;
      padding-right: 20px;
      /* overflow-y: hidden; */

      &-row {
        display: flex;
        /* position: relative; */
        /* border-bottom: 0.5px solid #d9ccf9; */

        &:last-child {
          border-bottom: none;
        }

        &.table__heading > div {
          padding-top: 20px;
          padding-right: 20px;
        }

        &.table__footer > div {
          border: none;
          padding-right: 20px;
        }

        > div {
          /* margin-left: 20px!important; */
          width: 130px;
          flex-shrink: 0;
          border-bottom: 1px solid #d9ccf9;
          padding: 10px 0 10px 20px;

          /* &:first-child {
            padding-right: 20px;

            @media (max-width: 767px) {
              padding-right: 10px;
            }
          } */
        }
      }
    }

    &__col-content {
      direction: ltr;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        .payment-report {
          opacity: 1;
          visibility: visible;
        }
      }
      &:not(.has-note) {
        padding-right: 20px;
      }
    }

    &__payments {
      display: flex;
      justify-content: space-between;
      position: relative;

      .sparkline {
        height: 16px;
        position: absolute;
        right: 20px;

        @media (max-width: 767px) {
          right: 10px;
        }
      }
    }
  }
`;
// const Onboarding = styled.div`
//   background-color: #333;
//   border-radius: 4px;
//   padding: 10px 12px 11px;
//   width: 200px;
//   position: absolute;
//   /* left: -4px;
//   top: 11.5%; */
//   left: -18%;
//   /* bottom: 60px; */
//   z-index: 1;
//   @media all and (min-width: 1400px) {
//     padding: 18px 18px 12px;
//     left: -222px;
//     /* bottom: -6px; */
//   }
//   &.bounce {
//     animation-name: bounce;
//     animation-timing-function: linear;
//     animation-duration: 2s;
//     animation-iteration-count: infinite;
//     &:after {
//       border-radius: 4px;
//       box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
//       bottom: 0;
//       content: '';
//       height: 100%;
//       left: 0;
//       position: absolute;
//       width: 100%;
//       z-index: -1;
//     }
//   }
//   img {
//     margin-right: 2px;
//     vertical-align: bottom;
//     width: 18px;
//   }
//   .text {
//     color: #fff;
//     font-size: 11px;
//     line-height: 16px;
//     text-align: left;
//     @media all and (min-width: 480px) {
//       font-size: 12px;
//     }
//   }
//   .btn__got-it {
//     background-color: rgba(255, 255, 255, 0.1);
//     border: 0;
//     border-radius: 10px;
//     color: #fff;
//     cursor: pointer;
//     display: block;
//     font-size: 11px;
//     font-weight: bold;
//     float: right;
//     margin-top: 5px;
//     padding: 2px 9px;
//     transition: background-color 0.2s ease-in-out;
//     @media all and (min-width: 480px) {
//       font-size: 12px;
//     }
//     &:hover {
//       background-color: rgba(255, 255, 255, 0.2);
//     }
//     &:focus {
//       outline: 0;
//     }
//   }
//   .caret {
//     height: 0;
//     width: 0;
//     position: absolute;
//     bottom: -10px;
//     right: 134px;
//     /* right: 164px; */
//     border-left: 10px solid transparent;
//     border-right: 10px solid transparent;
//     border-top: 14px solid #333;
//     @media all and (min-width: 1400px) {
//       border-top: 10px solid transparent;
//       border-bottom: 10px solid transparent;
//       border-left: 14px solid #333;
//       border-right: 0;
//       top: 42px;
//       right: -13px;
//     }
//   }

//   @keyframes bounce {
//     0% {
//       transform: translateY(0);
//     }
//     50% {
//       transform: translateY(-10px);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }
// `;
