import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import moment from 'moment';
import {
  Dropdown,
  DropdownMenu,
  transitionStyles
} from '../../../components/layouts/dropdown/dropdownStyles';
import { Transition } from 'react-transition-group';
import Colours from '../../../components/styled/theme/colours';
const momentTz = require('moment-timezone');
const { primary } = Colours;

class companyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isUrl(str) {
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  }

  displayYearsAvailable(companyDetail){
    let companyData = [...companyDetail.reportYear].sort((a, b) => a.year - b.year)
    return companyData.map((d, index) => {
                        return (
                          <React.Fragment>
                          <a
                            key={d.year}
                            href={d.link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {`${d.year} (${d.format.split('.').join('')})`}
                          </a>
                          {(index !== companyDetail.reportYear.length - 1) ? ', ' : ''}
                          </React.Fragment>
                        );
                      })
  }

  displaySourcesReport(companyDetail){
    let companyData = [...companyDetail.reportYear].sort((a, b) => b.year - a.year)
    return companyData.map((sourceData) => {
      let format = sourceData.format ? sourceData.format.split('.')[1] : 'N/A'
      return (         
                <tr>
                  <td data-label="PUBLISHED DATE">{sourceData.year
                    ? sourceData.year
                    : 'Not Available'}
                  </td>
                  <td>
                  <span className="published-year">{format.toUpperCase()}</span>
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={sourceData.link}
                      title="View the source document"
                    >
                      Link         
                  </a>
                  {' |'}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={sourceData.original}
                      title="Go to external source site"
                    >
                      Origin
                    </a>
                  </td>   
                  <td>     
                  {sourceData.unused_sources &&
                    sourceData.unused_sources.map((unUsedData)=>{
                      let unusedFormat = unUsedData.format ? unUsedData.format.split('.')[1] : 'N/A'
                    return (
                      <div>
                      <span className="published-year">{unusedFormat.toUpperCase()}</span>
                      <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={unUsedData.link}
                          title="View the source document"
                        >
                          Link
                      </a>
                      {' |'}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={unUsedData.original}
                          title="Go to external source site"
                        >
                          Origin
                        </a>
                        </div>)
                    })
                    } 
                  </td>         
                </tr>
      )
    })
  }


  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  render() {
    const { companyDetail, source } = this.props;
    return (
      <div>
        <ul>
          <li className="headquarter">
            <span>Headquarters</span>
            <span>
              <strong>
                {companyDetail.hq && companyDetail.hq.trim()
                  ? companyDetail.hq
                  : 'N/A'}
              </strong>
            </span>
          </li>
          <li className="website">
            <span>Website</span>
            <span>
              <strong>
                {this.isUrl(
                  companyDetail.website && companyDetail.website.trim()
                ) ? (
                  <a
                    href={companyDetail.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {companyDetail.website}
                  </a>
                ) : (
                  'N/A'
                )}
              </strong>
            </span>
          </li>
          <li className="lei">
            <span>Legal Entity Identifier (LEI)</span>
            <span>
              <strong>
                {companyDetail.lei &&
                companyDetail.lei.toLowerCase() === 'x' ? (
                  'N/A'
                ) : companyDetail.lei && companyDetail.lei.trim() ? (
                  <a
                    href={'https://www.gleif.org/lei/' + companyDetail.lei}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {companyDetail.lei}
                  </a>
                ) : (
                  'N/A'
                )}
              </strong>
            </span>
          </li>
          <li className="stock-ticker">
            <span>Stock Ticker(s)</span>
            <span>
              <strong>
                {companyDetail.stockTickers &&
                companyDetail.stockTickers.toLowerCase() === 'x'
                  ? 'N/A'
                  : companyDetail.stockTickers &&
                    companyDetail.stockTickers.trim()
                  ? companyDetail.stockTickers
                  : 'N/A'}
              </strong>
            </span>
          </li>
          <li className="report">
            <span>Reporting In</span>
            <span>
              <strong>
                {companyDetail && companyDetail.reportsUnder
                  ? companyDetail.reportsUnder.map((country, index) => {
                      if (index === companyDetail.reportsUnder.length - 1)
                        return `${country}`;
                      else return `${country}, `;
                    })
                  : 'N/A'}
              </strong>
            </span>
          </li>
          <li className="report-year">
            <span>Reporting year start/end</span>
            <span>
              <strong>
                {`${momentTz(source.reportStartDate)
                  .tz('Asia/Kathmandu')
                  .format('MMM DD')} - ${momentTz(source.reportEndDate)
                  .tz('Asia/Kathmandu')
                  .format('MMM DD')}`}
              </strong>
            </span>
          </li>
          <li className="year">          
            <Dropdown
              onMouseEnter={() => this.handleDropdownMenu(true)}
              onMouseLeave={() => this.handleDropdownMenu(false)}
              className="dropdown_year"
            >
              <DropdownButton>
                <Title className="title">Link to reports</Title>
                <DropdownIcon isOpen={this.state.isOpen} />
              </DropdownButton>

              <Transition
                in={this.state.isOpen}
                timeout={100}
                mountOnEnter={true}
              >
                {state => (
                  <DropdownMenu
                    style={{
                      ...transitionStyles[state]
                    }}
                  >
                    <ul className={companyDetail.reportYear && companyDetail.reportYear.filter((data)=>data.unused_sources).length > 0 ? 'dropdown-list unused':'dropdown-list'}>
                          <TableUnusedSource>
                            <thead>
                              <tr>
                                <th>Year</th>
                                <th>Sources</th>
                                {companyDetail.reportYear && companyDetail.reportYear.filter((data)=>data.unused_sources).length > 0&&<th>Additional Sources</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {this.displaySourcesReport(companyDetail)}                       
                            </tbody>
                        </TableUnusedSource>
                    </ul>
                  </DropdownMenu>
                )}
              </Transition>
            </Dropdown>
          </li>
        </ul>
      </div>
    );
  }
}

export default companyDetail;

companyDetail.propTypes = {
  companyDetail: PropTypes.shape({
    hq: PropTypes.string,
    lei: PropTypes.string,
    website: PropTypes.string,
    stockTickers: PropTypes.string,
    leiCountry: PropTypes.string
  })
};


const DropdownButton = styled.span`
  position: relative;
  display: inline-block;
  background-color: #ECEAF1;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #333;
  font-size: 9px;
  cursor: pointer;
  padding: 2px 8px 1px;
  margin-left: 4px;
  border-radius: 16px;

  &:hover {
    border-color: #333;
  }
`;

const Title = styled.span`
  color: #333;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent #333;`
      : `#333 transparent transparent;`};
  border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  margin-top: -1px;
`;

const TableUnusedSource = styled.table`
    color: #333;  
    font-size: 12px;
    th,td {
      padding: 8px 16px;
    }
    th {
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
    }
    tr {
      cursor: auto;
    }
    tr td {
      font-weight: 400;
      padding-bottom: 8px!important;
      > a {
        color: ${primary.primary};
        font-weight: 700;
        margin-left: 6px;
      }
    }
    .unusedsource-text div{
      display: flex;
      align-items: flex-start;
      p {
        color: #3A424C;
        font-size: 11px;
        font-weight: 400;
      }
    }
`;