import React from 'react';
import ResponsiveWrapper from '../responsive-wrapper/responsive-wrapper';
import { scaleTime, scaleLinear, scaleOrdinal } from 'd3-scale';
import { extent } from 'd3-array';
import { stack, stackOrderDescending } from 'd3-shape';
import XYAxis from '../axis/XYAxis';
import StackArea from './stack-area';

const formatData = data => {
  let copyData = Object.assign({}, data); // do not mutate original data

  copyData = Object.keys(copyData).map(key => {
    return Object.assign(
      {},
      { date: new Date(key, 0, 1) },
      { ...copyData[key] }
    );
  });
  copyData.forEach(d => {
    let sum = 0;
    Object.keys(d).forEach(key => {
      if (key !== 'date') sum += parseFloat(d[key]) ? parseFloat(d[key]) : 0;
    });
    Object.keys(d).forEach(key => {
      if (key !== 'date') d[key] = d[key] / sum;
    });
  });
  const keys = Object.keys(copyData[0]).slice(1);

  return {
    keys,
    data: copyData
  };
};

class StackAreaChart extends React.Component {
  render() {
    const { parentWidth } = this.props;

    const { keys, data } = formatData(this.props.data);

    const svgDimensions = {
      width: parentWidth,
      height: 230
    };
    const margin = {
      top: 10,
      right: 20,
      bottom: 20,
      left: 40
    };
    const width = svgDimensions.width - margin.left - margin.right;
    const height = svgDimensions.height - margin.top - margin.bottom;

    const x = scaleTime().range([0, width]);
    const y = scaleLinear().range([height, 0]);
    const z = scaleOrdinal([
      '#fccde5',
      '#d9d9d9',
      '#dcdc4a',
      '#bebada',
      '#fb8072',
      '#80b1d3',
      '#fdb462',
      '#b3de69',
      '#8dd3c7'
    ]);
    x.domain(extent(data, d => d.date));
    z.domain(keys);

    const newStack = stack();

    newStack.keys(keys).order(stackOrderDescending);

    const areaSettings = {
      scales: { x, y, z },
      data,
      newStack
    };

    return (
      <svg width={svgDimensions.width} height={svgDimensions.height}>
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <XYAxis scales={{ x, y }} dimensions={{ height, width }} />
          <StackArea
            countryName={this.props.countryName}
            nonFormatedData={this.props.data}
            {...areaSettings}
          />
        </g>
      </svg>
    );
  }
}

export default ResponsiveWrapper(StackAreaChart);
