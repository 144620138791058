import Api from '../api';

class Attribute {

	async list(filter) {
		try {
			let res = await Api.get('entity/attributes', {params:filter});

			return res;
		} catch (error) {
			console.log(error);
		}
	}
}

export default new Attribute();
