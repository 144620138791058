import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Progress = ({ percent, color }) => {
  return (
    <Content>
      <Fill percent={percent} color={color} />
    </Content>
  );
};

Progress.propTypes = {
  percent: PropTypes.number,
  color: PropTypes.string
};

const Content = styled.div`
  background-color: rgb(217, 217, 217);
  border-radius: 4px;
  height: 8px;
  width: 100%;
`;

const Fill = styled.div`
  background-color: rgb(217, 217, 217);
  border-radius: 4px;
  height: 8px;
  background-color: ${props => props.color};
  width: ${props => (props.percent ? props.percent + '%' : 0)};
  transition: width 200ms linear;
`;

export default Progress;
