import styled from 'styled-components';
const Dropdown = styled.span`
  position: relative;
`;

const DropdownMenu = styled.ul`
  padding-top: 6px;
  position: absolute;
  top: 100%;
  /* left: 0; */
  right: 0;
  width: auto;
  min-width: 184px;
  z-index: 1000;
  display: none;
  /* opacity: 0;
  visibility: hidden; */
  transform: translateY(-4px);
  transition: all 300ms ease-in-out;

  .dropdown-list {
    background-color: #fff;
    border-radius: 4px;
    /* box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.15); */
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.15);

    &:before {
      // content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: #ffffff;
      transform: rotate(45deg);
      position: absolute;
      top: 17px;
      left: 17px;
    }

    .dropdown-list-item {
      display: block;
      padding: 10px 20px;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      transition: all 0.2s ease-in;
      line-height: 1.4;
      cursor: pointer;
      margin: 0;
      &:not(:last-child) {
        border-bottom: 1px solid #e4eaeb;
      }
      &:hover {
        color: ${props => props.theme.primary.primary};
        cursor: pointer;
      }
      > a {
        color: ${props => props.theme.primary.dark};
        white-space: nowrap;
        &.active {
          color: ${props => props.theme.primary.primary};
        }

        &:hover {
          color: ${props => props.theme.primary.primary};
          cursor: pointer;
        }
      }
    }

    .dropdown-list-item-link {
      display: flex;
      white-space: nowrap;
    }

    &.limit-height{
      max-height: 300px;
      overflow-y: auto;
    }
  }
`;

const transitionStyles = {
  entered: {
    // opacity: 1,
    // visibility: 'visible',
    display: 'block',
    transform: 'translateY(0)'
  }
};

export { Dropdown, DropdownMenu, transitionStyles };
