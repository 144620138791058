export function fetching() {
	return {
		type: 'SOURCE_ATTRIBUTE_FETCHING',
	};
}

export function fetched(payload) {
	return {
		type: 'SOURCE_ATTRIBUTE_FETCHED',
		payload
	};
}

export function error(payload) {
	return {
		type: 'SOURCE_ATTRIBUTE_ERROR',
		payload
	};
}