export function fetching() {
  return {
    type: 'PROJECT_FETCHING'
  };
}

export function fetched(payload) {
  return {
    type: 'PROJECT_FETCHED',
    payload
  };
}

export function error(payload) {
  return {
    type: 'PROJECT_ERROR',
    payload
  };
}

export function setFilter(payload) {
  return {
    type: 'PROJECT_SET_FILTER',
    payload
  };
}

export function bubbleDataFetching() {
  return {
    type: 'BUBBLE_DATA_FETCHING'
  };
}

export function bubbleDataFetched(payload) {
  return {
    type: 'BUBBLE_DATA_FETCHED',
    payload
  };
}

export function bubbleDataError(payload) {
  return {
    type: 'BUBBLE_DATA_ERROR',
    payload
  };
}

export function setSort(payload) {
  return {
    type: 'PROJECT_SET_SORT',
    payload
  };
}

export function setQuery(payload) {
  return {
    type: 'PROJECT_SET_QUERY',
    payload
  };
}

export function setCurrentPage(payload) {
  return {
    type: 'PROJECT_SET_CURRENT_PAGE',
    payload
  };
}

export function countryIncome(payload) {
  return {
    type: 'COUNTRY_INCOME',
    payload
  };
}

/******************** Visualization ******************************/
export function visualizationDataFetching() {
  return {
    type: 'VISUALIZATION_DATA_FETCHING',
  };
}
export function visualizationDataFetched(payload) {
  return {
    type: 'VISUALIZATION_DATA_FETCHED',
    payload
  };
}
export function visualizationDataError(payload) {
  return {
    type: 'VISUALIZATION_DATA_ERROR',
    payload
  };
}
export function mapData(payload) {
  return {
    type: 'MAP_DATA',
    payload
  };
}
export function selectedTotalPayments(payload) {
  return {
    type: 'SELECTED_TOTAL_PAYMENTS',
    payload
  };
}
export function totalPaymentsData(payload) {
  return {
    type: 'TOTAL_PAYMENTS_DATA',
    payload
  };
}
export function selectedTopProjects(payload) {
  return {
    type: 'SELECTED_TOP_PROJECTS',
    payload,
  };
}
export function yearsFilter(payload) {
  return {
    type: 'YEARS_FILTER',
    payload,
  };
}
export function markersData(payload) {
  return {
    type: 'MARKERS_DATA',
    payload
  };
}
export function selectedCountry(payload) {
  return {
    type: 'SELECTED_COUNTRY',
    payload,
  };
}
export function mapTooltipData(payload) {
  return {
    type: 'MAP_TOOLTIP_DATA',
    payload,
  };
}
export function markerTooltipData(payload) {
  return {
    type: 'MARKER_TOOLTIP_DATA',
    payload,
  };
}
export function barChartTooltipData(payload) {
  return {
    type: 'BAR_CHART_TOOLTIP_DATA',
    payload,
  };
}