import Api from '../api';

class Entity {

	async paginate(currentPage, perPage, filter, sortBy, order, download) {

		let params = { currentPage, perPage, ...filter, sortBy, order, download };

		return await Api.get('entities', { params });
	}
}

export default new Entity();
