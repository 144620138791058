import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as CountryAction from '../../../actions/country/index';
import CountryService from '../../../services/country/index';
import styled from 'styled-components';
import 'react-vis/dist/style.css';
import NumberFormatter from '../../../utils/numberFormatter';
import * as d3 from 'd3';
import Colours from '../../../theme/colours';
import { NoData, Loader } from '../../../components/layouts/';

const { type } = Colours;

let margin = {
  top: 0,
  right: 40,
  bottom: 0,
  left: 30
};
let svg = '',
  g = '',
  width = 200,
  height = 300;
const paymentTypeColors = {
  Bonuses: type.bonus,
  Dividends: type.dividend,
  Fees: type.fees,
  Other: type.other,
  'Payments for infrastructure improvements': type.infrastructure,
  'Production entitlements': type.entitle,
  Royalties: type.royalty,
  Taxes: type.tax
};

class BarCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      projectCountryBarData: [],
      entityCountryBarData: [],     
      defaultBarChartData: [],
      component: 'project',
      barWidth: 400,
      sankeyClicked: false,
      clickedData: [],
      hoveredData: [],
      yearFilter: [],
      filter: {
        years: ['all']
      },
      currentNode: '',
      countryName: '',
      countryCode: ''
    };
    this.calculateBarWidth = this.calculateBarWidth.bind(this);
    this.handleSankeyHovered = this.handleSankeyHovered.bind(this);
    this.handleSankeyClicked = this.handleSankeyClicked.bind(this);
  }

  componentWillMount() {
    let { countryName, selectedNode } = this.props;
    let component = selectedNode.component;
    let filterBy = selectedNode.filterBy;
    this.props.getBarChartData(countryName, component, filterBy);
    this.setState({
      yearFilter: filterBy.years,
      countryName: countryName
    });
  }

  componentWillReceiveProps(newProps) {
    let { selectedNode } = newProps;
    let component = selectedNode.component;
    let filterBy = selectedNode.filterBy;

    if (this.state.projectCountryBarData.length === 0 && newProps.barFetched) {
      let barChartData = newProps.barChartData;
      this.setState({
        projectCountryBarData: barChartData.projectPaymentTypes,
        entityCountryBarData: barChartData.entityPaymentTypes,
        defaultBarChartData: this.filterBarchartData(
          component === 'project'
            ? newProps.barChartData.projectPaymentTypes
            : newProps.barChartData.entityPaymentTypes,
          {
            years: filterBy.years
          }
        )
      });
    } else if (this.props.countryName !== newProps.countryName) {
      let { countryName, selectedNode } = newProps;
      let component = selectedNode.component;
      let filterBy = selectedNode.filterBy;
      this.props.getBarChartData(countryName, component, filterBy);
      this.setState({
        yearFilter: filterBy.years,
        countryName: countryName,
        projectCountryBarData: []
      });
    }

    this.setState({
      sankeyClicked: selectedNode.sankeyClicked.clicked
    });

    if (this.state.yearFilter !== filterBy.years) {
      this.setState({
        yearFilter: filterBy.years,
        defaultBarChartData: this.filterBarchartData(
          component === 'project'
            ? newProps.barChartData.projectPaymentTypes
            : newProps.barChartData.entityPaymentTypes,
          {
            years: filterBy.years
          }
        )
      });
    }

    if (component !== this.state.component) {
      this.setState({
        defaultBarChartData: this.filterBarchartData(
          component === 'project'
            ? newProps.barChartData.projectPaymentTypes
            : newProps.barChartData.entityPaymentTypes,
          {
            years: filterBy.years
          }
        ),
        component: component
      });
    }

    this.handleSankeyHovered(newProps);
    this.handleSankeyClicked(newProps, selectedNode.sankeyClicked.hovered);

    this.calculateBarWidth();
  }

  handleSankeyHovered(data) {
    let { selectedNode } = data;
    let component = selectedNode.component;
    let filterBy = selectedNode.filterBy;

    if (
      (filterBy.repOrg || filterBy.endPoint) &&
      selectedNode.sankeyClicked.hovered
    ) {
      let clickedData = this.filterBarchartData(
        component === 'project'
          ? data.barChartData.projectPaymentTypes
          : data.barChartData.entityPaymentTypes,
        filterBy
      );
      this.setState({
        clickedData: clickedData
      });
    } else if (
      (!filterBy.repOrg || !filterBy.endPoint) &&
      !this.state.sankeyClicked
    ) {
      this.setState({
        clickedData: []
      });
    } else if (
      !selectedNode.sankeyClicked.clicked &&
      !selectedNode.sankeyClicked.hovered
    ) {
      this.setState({
        clickedData: [],
        currentNode: ''
      });
    }
  }
  handleSankeyClicked(data, hovered) {
    let { selectedNode } = data;
    let component = selectedNode.component;
    let filterBy = selectedNode.filterBy;

    if (selectedNode.sankeyClicked.clicked) {
      let currentNode = selectedNode.sankeyClicked.currentNode;

      if (!this.state.currentNode || this.state.currentNode !== currentNode) {
        this.setState({
          currentNode: currentNode,
          filter: filterBy
        });
      }

      if (
        selectedNode.sankeyClicked.nextOther &&
        this.state.filter !== filterBy
      ) {
        this.setState({
          filter: filterBy
        });
      }

      setImmediate(() => {
        if (!hovered) {
          this.setState({
            clickedData: this.filterBarchartData(
              component === 'project'
                ? data.barChartData.projectPaymentTypes
                : data.barChartData.entityPaymentTypes,
              this.state.filter
            )
          });
        }
        this.forceUpdate();
      });
    }
  }

  componentDidUpdate() {
    this._renderBarChart();
  }

  componentDidMount() {
    this.calculateBarWidth();
    window.addEventListener('resize', this.calculateBarWidth);
    let margin = {
      top: 15,
      right: 30,
      bottom: 15,
      left: 90
    };

    width = this.state.barWidth - margin.left - margin.right;
    height = 300 - margin.top - margin.bottom;
    svg = d3.select(this.barParent).append('svg');

    g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateBarWidth, false);
  }

  filterBarchartData(paymentTypes, filterBy) {
    if (filterBy.countryCode) {
      this.setState({ countryCode: filterBy.countryCode[0].code });
    }

    let final = [
      { x: 0, y: 'Bonuses' },
      { x: 0, y: 'Dividends' },
      { x: 0, y: 'Fees' },
      { x: 0, y: 'Other' },
      { x: 0, y: 'Payments for infrastructure improvements' },
      { x: 0, y: 'Production entitlements' },
      { x: 0, y: 'Royalties' },
      { x: 0, y: 'Taxes' }
    ];

    if (filterBy.years.indexOf('all') === -1) {
      paymentTypes = paymentTypes.filter(d =>
        filterBy.years.includes(d._id.startDate)
      );
    }

    if (filterBy.repOrg && filterBy.repOrg !== 'Other Companies') {
      paymentTypes = paymentTypes.filter(
        d => d._id.reportingCompany === filterBy.repOrg
      );
    } else if (filterBy.repOrg && filterBy.repOrg === 'Other Companies') {
      paymentTypes = paymentTypes.filter(d =>
        filterBy.otherRep.includes(d._id.reportingCompany + ' (company)')
      );
    }
    let displayTitle =
      this.state.component === 'project' ? 'projects' : 'agencies';

    if (filterBy.endPoint && filterBy.endPoint !== `Other ${displayTitle}`) {
      paymentTypes = paymentTypes.filter(
        d => d._id[this.state.component + 'Name'] === filterBy.endPoint
      );
    } else if (
      filterBy.endPoint &&
      filterBy.endPoint === `Other ${displayTitle}`
    ) {
      paymentTypes = paymentTypes.filter(d =>
        filterBy.others.includes(
          d._id[this.state.component + 'Name'] + ' (project)'
        )
      );
    }

    if (this.state.filter.endPoint && this.state.currentNode) {
      paymentTypes =
        this.state.currentNode === `Other ${displayTitle}`
          ? paymentTypes.filter(d =>
              this.state.filter.others.includes(
                d._id[this.state.component + 'Name'] + ' (project)'
              )
            )
          : paymentTypes.filter(
              d =>
                d._id[this.state.component + 'Name'] === this.state.currentNode
            );
    } else if (this.state.filter.repOrg && this.state.currentNode) {
      paymentTypes =
        this.state.currentNode === 'Other Companies'
          ? paymentTypes.filter(d =>
              this.state.filter.otherRep.includes(
                d._id.reportingCompany + ' (company)'
              )
            )
          : paymentTypes.filter(
              d => d._id.reportingCompany === this.state.currentNode
            );
    }

    let temp = [];

    paymentTypes.forEach(link => {
      let key = link._id.paymentType;

      if (temp[key]) {
        temp[key] = link.sum + temp[key];
      } else {
        temp[key] = link.sum;
      }
    });

    paymentTypes = Object.keys(temp).map(d => {
      return {
        _id: d,
        sum: temp[d]
      };
    });

    paymentTypes.forEach(d => {
      let index = 0;
      var found = final.some(el => {
        index = final.indexOf(el);
        return el.y === d._id;
      });

      if (found) {
        final[index].x = d.sum;
        final[index].y =
          d._id === 'Payments for infrastructure improvements'
            ? 'Infrastructure improvements'
            : d._id;
        final[index].color = paymentTypeColors[d._id];
      }
    });

    return final;
  }

  calculateBarWidth() {
    if (!this.barParent) return null; 
    const { barWidth } = this.state;
    let currentWidth = this.barParent.getBoundingClientRect().width;

    const shouldResize = barWidth !== currentWidth;

    if (shouldResize) {
      this.setState({
        barWidth: currentWidth
      });
    }
  }

  getCountryImage() {
    const code = this.state.countryCode;
    return code.length
      ? 'https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/' +
          code.toLowerCase() +
          '.png'
      : '';
  }

  _renderBarChart() {
    let data = this.state.defaultBarChartData;
      // self = this;
    data = data.filter(d => d.x > 0);
    if (data.length === 0) {
      // g.select('.x.axis').hide();
    }

    // let tooltip = d3
    //   .select('body')
    //   .append('div')
    //   .attr('class', 'tooltip');

    // function onMouseOver(d) {
    //   const html = `
    // 	<div>
    // 		<table class='table'>
    //       <tbody>
    //       <tr>
    //         <th>Pay. Type</th>
    //         <th class='title'>${d.y}</th>
    //       </tr>
    //         <tr>
    // 					<td>Amount</td>
    // 					<td>USD ${NumberFormatter(d.x)}</td>
    //         </tr>
    //         <tr>
    //           <td>Country</td>
    //           <td>${self.state.countryName}</td>
    //         </tr>
    // 			</tbody>
    // 		</table>
    // 	</div>
    // 	`;

    //   tooltip
    //     .style('left', Math.max(0, d3.event.pageX - 150) + 'px')
    //     .style('top', d3.event.pageY + 10 + 'px')
    //     .style('display', 'inline-block')
    //     .html(html);
    // }
    let data2 = data.length === 0 ? data : this.state.clickedData;

    if (data2.length > 0) {
      data2 = this.state.clickedData.map(d => {
        d.y =
          d.y === 'Payments for infrastructure improvements'
            ? 'Infrastructure improvements'
            : d.y;
        d.label = NumberFormatter(d.x);
        d.style = {
          fontSize: 11,
          fill: '#535e61b3'
        };
        d.xOffset = 5;
        return d;
      });
    }

    let data1 =
      data.length === 0
        ? data
        : this.state.defaultBarChartData.map(d => {
            d.y =
              d.y === 'Payments for infrastructure improvements'
                ? 'Infrastructure improvements'
                : d.y;
            return d;
          });

    let originalData = data2;
    data2 = data2.length ? data2 : data1;

    data1.sort((a, b) => {
      return a.x - b.x;
    });

    data2 = data1.map(d => {
      return data2.find(e => e.y === d.y);
    });

    width = this.state.barWidth - margin.left - margin.right;
    // height = 300 - margin.top - margin.bottom;
    svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + 40);

    let x = d3.scaleLinear().range([0, width - margin.left]);
    let y = d3.scaleBand().range([height, 0]);

    x.domain([
      0,
      d3.max(data1, function(d) {
        return d.x;
      })
    ]);
    // x.domain([0, 600000000]);
    y.domain(
      data1.map(function(d) {
        return d.y;
      })
    ).padding(0);
    // let xAxis = d3
    //   .axisBottom(x)
    //   .ticks(5)
    //   .tickFormat(function(d) {
    //     return NumberFormatter(d);
    //   });

    // g.select('.x.axis').remove();
    g.select('.y.axis').remove();

    // g.append('g')
    //   .attr('class', 'x axis')
    //   .attr('transform', 'translate(0,' + height + ')')
    //   .call(xAxis);

    g.append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(10)')
      .call(d3.axisLeft(y))
      .selectAll('.tick text')
      .call(wrap);

    var bar = g.selectAll('.bar').data(data1);

    bar
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', 0)
      .attr('height', 30)
      .attr('y', function(d) {
        return y(d.y);
      })
      .attr('width', function(d) {
        return parseFloat(x(d.x)) <= 0 ? 0 : parseFloat(x(d.x));
      })
      .style('fill', '#b0b6be')
      .style('opacity', 0.5);

    let dataset = data2;

    let bar1 = g.selectAll('.bar1').data(dataset);
    // new data:
    bar1
      .enter()
      .append('rect')
      .attr('class', 'bar1')
      .attr('x', 0)
      .attr('height', 30)
      .attr('y', function(d) {
        return y(d.y);
      })
      .attr('width', function(d) {
        return parseFloat(x(d.x)) <= 0 ? 0 : parseFloat(x(d.x));
      })
      .style('fill', '#b0b6be')
      .style('opacity', 1);
    // .on('mousemove', function(d) {
    //   d3.select(this).style('fill', '#8f939b');
    //   onMouseOver(d);
    // })
    //   .on('mousemove', onMouseOver)
    // .on('mouseout', function() {
    //   tooltip.style('display', 'none');
    //   d3.select(this).style('fill', '#b0b6be');
    // });

    let number = g.selectAll('.text').data(dataset);
    number
      .enter()
      .append('text')
      .attr('class', 'text')
      .attr('x', d => {
        let label = d.label || '';
        if (x(d.x) < 50) {
          return x(d.x) + 3;
        }
        return x(d.x) - 42 - label.replace('.', '').length;
      })
      .attr('height', 30)
      .attr('y', function(d) {
        return y(d.y) + 20;
      })
      .text(d => {
        return NumberFormatter(d.x);
      });

    let bar2 = g.selectAll('.bar2').data(dataset);

    bar2
      .enter()
      .append('rect')
      .attr('class', 'bar2')
      .attr('x', function(d) {
        return x(d.x) <= 0 ? 0 : x(d.x);
      })
      .attr('height', 30)
      .attr('y', function(d) {
        return y(d.y);
      })
      .attr('width', function(d) {
        return originalData.length ? (x(d.x) - 3 <= 0 ? 0 : 3) : 0;
      })
      .style('fill', '#959AA1')
      .style('opacity', 1);

    // removed data:
    bar.exit().remove();
    bar1.exit().remove();
    bar2.exit().remove();
    number.exit().remove();
  
    // // updated data:
    number
    .transition()
    .ease(d3.easeLinear)
    .duration(600)
    .attr('x',  d => {
      let label = d.label || '';
      if (x(d.x) < 50) {
        return x(d.x) + 3;
      }
      return x(d.x) - 42 - label.length;
    })
    .text(d => {
      // console.log(d);
      return NumberFormatter(d.x);
    });

    bar
      .transition()
      .ease(d3.easeLinear)
      .duration(600)
      .attr('x', 0)
      .attr('y', function(d) {
        return y(d.y);
      })
      .attr('width', function(d) {
        let w = parseFloat(x(d.x));
        return w <= 0 ? 0 : w;
      })
      .attr('height', 30);
    bar1
      .transition()
      .ease(d3.easeLinear)
      .duration(600)
      .attr('x', 0)
      .attr('y', function(d) {
        return y(d.y);
      })
      .attr('width', function(d) {
        let w = parseFloat(x(d.x));
        return w <= 0 ? 0 : w;
      })
      .attr('height', 30);

    bar2
      .attr('width', function(d) {
        return d.x <= 0 ? 0 : 3;
      })
      .transition()
      .ease(d3.easeLinear)
      .duration(600)
      .attr('x', function(d) {
        return x(d.x) - 3 <= 0 ? 0 : x(d.x) - 3;
      })
      .attr('width', function(d) {
        return originalData.length ? (x(d.x) - 3 <= 0 ? 0 : 3) : 0;
      })
      .attr('y', function(d) {
        return y(d.y);
      })

      .attr('height', 30);

    function wrap(text) {
      text.each(function() {
        var text = d3.select(this),
          words = text.text().split(' '),
          h = words.length > 1 ? '0' : '0.2em',
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy')),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', -10)
            .attr('y', y)
            .attr('dy', h);

        words.map((word, i) => {
          dy = i === 0 ? (h === 0 ? '-0.5em' : h) : '0.8em';
          tspan = text
            .append('tspan')
            .attr('x', -20)
            .attr('y', y)
            .attr('dy', dy)
            .text(word);
          return true;
        });
      });
    }
  }
  renderNoData() {
    if (
      this.state.projectCountryBarData.length === 0 &&
      this.props.barChartData.length !== 0 && this.props.barFetched && this.props.barFetching === false
    )
      return <NoData countryName={this.props.countryName} />;
  }

  render() {
    return (
      <BarCardWrap>
        {this.renderNoData()}
        {this.props.barFetching === true && (<Loader/>)}
        <div
          ref={el => {
            this.barParent = el;
          }}
          className="barchart-svg"
          style={{visibility: this.props.barFetching ? 'hidden' : 'visible'}}
        />
      </BarCardWrap>
    );
  }
}

const mapStateToProps = state => ({
  barChartData: state.country.barChartData,
  barFetched: state.country.barFetched,
  barFetching: state.country.barFetching,
  selectedNode: state.country.selectedValue
});

const mapDispatchToProps = dispatch => ({
  getBarChartData: (countryName, component, filterBy) => {
    dispatch(CountryAction.barChartFetching());

    CountryService.getBarChartData(countryName, component, filterBy)
      .then(response => {
        if (response.status) {
          dispatch(CountryAction.barChartFetched(response.body));
        } else {
          dispatch(CountryAction.barChartError(response.body));
        }
      })
      .catch(error => {
        dispatch(CountryAction.barChartError(error));
      });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarCard);

const BarCardWrap = styled.div`
  .bar {
    fill: #6f257f;
    transform: translateX(0);
  }

  .bar1 {
    fill: #6f257f;
    transform: translateX(0);
  }

  .toolTip {
    position: absolute;
    display: none;
    min-width: 80px;
    height: auto;
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #6f257f;
    padding: 14px;
    text-align: center;
  }
`;
