import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading } from '../../../components/styled/ui/';
import { flex } from '../../../components/styled/utils/';
import { Transition } from 'react-transition-group';
import CountryService from '../../../services/country/index';
import * as CountryAction from '../../../actions/country/index';
import {withRouter, Link} from 'react-router-dom';
// import iso2toiso3 from '../../../helpers/iso2toiso3';

class CountrySwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredCountries: [],
      countrySearch: '',
      isOpen: false
    };
    this.imageBaseURL =
      'https://raw.githubusercontent.com/younginnovations/country-flags/master/png250px/';
    this.countryChanged = this.countryChanged.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  getCountryImage(countryCode) {
    return this.imageBaseURL + countryCode.toLowerCase() + '.png';
  }

  componentWillMount() {
    this.props.getCountryList();
  }

  componentDidUpdate(nextProps) {
    if (this.state.filteredCountries.length === 0 && this.state.isOpen){
      this.setState({filteredCountries: nextProps.countryList})
    }
  }

  componentWillReceiveProps(props) {
    if (
      !props.countryList
        .map(d => d.name.toLowerCase())
        .includes(props.countryName.toLowerCase())
    ) {
      props.history.push('/404', {
        keyword: props.countryName,
        backUrl: '/country-profiles',
        backText: 'Country Profile',
        name: 'Country'
      });
    } else {
      this.setState({ filteredCountries: props.countryList });
    }
  }

  countryChanged(e) {
    //accept element and string
    let value = e.target.value;
    this.setState({ countrySearch: value });
    let countries = this.props.countryList;

    //filter value
    let filteredCountries = countries.filter(country => {
      let exp = new RegExp(value, 'i');
      return exp.test(country.name);
    });

    this.setState({ filteredCountries });
  }

  handleDropdownMenu(type) {
    this.setState({
      isOpen: type==='enter' ? true : false
    });
  }

  render() {
    const { countryName } = this.props;
    let countryList = this.state.filteredCountries;
    countryList = countryList.filter(d => !d.name.includes('unclear'));
    const currentCountry = this.props.countryList.filter(
      c => c.name === countryName
    );
    const currentCountryImage = currentCountry.length
      ? this.getCountryImage(currentCountry[0].code)
      : '';

      const transitionStyles = {
      entered: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0)'
      }
    };

    return (
      <Dropdown
      onMouseEnter={() => this.handleDropdownMenu('enter')}
      onMouseLeave={() => this.handleDropdownMenu('leave')}
      >
        <DropdownTitle isOpen={this.state.isOpen}>
          <img src={currentCountryImage} alt={countryName} className="image" />
          <Heading.h2 className="title">{countryName}</Heading.h2>
          <span className="icon" />
        </DropdownTitle>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <div className="search-wrap">
                <SearchInput
                  type="text"
                  autoComplete="none"
                  placeholder="country name..."
                  onChange={this.countryChanged}
                />
              </div>
                <ul className="list">
                  {countryList.map((country, i) => {
                    return (
                      <li key={i}>
                        <Link  onClick={() => this.setState({isOpen: false})} to={`/country/${country.code}`}>
                          <span>
                            <img
                              className="flag"
                              src={this.getCountryImage(country.code)}
                              alt=""
                            />
                          </span>

                          <span>{country.name}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

const mapStateToProps = state => ({ countryList: state.country.countryList });

const mapDispatchToProps = dispatch => ({
  getCountryList: () => {
    CountryService.getCountryList().then(response => {
      dispatch(CountryAction.countryListFetched(response.result));
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CountrySwitcher));

const Dropdown = styled.div`
  position: relative;
  padding: 12px 0;
  margin-right: 36px;

  @media screen and (max-width: 546px) {
    margin-bottom: 12px;
  }
`;

const DropdownTitle = styled.div`
  ${flex('row', null, 'center')};
  cursor: pointer;


  .image {
    background-color: #eee;
    border: 2px solid ${props => props.theme.primary.white};
    height: 28px;
    margin-right: 7px;
    text-indent: -9999px;
    width: 38px;
  }

  .title {
    color: ${props => props.theme.primary.white};
    display: inline;
    line-height: 1;
  }

  .icon{
    border-color: ${props =>
      props.isOpen
        ? 'transparent transparent #fff;'
        : '#fff transparent transparent;'}
      border-width: ${props => (props.isOpen ? '0 5px 5px' : '5px 5px 0')};
    border-style: solid;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
  }

`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  width: auto;
  min-width: 184px;
  z-index: 9999000;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transform: translateY(-4px);
  transition: all 300ms ease-in-out;
  width: 240px;

  .search-wrap {
    padding: 18px 8px 10px;
    background-color: ${props => props.theme.primary.white};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
  }

  .list {
    background-color: ${props => props.theme.primary.white};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 40px -8px;
    border-top-bottom-radius: 4px;
    border-top-bottom-radius: 4px;
    max-height: 239px;
    overflow: hidden;
    overflow-y: scroll;
    > li {
      color: rgb(32, 188, 251);
      border-bottom: 1px solid rgb(238, 238, 238);
      transition: all 0.1s ease-in-out;

      &:hover {
        background: ${props => props.theme.primary.primary};
        > a {
          color: ${props => props.theme.primary.primary};
        }
      }

      > a {
        display: flex;
        align-items: center;
        padding: 9px 20px;

        > span {
          &:nth-child(1) {
            flex: 0 0 24px;
            margin-right: 12px;

            img {
              height: 12px;
              width: auto;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
            }
          }
          &:nth-child(2) {
            color: ${props => props.theme.primary.dark};
            flex: 1;
            line-height: 1.4;
          }
        }
      }
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  background: ${props => props.theme.sec.lightGreen};
  border: 1px solid #d8e1e6;
  border-radius: 18px;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;
