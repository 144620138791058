import React from 'react';
import styled from 'styled-components';
import { getAppImages } from '../../../helpers';
// import paymentIcon from "../../../images/ic_payment.svg"

const { companySpriteIcon, paymentIcon } = getAppImages;
const Snapshot = ({ snapShotData }) => {
  return (
    <SnapshotWrapper id='country-stats'>
      <div className="snapshot__main">
        <div className="snapshot__main-container icon icon--company">
          <p className="snapshot__title">{snapShotData.totalCompanies}</p>
          <p className="snapshot__subtitle">Companies reporting payments</p>
        </div>
        <hr />
        <div className="snapshot__main-container icon icon--price">
          <p className="snapshot__title">${snapShotData.totalPayments}</p>
          <p className="snapshot__subtitle">
            Payments reported for <span>{snapShotData.maxYear}</span>
          </p>
          <span>(From {snapShotData.totalCompanyMaxYear} companies)</span>
        </div>
      </div>
      <div className="snapshot__entity">
        <div>
          <p className="snapshot__entity-heading">
            Projects reporting payments
          </p>
          <div className="entity__items">
            {snapShotData.projectPayments['Oil & Gas'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.projectPayments['Oil & Gas']}
                </p>
                <p className="snapshot__subtitle">oil & gas</p>
              </div>
            )}
            {snapShotData.projectPayments['Mining'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.projectPayments['Mining']}
                </p>
                <p className="snapshot__subtitle">mining</p>
              </div>
            )}
            {snapShotData.projectPayments['Corporate'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.projectPayments['Corporate']}
                </p>
                <p className="snapshot__subtitle">corporate</p>
              </div>
            )}
            {(snapShotData.projectPayments['Other'] ||
              snapShotData.projectPayments['Unidentifiable'] ||
              snapShotData.projectPayments['undefined']) && (
              <div className="entity__item">
                <p className="entity__title">
                  {(snapShotData.projectPayments['Other'] || 0) +
                    (snapShotData.projectPayments['Unidentifiable'] || 0) +
                    (snapShotData.projectPayments['Unidentifiable'] || 0)}
                </p>
                <p className="snapshot__subtitle">other</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="snapshot__entity-heading">
            Government entities receiving payments
          </p>
          <div className="entity__items">
            {snapShotData.entityPayments['National'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.entityPayments['National']}
                </p>
                <p className="snapshot__subtitle">national</p>
              </div>
            )}
            {snapShotData.entityPayments['Regional'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.entityPayments['Regional']}
                </p>
                <p className="snapshot__subtitle">regional</p>
              </div>
            )}
            {snapShotData.entityPayments['Local'] && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.entityPayments['Local']}
                </p>
                <p className="snapshot__subtitle">local</p>
              </div>
            )}
            {(snapShotData.entityPayments['Other'] ||
              snapShotData.entityPayments['undefined']) && (
              <div className="entity__item">
                <p className="entity__title">
                  {snapShotData.entityPayments['Other'] ||
                    0 + (snapShotData.entityPayments['undefined'] || 0)}
                </p>
                <p className="snapshot__subtitle">other</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </SnapshotWrapper>
  );
};

export default Snapshot;

const SnapshotWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #333;

  @media (max-width: 767px) {
    flex-direction: column;
    /* margin-bottom: 50px; */
  }

  .snapshot {
    &__main {
      flex-basis: 230px;

      @media (max-width: 767px) {
        flex-basis: unset;
        margin-bottom: 40px;
        display: flex;
      }

      hr {
        border: 1px solid #eceaf1;
        margin: 26px 10px 26px 0;

        @media (max-width: 767px) {
          margin: 0 10px;
        }
      }
    }
    &__main-container {
      padding: 0 0 0 34px;

      @media (max-width: 767px) {
        padding: 0 0 0 30px;
      }

      span {
        font-size: 10px;
      }

      &.icon {
        position: relative;

        &:before {
          background-repeat: no-repeat;
          content: '';
          height: 24px;
          left: 0;
          position: absolute;
          top: 5px;
          width: 24px;
          background-position: 0px 0px;
        }

        &--company {
          &:before {
            background-image: url(${companySpriteIcon});
          }
        }

        &--price {
          &:before {
            background-image: url(${paymentIcon});
          }
        }
      }
    }
    &__title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__subtitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__entity {
      flex: 1;
      margin: 0 0 0 40px;

      @media (max-width: 767px) {
        margin: 0 0 0 14px;
      }

      > div {
        &:first-child {
          margin-bottom: 40px;
        }
      }
    }

    &__entity-heading {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 8px;
      line-height: 16px;
    }
  }

  .entity {
    &__items {
      display: flex;
      /* flex-wrap: wrap; */
    }

    &__item {
      margin-right: 20px;
      flex-basis: 25%;

      &:last-child {
        margin-right: 0;
      }
    }
    &__title {
      font-size: 24px;
      font-weight: bold;
      line-height: 34px;
    }
  }
`;
