import React from 'react';
import styled from 'styled-components';
import { Motion, spring } from 'react-motion';
import Colours from '../../theme/colours';
import { getAppImages } from '../../helpers';
import NoteIcon from '../../images/note-icon.svg';
const { primary } = Colours;
// const { NoteIcon, NoteIconWhite, NRGILogo3x } = getAppImages;
const { NoteIconWhite, NRGILogo3x } = getAppImages;

const NoteWrapper = styled.span`
	display: inline-block;
	vertical-align: middle;
	/* height: 16px;
	width: 12px; */
  height: 10px;
  width: 7px;
	/* position: relative; */
	float: right;
	margin-left: 4px;
	/* margin-right: 4px; */
    z-index: ${props => (props.active ? '1000' : '100')};

	/* @media (max-width: 767px){
		height: 46px;
		width: 100%;
		padding: 14px;
		float: none;
		margin-left: 0;
		margin-right: 0;
		border-bottom-left-radius: 4px;
		background-color: #fff;

		&:after{
			content: '';
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			top: 49px;
			left: 50%;
			transform: translateX(-50%);
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent ${props =>
        props.hasNotes ? primary.gray : '#535353'} transparent;
			border-style: solid;
			opacity: 0;
			transition: all 0s ease-in;
		}

		&:hover{
			color: ${primary.gray};

			&:after{
				opacity: 1;
				transition-duration: 0.22s;
			}
		}
	} */

	.note-icon{
		display: inline-block;
		vertical-align: top;
		/* height: 16px;
		width: 12px; */
    height: 10px;
		width: 7px;
		transition: all 0.2s ease-in-out;
		background: url('${NoteIcon}') no-repeat center center/contain;
		opacity: 0.9;
    /* pointer-events: none; */

		&:hover{
			opacity: 1;
			box-shadow: 0 1px 6px 0 rgba(0,0,0,0.5); 
		}
	}

    .label{
            display: none;
        /* font-size: 12px;
        font-weight: bold;
        margin-right: 4px;

        @media (min-width: 768px){
        } */
    }
`;

const NoteContent = styled.div`
  position: absolute;
  /* ${props =>
    props.position === 'top'
      ? `
			bottom: 0;
		`
      : `
			top: 0;
		`}  */
  top: ${props => props.posTop + 20}px;
  left: calc(${props => props.pos.left}px - 440px);
  width: 400px;
  max-width: calc(100vw - 100px);
  color: ${primary.white};
  font-weight: 300;
  padding: 16px;
  text-align: left;
  z-index: 10000000;

  @media (max-width: 767px) {
    top: 38px;
    right: auto;
    left: 0;
    max-width: 300px;
    padding: 16px 0;
    transform: translate(0);
    text-align: left;
    border-bottom-left-radius: 4px;
  }

  .note-title {
    font-size: 16px;
    margin-top: 0;
    opacity: 0.7;
    padding-left: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 3px;
    text-align: left;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  .note-text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    text-align: left;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  * {
    pointer-events: none;
  }
`;

const MainNote = styled.div`
	padding: 36px 36px 26px 36px;
	background: ${primary.gray};
	border-radius: ${props => (props.noNrgiNotes ? '4px' : '4px 4px 0 0')};
  	z-index: 99;
	.note-title{
		background: url('${NoteIconWhite}') no-repeat left center;
		background-size: 18px 24px;
		text-align: left;
	}
`;

const NRGINote = styled.div`
	padding: 26px 36px 36px;
	background: #535353;
	border-radius: ${props => (props.noNotes ? '4px' : '0 0 4px 4px')};

	.note-title{
		background: url('${NRGILogo3x}') no-repeat left center;
		background-size: 24px 20px;
		text-align: left;
	}
`;

export const Notes = ({
  notes,
  index,
  activeNote,
  position,
  nrgiNotes,
  onHide,
  onSetActive,
  pos,
  tablePos,
  extendedTablePos
}) => {
  let posTop = tablePos.top - extendedTablePos.top;
  // console.log(tablePos.top);
  // console.log(extendedTablePos.top);

  return (
    <NoteWrapper
      notes={notes}
      onMouseEnter={e => onSetActive(e, index)}
      onMouseLeave={onHide}
      hasNotes={notes !== 'NA' ? true : false}
      active={activeNote === index}
    >
      <span className="label">NOTE</span>
      <span className="note-icon" />
      {activeNote === index && (
        <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
          {newStyle => {
            return (
              <NoteContent
                className="note-content"
                style={newStyle}
                position={position}
                pos={pos}
                posTop={posTop}
              >
                {notes[0] !== null &&
                  notes !== undefined &&
                  notes !== 'NA' &&
                  notes && (
                    <MainNote
                      noNrgiNotes={
                        nrgiNotes === 'NA' || nrgiNotes === undefined
                          ? true
                          : false
                      }
                    >
                      <p className="note-title">
                        Payment notes from the report
                      </p>
                      <p className="note-text">{notes}</p>
                    </MainNote>
                  )}
                {nrgiNotes[0] !== null && nrgiNotes !== undefined && nrgiNotes && (
                  <NRGINote
                    noNotes={
                      notes === 'NA' || notes === undefined ? true : false
                    }
                  >
                    <p className="note-title">Comment by NRGI</p>
                    <p className="note-text">{nrgiNotes}</p>
                  </NRGINote>
                )}
              </NoteContent>
            );
          }}
        </Motion>
      )}
    </NoteWrapper>
  );
};
