export function fetching() {
  return {
    type: 'COMPANY_FETCHING'
  };
}

export function fetched(payload) {
	return {
		type: 'COMPANY_FETCHED',
		payload
	};
}

export function companyProfileFetched(payload) {
	return {
		type: 'COMPANY_PROFILE_FETCHED',
		payload
	};
}

export function companyTableFetching() {
  return {
    type: 'COMPANY_TABLE_FETCHING'
  };
}

export function companyTableFetched(payload) {
  return {
    type: 'COMPANY_TABLE_FETCHED',
    payload
  };
}