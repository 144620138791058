import React from 'react';
import { connect } from 'react-redux';
import { scaleThreshold } from 'd3-scale';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { select } from 'd3-selection';
import * as projectAction from '../../actions/project/index';
import iso3Toiso2 from '../../helpers/iso3toiso2';
import iso2Toiso3 from '../../helpers/iso2toiso3';
import iso2Country from '../../helpers/iso2-countries-code';
import * as ProjectActions from '../../actions/project/index';
import numberFormatter from '../../utils/numberFormatter';

// convert iso3 id to respective country name
const getCountryName = id => {
  const iso2Code = iso3Toiso2[id];
  return iso2Country[iso2Code];
};

const getIso2code = id => {
  return iso3Toiso2[id];
};

let debounceCountryName;

class CountriesLayer extends React.Component {
  constructor() {
    super();
    this.state = {
      colorScale: scaleThreshold().range([
        '#8CC1D9',
        '#67A9CF',
        '#3690BF',
        '#1D8A76',
        '#006C59',
        '#035945'
      ]),
      mapData: [],
      topFiveMapData: []
    };
    this.id = null;
  }
  componentWillReceiveProps(nextProps) {
    this.handleSelectedTopProjects(nextProps);

    const mapData = this.getMapData(nextProps);
    const topFiveThresholdIndex = Math.round(0.05 * mapData.length);

    const maxPayment = mapData[topFiveThresholdIndex].payment;

    const oneFifthPayment = Math.floor(maxPayment / 5);
    const domain = [
      oneFifthPayment,
      2 * oneFifthPayment,
      3 * oneFifthPayment,
      4 * oneFifthPayment,
      5 * oneFifthPayment
    ];

    this.setState(({ colorScale }) => ({
      colorScale: colorScale.domain(domain),
      topFiveMapData: mapData.slice(0, topFiveThresholdIndex - 1),
      mapData
    }));

    // // dispatch mapData for legend
    this.props.dispatchMapData(mapData);
  }
  handleSelectedTopProjects({ visualizationData, selectedTopProjects }) {
    if (selectedTopProjects !== 'all') {
      let countries = visualizationData
        .filter(o => o.name === selectedTopProjects)
        .map(o => o.country);

      countries = [...new Set(countries)];

      let id = Object.keys(iso2Country).find(key => {
        return iso2Country[key] === countries[0];
      });
      id = Object.keys(iso3Toiso2).find(key => {
        return iso3Toiso2[key] === id;
      });

      select(`#${id}`).attr('class', 'countries-path active');
      this.id = id;
    } else {
      select(`#${this.id}`).attr('class', 'countries-path');
    }
  }
  getMapData({ visualizationData, selectedTotalPayments, yearsFilter }) {
    if (yearsFilter[0] !== 'all') {
      let dataByYears = [];
      yearsFilter.forEach(year => {
        const temp = visualizationData.filter(o => o.reportStartDate === year);
        dataByYears = [...temp, ...dataByYears];
      });
      visualizationData = dataByYears;
    }
    if (selectedTotalPayments !== 'all') {
      visualizationData = visualizationData.filter(
        o => o.paymentType === selectedTotalPayments
      );
    }

    const mapData = _(visualizationData)
      .groupBy('country')
      .map((d, countryName) => {
        const payment = _.sumBy(d, 'payment');
        const countryCode = d[0].countryCode;
        return {
          countryName,
          payment,
          countryCode
        };
      })
      .sort((a, b) => b.payment - a.payment)
      .value();

    return mapData;
  }
  render() {
    return (
      <g className="countries-layer" ref={node => (this.node = node)}>
        {this.props.countriesGeojson.features.map((d, i) => {
          return (
            <path
              className="countries-path"
              id={d.id}
              key={`key-${i}`}
              d={this.props.path(d)}
              fill={this.getcountriesColor(d)}
              onMouseEnter={e => this.onMouseEnter(e, d)}
              onClick={() => this.onClick(d)}
              onContextMenu = {e => this.onRightClick(e,d)}
              onMouseMove={e => this.onMouseMove(e, d)}
              onMouseOut={e => this.onMouseOut(e)}
            />
          );
        })}
      </g>
    );
  }
  getcountriesColor(d) {
    const { id } = d;
    const { mapData, topFiveMapData, colorScale } = this.state;
    const country = getCountryName(id);

    if (mapData.length === 0) return '#EFEFEF';

    const payment = mapData.find(d => d.countryName === country);
    const thresholdPayment = topFiveMapData[topFiveMapData.length - 1].payment;

    if (country && payment && payment.payment <= thresholdPayment) {
      return colorScale(payment.payment);
    }
    if (payment && payment.payment > thresholdPayment) {
      return '#035945';
    }
    return '#EFEFEF';
  }
  onMouseEnter(e, d) {
    const path = e.target;
    const { mouseEventDelay } = this.props;
    const { mapData } = this.state;

    select(path).attr('class', 'countries-path active');
    const country = getCountryName(d.id);

    const matchedData = mapData.find(d => d.countryName === country);

    if (matchedData) {
      select(path).attr('cursor', 'pointer');
    }

    clearTimeout(debounceCountryName);
    debounceCountryName = setTimeout(() => {
      this.props.dispatchselectedCountry(country);
    }, mouseEventDelay.onCountrySelect);
  }

  onClick(d) {
    const { mapData } = this.state;
    // const country = getCountryName(d.id);
    // for iso code changes here.
    const country = getIso2code(d.id)

    const matchedData = mapData.find(d => d.countryCode === country);

    if (matchedData) {
      this.props.history.push(`country/${country}`);
      this.props.dispatchMapTooltipData({
        shouldShowTooltip: false
      });
    }
  }

  onRightClick(e,d) {
    const { mapData } = this.state;
    // const country = getCountryName(d.id);
    const country = getIso2code(d.id)
    const matchedData = mapData.find(d => d.countryCode === country);
    if (matchedData) {
      window.open(`country/${country}`, '_blank');   
      e.preventDefault(); 
    }
  }
  

  onMouseOut(e) {
    select(e.target).attr('class', 'countries-path');
    this.props.dispatchMapTooltipData({
      shouldShowTooltip: false
    });
  }
  onMouseMove(e, d) {
    const { mapData } = this.state;
    const country = getCountryName(d.id);
    let amount;

    const matchedData = mapData.find(d => d.countryName === country);

    if (matchedData) {
      amount = numberFormatter(matchedData.payment);
    } else {
      amount = null;
    }

    let { x, y } = document
      .getElementById('project-payment')
      .getBoundingClientRect();
    x = e.clientX - x;
    y = e.clientY - y;

    this.props.dispatchMapTooltipData({
      shouldShowTooltip: true,
      tooltipX: x,
      tooltipY: y,
      amount,
      country
    });
  }
}

const mapStateToProps = state => {
  return {
    visualizationData: state.project.visualizationData,
    selectedTotalPayments: state.project.selectedTotalPayments,
    selectedTopProjects: state.project.selectedTopProjects,
    yearsFilter: state.project.yearsFilter
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatchselectedCountry: country => {
      dispatch(ProjectActions.selectedCountry(country));
    },
    dispatchMapTooltipData: mapTooltipData => {
      dispatch(projectAction.mapTooltipData(mapTooltipData));
    },
    dispatchMapData: mapData => {
      dispatch(ProjectActions.mapData(mapData));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CountriesLayer)
);
