import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { Heading, Text, Container } from '../../components/styled/ui/';
import { flex, media } from '../../components/styled/utils/';
import { getAppImages } from "../../helpers";
const {NRGILogo} = getAppImages;

export const Footer = ({ footerClassName }) => (
  <SiteFooter className={footerClassName}>
    <Container>
      <div className="main-footer">
        <div className="footer-left">
          <div className="nrgi-logo">
            <a href="https://resourcegovernance.org/">
              <img src={NRGILogo} alt="NRGI Logo" width="142" height="55"/>
            </a>
          </div>
          <div className="footer-subscribe">
            <Link to="/subscribe" className="btn-subscribe">
              Setup alerts
            </Link>
          </div>
        </div>
        <div className="footer-text">
          <Text size={12} light>
            This website presents payment data that is scraped from mandatory
            disclosure reports along with additional data on extractive sector
            projects, government agencies and companies. We cannot guarantee the
            completeness or accuracy of this data and users are advised to check
            against original sources before making serious decisions based on
            the data presented here.
          </Text>
        </div>

        <div className="footer-end">
          <div className="footer-email">
            <Text size={12} light>
              Report errors or omissions to
            </Text>
            <a href="mailto:admin@resourceprojects.org">
              admin@resourceprojects.org
            </a>
          </div>
          <div className="footer-tagline-yipl">
            <Text size={12} light>
              Designed and Developed by
            </Text>
            <a
              href="http://younginnovations.com.np/"
              target="_blank"
              rel="noopener noreferrer"
            >
              YoungInnovations
            </a>
          </div>
        </div>
      </div>
    </Container>
  </SiteFooter>
);

const SiteFooter = styled.footer`
  margin: 0;
  padding: 80px 0;
  font-size: 12px;
  /* max-height: 260px; */
  position: relative;
  &.footer-home {
    background-color: #3a424c;
    .footer-subscribe {
      display: none;
    }
    .footer-end {
      display: block;
      p {
        font-size: 12px;
      }
    }

    .nrgi-logo img {
      width: 100%;
    } 
  }

  a {
    color: #20bcfb;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }

  .main-footer {
    /* padding: 0 0 50px 0; */
    ${media.sm`
    ${flex('row', 'space-between', 'flex-start')};
    `};
  }

  .nrgi-logo {
    margin-bottom: 10px;
    img {
      width: 86%;
    }
  }

  .footer-subscribe {
    margin-top: 14px;
    @media (max-width: 767px) {
      width: 100%;
      margin: 24px 0;
    }
    p {
      display: inline;
      margin-right: 5px;
    }
    .btn-subscribe {
      color: #fff;
      font-size: 11px;
      line-height: 26px;
      height: 26px;
      padding: 0 16px;
      width: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .footer-text {
    width: 50%;
    margin: 0 36px;

    @media (max-width: 767px) {
      width: 100% !important;
      margin: 24px 0 !important;
    }
  }

  .footer-email {
    p {
      white-space: pre-wrap;
    }

    a {
      display: block;
      line-height: 1.4;
    }

    @media (max-width: 768px) {
      a {
        display: inline-block;
      }
    }
  }

  .footer-left {
    @media all and (min-width: 768px) and (max-width: 870px) {
      width: 27%;
    }
  }

  .footer-left .footer-end {
    display: block;
    p,
    a {
      font-size: 11px;
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    background: #ebf1f2;
    padding: 24px 36px;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 480px) {
      padding: 24px 10px;
    }
  }

  .lincense {
    margin-right: 72px;
    white-space: pre-wrap;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  a {
    display: inline-block;
  }

  .footer-tagline-yipl {
    margin-top: 8px;
    a {
      display: inline-block;
      line-height: 1.4;
    }
  }
`;
