import Api from './api';

class Page {

	async fetch() {
		try {
			let res = await Api.get('pages');

			return res.body;
		}
		catch (error) {
			console.log(error);

			return [];
		}
	}
}

export default new Page();
