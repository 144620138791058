import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResponsiveWrapper from '../responsive-wrapper/responsive-wrapper';
import { scaleTime, scaleLinear } from 'd3-scale';
import { extent, min, max } from 'd3-array';
import XYAxis from '../axis/XYAxis';
import XYGrid from '../grid/XYGrid';
import Line from './line';
import Tooltip from './tooltip';

const xScale = (domain, range) =>
  scaleTime()
    .domain(domain)
    .range(range);
const yScale = (domain, range) =>
  scaleLinear()
    .domain(domain)
    .range(range);

// const colors = scaleOrdinal()
//     .domain([0, 1, 2, 3, 4, 5])
//     .range(['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#d4d4bc']);

const formatData = data => {
  let copyData = Object.assign({}, data); // do not mutate original data
  // const minMaxDate = extent(Object.keys(copyData), d => new Date(d, 0, 1));
  const keys = Object.keys(copyData[Object.keys(copyData)[0]]);

  copyData = keys.map(key => {
    const values = [];
    Object.keys(copyData).forEach(date => {
      const row = copyData[date];
      if (parseFloat(row[key])) {
        values.push({
          date: new Date(date, 0, 1),
          payload: row[key]
        });
      }
    });
    return {
      id: key,
      values
    };
  });

  data = [];
  copyData.forEach(d => {
    if (d.values.length > 0) {
      data.push(d);
    }
  });

  let minMaxDate = [];

  data.map(t => {
    return t.values.map(d => minMaxDate.push(d.date));
  });

  minMaxDate = extent(minMaxDate);

  return {
    minMaxDate,
    data: data
  };
};

class LineChart extends React.Component {
  
  render() {
    const { parentWidth, selected } = this.props;

    const { minMaxDate, data } = formatData(this.props.data);

    const svgDimensions = {
      width: parentWidth,
      height: 230
    };
    const margin = {
      top: 10,
      right: 20,
      bottom: 20,
      left: 40
    };
    const width = svgDimensions.width - margin.left - margin.right;
    const height = svgDimensions.height - margin.top - margin.bottom;

    const x = xScale(minMaxDate, [0, width]);
    const y = yScale(
      [
        min(data, d => min(d.values, d => parseFloat(d.payload))),
        max(data, d => max(d.values, d => parseFloat(d.payload)))
      ],
      [height, 0]
    );

    const lineSettings = {
      scales: { x, y },
      data,
      selected,
      colors: this.props.colors
    };

    return (
      <LineChartStyle>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <XYAxis scales={{ x, y }} dimensions={{ height, width }} />
            <XYGrid scales={{ x, y }} dimensions={{ height, width }} />
            <Line {...lineSettings} />
            <Tooltip
              {...{ height, width }}
              margin={margin}
              data={data}
              colors={this.props.colors}
              handleChangeColor={(i) => this.props.handleChangeColor(i)}
              handleResetColor={this.props.handleResetColor}
              {...{ x, y }}
            />
          </g>
        </svg>
      </LineChartStyle>
    );
  }
}

LineChart.propTypes = {
  parentWidth: PropTypes.number,
  data: PropTypes.object
};

const LineChartStyle = styled.div`
  .axis-group {
    line,
    path {
      display: none;
    }
  }
  .grid-group {
    text,
    path {
      display: none;
    }
    line {
      stroke: lightgrey;
      stroke-opacity: 0.2;
      shape-rendering: crispEdges;
    }
  }
`;

export default ResponsiveWrapper(LineChart);
