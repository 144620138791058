const initialState = {
	fetching: true,
	fetched: false,
	data: [],
	total: 0,
	perPage: 25,
	currentPage: 1,
	filter: {
		countries: '',
		reportingCompanies: '',
	},
	sortBy:'reportingCompany',
	order:'asc',
	query:'',
	error: null
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'SOURCE_FETCHING':
		state = { ...state, ...{ error: null, fetching: true } };
		break;

	case 'SOURCE_SET_CURRENT_PAGE':
		state = { ...state, ...{ currentPage: parseInt(payload, 10) } };
		break;

	case 'SOURCE_SET_FILTER':
		state = { ...state, ...{ filter: payload } };
		break;

	case 'SOURCE_SET_SORT':
		state = { ...state, ...{ sortBy: payload.sortBy, order: payload.order } };
		break;

	case 'SOURCE_SET_QUERY':
		state = { ...state, ...{ query: payload } };
		break;

	case 'SOURCE_FETCHED':
		state = { ...state, ...{ error: null, data: payload.result, total: payload.total, fetching: false, fetched: true } };
		break;

	case 'SOURCE_ERROR':
		state = { ...state, ...{ error: payload, fetching: false, fetched: true } };
		break;
	default:
		break;

	}
	return state;
};

export default reducer;
