import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableWrapper, Table, TH, TR, TD } from '../../../components/styled';
import { media } from '../../../components/styled/utils';
import styled from 'styled-components';
import { getAppImages } from '../../../helpers';
import { Loading, Sort } from '../../../components/layouts/filters/';
import Sparkline from '../../../screens/company/sparkline/sparkline';

import { Link } from 'react-router-dom';
import numberFormatter from '../../../utils/numberFormatter';

const { clickIcon } = getAppImages;

class ReportingCompanyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingHide: false
    };
  }

  tableHeader() {
    const { sortHandle, order, sortBy } = this.props;
    return (
      <TR>
        <TH width="48%">
          <Sort
            onClickHandler={() => sortHandle('_id')}
            showOrder={sortBy === '_id'}
            order={order}
          >
            Reporting Company
          </Sort>
        </TH>
        <TH width="25%">
          <Sort
            onClickHandler={() => sortHandle('reports')}
            showOrder={sortBy === 'reports'}
            order={order}
          >
            No. of Reports{' '}
          </Sort>
        </TH>
        <TH width="27%">
          <Sort
            onClickHandler={() => sortHandle('totalPayment')}
            showOrder={sortBy === 'totalPayment'}
            order={order}
          >
            Payment Value
          </Sort>
        </TH>
        {/* <TH /> */}
      </TR>
    );
  }
  tableBody(list) {
    const temp = list.reduce(
      (acc, current) => [...acc, ...current.payments],
      []
    );
    const minYear = Math.min(...temp.map(d => d.year));
    const maxYear = Math.max(...temp.map(d => d.year));
    // console.log(minYear, maxYear, list[0].payments)

    return list.map(item => (
      <TR key={item._id}>
        <TD data-label="Reporting Company">
          <Link to={`/company/${encodeURIComponent(item._id)}`}>
            <span className="content">
              <strong>{item._id.substr(0,40)}</strong>
              {item._id.length <= 40 ? (''): <span>...</span>}
              {item._id.length <= 40 ? (''): <span className="popup-content">{item._id}</span>}            
            </span>
          </Link>
        </TD>
        <TD data-label="No. of Reports">
          {item.reports.length}{' '}
          {item.reports.length === 1 ? 'Report' : 'Reports'}
        </TD>
        <TD data-label="Payment Value">
          <strong>{numberFormatter(item.totalPayment)}</strong>
          {item.totalPayment !== 0 && <Sparkline data={item.payments} minYear={minYear} maxYear={maxYear} />}
        </TD>
        {/* <TD>
          {item.totalPayment !== 0 && <Sparkline data={item.payments} minYear={minYear} maxYear={maxYear} />}
        </TD> */}
      </TR>
    ));
  }

  renderOnboarding = () => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('company-profile-render')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" /> on Reporting Company names to
            access summarized information about their activities and payments.
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'company-profile-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  render() {
    const { fetching, fetched, list } = this.props;

    return (
      <TopCompanyTableWrap>
        {!fetching && fetched ? this.renderOnboarding() : ''}
        <TableWrapper>
          <Table>
            <thead>{this.tableHeader()}</thead>
            <tbody>
              {!fetching && fetched ? (
                this.tableBody(list)
              ) : (
                <TR>
                  <Loading />
                </TR>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </TopCompanyTableWrap>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.company.fetching,
  fetched: state.company.fetched
});
export default connect(mapStateToProps)(ReportingCompanyTable);

const TopCompanyTableWrap = styled.div`
  position: relative;
  table {
    /* table-layout: initial; */
    tr {
      th {
        background-color: #fff;
        border-bottom: 1px solid #e9e8eb;
        color: rgba(58, 66, 76, 0.7);
        font-size: 12px;
        text-transform: uppercase;
        padding: 18px 28px !important;
        &:last-child {
          min-width: 138px;
          width: 27%;
        }
      }
      td {
        background: #fff;
        border-radius: 0 !important;
        border-bottom: 1px solid #e9e8eb;
        color: #333;
        font-size: 14px;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        ${media.sm`padding: 16px 28px !important;`}
        strong {
          font-weight: bold;
        }
        &:nth-child(2) {
          color: rgba(51, 51, 51, 0.7);
          font-size: 12px;
        }
        a {
          color: #333;
        }
        &:last-child strong {
          display: inline-block;
          margin-right: 25px;
          text-align: right;
          width: 50px;
          & + div {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      &:hover td {
        background: #f5f5ff;
      }
    }
  }
  .loading-text {
    color: #3a424c !important;
  }
`;

const Onboarding = styled.div `
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 221px;
  position: absolute;
  /* left: -4px;
  top: 11.5%; */
  left: 19%;
  top: -2.5%;
  z-index: 1;
  ${media.sm`
    top: -5%;
    left: 20px;
  `}
  @media all and (min-width:1280px) {
    padding: 18px 18px 12px;
    left: -242px;  
    top: 36px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
    ${media.sm`width:22px;`}
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width:480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width:480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 114px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    ${media.sm`right: 184px;`}
    @media all and (min-width:1280px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 32px; 
      right: -13px;
    }
  }
     

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
