import Colours from './colours';
import Defaults from './defaultTheme';
const { primary, sec, neutral } = Colours;
const { fontFamily, fontSize, fontWeight, lineHeight, transition } = Defaults;

const theme = {
  primary,
  sec,
  fontFamily,
  neutral,
  fontSize,
  fontWeight,
  lineHeight,
  transition
};

export default theme;
