import React from 'react';
import { LoadingTD } from '../../styled';

export const Loading = () => {
	return (<LoadingTD colSpan='5'>
		<div className="loading-row">
			<div className="horizontal"></div>
			<div className="vertical">
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
			</div>
		</div>
		<div className="loading-row">
			<div className="horizontal"></div>
			<div className="vertical">
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
				<div className="overlay too"></div>
			</div>
		</div>
		<div className="loading-text" style={{color: '#fff'}}>Loading...</div>
	</LoadingTD>);
};
