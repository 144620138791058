export function fetching() {
  return {
    type: 'COUNTRY_FETCHING'
  };
}

export function fetched(payload) {
  return {
    type: 'COUNTRY_FETCHED',
    payload
  };
}

export function error(payload) {
  return {
    type: 'COUNTRY_ERROR',
    payload
  };
}

export function sankeyFetching() {
  return {
    type: 'SANKEY_FETCHING'
  };
}

export function sankeyFetched(payload) {
  return {
    type: 'SANKEY_FETCHED',
    payload
  };
}

export function sankeyError(payload) {
  return {
    type: 'SANKEY_ERROR',
    payload
  };
}

export function statsFetched(payload) {
  return {
    type: 'COUNTRY_STATS_FETCHED',
    payload
  };
}

export function statsFetching() {
  return {
    type: 'COUNTRY_STATS_FETCHING'
  };
}

export function statsError(payload) {
  return {
    type: 'COUNTRY_STATS_ERROR',
    payload
  };
}

export function barChartFetched(payload) {
  return {
    type: 'BAR_CHART_FETCHED',
    payload
  };
}

export function barChartFetching() {
  return {
    type: 'BAR_CHART_FETCHING'
  };
}

export function barChartError(payload) {
  return {
    type: 'BAR_CHART_ERROR',
    payload
  };
}

export function setSelected(payload) {
  return {
    type: 'SELECTED_SANKEY_NODE',
    payload
  };
}

export function countryListFetched(payload) {
  return {
    type: 'COUNTRY_LIST_FETCHED',
    payload
  };
}

export function overallStatsFetched(payload) {
  return {
    type: 'OVERALL_STATS_FETCHED',
    payload
  };
}

export function countryYearsFetched(payload) {
  return {
    type: 'COUNTRY_YEARS_FETCHED',
    payload
  };
}

export function countryYearsFetching() {
  return {
    type: 'COUNTRY_YEARS_FETCHING'
  };
}

export function countryYearsError(payload) {
  return {
    type: 'COUNTRY_YEARS_ERROR',
    payload
  };
}

export function plotDataFetched(payload) {
  return {
    type: 'PLOT_FETCHED',
    payload
  };
}

export function plotDataFetching() {
  return {
    type: 'PLOT_FETCHING'
  };
}

export function plotDataError(payload) {
  return {
    type: 'PLOT_ERROR',
    payload
  };
}

export function setSelectedYears(payload) {
  return {
    type: 'SET_SELECTED_YEARS',
    payload
  };
}

export function incomeGroupFetched(payload) {
  return {
    type: 'INCOME_GROUP_FETCHED',
    payload
  };
}

export function setPaymentType(payload) {
  return {
    type: 'SET_PAYMENT_TYPE',
    payload
  };
}

export function setBarData(payload) {
  return {
    type: 'SET_BAR_DATA',
    payload
  };
}

export function countryProjectListFetched(payload) {
  return {
    type: 'COUNTRY_PROJECT_LIST_FETCHED',
    payload
  };
}
export function countryEntityListFetched(payload) {
  return {
    type: 'COUNTRY_ENTITY_LIST_FETCHED',
    payload
  };
}
export function countryProjectListFetching() {
  return {
    type: 'COUNTRY_PROJECT_LIST_FETCHING'
  };
}

export function countryCompanyList(payload) {
  return {
    type: 'COUNTRY_AND_COMPANY_LIST_FETCHED',
    payload
  };
}
