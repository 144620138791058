import React, { Component } from 'react';
import styled from 'styled-components';
import Area from './Area';
import Line from './Line';
import Circle from './Circle';
import { line, area } from 'd3-shape';
import { extent, bisector, max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { timeParse } from 'd3-time-format';
import numberFormatter from '../../../utils/numberFormatter';

const parseTime2 = timeParse('%Y');
const bisectDate = bisector(d => d.year).left;

const getScaleX = (data, width) =>
  scaleTime()
    .domain(extent(data, d => d[Object.keys(d)[0]]))
    .range([0, width]);

const getScaleY = (data, height) =>
  scaleLinear()
    .range([0, height])
    .domain([
      max(data, function(d) {
        return Math.abs(d[Object.keys(d)[1]]);
      }),
      max(data, function(d) {
        return Math.abs(d[Object.keys(d)[1]]);
      }) * -1
    ]);

const margins = {
  top: 5,
  right: 5,
  bottom: 5,
  left: 5
};

class Sparkline extends Component {
  constructor() {
    super();
    this.state = {
      width: 0,
      height: 0,
      xScale: () => {},
      yScale: () => {},
      hoveredValue: {
        year: null,
        value: null
      },
      data: []
    };
    this.lineGenerator = function() {};
    this.areaGenerator = function() {};
  }

  onMouseMove(e) {
    const { xScale, data } = this.state;
    const { left } = e.target.getBoundingClientRect();
    const { pageX } = e;
    const mouseX = pageX - left;
    const x0 = xScale.invert(mouseX) - 30;
    const i = bisectDate(data, x0, 1, data.length - 1);
    const d0 = data[i - 1];
    const d1 = data[i];

    if (d1) {
      const d = x0 - d0.year > d1.year - x0 ? d1 : d0;
      this.setState({
        hoveredValue: d
      });
    } else {
      this.setState({
        hoveredValue: d0
      });
    }
  }

  onMouseOut() {
    setTimeout(() => {
      this.setState({
        hoveredValue: {
          year: null,
          value: null
        }
      });
    }, 300);
  }

  formatData(props) {
    const { data, minYear, maxYear } = props;
    let temp = [];

    data.sort((a, b) => a.year - b.year);

    for (let i = minYear; i <= maxYear; i++) {
      temp.push(i);
    }

    const formatedData = temp.map(year => {
      const found = data.find(obj => parseInt(obj.year) === year);
      let value;
      if (found) value = found.value;
      else if (year >= data[0].year && year <= data[data.length - 1].year)
        value = 0;
      else value = null;
      return {
        year: parseTime2(year),
        value
      };
    });
    // console.log(formatedData);

    const parentWidth = 70;
    const width = parentWidth - margins.left - margins.right;
    const height = 20 - margins.top - margins.bottom;
    const xScale = getScaleX(formatedData, width);
    const yScale = getScaleY(formatedData, 20);

    this.lineGenerator = line()
      .x(d => xScale(d.year))
      .y(d => yScale(d.value))
      .defined(d => {
        if (d.value === null) {
          return false;
        }
        return true;
      });

    this.areaGenerator = area()
      .x(d => xScale(d.year))
      .y0(height)
      .y1(d => yScale(d.value))
      .defined(d => {
        if (d.value === null) {
          return false;
        }
        return true;
      });

    this.setState({
      width,
      height,
      xScale,
      yScale,
      data: formatedData
    });
  }

  componentDidMount() {
    this.formatData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.formatData(nextProps);
  }

  render() {
    const { width, height, xScale, yScale, hoveredValue, data } = this.state;
    // const zeroData = data.map(d => d.value ? ({ year: d.year, value: 0 }):  ({ year: d.year, value:null }));
    const { year, value } = hoveredValue;
    const { lineGenerator, areaGenerator } = this;
    const h = height + margins.top + margins.bottom;
    const w = width + margins.left + margins.right;

    if (!width) return <h1>loader</h1>;

    return (
      <Container
        width={w}
        height={30}
        xScale={xScale}
        yScale={yScale}
        hoveredValue={hoveredValue}
        className="sparkline"
      >
        <svg>
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            <clipPath id="clipPathNeg">
              <rect x="0" y={h / 2 + 1} width={w} height={h / 2}></rect>
            </clipPath>
            <clipPath id="clipPathPos">
              <rect x="0" y="-5" width={w} height={h / 2 + 5}></rect>
              {/* <rect x="0" y="0" width={w} height={(h / 2)}></rect> */}
            </clipPath>
            <Line
              data={data}
              lineGenerator={lineGenerator}
              stroke="#3A424C"
              clipPath={'url(#clipPathPos)'}
              className="posLine"
            />
            <Line
              data={data}
              lineGenerator={lineGenerator}
              stroke="#da4c4c"
              clipPath={'url(#clipPathNeg)'}
              className="negLine"
            />
            <Area
              data={data}
              areaGenerator={areaGenerator}
              fill="#ded8e6"
              clipPath={'url(#clipPathPos)'}
              className="posLine"
            />
            <Area
              data={data}
              areaGenerator={areaGenerator}
              fill="#f5c5c5"
              clipPath={'url(#clipPathNeg)'}
              className="negLine"
            />
            {/* <Line
              data={zeroData}
              lineGenerator={lineGenerator}
              stroke="#c8c2d0"
              // strokeWidth='2px'
              strokeDasharray= '4'
            /> */}
            <line
              x1="0"
              y1={h / 2}
              x2={width}
              y2={h / 2}
              stroke="#c8c2d0"
              strokeDasharray="2"
              // strokeWidth="1px"
            />
            {data.map((d, index) =>
              d.value !== null ? (
                <Circle
                  key={index}
                  d={d}
                  xScale={xScale}
                  yScale={yScale}
                  selectedYear={year}
                />
              ) : (
                ''
              )
            )}
          </g>
        </svg>
        {/* <div className="sparklineTooltip">
          <span className="arrow" />
          Year: <span>2018</span> | Value: <span> 4.2 bn</span>
        </div> */}

        {value !== null && (
          <div className="sparklineTooltip">
            <span className="arrow" />
            Year: <span>{year.getFullYear()}</span> | Value:{' '}
            <span>{numberFormatter(value)}</span>
          </div>
        )}
        {/* <span style={{fontSize: '7px', float: 'left', marginLeft: '1px', marginTop: '7px',color: '#9f98a9'}}>0</span> */}

        <div
          className="overlay"
          onMouseMove={this.onMouseMove.bind(this)}
          onMouseOut={this.onMouseOut.bind(this)}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }

  .sparklineTooltip {
    position: absolute;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    color: #999;
    font-size: 10px;
    padding: 5px 10px;
    transform: translateX(-50%);
    left: ${props => props.xScale(props.hoveredValue.year) - 83}px;
    bottom: ${props =>
      props.height - props.yScale(props.hoveredValue.value) - 18}px;
    /* bottom: 0; */
    background-color: #fff;
    min-width: 160px;
    z-index: 100;
    > span {
      color: #3a424c;
    }
  }

  .arrow:after,
  .arrow:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow:after {
    border-left-color: #fff;
    border-width: 4px;
    margin-top: -4px;
  }
  .arrow:before {
    border-left-color: rgba(0, 0, 0, 0.05);
    border-width: 6px;
    margin-top: -6px;
  }
`;

export default Sparkline;
