import React from 'react';
import ReactPaginate from 'react-paginate';

export const Pagination = ({ total, current, onPageClick }) => {
	let forcePageObj ={}
    if(current === 1){
        forcePageObj['forcePage']=0
    }
    return (
        <ReactPaginate
            previousLabel="previous"
            nextLabel="next"
            breakLabel={<span>...</span>}
            breakClassName="more"
            pageCount={total}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            initialPage={current - 1}
            disableInitialCallback={false}
            onPageChange={onPageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            pageClassName="page"
            pageLinkClassName="page-link"
            nextLinkClassName="next"
            previousLinkClassName="prev"
            {...forcePageObj}
         />
    );
};
