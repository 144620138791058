import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { area } from 'd3-shape';
import NumberFormatter from '../../../../utils/numberFormatter';

const StackArea = props => {
  let final = {};
  Object.keys(props.nonFormatedData).forEach(d => {
    Object.keys(props.nonFormatedData[d]).forEach(t => {
      final[t] = final[t]
        ? parseFloat(final[t]) + parseFloat(props.nonFormatedData[d][t])
        : parseFloat(props.nonFormatedData[d][t]);
    });
  });

  // console.log(final);
  const { scales, data, newStack } = props;
  const { x, y } = scales;
  const colors = {
    'Human Capital per Capita Constant 2014 Us': '#fccde5',
    'Natural Capital per Capita Agricultural Land Constant 2014 Us': '#dcdc4a',
    'Natural Capital per Capita Forests Constant 2014 Us': '#bebada',
    'Natural Capital per Capita Subsoil Assets Coal Constant 2014 Us':
      '#80b1d3',
    'Natural Capital per Capita Subsoil Assets Gas Constant 2014 Us': '#fdb462',
    'Natural Capital per Capita Subsoil Assets Minerals Constant 2014 Us':
      '#b3de69',
    'Natural Capital per Capita Subsoil Assets Oil Constant 2014 Us': '#8dd3c7'
  };

  let tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip');

  function onMouseOver(e, name, value) {
    const html = `
  <div>
    <table class='table'>
      <tbody>
        <tr>
          <th>Natural Wealth</th>
          <th class='title'>${name}</th>
        </tr>
        <tr>
          <td>Amount</td>
          <td>USD ${NumberFormatter(value)}</td>
        </tr>
        <tr>
          <td>Country</td>
          <td>${props.countryName}</td>
        </tr>
      </tbody>
    </table>
  </div>
  `;
    tooltip
      .style('left', e.pageX + 10 + 'px')
      .style('top', e.pageY + 10 + 'px')
      .style('display', 'inline-block')
      .html(html);
  }
  const areaGenerator = area()
    .x(function(d) {
      return x(d.data.date);
    })
    .y0(function(d) {
      return y(d[0]);
    })
    .y1(function(d) {
      return y(d[1]);
    });

  const loop = (row, key) => {
    let val = row.key;
    return (
      <g className="layer" key={key}>
        <path
          className="area"
          onMouseMove={e => {
            onMouseOver(e, val, final[val]);
          }}
          onMouseOut={() => tooltip.style('display', 'none')}
          fill={colors[val]}
          d={areaGenerator(row)}
        />
      </g>
    );
  };

  return <g className="stack-area-group">{newStack(data).map(loop)}</g>;
};

StackArea.propTypes = {
  scales: PropTypes.object
};

export default StackArea;
