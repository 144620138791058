const initialState = {
	fetching: false,
	fetched: false,
	all: [],
	error: null
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'PAGE_FETCHING':
		state = { ...state, ...{ fetching: true } };
		break;
	case 'PAGE_ERROR':
		state = { ...state, ...{ error: payload.error, fetched: true } };
		break;

	case 'PAGE_FETCHED': {
		state = { ...state, ...{ all: payload, fetching: false, fetched: true } };
		break;
	}
	default:
		break;
	}
	return state;
};

export default reducer;
