export function fetching() {
	return {
		type: 'PAGE_FETCHING',
	};
}

export function fetched(payload) {
	return {
		type: 'PAGE_FETCHED',
		payload
	};
}

export function error(payload) {
	return {
		type: 'PAGE_ERROR',
		payload
	};
}
