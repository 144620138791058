import styled from 'styled-components';

const Text = styled.p`
  ${props => {
    return `
   color: ${
     props.light ? props.theme.neutral.darkWhite : props.theme.primary.dark
   };
   font-size: ${
     props.size ? props.size + 'px' : props.theme.fontSize.base + 'px'
   };
   line-height: ${props.theme.lineHeight.base};

   text-align: ${props.align ? props.align : ''};

     ${props.uppercase &&
       `
          text-transform: uppercase;
     `} 	   

 
     
`;
  }};
`;

Text.span = Text.withComponent('span').extend`
    &.currency {
        bottom: -1px;
        font-size: 10px;
        float: right;
        opacity: 0.7;
        position: absolute;
        right: 0;
     }
`;

Text.displayName = 'Text';

export default Text;
