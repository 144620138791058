import React, { Component } from 'react';
import styled from 'styled-components';
import StackAreaChart from './stack-area-chart/stack-area-chart';
import { flex, media } from '../../../components/styled/utils/';

// const mapObject = (object, callback) =>
//   Object.keys(object).map(function(key) {
//     return callback(key, object[key]);
//   });

const parent = [
  'Natural Capital per Capita Constant 2014 Us',
  'Natural Capital per Capita Subsoil Assets Constant 2014 Us'
];

const formatLegendData = legendData => {
  const data = [];
  const legend = {
    'Human Capital per Capita Constant 2014 Us': {
      color: '#fccde5',
      display: 'Human Capital'
    },
    'Natural Capital per Capita Agricultural Land Constant 2014 Us': {
      color: '#dcdc4a',
      display: 'Agricultural Capital'
    },
    'Natural Capital per Capita Forests Constant 2014 Us': {
      color: '#bebada',
      display: 'Forests Capital'
    },
    'Natural Capital per Capita Subsoil Assets Coal Constant 2014 Us': {
      color: '#80b1d3',
      display: 'Coal Capital'
    },
    'Natural Capital per Capita Subsoil Assets Gas Constant 2014 Us': {
      color: '#fdb462',
      display: 'Gas Capital'
    },
    'Natural Capital per Capita Subsoil Assets Minerals Constant 2014 Us': {
      color: '#b3de69',
      display: 'Minerals Capital'
    },
    'Natural Capital per Capita Subsoil Assets Oil Constant 2014 Us': {
      color: '#8dd3c7',
      display: 'Oil Capital'
    }
  };

  let i = 0;
  for (var key in legendData) {
    let p = parent.filter(d => key.indexOf(d) !== -1);

    if (p.length === 0) {
      // key = key.split('2014');
      data.push({ value: key, i: i++ });
    }
  }

  data.forEach(key => {
    let val = key.value.trim();
    key.color = legend[val].color;
    key.display = legend[val].display;
  });

  return data;
};

class NaturalWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      naturalWealth: [],
      legendData: [],
      unChecked: [],
      selected: ''
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.excludeUnChecked = this.excludeUnChecked.bind(this);
  }

  componentWillMount() {
    let naturalWealth = this.formatNaturalWealthData(this.props.naturalWealth);
    let legendData = naturalWealth[Object.keys(naturalWealth)[0]];
    let formattedData = formatLegendData(legendData);

    this.setState({ naturalWealth: naturalWealth, legendData: formattedData });
  }

  formatNaturalWealthData(data) {
    for (let d in data) {
      for (let k in data[d]) {
        if (parent.includes(k)) {
          delete data[d][k];
        }
      }
    }

    return data;
  }

  excludeUnChecked(data) {
    const projects = {};
    let years = Object.keys(data);
    years.forEach(year => {
      projects[year] = Object.keys(data[year])
        .filter(project => !this.state.unChecked.includes(project))
        .reduce((obj, key) => {
          obj[key] = data[year][key];
          return obj;
        }, {});
    });
    return projects;
  }
  handleOnChange(e) {
    let unChecked = this.state.unChecked;
    let position = unChecked.indexOf(e.target.value);
    if (position === -1) {
      unChecked.push(e.target.value);
    } else {
      unChecked.splice(position, 1);
    }
    this.setState(unChecked);
  }

  render() {
    let { legendData, unChecked } = this.state;
    let naturalWealth = this.excludeUnChecked(this.state.naturalWealth);

    return (
      <div id='country-stats'>
      <Content>
        <div className="column">
          <StackAreaChart
            countryName={this.props.countryName}
            data={naturalWealth}
          />
        </div>
        <div className="column">
          {/* <ScrollBar
            className="options"
            contentClassName="content"
            smoothScrolling={true}
            minScrollSize={30}
          > */}
          <Legend>
            {legendData.map((value, key) => (
              <LegendItem key={key} color={value.color}>
                <label className="has-checkbox">
                  <input
                    type="checkbox"
                    value={value.value}
                    name="legendCheckbox"
                    onChange={this.handleOnChange}
                    checked={
                      unChecked.indexOf(value.value) === -1 ? true : false
                    }
                  />

                  <span className="checkbox" />
                  {value.display}
                </label>
              </LegendItem>
            ))}
          </Legend>
          {/* </ScrollBar> */}
        </div>
      </Content>
      </div>
    );
  }
}

export default NaturalWealth;

const Content = styled.div`
  ${flex('column', null, null)};

  ${media.sm`
  	  ${flex('row', 'flex-start', null)};
  `};

  .column {
    &:first-child {
      flex: 1 1 75%;
      margin-bottom: 12px;

      ${media.sm`
        margin-bottom: 0;
      `};
    }

    &:nth-child(2) {
      flex: 0 0 25%;
    }

    .options {
      padding-right: 14px;
      max-height: 212px;
    }
  }
`;

const Legend = styled.div`
  margin-top: 8px;
  list-style: none;
  padding-left: 0;
`;

const LegendItem = styled.li`
  color: ${props => props.theme.primary.gray};
  display: block;
  font-size: 11px;
  line-height: 1.3;

  ${media.sm`
    ${flex('row', 'flex-start', 'center')};
`};

  &:not(:last-child) {
    margin: 0 17px 8px 0;

    ${media.sm`
      margin: 0 0 8px 0;
  `};
  }

  span {
    flex: 1;
  }

  .has-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 24px;
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border: 2px solid ${props => props.color};
      border-radius: 2px;
      position: absolute;
      left: 0;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 50%;
        height: 30%;
        background: transparent;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        opacity: 0;
        -webkit-filter: blur(2px);
      }
    }
    input[type='checkbox'] {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      line-height: 32px;
      border: none;
      z-index: 2;
      margin: 0;
      -webkit-appearance: none;
      background: green;
      opacity: 0;
      outline: none;
    }
    input[type='checkbox']:checked ~ span.checkbox {
      background: ${props => props.color};
    }
    input[type='checkbox']:checked ~ span.checkbox:before {
      transform: translate(-50%, -50%) rotate(-45deg);
      opacity: 1;
      -webkit-filter: blur(0px);
    }
  }

  &:nth-child(1) {
  }

  &:nth-child(2) {
  }

  &:nth-child(3) {
  }

  &:nth-child(4) {
  }

  &:nth-child(5) {
  }

  &:nth-child(6) {
  }

  &:nth-child(7) {
  }

  &:nth-child(8) {
  }

  &:nth-child(9) {
  }
`;
