import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';
import { Dropdown, DropdownMenu, transitionStyles } from './dropdownStyles';
import { Transition } from 'react-transition-group';
import Colours from '../../../components/styled/theme/colours';
const { primary } = Colours;

class NavDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedValue: props.title
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  handleDropdownMenu(dropdownState) {
    this.setState({
      isOpen: dropdownState
    });
  }

  handleChange(dropdownValue) {
    this.setState(
      {
        isOpen: false
      },
      () => {
        this.props.onChange();
        this.props.history.push('/' + dropdownValue);
      }
    );
  }

  render() {
    const { items } = this.props;

    return (
      <Dropdown
        onMouseEnter={() => this.handleDropdownMenu(true)}
        onMouseLeave={() => this.handleDropdownMenu(false)}
      >
        <DropdownLink>
          <Title className="title" isOpen={this.state.isOpen}>
            {this.state.selectedValue}
          </Title>
          <DropdownIcon className="icon" isOpen={this.state.isOpen} />
        </DropdownLink>

        <Transition in={this.state.isOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <ul className="dropdown-list">
                {items.map((val, key) => (
                  <li className="dropdown-list-item" key={key}>
                    <NavLink
                      to={'/' + val.value}
                      activeClassName="active"
                      onClick={() => this.handleChange(val.value)}
                    >
                      {val.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

export default withRouter(NavDropdown);

const DropdownLink = styled.div`
  display: inline-block;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-size: 12px;
  cursor: default;
  background: transparent;
  text-decoration: none;
  padding: 8px 16px;
  cursor: pointer;
`;

const Title = styled.span`
  cursor: pointer;
  display: inline-block;
  color: ${props => (props.isOpen ? primary.primary : primary.white)};
`;

const DropdownIcon = styled.span`
  border-color: ${props =>
    props.isOpen
      ? `transparent transparent ${primary.primary};`
      : `${primary.white} transparent transparent;`}
    border-width: ${props => (props.isOpen ? '0 3px 3px' : '3px 3px 0')};
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  transition: all 200ms ease-in-out;
`;
