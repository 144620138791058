import Api from '../api';

class Company {
  async companyData(company) {
    const params = { company };
    const response = await Api.get('/company', { params });
    // console.log('inside company ko service',response)
    return response.body;
  }

  async companyStats() {
    const response = await Api.get('/company/overallStats');
    return response.body;
  }

  async companyTable() {
    const response = await Api.get('/company/detailTable');
    return response.body;
  }
}

export default new Company();
