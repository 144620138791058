import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Colours from '../theme/colours';
import {Link} from 'react-router-dom';

const { primary } = Colours;

const Wrapper = styled.div`
  padding: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const StatusCode = styled.span`
  display: block;
  font-size: 150px;
  line-height: 180px;
  /* font-weight: 100; */
  color: ${primary.cyan};
  margin-bottom: 6px;
  text-shadow: 0px 0px 30px rgba(1, 172, 162, 0.3);
`;

const Text = styled.p`
  font-size: 24px;
  line-height: 20px;
  font-weight: 300;
  color: ${primary.white};
  /* opacity: 0.35; */
  margin: 0;
`;

const NoMatch = props => {
  const { staticContext } = props;
  // Tell server we should send a 404 not found
  if (staticContext) {
    props.staticContext.statusCode = 404;
  }
  const {keyword , backUrl, backText, name} = props.location.state || {};
  return (
    <Wrapper>
      <Helmet>
        <title>Page Not found!</title>
      </Helmet>
      <StatusCode>404</StatusCode>
      <Text>{name  ? 'The '+name +' "' + keyword + '" could not be found' :'Page Not Found!'}</Text>
      {
        backUrl && <Link  className='btn-subscribe' style={{width: '250px', fontSize: '15p', padding: '15px', marginTop:'30px'}} to={backUrl}>{'Back to ' + backText}</Link>
      }
    </Wrapper>
  );
};

export default NoMatch;
