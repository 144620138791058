const initialState = {
	fetching: true,
	fetched: false,
	data: {},
	years: [],
	selectedYears: ['all'],
	paymentType: 'project',
	incomeFilter: [],
	query: '',
	error: null
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'COUNTRY_STATS_ERROR':
		state = {
			...state,
			...{ error: payload, fetching: false, fetched: true }
		};
		break;
	case 'COUNTRY_STATS_FETCHING':
		state = { ...state, ...{ fetching: true, fetched: false } };
		break;
	case 'COUNTRY_STATS_FETCHED':
		state = {
			...state,
			...{ error: null, data: payload, fetching: false, fetched: true }
		};
		break;
	case 'COUNTRY_YEARS_ERROR':
		state = {
			...state,
			...{ error: payload, fetching: false, fetched: true }
		};
		break;
	case 'COUNTRY_YEARS_FETCHING':
		state = { ...state, ...{ fetching: true, fetched: false } };
		break;
	case 'COUNTRY_YEARS_FETCHED':
		state = {
			...state,
			...{
				error: null,
				years: payload.result.result,
				fetching: false,
				fetched: true
			}
		};
		break;
	case 'SET_SELECTED_YEARS':
		state = {
			...state,
			...{
				error: null,
				selectedYears: payload,
				fetching: false,
				fetched: true
			}
		};
		break;
	case 'SET_PAYMENT_TYPE':
		state = {
			...state,
			...{ error: null, paymentType: payload, fetching: false, fetched: true }
		};
		break;
	case 'INCOME_GROUP_FETCHED':
		state = {
			...state,
			...{
				error: null,
				incomeFilter: payload,
				fetching: false,
				fetched: true
			}
		};
		break;
	default:
		break;
	}
	return state;
};

export default reducer;
