import React, { Component } from 'react';
import styled from 'styled-components';
import { Heading } from '../../../components/styled/ui/';
import { flex } from '../../../components/styled/utils/';
import { Transition } from 'react-transition-group';
import {Link} from 'react-router-dom';

class CompanySwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownIsOpen: false,
      searchValue: ''
    };
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
  }

  companyChanged(e) {
    this.setState({
      searchValue: e.target.value
    });
  }

  handleDropdownMenu(type) {
    // this.setState(({ dropDownIsOpen }) => ({
    //   dropDownIsOpen: !dropDownIsOpen
    // }));
    this.setState({dropDownIsOpen: type==='enter' ? true : false})
  }

  render() {
    const { companyName, companyList } = this.props;
    const { dropDownIsOpen, searchValue } = this.state;

    const filteredCompanyList = companyList && companyList.filter(company =>
      company.toLowerCase().includes(searchValue.toLowerCase())
    );

    const transitionStyles = {
      entered: {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0)'
      }
    };

    return (
      <Dropdown
        onMouseEnter={() => this.handleDropdownMenu('enter')}
        onMouseLeave={() => this.handleDropdownMenu('leave')}
      >
        <DropdownTitle isOpen={dropDownIsOpen}>
          <Heading.h2 className="title">{companyName}</Heading.h2>
          <span className="icon" />
        </DropdownTitle>
        <Transition in={dropDownIsOpen} timeout={100} mountOnEnter={true}>
          {state => (
            <DropdownMenu
              style={{
                ...transitionStyles[state]
              }}
            >
              <div className="search-wrap">
                <SearchInput
                  type="text"
                  placeholder="company name..."
                  autoComplete="none"
                  onChange={this.companyChanged.bind(this)}
                />
              </div>
                <ul className="list">
                  {filteredCompanyList.map(company => (
                    <li key={company}>
                      <Link onClick={()=> this.setState({dropDownIsOpen: false})}  to={`/company/${encodeURIComponent(company)}`}>
                        <span>{company}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
            </DropdownMenu>
          )}
        </Transition>
      </Dropdown>
    );
  }
}

export default CompanySwitcher;

const Dropdown = styled.div`
  position: relative;
  padding: 12px 0;
  margin-right: 36px;

  @media screen and (max-width: 546px) {
    margin-bottom: 12px;
  }
`;

const DropdownTitle = styled.div`
  ${flex('row', null, 'center')};
  cursor: pointer;


  .image {
    background-color: #eee;
    border: 2px solid ${props => props.theme.primary.white};
    height: 28px;
    margin-right: 7px;
    text-indent: -9999px;
    width: 38px;
  }

  .title {
    color: ${props => props.theme.primary.white};
    display: inline;
    line-height: 1;
  }

  .icon{
    border-color: ${props =>
      props.isOpen
        ? 'transparent transparent #fff;'
        : '#fff transparent transparent;'}
      border-width: ${props => (props.isOpen ? '0 5px 5px' : '5px 5px 0')};
    border-style: solid;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
  }

`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  width: auto;
  min-width: 184px;
  z-index: 9999000;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transform: translateY(-4px);
  transition: all 300ms ease-in-out;
  width: 240px;

  .search-wrap {
    padding: 18px 8px 10px;
    background-color: ${props => props.theme.primary.white};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
  }

  .list {
    background-color: ${props => props.theme.primary.white};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 40px -8px;
    border-top-bottom-radius: 4px;
    border-top-bottom-radius: 4px;
    max-height: 239px;
    overflow: hidden;
    overflow-y: scroll;
    > li {
      color: rgb(32, 188, 251);
      border-bottom: 1px solid rgb(238, 238, 238);
      transition: all 0.1s ease-in-out;

      &:hover {
        background: ${props => props.theme.primary.primary};
        > a {
          color: ${props => props.theme.primary.primary};
        }
      }

      > a {
        display: flex;
        align-items: center;
        padding: 9px 20px;

        > span {
          color: ${props => props.theme.primary.dark};
          flex: 1;
          line-height: 1.4;
        }
      }
    }
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  background: ${props => props.theme.sec.lightGreen};
  border: 1px solid #d8e1e6;
  border-radius: 18px;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;
