import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'styled-components';
import { Main, Content, Container } from '../components/styled';
import { Helmet } from 'react-helmet';
import '../components/styled/global/global';
import theme from '../components/styled/theme/theme';
import { getAppImages } from '../helpers';
import styled from 'styled-components';
const { rpLogo, wrongIcon } = getAppImages;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_ENV !== 'local') {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <ThemeProvider theme={theme}>
          <Main>
            <Content>
              <Helmet
                defaultTitle="Resource Projects : An open-source data repository on oil, gas and mining projects"
                titleTemplate="%s - Resource Projects : An open-source data repository on oil, gas and mining projects"
              >
                <meta charset="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta name="theme-color" content="#000000" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="shortcut icon" href="/favicon.ico" />
                <meta
                  name="og:description"
                  content="ResourceProjects.org provides a platform to collect and search extractive project information using open data. It aims to harvest data on project-by-project payments to governments based on recent mandatory disclosure legislation in the EU, Norway, US and Canada as well as in EITI reports."
                />
                <meta
                  name="og:title"
                  content="Resource Projects : An open-source data repository on oil, gas and mining projects"
                />
                <meta name="og:image" content={rpLogo} />
                <meta name="twitter:card" content="summary" />
              </Helmet>
              {/* <Header /> */}
              <Container>
                <ErrorWrap>
                  <div>
                    <img src={wrongIcon} alt="" />
                    <h1>Oops! Something went wrong.</h1>
                    <p>
                      Don’t worry, we are looking into it.
                      <br />
                      <a onClick={() => Sentry.showReportDialog()}>
                        Send us a feedback
                      </a>{' '}
                      if you are seeing this page more often.
                    </p>
                  </div>
                </ErrorWrap>
              </Container>
            </Content>
          </Main>
        </ThemeProvider>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;

const ErrorWrap = styled.div`
  color: #fff;
  max-width: 490px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    margin-bottom: 34px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    a {
      color: #20bcfb;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
