import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarChart from './bar-chart.js';
import { NoData, Loader } from '../../../../components/layouts/';
import { rgba } from '../../../../components/styled/utils/';
import styled from 'styled-components';

class Bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barData: [],
      types: [],
      jitterWidth: 300
    };

    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ barData: nextProps.barData.data });
  }

  calculateWidth() {
    const { jitterWidth } = this.state;
    const currentWidth = this.jitterBar.getBoundingClientRect().width;

    const shouldResize = jitterWidth !== currentWidth;

    if (shouldResize) {
      this.setState({ jitterWidth: currentWidth });
    }
  }

  renderNoData() {
    const barData = this.state.barData || [];
    let data = barData.filter(d => d.country !== 0);

    if (data.length === 0 && this.props.barData.data) {
      return (
        <JitterNoData>
          <NoData
            className="jitter-no-data"
            id="no-data"
            countryName={this.props.countryName}
          />
        </JitterNoData>
      );
    }
  }

  render() {
    let { barData } = this.state;
    // barData.sort((a,b) =>a.country-b.country);
    return (
      <div
        ref={el => {
          this.jitterBar = el;
        }}
      >
        {this.renderNoData()}
        {this.props.barData.length === 0 && <Loader/>}
        <BarChart
          data={barData}
          width={this.state.jitterWidth}
          countryName={this.props.countryName}
          otherCountryLabel = {this.props.barData.otherCountryLabel}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  barData: state.country.barData
});

export default connect(mapStateToProps)(Bar);

const JitterNoData = styled.div`
  background: ${props => rgba(props.theme.primary.white, 0.8)};
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
`;
