import React from 'react';

class DataCircles extends React.Component {
	renderCircle(coords) {
		return (
			<circle
				cx={this.props.xScale(coords.x)}
				cy={this.props.yScale(coords.y)}
				r={ this.props.r || 2}
				fill={coords.fill}
				strokeWidth={0.8}
				stroke={coords.color}
				key={coords.x+coords.y+coords.color}
				onMouseOver={(event) => this.props.onValueMouseOver(coords, event, this.props.xScale, this.props.yScale)}
				onMouseOut={this.props.onValueMouseOut}
			/>
		);
	}
  
	render() {
		return <g className='jitter-plots'>{this.props.data.map(this.renderCircle.bind(this))}</g>;
	}
}

export default DataCircles;
