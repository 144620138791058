import { css } from 'styled-components';
import Colours from '../../../components/styled/theme/colours';
const { primary } = Colours;

const visualization = css`
  svg {
    text,
    tspan {
      fill: ${primary.dark} !important;
      font-size: 11px;
    }

    .x {
      path,
      line {
        color: #3a424c;
        opacity: 0.3;
      }
    }

    .y {
      path,
      line {
        display: none;
        fill: none;
        stroke: #d4d8da;
        stroke-width: 1px;
        shape-rendering: crispEdges;
      }
    }
  }

  .tooltip {
    display: none;
    background: #fff;
    padding: 6px 12px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: absolute;
    z-index: 9999999;

    .with-img {
      margin-top: 10px;
      padding: 6px;
      img {
        width: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
      }
      color: ${primary.dark};
      font-size: 11px;
    }
    span {
      padding: 4px 6px;
      font-size: 9px;
      color: #aaa;
    }
  }

  .tooltip,
  .leaflet-popup-content {
    .title {
      font-size: 12px;
      color: ${primary.dark};
      font-weight: 600;
      padding: 6px;
    }

    .table {
      border-collapse: collapse;
      border-spacing: 0;
      text-align: left;
      tr {
        font-size: 11px;

        td,
        th {
          padding: 4px 6px;
          &:nth-child(1) {
            color: #aaa;
          }
          &:nth-child(2) {
            color: ${primary.dark};
          }
        }
      }
    }
  }

  .leaflet-popup-content-wrapper {
    border-radius: 4px;

    .leaflet-popup-content {
      margin: 4px;
    }
  }
`;
export default visualization;
