export default labelValue => {
  const sign = labelValue >= 0 ? '': '-';
  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    return sign + Number((Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)) + 'bn';
  } else if (Math.abs(Number(labelValue)) >= 1.0e6) {
    return sign + Number((Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)) + 'm';
  } else if (Math.abs(Number(labelValue)) >= 1.0e3) {
    return sign + Number((Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)) + 'k';
  } else {
    let number = Number(Math.abs(Number(labelValue)))
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      .replace('.00', '');
    number = number.split('.')[0];
    return sign + number;
  }
};
