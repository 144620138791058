import React        from 'react';
import  * as d3           from 'd3';
import DataCircles  from './data-circles';
import XYAxis from './x-y-axis';

class ScatterPlot extends React.Component {
    
	getXScale() {
		const domain = this.props.xDomain || [0,d3.max(this.props.data, (d) => d.x)];
    
		return d3.scaleLinear()
			.domain(domain)
			.range([this.props.padding, (this.props.width - this.props.padding * 2-5)]).nice(5);//5 because of radius of r
	}
  
	getYScale() {
		const domain = this.props.yDomain || [0,this.props.types.length];
		
		return d3.scaleLinear()
			.domain(domain)
			.range([this.props.height-40, 0]);
	}

	// drawLines(yScale) {
	// 	let {types} = this.props;
	// 	return types.map((p,i) => {
	// 		let _height = yScale(i);
	// 		let y=_height-1;//to draw little above the plots
	// 		// if (i!==0)
	// 		// 	return <line key={i} x1="80" y1={y} x2={width} y2={y} style={{stroke:'#f5f5f5', strokeWidth:1}} />;
	// 	});
	// }
  
	render() {
		const xScale = this.getXScale();
		const yScale = this.getYScale();

		return (
			<svg width={this.props.width} height={this.props.height}>
				<DataCircles xScale={xScale} yScale={yScale} {...this.props} />
				<XYAxis xScale={xScale} yScale={yScale} {...this.props} />
				{/* <g>{this.props.horizontalLines && this.drawLines(yScale)}</g> */}
			</svg>
		);
	}
}

export default ScatterPlot;
