import React from 'react';
import PropTypes from 'prop-types';
import Grid from './grid';

const XYGrid = props => {
  const { scales, dimensions } = props;

  const ySettings = {
    scale: scales.y,
    name: 'y-grid',
    translate: 'translate(0, 0)',
    tickSize: dimensions.width,
  };

  return (
    <g className="grid-group">
      <Grid {...ySettings} />
    </g>
  );
};

XYGrid.propTypes = {
    scales: PropTypes.object,
    dimensions: PropTypes.object,
};

export default XYGrid;
