import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import Select2 from 'react-select2-wrapper';
import Select from 'react-select';
import CountryService from '../../services/country/index';
import * as CountryAction from '../../actions/country/index';
import SourceAttribute from '../../services/source/attribute';
import * as sourceAttributeAction from '../../actions/source/attribute';
import SubscribeService from '../../services/subscribe/index';
import { media } from '../../components/styled/utils/';
import Colours from '../../components/styled/theme/colours';
import Loader from '../../components/loader/';
import { getAppImages } from '../../helpers';
import { Helmet } from 'react-helmet';

// import chroma from 'chroma-js';
const { primary, neutral, sec } = Colours;

const { successIcon, errorIcon } = getAppImages;

const customStyles = {
  input: () => ({
    // height: 50,
    overflow: 'hidden'
  }),

  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      border: 0,
      minHeight: isDisabled ? 50 : isFocused ? 48 : 50,
      // boxShadow: 'none',
      boxShadow: isDisabled
        ? '#ccc'
        : isFocused
        ? '0 0 0 1px rgba(9, 215, 203, 0.5)'
        : '0 0 0 1px transparent'
    };
  },

  // control: styles => ({
  //   ...styles,
  //   backgroundColor: 'transparent',
  //   border: 0,
  //   height: 50,
  //   boxShadow: 0
  // }),
  option: provided => ({
    ...provided,
    color: '#3A424C',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: '1px solid #eee'
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#f7f6f9',
    lineHeight: '25px',
    borderRadius: 4,
    border: '1px solid #ccc'
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    opacity: '0.7',
    ':hover': {
      backgroundColor: data.color,
      opacity: 1
      // color: 'white'
    }
  })
};
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      frequency: 'weekly',
      loading: false,
      messageHeader: '',
      operation: '',
      message: '',
      emailError: '',
      errorFirstName: '',
      errorLastName: '',
      status: false,
      isUpdate: false,
      countries: [],
      reportingCompanies: [],
      reportingJurisdiction: [],
      count: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    const { message, status } = this.props;
    const { id } = this.props.match.params;
    if (id) {
      SubscribeService.getUser(id).then(res => {
        if (res.user) {
          let {
            countries,
            reportingCompanies,
            reportingJurisdiction,
            email,
            firstName = '',
            lastName = '',
            frequency
          } = res.user;
          const count =
            countries.length +
            reportingCompanies.length +
            reportingJurisdiction.length;
          countries = countries.map(d => {
            return { value: d, label: d };
          });

          reportingCompanies =
            reportingCompanies &&
            reportingCompanies.map(d => {
              return { value: d, label: d };
            });

          reportingJurisdiction =
            reportingJurisdiction &&
            reportingJurisdiction.map(d => {
              return { value: d, label: d };
            });
          this.setState({
            countries,
            reportingCompanies,
            reportingJurisdiction,
            email,
            firstName,
            lastName,
            frequency,
            isUpdate: true,
            message,
            count,
            messageHeader: status === true ? 'Success!' : 'Error!'
          });
        } else {
          // this.setState({ message });
          this.props.history.push('/subscribe');
        }
      });
    }
    this.props.getCountryList();
    this.props.getSourceAttribute();
  }

  validateEmail() {
    let { email } = this.state;
    let error = 0,
      emailError = '';
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length < 1) {
      emailError = 'Please provide an email address';
      error++;
    } else if (!re.test(email)) {
      console.log(!re.test(email), email);
      emailError = 'Please provide a valid email address';
      error++;
    }

    if (error) {
      this.setState({ loading: false });
    }

    this.setState({ emailError });

    return error === 0;
  }

  _submitHandler() {
    let { email, frequency, isUpdate, firstName, lastName } = this.state;
    let { countries, reportingCompanies, reportingJurisdiction } = this.state;
    countries = countries.map(c => c.value);
    reportingCompanies = reportingCompanies.map(c => c.value);
    reportingJurisdiction = reportingJurisdiction.map(c => c.value);
    const { id } = this.props.match.params;
    this.setState({ loading: true });
    if (firstName === '') {
      this.setState({
        errorFirstName: 'Please enter first name.',
        loading: false
      });
    }
    if (lastName === '') {
      this.setState({
        errorLastName: 'Please enter last name.',
        loading: false
      });
    }
    if (
      this.validateEmail() &&
      !isUpdate &&
      firstName !== '' &&
      lastName !== ''
    ) {
      SubscribeService.subscribe({
        email,
        firstName,
        lastName,
        countries,
        reportingCompanies,
        reportingJurisdiction,
        frequency
      }).then(res => {
        if (res && res.status) {
          // console.log(res);
          this.setState({
            countries: [],
            reportingCompanies: [],
            reportingJurisdiction: [],
            email: '',
            firstName: '',
            lastName: '',
            operation: res.operation,
            message: res.message,
            messageHeader: res.status ? 'Success!' : 'Error!',
            loading: false,
            status: true
          });
        } else {
          this.setState({
            loading: false,
            messageHeader: 'Error!',
            message: 'Oops!!! Something went wrong. Please try again later.',
            status: false
          });
        }
      });
      // console.log('submitted!!!');
    }

    if (isUpdate && firstName !== '' && lastName !== '') {
      SubscribeService.updateSubscribe({
        id,
        email,
        firstName,
        lastName,
        countries,
        reportingCompanies,
        reportingJurisdiction,
        frequency
      }).then(res => {
        if (res && res.status) {
          // console.log(res);

          this.setState({
            countries: [],
            reportingCompanies: [],
            reportingJurisdiction: [],
            loading: false,
            email: '',
            firstName: '',
            lastName: '',
            operation: res.operation,
            message: res.message,
            messageHeader: res.status ? 'Success!' : 'Error!',
            status: true
          });
        } else {
          this.setState({
            loading: false,
            messageHeader: 'Error!',
            message: 'Oops!!! Something went wrong. Please try again later.',
            status: false
          });
        }
      });
    }
    window.scrollTo(0, 0);
  }

  handleChange(selectedOption, type) {
    this.setState({ [type]: selectedOption }, () => {
      const {
        countries,
        reportingJurisdiction,
        reportingCompanies
      } = this.state;
      const count =
        countries.length +
        reportingJurisdiction.length +
        reportingCompanies.length;
      this.setState({ count });
    });
  }
  checkMail(email) {
    if (email.indexOf('@') !== -1 && email.indexOf('.') !== -1) {
      SubscribeService.getMailCheck(email).then(res => {
        if (res.user) {
          let firstName = res.user.firstName || '';
          let lastName = res.user.lastName || '';
          let countriesArray = res.user.countries;
          let reportingCompaniesArray = res.user.reportingCompanies;
          let reportingJurisdictionArray = res.user.reportingJurisdiction;
          countriesArray = countriesArray.map(c => ({ value: c, label: c }));
          reportingCompaniesArray = reportingCompaniesArray.map(c => ({
            value: c,
            label: c
          }));
          reportingJurisdictionArray = reportingJurisdictionArray.map(c => ({
            value: c,
            label: c
          }));
          this.setState({ firstName: firstName });
          this.setState({ lastName: lastName });
          this.setState({ countries: countriesArray });
          this.setState({ reportingCompanies: reportingCompaniesArray });
          this.setState({ reportingJurisdiction: reportingJurisdictionArray });
        } else {
          this.setState({ countries: [] });
          this.setState({ reportingCompanies: [] });
          this.setState({ reportingJurisdiction: [] });
        }
      });
    }
    if (email === '') {
      this.setState({ firstName: '' });
      this.setState({ lastName: '' });
      this.setState({ countries: [] });
      this.setState({ reportingCompanies: [] });
      this.setState({ reportingJurisdiction: [] });
    }
  }

  render() {
    let {
      email,
      firstName,
      lastName,
      emailError,
      errorFirstName,
      errorLastName,
      isUpdate,
      loading,
      message,
      messageHeader,
      operation
    } = this.state;
    let { countryList, sourceAttribute } = this.props;
    let reportingJurisdictionList = sourceAttribute.data.countries;
    let reportingCompaniesList = sourceAttribute.data.reportingCompanies;

    countryList = countryList.map(d => {
      return { value: d.name, label: d.name };
    });

    reportingCompaniesList =
      reportingCompaniesList &&
      reportingCompaniesList.map(d => {
        return { value: d, label: d };
      });

    reportingJurisdictionList =
      reportingJurisdictionList &&
      reportingJurisdictionList.map(d => {
        return { value: d, label: d };
      });

    return (
      <SubscribePage>
        <Helmet>
          <title>{'Setup an Alert'}</title>
          <meta
            name="description"
            content={
              'Interested in getting alerts for extractive sector payment data? Use this section to setup an alert that lands straight into your inbox. We will only send you an email if there is new data that is relevant to your criteria. You will receive no more than one email per week (every Monday).'
            }
          />
          <meta name="title" content={'Setup an Alert'} />
        </Helmet>
        <section className="section__top">
          <div>
            <h1>Setup an Alert</h1>
            <p>
              Interested in getting alerts for extractive sector payment data?
              Use this section to setup an alert that lands straight into your
              inbox. We will only send you an email if there is new data that is
              relevant to your criteria. You will receive no more than one email
              per week (every Monday).
            </p>
          </div>
        </section>
        <section className="section__content">
          <div className="static-page">
            <SubscribeForm>
              {!message ? (
                <div>
                  {loading && <Loader />}
                  <SubscribeBlock>
                    <div>
                      <SubscribeLabel>First Name</SubscribeLabel>
                      <SubscribeInput
                        onChange={e =>
                          this.setState({ firstName: e.target.value })
                        }
                        className="report-field"
                        value={firstName}
                        type="text"
                      />
                      {firstName.length < 1 && errorFirstName !== '' && (
                        <p className="error-text alert alert-danger">
                          {errorFirstName}
                        </p>
                      )}
                    </div>
                    <div>
                      <SubscribeLabel>Last Name</SubscribeLabel>
                      <SubscribeInput
                        onChange={e =>
                          this.setState({ lastName: e.target.value })
                        }
                        className="report-field"
                        value={lastName}
                        type="text"
                      />
                      {lastName.length < 1 && errorLastName !== '' && (
                        <p className="error-text alert alert-danger">
                          {errorLastName}
                        </p>
                      )}
                    </div>
                  </SubscribeBlock>
                  <SubscribeBlock>
                    <div>
                      <SubscribeLabel>Your Email</SubscribeLabel>
                      <SubscribeInput
                        onChange={e => {
                          this.setState({
                            email: e.target.value,
                            emailError: ''
                          });
                          this.checkMail(e.target.value);
                        }}
                        disabled={isUpdate}
                        className="report-field"
                        value={email}
                        type="email"
                      />
                      {emailError !== '' && (
                        <p className="error-text alert alert-danger">
                          {emailError}
                        </p>
                      )}
                    </div>
                    <div className="when_there_is_new_data">
                      <p>
                        We will use this email address to notify you when we add
                        new data that you are interested in tracking.
                      </p>
                    </div>
                  </SubscribeBlock>

                  <SubscribeBlock
                    style={{ alignItems: 'flex-end', marginBottom: 30 }}
                  >
                    <div className="selection-text">
                      <p>
                        Use the dropdowns to filter types of data that you are
                        interested in tracking (not more than 5 values in
                        total). If you would like to track all new extractive
                        revenue data then leave these empty.
                      </p>
                    </div>
                    <div className="count-text">
                      {this.state.count > 4 ? (
                        <div>
                          <strong>{this.state.count}</strong> of 5 selected.
                          <span>(You have selected all 5 variables)</span>
                        </div>
                      ) : this.state.count > 0 ? (
                        <div>
                          <strong>{this.state.count}</strong> of 5 selected.
                          <span>
                            (You can select {5 - this.state.count} more
                            variables)
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </SubscribeBlock>
                  <SubscribeBlock style={{ alignItems: 'initial' }}>
                    <div>
                      <SubscribeLabel>
                        Countries you are interested in
                      </SubscribeLabel>
                      <SubscribeSelect2>
                        <Select
                          styles={customStyles}
                          isMulti
                          inputDisabled
                          value={this.state.countries}
                          onChange={selectedOption =>
                            this.handleChange(selectedOption, 'countries')
                          }
                          options={
                            this.state.count >= 5
                              ? this.state.countries
                              : countryList
                          }
                          placeholder={'All countries'}
                          noOptionsMessage={() =>
                            'You have reached your selection limit'
                          }
                        />
                      </SubscribeSelect2>
                      <SubscribeLabel>
                        Reporting Jurisdictions you are interested in
                      </SubscribeLabel>
                      <SubscribeSelect2>
                        <Select
                          isMulti
                          styles={customStyles}
                          value={this.state.reportingJurisdiction}
                          onChange={selectedOption =>
                            this.handleChange(
                              selectedOption,
                              'reportingJurisdiction'
                            )
                          }
                          options={
                            this.state.count >= 5
                              ? this.state.reportingJurisdiction
                              : reportingJurisdictionList
                          }
                          placeholder={'All reporting Jurisdiction'}
                          noOptionsMessage={() =>
                            'You have reached your selection limit'
                          }
                        />
                      </SubscribeSelect2>
                    </div>
                    <div>
                      <SubscribeLabel>
                        Companies you are interested in
                      </SubscribeLabel>
                      <SubscribeSelect2>
                        <Select
                          styles={customStyles}
                          isMulti
                          value={this.state.reportingCompanies}
                          onChange={selectedOption =>
                            this.handleChange(
                              selectedOption,
                              'reportingCompanies'
                            )
                          }
                          options={
                            this.state.count >= 5
                              ? this.state.reportingCompanies
                              : reportingCompaniesList
                          }
                          placeholder={'All reporting companies'}
                          noOptionsMessage={() =>
                            'You have reached your selection limit'
                          }
                        />
                      </SubscribeSelect2>
                    </div>
                  </SubscribeBlock>

                  <SubscribeButton
                    type="button"
                    onClick={this._submitHandler.bind(this)}
                    className="report-button"
                  >
                    {isUpdate ? 'Update Subscription' : 'Setup'}
                  </SubscribeButton>
                </div>
              ) : (
                <NotifyMessage
                  className={messageHeader === 'Success!' ? 'success' : 'error'}
                >
                  {operation !== 'update' && <h2>{messageHeader}</h2>}
                  <p>{message}</p>
                  <Link to="/" title="Continue" className="btn">
                    Continue
                  </Link>
                </NotifyMessage>
              )}
            </SubscribeForm>
          </div>
        </section>
      </SubscribePage>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.country.countryList,
  sourceAttribute: state.sourceAttribute
  // reportingCompanies: state.reportingCompanies,
  // reportingJurisdiction: state.reportingJurisdiction
});

const mapDispatchToProps = dispatch => ({
  getCountryList: () => {
    CountryService.getCountryList().then(response => {
      dispatch(CountryAction.countryListFetched(response.result));
    });
  },

  getSourceAttribute: filter => {
    const reportAttributeService = SourceAttribute;
    const reportAttributeAction = sourceAttributeAction;
    dispatch(reportAttributeAction.fetching());

    reportAttributeService
      .list(filter)
      .then(response => {
        if (response.status) {
          dispatch(reportAttributeAction.fetched(response.body));
        } else {
          dispatch(reportAttributeAction.error(response.body));
        }
      })
      .catch(error => {
        dispatch(reportAttributeAction.error(error));
      });
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription);

const SubscribePage = styled.div`
  .section__top {
    padding: 50px 0;
    ${media.sm`padding: 50px 0px 280px;`}
    > div {
      position: relative;
      max-width: 640px;
      text-align: center;
      z-index: 100;
      margin: 35px auto 0px;
    }
    h1 {
      font-size: 24px;
      line-height: 1.4;
      color: ${props => props.theme.neutral.darkWhite};
      font-weight: 700;
      margin-bottom: 18px;
      padding: 0 20px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      color: ${props => props.theme.neutral.darkWhite};
      margin-bottom: 60px;
      padding: 0 20px;
    }
    strong {
      font-weight: bold;
    }
  }
  .section__content {
    background-color: ${props => props.theme.neutral.darkWhite};
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      max-width: 768px;
      max-height: 600px;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: rgba(0, 0, 0, 0.2) 0px -34px 50px -10px;
      z-index: 25;
      background-image: linear-gradient(-180deg, #ffffff 0%, #eceaf1 100%);
      ${media.sm`margin-top: -110px; border-radius: 12px;`}
    }
  }
  .static-page {
    position: relative;
    max-width: 768px;
    z-index: 100;
    min-height: 400px;
    padding: 8px 0 54px;
    ${media.sm`margin: -120px auto 0px; padding: 16px 0 0;`}
    > div {
      ${media.sm`margin-top: -126px;`}
    }
  }
  .alert-danger {
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    background: rgb(255, 128, 145);
    border: 0;
    padding: 8px 10px;
  }
  input + .alert-danger {
    margin-top: -14px;
  }
`;
const SubscribeForm = styled.div`
  background-color: ${props => primary.white};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 40px 20px;

  ${media.sm`
  padding: 64px 40px 84px;
 
  .alert {
    width: 90%;
  }
  `};
`;

const SubscribeLabel = styled.label`
  color: rgba(51, 51, 51, 0.7);
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
  .required {
    color: ${props => sec.red};
    font-size: 12px;
    margin-left: 2px;
  }
`;

const SubscribeInput = styled.input`
  padding: 14px 12px;
  font-size: 12px;
  background: ${neutral.darkWhite};
  border: 1px solid #b0b6be;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 20px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  ${media.sm`
  width: 90%;
  `} &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
`;

const SubscribeSelect2 = styled.div`
  min-height: 50px;
  font-size: 12px;
  line-height: 50px;
  background: ${neutral.darkWhite};
  border: 1px solid #b0b6be;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 30px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  > div > div {
    display: flex;
  }
  ${media.sm`
  width: 90%;
  `} &:focus {
    outline: none;
    border-color: rgba(9, 215, 203, 0.5);
  }
  .select2-container {
    width: 100% !important;
  }
  .select2-container--default .select2-selection--multiple,
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    background-color: transparent;
    border: 0;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    border-radius: 14px;
    height: 30px;
    line-height: 30px;
  }
  > div {
    div:focus {
      box-shadow: none;
      outline: 0;
    }
    > div:first-child > div:first-child {
      padding: 0 8px !important;
      /* height: 50px;
      overflow: scroll; */
    }
  }
`;

const SubscribeBlock = styled.div`
  justify-content: space-between;
  margin-bottom: 10px;
  @media all and (min-width: 700px) {
    display: flex;
    > div {
      width: 48%;
    }
  }
  > div {
    ${media.sm` width: 50%`}
  }
  strong {
    font-weight: bold;
  }
  .selection-text {
    margin-bottom: 10px;
    @media all and (min-width: 700px) {
      margin-bottom: 0;
    }
    p {
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      font-size: 14px;
      width: 90%;
    }
  }
  .when_there_is_new_data {
    p {
      margin-top: -10px;
      margin-bottom: 10px;
      color: rgba(51, 51, 51, 0.7);
      line-height: 20px;
      font-size: 14px;
      width: 90%;
      @media all and (min-width: 700px) {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
  .count-text {
    > div {
      background: #eee;
      border-radius: 20px;
      padding: 10px 20px;
      width: 90%;
    }
    span {
      color: #777;
      font-size: 13px;
      font-style: italic;
      padding-left: 2px;
    }
  }
  &:nth-child(2) {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
`;

const SubscribeButton = styled.button`
  background: linear-gradient(rgb(67, 223, 253), rgb(32, 188, 251));
  border: 0;
  border-radius: 26px;
  color: ${props => primary.white};
  font-size: 14px;
  cursor: pointer;
  padding: 15px 24px;
  min-width: 130px;
  &:hover {
    background: linear-gradient(rgb(67, 223, 253), rgb(37, 159, 208));
  }
  &:focus {
    outline: 0;
  }
`;

const NotifyMessage = styled.div`
  text-align: center;
  h2 {
    color: #33de33;
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 100px;
    &:before {
      background: url(${successIcon}) no-repeat center/70%;
      content: '';
      left: 50%;
      height: 100px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100px;
    }
  }
  p {
    color: rgba(51, 51, 51, 0.5);
    font-size: 16px;
    line-height: 25px;
    max-width: 400px;
    margin: 0 auto 20px;
  }
  &.error {
    h2 {
      color: #d80027;
      &:before {
        background-image: url(${errorIcon});
      }
    }
  }
  .btn {
    background: linear-gradient(rgb(67, 223, 253), rgb(32, 188, 251));
    border: 0;
    border-radius: 26px;
    color: ${props => primary.white};
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    padding: 15px 24px;
    width: 150px;
    &:hover {
      background: linear-gradient(rgb(67, 223, 253), rgb(37, 159, 208));
    }
    &:focus {
      outline: 0;
    }
  }
`;
