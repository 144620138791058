import React from 'react';

export default ({ d, xScale, yScale, selectedYear }) => (
  <circle
    key={d.year}
    r={selectedYear === d.year ? 4 : 2}
    fill={d.value >= 0 ? 'black': 'red'}
    stroke="#fff"
    strokeWidth={1.5}
    cx={xScale(d.year)}
    cy={yScale(d.value)}
  />
);
