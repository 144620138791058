const initialState = {
  query: '',
  countries: '',
  reportingCompanies: '',
  paymentTypes: '',
  reportingJurisdiction: '',
  years: '',
  projectTypes: '',
  entityTypes: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_FILTER':
      //reset filter if null
      payload = payload || initialState;

      state = greedyAssign(state, payload);
      break;
    default:
      break;
  }
  return state;
};

function greedyAssign(initial, payload) {
  let temp = {};
  for (let key in initial) {
    if (initial[key] instanceof Object) {
      temp[key] =
        payload[key] == null
          ? initial[key]
          : greedyAssign(initial[key], payload[key]);
    } else {
      temp[key] = payload[key] == null ? initial[key] : payload[key];
    }
  }
  return temp;
}

export default reducer;
