import React from 'react';
import { connect } from 'react-redux';
import * as ProjectActions from '../../actions/project/index';

let debounceCountryName;

class CountryStrokeLayer extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.parentWidth !== nextProps.parentWidth;
  }
  render() {
    const { name, path } = this.props;
    return (
      <g className={name}>
        {this.props.countriesGeojson.features.map((d, key) => {
          if (name === 'first-stroke-layer') {
            return (
              <path
                d={path(d)}
                onMouseMove={() => this.onMouseEnter()}
                key={key}
              />
            );
          }
          return <path d={path(d)} key={key} />;
        })}
      </g>
    );
  }
  onMouseEnter() {
    const { mouseEventDelay } = this.props;
    // send 'all' when no country hovered
    clearTimeout(debounceCountryName);
    debounceCountryName = setTimeout(() => {
      this.props.dispatchSelectedCountry('all');
    }, mouseEventDelay.onAllCountrySelect);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSelectedCountry: country => {
      dispatch(ProjectActions.selectedCountry(country));
    }
  };
};
export default connect(null, mapDispatchToProps)(CountryStrokeLayer);
