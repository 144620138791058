import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components/styled/ui/';
import { flex, rgba } from '../../../components/styled/utils/';
import { connect } from 'react-redux';
import Select2Component from '../select-2/select-2';
import * as CountryAction from '../../../actions/country';

class PaymentFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOpen: false,
      selectedYears: ['all'],
      windowWidth: 0
    };

    this.toggleFilterOptions = this.toggleFilterOptions.bind(this);
    this.renderFilterIcon = this.renderFilterIcon.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  toggleFilterOptions() {
    this.setState({
      filterOpen: !this.state.filterOpen
    });
  }

  handleYearChange(years) {
    if (JSON.stringify(years) !== JSON.stringify(this.state.selectedYears)) {
      this.setState({
        selectedYears: years
      });

      if (this.props.for === 'sankey') this.props.yearFilter(years);
      else this.props.onGlobalFilterChange({ filters: { years: years } });
    }
  }

  renderFilterIcon() {
    return (
      <FilterIcon onClick={this.toggleFilterOptions}>
        <div className="filter">
          <a
            className={
              this.state.filterOpen ? 'hamburger-icon active' : 'hamburger-icon'
            }
            title="Menu"
          >
            <span className="line line-1" />
            <span className="line line-2" />
            <span className="line line-3" />
            <span className="line line-4" />
          </a>
        </div>
        <Text.span size={10} uppercase bold className="label">
          Filters
        </Text.span>
      </FilterIcon>
    );
  }

  renderFilters() {
    let value = [];
    let years = this.props.years;
    // console.log(years);

    if (this.state.selectedYears.indexOf('all') === -1) {
      this.state.selectedYears.forEach(year => {
        value.push(year);
      });
    }

    value = value.length > 0 ? value : null;

    return (
      <FilterOptions>
        <div
          className={
            this.state.filterOpen ? 'filter-options is-open' : 'filter-options'
          }
        >
          <Select2Component
            reference="startYear"
            data={years}
            value={value}
            onSelectChange={this.handleYearChange}
            placeholder={'All years'}
          />
        </div>
      </FilterOptions>
    );
  }

  render() {
    return (
      <Filters>
        {/* {this.renderFilterIcon()} */}
        {this.renderFilters()}
      </Filters>
    );
  }
}

const mapStateToProps = state => ({ years: state.countryStats.years });

const mapDispatchToProps = dispatch => ({
  yearFilter: selectedYears => {
    dispatch(CountryAction.setSelectedYears(selectedYears));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFilter);

const Filters = styled.div`
  display: inline-block !important;
  position: relative;
  /* margin-top: 10px; */
  .is-hidden {
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
`;

const FilterIcon = styled.div`
  display: none;
  @media screen and (min-width: 1340px) {
    position: absolute;
    left: -74px;
    display: block;
    top: 28px;
    ${flex('column', 'center', 'center')};
  }

  .filter {
    background-color: #c6cce0;
    display: inline-block;
    padding: 9px 7px;
    border-radius: 50%;
    margin-bottom: 2px;
    & + .label {
      font-weight: bold;
    }
  }

  .hamburger-icon {
    width: 18px;
    height: 10px;
    position: relative;
    display: inline-block;
    .line {
      display: block;
      background: #3a424c;
      width: 18px;
      height: 2px;
      position: absolute;
      left: 0;
      border-radius: 1px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      -moz-transition: all 0.4s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
        width: 12px;
      }
      &.line-3 {
        top: 100%;
        width: 4px;
      }
      &.line-4 {
        top: 100%;
        width: 18px;
        opacity: 0;
      }
    }
    &:hover .line-1,
    &:focus .line-1 {
      transform: translateY(-1px);
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
    }
    &:hover .line-3,
    &:focus .line-3 {
      transform: translateY(1px);
      -webkit-transform: translateY(1px);
      -moz-transform: translateY(1px);
    }
    &.active {
      .line-1 {
        transform: translateY(5px) translateX(0) rotate(45deg);
        -webkit-transform: translateY(5px) translateX(0) rotate(45deg);
        -moz-transform: translateY(5px) translateX(0) rotate(45deg);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        opacity: 0;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
      .line-4 {
        opacity: 1;
        transform: translateY(-5px) translateX(0) rotate(-45deg);
        -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
        -moz-transform: translateY(-5px) translateX(0) rotate(-45deg);
      }
    }
  }

  .icon {
    ${flex('column', 'center', 'center')};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: ${props => rgba(props.theme.primary.white, 0.7)};
    margin-bottom: 4px;
    padding: 8px;
    transition: ${props => props.theme.transition.base};
  }

  .label {
    color: ${props => rgba(props.theme.primary.white, 0.35)};
    transition: ${props => props.theme.transition.base};
  }

  cursor: pointer;

  &:hover {
    .icon {
      background: ${props => props.theme.primary.white};
    }
    .label {
      color: ${props => props.theme.primary.white};
    }
  }
`;

const FilterOptions = styled.div`
  margin-bottom: 3px;
  margin-left: 5px;

  .filter-options {
    opacity: 1;
    position: relative;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    .select2-container {
      width: auto !important;
      min-width: 142px;
      max-width: 300px;
      .select2-search--inline {
        color: #fff;
        width: 100%;
        &:after {
          border-top: 3px solid #fff;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          content: '';
          position: absolute;
          right: 14px;
          top: 12px;
        }
        .select2-search__field {
          cursor: pointer !important;
        }
      }
      .select2-selection--multiple {
        min-height: 26px;
      }
    }
    .select2-container--default .select2-selection--multiple {
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
      cursor: pointer;
      margin-bottom: 2px;
    }

    .select2-container--default .select2-search--inline .select2-search__field {
      color: #fff;
      font-weight: 600;
      margin-top: 0;
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
      }
    }
  }

  /* @media screen and (min-width: 1340px) {
    position: absolute;
    left: -176px;
    top: 84px;

    .filter-options {
      opacity: 0;
      transform: translateY(-4px);

      &.is-open {
        transform: translateY(0);
        opacity: 1;
      }
    }
  } */
`;
