import { css } from 'styled-components';
import Colours from '../../../theme/colours';
import { getAppImages } from '../../../helpers';
import { media } from '../../../components/styled/utils/';

const { DownloadIcon } = getAppImages;

const { primary } = Colours;

const base = css`

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800');

  *{
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif!important;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
        -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(-173deg, #441354 15%, #1167D1 100%);
    
    // color: #333;
    // linear-gradient(-173deg, #393546, #367CD0)
    

    &.open{
      @media (max-width: 992px){
        overflow: hidden;
      }
    }

    &.no-scroll{
      overflow: hidden;
    }

    @media (max-width:568px){
      font-size: 12px;
      line-height: 18px
    }
  }

  input,button,textarea{
    font-family: 'Open Sans', sans-serif!important;
  }

  a{
    text-decoration: none;
  }

  .sticky-holder{
    position: relative;
    z-index: 10000;
  }

 

  .custom-checkbox{
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      border: 1px solid ${primary.primary};
      flex: 0 0 12px;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      top: -1px;
      z-index: 10;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0 0 15px rgba(9,215,203,0);
      margin-right: 6px;
      margin-left: 3px;

    &:after{
        content: '';
        display: block;
        height: 2px;
        width: 6px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-color: ${primary.white};
        position: absolute;
        top: 5%;
        left: 50%;
        transform: scale(0) rotate(-45deg) translate(-50%, -50%);
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
  }

  .primary-btn{
      vertical-align: top;
      background: linear-gradient(#fff, #E8F3F8);
      padding: 0 12px;
      border: 1px solid #D8E1E6;
      border-radius: 20px;
      font-weight: bold;
      color: #333;
      cursor: pointer;
      font-size: 12px;
      line-height: 28px;
      transition: all 0.1s ease-in-out;

      &:focus{
          outline: none;
      }

      &:hover{
          background: linear-gradient(#fff, #cedfe7)
      }

      &:active{
          opacity: 0.6;
      }
  }

  .scrollarea .scrollbar-container{
    width: 4px!important;
    background: #f4ebf7 !important;
    opacity: 1!important;
    border-radius: 3px!important;
    z-index: 100 !important;
  }

  .scrollbar{
    width: 4px!important;
    background:  #4c3154 !important;
    border-radius: 3px!important;
    margin-left: 0!important;
  }
  .filter-reset-btn{
    margin-top: 12px;
  }

  .download-icon{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    width: 24px;
    height: 24px;
    background: url('${DownloadIcon}') no-repeat center center;
    background-size: contain;
    margin-right: 6px;
  }

  .project-payment-table{
    .column-date{
      width: 8%;

      @media (max-width: 1400px){
        width: 8%;
      }

      @media (max-width: 1200px){
        width: 10%;
      }

      @media (max-width: 992px){
        width: 11%;
      }
    }

    .column-value{
        width: 8%;

        @media (max-width: 1400px){
          width: 10%;
        }

        @media (max-width: 1200px){
          width: 12%;
        }

        @media (max-width: 992px){
          width: 12%;
        }
    }
  }

  .rv-discrete-color-legend-item__title{
   	display: inline-block !important;
  }

  .scrollarea.sankey-options {
  max-height: 62vh;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  height: 204px;
  left: 50%;
  top: 10%;
  width: 204px;
  transform:translate(-50%,-50%);
  & + div {
    padding-top: 400px;
  }
  @media all and (min-width: 992px) {
    top: 50%;
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 75px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.column--chart {
    margin-top: 60px;
    position: relative;
    padding-bottom: 40px;
    max-width: 700px;
    svg g {
      cursor: default!important;
    }
    @media (min-width: 992px) {
      margin-top: 10px;
    }
    .bubble {
      max-height: 475px;
      @media (min-width: 500px) and (max-width: 600px) {
        width: 90%;
      }
      @media (min-width: 601px) and (max-width: 767px) {
        width: 72%;
      }
      @media (min-width: 768px) and (max-width: 900px) {
        width: 62%;
      }
      @media (min-width: 850px) and (max-width: 992px) {
        margin-bottom: 20px;
      }
      @media (min-width: 900px) and (max-width: 992px) { 
        width: 60%;
        margin-bottom: 40px;
      }
      @media all and (min-width: 992px) {
        height: 400px;
        margin: -10px auto 0;
        width: 440px;
      }
      @media all and (min-width:500px) and (min-height: 800px) {
        width: 470px;
        height: 430px;
      }
    }
}

.alert-danger {
  background: #ff8091;
  margin-bottom: 20px;
  padding: 8px 10px;
  color: #fff;
}
.alert-success {
  background: #3c763d;
  margin-bottom: 20px;
  padding: 8px 10px;
  color: #fff;
}

svg {
  will-change: transform;
}

.sentry-error-embed h2 {
  line-height: 50px;
}

.sentry-error-embed .form-submit .close {
    padding: 14px!important;
    border: 1px solid #aaa;
    &:hover {
      background: #ddd;
      text-decoration:none!important;
    }
}

.sentry-error-embed .message-error {
    margin-bottom: 20px!important;
    padding: 12px!important;
    font-size: 13px!important;
}

.source-text {
  align-items: flex-start;
  background-color: rgba(241, 244, 253, 0.8);
  display: flex;
  padding: 10px 20px;
  p {
  span {
    color: #3A424C;
    font-size: 11px;
    font-weight: 400;
    &:nth-child(2):hover {
      color: #3A424C!important;
    }
  }
}
}

.btn-subscribe {
  background-color: #20bcfb;
  border: 0;
  border-radius: 23px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  width: 100%;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #259fd0;
  }
}

.static-page.Analysis {
  padding-top: 50px;
  @media all and (min-width: 600px) and (max-width: 767px) {
    padding-top: 20px;
  }
  ${media.sm`padding-left: 18px; padding-right: 18px`}
  > div {
    margin-top: -100px;
  }
  @media all and (min-width:768px) {
    > div {
      margin-top: -170px;
    }    
  }
  .pagination {
    li a.prev,
    li a.next {
      color: #3A424C;
    }

    li:not(.previous):not(.next) > a {
      border-color:  #dbdade;
    }   
  }

  @media all and (max-width: 640px) {
    .pagination li a,
    .pagination li span {
      color: inherit !important;
    }
  }
  ul li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}


#country-stats {
  max-width: 568px;
  padding: 5px 10px 40px!important;
}

#country-stats-legend  {
  max-width: 568px;
  padding: 5px 10px 0;
}

#top-ten-entity,#top-ten-project {
  padding-bottom: 40px;
}

#sankey,#project-type,#type {
  padding-bottom: 14px;
}

 #jitter,#company-details {
   padding-bottom: 20px;
 }

 .select2-results__option[aria-selected=true]:before {
  content: url("../../../../images/ic_checked.svg")!important;
 }

 `;

export default base;
