import React, { Component } from 'react';
import styled from 'styled-components';
import * as ProjectActions from '../../actions/project/index';
import { PageSwitcher } from '../../components/layouts/';
import { Container, DownloadButton } from '../../components/styled/ui/';
import DataTable from './tabs/data';
import Stats from './stats/stats';
import { flex, media } from '../../components/styled/utils';
import { API_URL } from '../../helpers';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { Helmet } from 'react-helmet';

// import Colours from '../../components/styled/theme/colours';
// const { primary } = Colours;

class Data extends Component {
  constructor(props) {
    super(props);
    this.onYearFilterChange = this.onYearFilterChange.bind(this);
  }

  componentDidMount() {
    this.onYearFilterChange({ years: ['all'] });
  }

  onYearFilterChange(years) {
    this.props.dispatchYearsFilter(years);
  }

  getSelectedTab() {
    let search = this.props.location.search;
    if (search === '') {
      return 0;
    }
    let query = querystring.parse(this.props.location.search.replace('?', ''));
    return typeof query === 'object' && 'tab' in query ? query.tab : 0;
  }

  _downloadAllData(e, pageName) {
    e.preventDefault();
    let { filter } = this.props;
    let { sortBy, order } = this.props[pageName.name];
    window.open(
      API_URL +
        pageName.path +
        '?currentPage=&perPage=&' +
        querystring.stringify(filter) +
        '&sortBy=' +
        sortBy +
        '&order=' +
        order +
        '&download=1',
      '_blank'
    );
  }

  getDatasetSchema(component) {
    let url;
    if(component === 'Project') {
      url = 'https://resourceprojects.org/projects?tab=0&';
    } else if(component === 'Agency') {
      url = 'https://resourceprojects.org/entities?tab=0&';
    } else if(component === 'Source') {
      url = 'https://resourceprojects.org/sources?tab=0&';
    }
    return {
      '@context': 'http://schema.org',
      '@type': 'Dataset',
      name: 'Resource Projects',
      author: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute'
      },
      description:
        'Explore payments made by companies for extracting oil, gas and mining resources around the world.',
      url: url,
      keywords: [
        'MANDATORY DISCLOSURE > PAYMENTS',
        'PAYMENTS TO GOVERNMENT',
        'PROJECT LEVEL PAYMENTS',
        'EXTRACTIVE SECTOR PAYMENTS',
        'MINING AND OIL GAS PAYMENTS'
      ],
      creator: {
        '@type': 'Organization',
        name: 'Natural Resource Governance Institute',
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'public engagement',
          email: 'admin@resourceprojects.org',
          url: 'https://resourcegovernance.org/'
        }
      },
      distribution: [
        {
          '@type': 'DataDownload',
          encodingFormat: 'application/zip',
          contentUrl: url
        }
      ],
      temporalCoverage: '2014-01-01/..',
      spatialCoverage: {
        '@type': 'Place',
        name: 'Worldwide'
      }
    };
  }

  render() {
    let tabIndex = parseInt(this.getSelectedTab());
    const pageSwitchItem = [
      { label: 'Project Payments', value: 'projects' },
      { label: 'Gov\'t Entity Payments', value: 'entities' },
      { label: 'Sources', value: 'sources' }
    ];
    const fullTitle = {
      Project: 'Project Payments',
      Agency: 'Gov\'t Entity Payments',
      Source: 'Sources'
    };
    let data = '',
      component = '',
      pageName = {};
    if (this.props.match.path === '/projects') {
      data = this.props.project;
      component = 'Project';
      pageName = { path: 'projects', name: 'project' };
    } else if (this.props.match.path === '/sources') {
      data = this.props.source;
      component = 'Source';
      pageName = { path: 'sources', name: 'source' };
    } else {
      data = this.props.entity;
      component = 'Entity';
      pageName = { path: 'entities', name: 'entity' };
    }
    return (
      <PageWrap>
        <Helmet>
          <title>{fullTitle[component]}</title>
          <meta
            name="description"
            content={
              'Explore payments made by companies for extracting oil, gas and mining resources around the world.'
            }
          />
          <meta
            name="title"
            content={`${fullTitle[component]} - Resource Projects : An open-source data repository on oil, gas and mining projects`}
          />
          <script type="application/ld+json">
            {JSON.stringify(this.getDatasetSchema(component))}
          </script>
        </Helmet>
        <Container>
          <TopBar className="top-bar">
            <PageSwitcher
              selected={this.props.title}
              items={pageSwitchItem}
              tabIndex={tabIndex}
            />
            <TopRightBlock
              className={component === 'Source' ? 'source-top-right-block' : ''}
            >
              <Stats component={component} project={data} />
              <div style={{ display: 'flex' }}>
                <DownloadButton
                  to="#"
                  target="_blank"
                  className="download-button"
                  onClick={e => this._downloadAllData(e, pageName)}
                >
                  <span className="download-icon" />
                  <div>
                    <span> Download</span>
                    <span>Payment Data</span>
                  </div>
                </DownloadButton>
                {/* {component === 'Source' && (
                  <div style={{ marginLeft: 12, marginBottom: 0 }}>
                    <AddSourceButton
                      onClick={() =>
                        this.props.history.push('/sources/add-source')
                      }
                      className="add-source"
                    >
                      Submit New Report
                    </AddSourceButton>
                  </div>
                )} */}
              </div>
            </TopRightBlock>
          </TopBar>

          {this.props.match.path === '/sources' ? (
            <DataTable component="source" />
          ) : (
            <DataTable
              component={
                this.props.match.path === '/projects' ? 'project' : 'entity'
              }
            />
          )}
        </Container>
      </PageWrap>
    );
  }
}
const mapStateToProps = state => ({
  project: state.project,
  entity: state.entity,
  source: state.source,
  filter: state.filter
});
const mapDispatchToProps = dispatch => {
  return {
    dispatchYearsFilter: years => {
      dispatch(ProjectActions.yearsFilter(years.years));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Data);

const PageWrap = styled.div``;

/*--------------------------------------------------------------------*\
  Top bar with Page switcher, payment stats and download button
\*---------------------------------------------------------------------*/

const TopBar = styled.div`
  ${flex('column', null, 'flex-start')};
  width: 100%;
  margin-bottom: 36px;

  ${media.sm`
  	  ${flex('row', 'flex-start', 'center')};
  `};

  > span,
  > ul {
    margin-bottom: 12px;
    ${media.sm`
      margin-bottom: 0;
    `};
  }

  > div {
    margin-bottom: 12px;

    > div,
    > ul {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .download-button {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 21px;
    line-height: 13px;
    padding: 6px 14px 6px 8px;
    ${media.sm`
  		margin-left: auto;
  	`};
  }
`;

// const AddSourceButton = styled.button`
//   background: linear-gradient(rgb(67, 223, 253), rgb(32, 188, 251));
//   border: 0;
//   border-radius: 20px;
//   color: ${props => primary.white};
//   cursor: pointer;
//   font-size: 13px;
//   padding: 10px 20px;
//   &:hover {
//     background: linear-gradient(rgb(67, 223, 253), rgb(37, 159, 208));
//   }
//   &:focus {
//     outline: 0;
//   }
// `;

const TopRightBlock = styled.div`
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 100%;
  &.source-top-right-block {
    align-items: baseline;
    flex-direction: column;
    > div {
      margin-top: 12px;
    }
    @media all and (min-width: 500px) {
      align-items: center;
      flex-direction: row;
      > div {
        margin-bottom: 0;
      }
    }
  }
  > div {
    margin-bottom: 0 !important;
    @media all and (min-width: 500px) {
      align-items: center;
      display: flex !important;

      justify-content: space-between;
    }
  }
`;
