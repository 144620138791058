import React, { Component } from 'react';
// import moment from 'moment';
import { TableWrapper, Table, TH } from '../styled';
// import { Pagination } from '../../../components/layouts';
// import iso2ToCountryCode from '../../../helpers/iso2-countries-code';
import { getAppImages } from '../../helpers';
import styled from 'styled-components';
import ScrollBar from 'react-scrollbar/dist/no-css';
import { Sort } from '../layouts/filters';
import { Link } from 'react-router-dom';
import numberFormatter from '../../utils/numberFormatter';
import Sparkline from '../../screens/company/sparkline/sparkline';
import TableBody from './tableBody';
import { Loading } from '../../components/layouts/filters';

const { NoteIcon, clickIcon } = getAppImages;

// const dataPerPage = 10;

class CompanyTable extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
      // sortBy: 'payment',
      order: 'asc',
      showSubTable: false,
      renderOnboarding: false
    };
  }

  tableHeader(type, profile) {
    const { order } = this.state;
    const { sortBy } = this.props;
    return (
      <thead>
        <tr>
          <TH width="40%">
            <Sort
              onClickHandler={() => this.onSort(`${type}Name`)}
              showOrder={sortBy === `${type}Name`}
              order={order}
            >
              {`${type} name`}
            </Sort>
          </TH>
          {profile === 'company' ? (
            <TH width="20%">
              <Sort
                onClickHandler={() => this.onSort(`${type}Country`)}
                showOrder={sortBy === `${type}Country`}
                order={order}
              >
                {type} Country
              </Sort>
            </TH>
          ) : (
            <TH width="20%">
              <Sort
                onClickHandler={() => this.onSort('reportingCompany')}
                showOrder={sortBy === 'reportingCompany'}
                order={order}
              >
                reporting company
              </Sort>
            </TH>
          )}
          <TH width="20%">
            <Sort
              onClickHandler={() => this.onSort('type')}
              showOrder={sortBy === 'type'}
              order={order}
            >
              {type} Type
            </Sort>
          </TH>

          <TH width="20%">
            <Sort
              onClickHandler={() => this.onSort('lastReported')}
              showOrder={sortBy === 'lastReported'}
              order={order}
            >
              Last Reported
            </Sort>
          </TH>
          <TH width="22%">
            <Sort
              onClickHandler={() => this.onSort('payment')}
              showOrder={sortBy === 'payment'}
              order={order}
            >
              Payments (USD)
            </Sort>
          </TH>
          <TH width="1px" />
        </tr>
      </thead>
    );
  }

  handlePagination({ selected }) {
    this.setState({
      currentPage: selected + 1
    });
  }

  onSort = sortBy => {
    const { order } = this.state;
    const newOrder = order.toLowerCase() === 'asc' ? 'desc' : 'asc';
    this.setState({ order: newOrder });
    this.props.onSort(sortBy, newOrder);
  };

  renderOnboarding = component => {
    const onboardingRenderCount =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('filter-payment-onBoarding-render')
        : null;
    if (onboardingRenderCount && onboardingRenderCount >= 5) {
      return null;
    }
    return (
      !this.state.onboardingHide && (
        <Onboarding className="content bounce">
          <span className="caret" />
          <p className="text">
            <img src={clickIcon} alt="Click" />{' '}
            {`on a row to view payments by year and type for a ${component}.`}
          </p>
          <button
            className="btn__got-it"
            onClick={() => {
              this.setState({ onboardingHide: true });
              localStorage.setItem(
                'filter-payment-onBoarding-render',
                onboardingRenderCount ? parseInt(onboardingRenderCount) + 1 : 1
              );
            }}
          >
            Got it
          </button>
        </Onboarding>
      )
    );
  };

  render() {
    const { data, type, fetched, profile, selectedPaymentType } = this.props;

    return (
      <CompanyTableWrap>
        <span className="tooltip-content" id="topToolTip"></span>
        <TableWrapper>
          <Table>{this.tableHeader(type, profile)}</Table>
          {/* <ScrollBar
            className="options"
            contentClassName="content"
            smoothScrolling={true}
            minScrollSize={30}
          > */}
          <Table>
            <tbody>
              {!fetched ? (
                <tr>
                  <Loading />
                </tr>
              ) : (
                data.map((row, i) => (
                  <TableBody
                    key={i}
                    row={row}
                    type={type}
                    i={i}
                    profile={profile}
                    selectedPaymentType={selectedPaymentType}
                    hideOnboarding={() =>
                      this.setState({ onboardingHide: true })
                    }
                  />
                ))
              )}
            </tbody>
          </Table>
          {/* </ScrollBar> */}
          {this.renderOnboarding(type)}
        </TableWrapper>
      </CompanyTableWrap>
    );
  }
}
export default CompanyTable;

const CompanyTableWrap = styled.div`
  > div {
    display: block;
  }
  .tooltip-content {
    display: none;
    background: #fff;
    padding: 6px 12px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #3A424C;
    font-size: 12px;
    font-weight: normal;
    max-width: 300px;
    position: absolute;
    white-space: pre-wrap;
    z-index: 9999999;
  }
  table tr {
    td:first-child,
    td:nth-last-child(2) {
      color: ${props => props.theme.primary.dark};
      font-weight: 700;
    }
    td:last-child {
      padding-left: 6px;
    }
    .flag {
      margin-right: 7px;
      /* vertical-align: middle; */
    }
    .content {
      /* max-width: 162px; */
      vertical-align: middle;
    }
	/* .note-icon{
		display: inline-block;
		vertical-align: top;
		height: 16px;
		width: 12px;
		transition: all 0.2s ease-in-out;
		background: url('${NoteIcon}') no-repeat center center;
		opacity: 0.9;
    text-indent: -9999px;
		&:hover{
			opacity: 1;
			box-shadow: 0 1px 6px 0 rgba(0,0,0,0.5); 
		}
	} */
  th {
    padding-bottom: 10px;
  }
  }
  .scrollarea {
    background-color: ${props => props.theme.primary.white};
    border-radius: 4px;
    box-shadow: 0px 25px 40px -20px rgba(0, 0, 0, 0.5);
    max-height: 900px;
    width: 100%;
    .scrollbar-container.vertical {
      height: calc(100% - 10px);
      right: 5px;
      top: 5px;
    }
  }
`;

const Onboarding = styled.div`
  background-color: #333;
  border-radius: 4px;
  padding: 10px 12px 11px;
  width: 200px;
  position: absolute;
  left: -2%;
  z-index: 1;
  top: -70px;

  @media all and (min-width: 1400px) {
    padding: 18px 18px 12px;
    left: -222px;
    top: 10px;
  }
  &.bounce {
    animation-name: bounce;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:after {
      border-radius: 4px;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
  img {
    margin-right: 2px;
    vertical-align: bottom;
    width: 18px;
  }
  .text {
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    text-align: left;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
  }
  .btn__got-it {
    background-color: rgba(255, 255, 255, 0.1);
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: bold;
    float: right;
    margin-top: 5px;
    padding: 2px 9px;
    transition: background-color 0.2s ease-in-out;
    @media all and (min-width: 480px) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:focus {
      outline: 0;
    }
  }
  .caret {
    height: 0;
    width: 0;
    position: absolute;
    bottom: -10px;
    right: 134px;
    /* right: 164px; */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #333;
    @media all and (min-width: 1400px) {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #333;
      border-right: 0;
      top: 42px;
      right: -13px;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
